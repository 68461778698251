import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { AppBar, Toolbar, IconButton, TextField, Box, Menu, MenuItem, Avatar, Typography, Divider } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import PaymentIcon from '@mui/icons-material/Payment';
import LogoutIcon from '@mui/icons-material/Logout';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import HomeIcon from '@mui/icons-material/Home';
import SchoolIcon from '@mui/icons-material/School';
import Logo from '../Logo/Dark/Full - Copy/1Asset 17@72x-8.png';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import verifiedIcon from './verified_user.png';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import BookIcon from '@mui/icons-material/Book';
import DescriptionIcon from '@mui/icons-material/Description';

const API_URL = process.env.REACT_APP_API_URL;

function Header1({ userId, onLogout, page_name, onSearch }) {
    const [searchQuery, setSearchQuery] = useState('');
    const [anchorEl, setAnchorEl] = useState(null);
    const dropdownRef = useRef();
    const [isExpanded, setIsExpanded] = useState(false);
    const [isSearchActive, setIsSearchActive] = useState(false); // Tracks search icon active state for small screens

    const handleSearchFocus = () => {
        setIsExpanded(true);
    };

    const handleSearchBlur = () => {
        if (!searchQuery) {
            setIsExpanded(false);
        }
    };

    const [userData, setUserData] = useState({
        name: '',
        surname: '',
        image: '',
        role: '',
    });

    const navigate = useNavigate();

    useEffect(() => {
        if (!userId) {
            console.log('User ID is not defined!');
            return;
        }

        // Fetch user data
        axios.get(`${API_URL}/get_user_data/?user_id=${userId}`)
            .then((response) => {
                setUserData(response.data);
            })
            .catch((err) => console.error(err));
    }, [userId]);

    const handleSearchSubmit = (event) => {
        event.preventDefault();
        if (searchQuery.trim() !== '') {
            fetch(`${API_URL}/get_search_results/?page_name=${page_name}&search_input=${searchQuery}&user_id=${userId}`)
                .then(response => response.json())
                .then(data => {
                    onSearch(data);
                    setSearchQuery('');
                })
                .catch(error => console.error('Error searching:', error));
        }
    };

    const handleClear = () => setSearchQuery('');

    const handleMenuOpen = (event) => setAnchorEl(event.currentTarget);
    const handleMenuClose = () => setAnchorEl(null);

    const handleLogout = () => {
        onLogout();
        navigate('/login');
    };

    // Toggling the search bar active state on small screens
    const handleSearchIconClick = () => {
        setIsSearchActive(!isSearchActive);
    };

    return (
        <Box sx={{ position: 'fixed', top: 0, left: 0, right: 0, zIndex: 1100, width: '100%' }}>
            <Box sx={{ backgroundColor: 'black', color: 'white', boxSizing: 'border-box' }}>
                <Toolbar sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                    {/* Logo */}
                    <Link to="/home">
                        <Box sx={{ display: 'flex', alignItems: 'center', transition: 'transform 0.3s', mr: 1, '&:hover': { transform: 'scale(1.1)' } }}>
                            <img src={Logo} alt="Logo" style={{ width: '120px', height: 'auto', maxWidth: '100%' }} />
                        </Box>
                    </Link>

                    {['posted', 'category', 'recent-activity', 'posts', 'bookings'].includes(page_name) && (
                        <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center', mx: 2 }}>
                            <Box component="form" onSubmit={handleSearchSubmit} sx={{ display: 'flex', alignItems: 'center' }}>
                                <TextField
                                    variant="outlined"
                                    size="small"
                                    placeholder="Search..."
                                    value={searchQuery}
                                    onChange={(e) => setSearchQuery(e.target.value)}
                                    onFocus={handleSearchFocus}
                                    onBlur={handleSearchBlur}
                                    onClick={handleSearchIconClick} // Toggles search bar expansion
                                    InputProps={{
                                        endAdornment: (
                                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                {searchQuery && (
                                                    <IconButton onClick={handleClear} edge="end" sx={{ color: 'white' }}>
                                                        <ClearIcon />
                                                    </IconButton>
                                                )}
                                                <IconButton type="submit" edge="end" sx={{ color: 'white' }}>
                                                    <SearchIcon />
                                                </IconButton>
                                            </Box>
                                        ),
                                    }}
                                    sx={{
                                        bgcolor: 'black.800',
                                        color: 'white',
                                        borderRadius: 1,
                                        height: '40px',
                                        width: isExpanded
                                            ? { xs: '80%', sm: '500px' }
                                            : isSearchActive
                                                ? '100%' // Expand on small screens when search is active
                                                : { xs: '40px', sm: '250px' },
                                        transition: 'width 0.3s ease-in-out',
                                        '&:hover': { bgcolor: 'black.700' },
                                        '& .MuiInputBase-input': { color: 'white' },
                                        '& .MuiOutlinedInput-notchedOutline': { borderColor: 'grey.600' },
                                        mr: 1.5,
                                        display: 'flex',
                                        alignItems: 'center',
                                        '&:focus-within': { width: '100%' },
                                    }}
                                />
                            </Box>
                        </Box>
                    )}

                    {/* Navigation Icons */}
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, display: { xs: isSearchActive ? 'none' : 'flex', sm: 'flex' } }}>
                        <IconButton component={Link} to={`/platform/${userId}`} color="inherit">
                            <HomeIcon />
                        </IconButton>
                        <IconButton component={Link} to={`/Documentation`} color="inherit">
                            <DescriptionIcon />
                        </IconButton>

                        {(userData.role === 'admin' || userData.role === 'super_admin') && (
                            <IconButton component={Link} to={`/admin-panel`} color="inherit">
                                <PersonIcon sx={{ color: '#FF6347' }} />
                            </IconButton>
                        )}

                        {/* User Profile Dropdown */}
                        <IconButton onClick={handleMenuOpen} ref={dropdownRef} color="inherit">
                            <Avatar sx={{ bgcolor: '#3333', width: 48, height: 48, color: 'white' }}>
                                {userData.name ? userData.name[0] : ''} {userData.surname ? userData.surname[0] : ''}
                            </Avatar>
                        </IconButton>
                        <Menu
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={handleMenuClose}
                            PaperProps={{
                                style: { width: 200 },
                                sx: {
                                    bgcolor: 'black',
                                    color: 'white',
                                    border: '1px solid #333',
                                    borderRadius: 3,
                                    boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.8)',
                                },
                            }}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                        >
                            <Box sx={{ p: 2, display: 'flex', alignItems: 'center', textAlign: 'center', gap: 2, bgcolor: '#2222', borderRadius: '6px 6px 0 0' }}>
                                <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#E1E1E1', textAlign: 'center', alignItems: 'center' }}>
                                    {userData.name} {userData.surname}
                                </Typography>
                            </Box>
                            <Divider sx={{ bgcolor: '#000000' }} />
                            <MenuItem component={Link} to={`/UserProfile/${userId}`} onClick={handleMenuClose} sx={{ display: 'flex', alignItems: 'center', gap: 2, py: 1.5, '&:hover': { bgcolor: '#000000' } }}>
                                <PersonIcon sx={{ color: '#1E90FF' }} />
                                <Typography>User Portal</Typography>
                            </MenuItem>
                            <MenuItem component={Link} to={`/verification?userId=${userId}`} onClick={handleMenuClose} sx={{ display: 'flex', alignItems: 'center', gap: 2, py: 1.5, '&:hover': { bgcolor: '#000000' } }}>
                                <CheckCircleIcon sx={{ color: '#1E90FF' }} />
                                <Typography>Verification</Typography>
                            </MenuItem>
                            <MenuItem component={Link} to={`/subscriptions?userId=${userId}`} onClick={handleMenuClose} sx={{ display: 'flex', alignItems: 'center', gap: 2, py: 1.5, '&:hover': { bgcolor: '#000000' } }}>
                                <PaymentIcon sx={{ color: '#1E90FF' }} />
                                <Typography>Subscription</Typography>
                            </MenuItem>
                            <MenuItem onClick={() => { handleMenuClose(); console.log("Logout clicked"); onLogout(); }} sx={{ display: 'flex', alignItems: 'center', gap: 2, py: 1.5, '&:hover': { bgcolor: '#000000' } }}>
                                <LogoutIcon sx={{ color: '#1E90FF' }} />
                                <Typography>Logout</Typography>
                            </MenuItem>
                        </Menu>
                    </Box>
                </Toolbar>
            </Box>
        </Box>
    );
}

export default Header1;
