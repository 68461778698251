import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Typography, Box, Button, TextField, MenuItem, IconButton, Select, Card, Grid } from '@mui/material';
import Footer2 from '../Footer2';
import Header from '../../Header/Header';
import DemoImage from '../../Icon/Background2.png';
import Internships from '../../Icon/Internship.png';
import Jobs from '../../Icon/Jobs2.png';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';



const API_URL = process.env.REACT_APP_API_URL;

function Careers() {
  const [internshipRole, setInternshipRole] = useState('');
  const [jobRole, setJobRole] = useState('');
  const [name, setName] = useState('');
  const [contact, setContact] = useState('');
  const [email, setEmail] = useState('');
  const [resume, setResume] = useState(null);
  const [showForm, setShowForm] = useState(null); // null, 'internship', or 'job'

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const validateInputs = () => {
    if (!name || !email || !contact || !resume) {
      alert("Please fill in all fields and upload a resume.");
      return false;
    }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      alert("Please enter a valid email address.");
      return false;
    }
    if (!/^\d{10}$/.test(contact)) {
      alert("Contact number must be exactly 10 digits.");
      return false;
    }
    return true;
  };

  const handleApply = async () => {
    if (!validateInputs()) return;

    const type = showForm; // 'internship' or 'job'
    const role = type === 'internship' ? internshipRole : jobRole;

    const data = new FormData();
    data.append('name', name);
    data.append('contact', contact);
    data.append('email', email);
    data.append('role', role);
    data.append('type', type);

    if (resume) {
      data.append('resume', resume);
    }

    try {
      const response = await axios.post(`${API_URL}/create_application/`, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      alert(`Successfully applied for ${type} role: ${role}`);
      window.location.reload();
    } catch (error) {
      console.error("Error applying for the position:", error);
      alert("There was an error submitting your application. Please try again.");
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.size > 1 * 1024 * 1024) {
        alert("File size exceeds 1 MB. Please upload a smaller file.");
        return;
      }
      setResume(file);
    }
  };

  const removeFile = () => {
    setResume(null);
  };

  const renderForm = () => (
    <Card sx={{ marginBottom: '20px', borderRadius: '12px', backgroundColor: 'black', borderColor: 'white', padding: '30px' }}>
      <Typography
        variant="h6"
        sx={{
          textAlign: 'center',
          color: 'white',
          fontSize: '2rem',
          fontWeight: 'bold',
          fontFamily: 'Times New Roman, serif'
        }}
      >
        {showForm === 'internship' ? 'Apply for Internship' : 'Apply for Job'}
      </Typography>

      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, padding: '20px' }}>
        <Select
          value={showForm === 'internship' ? internshipRole : jobRole}
          onChange={(e) => {
            if (showForm === 'internship') setInternshipRole(e.target.value);
            else setJobRole(e.target.value);
          }}
          displayEmpty
          MenuProps={{
            PaperProps: {
              sx: {
                backgroundColor: 'black', // Set dropdown background color to black
                color: 'white', // Set dropdown text color to white
                '& .MuiMenuItem-root': {
                  '&:hover': {
                    backgroundColor: 'rgba(255, 255, 255, 0.1)', // Optional: Add hover effect for menu items
                  },
                },
              },
            },
          }}
          sx={{
            backgroundColor: 'black', // Background color of the select field
            color: 'white', // Text color for selected item
            '& .MuiSelect-select': {
              color: 'white', // Text color for select input
            },
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: 'white', // Border color
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: 'white', // Border color on hover
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: 'white', // Border color when focused
            },
          }}
        >
          <MenuItem value="" disabled>
            <span style={{ color: 'white' }}><em>Select Role</em></span> {/* Placeholder style */}
          </MenuItem>
          {showForm === 'internship'
            ? ['Software Developer', 'DevOps', 'Data Science', 'Marketing', 'Content Creation'].map((role) => (
              <MenuItem key={role} value={role}>
                <span style={{ color: 'white' }}>{role}</span> {/* Text color for each menu item */}
              </MenuItem>
            ))
            : ['Legal and Compliance', 'Finance', 'DevOps', 'Data Science', 'AI Research', 'Digital Marketing'].map((role) => (
              <MenuItem key={role} value={role}>
                <span style={{ color: 'white' }}>{role}</span> {/* Text color for each menu item */}
              </MenuItem>
            ))
          }
        </Select>


        <TextField
          label="Your Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          variant="outlined"
          sx={{
            backgroundColor: 'black', // Background color of the input field
            '& .MuiInputLabel-root': {
              color: 'white', // Label color
            },
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: 'white', // Border color for the input field
              },
              '&:hover fieldset': {
                borderColor: 'white', // Border color on hover
              },
              '&.Mui-focused fieldset': {
                borderColor: 'white', // Border color when focused
              },
              '& input': {
                color: 'white', // Text color in the input field
              },
              '& input::placeholder': {
                color: 'gray', // Placeholder text color
              },
            },
          }}
        />

        <TextField
          label="Contact Number"
          value={contact}
          onChange={(e) => setContact(e.target.value)}
          variant="outlined"
          sx={{
            backgroundColor: 'black', // Background color of the input field
            '& .MuiInputLabel-root': {
              color: 'white', // Label color
            },
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: 'white', // Border color for the input field
              },
              '&:hover fieldset': {
                borderColor: 'white', // Border color on hover
              },
              '&.Mui-focused fieldset': {
                borderColor: 'white', // Border color when focused
              },
              '& input': {
                color: 'white', // Text color in the input field
              },
              '& input::placeholder': {
                color: 'gray', // Placeholder text color
              },
            },
          }}
        />
        <TextField
          label="Email ID"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          variant="outlined"
          sx={{
            backgroundColor: 'black', // Background color of the input field
            '& .MuiInputLabel-root': {
              color: 'white', // Label color
            },
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: 'white', // Border color for the input field
              },
              '&:hover fieldset': {
                borderColor: 'white', // Border color on hover
              },
              '&.Mui-focused fieldset': {
                borderColor: 'white', // Border color when focused
              },
              '& input': {
                color: 'white', // Text color in the input field
              },
              '& input::placeholder': {
                color: 'gray', // Placeholder text color
              },
            },
          }}
        />

        <Box
          sx={{
            border: '2px dashed white',
            borderRadius: '12px',
            padding: '20px',
            textAlign: 'center',
            backgroundColor: 'grey',
            cursor: 'pointer',
            '&:hover': {
              backgroundColor: 'grey',
            }
          }}
          onClick={() => document.getElementById('file-upload').click()}
        >
          <input
            id="file-upload"
            type="file"
            accept=".pdf, .doc, .docx"
            onChange={handleFileChange}
            style={{ display: 'none' }}
          />
          {resume ? (
            <Typography variant="body1" sx={{ color: 'white' }}>
              {resume.name}
              <Button onClick={removeFile} sx={{ color: 'red', marginLeft: '10px' }}>
                Remove
              </Button>
            </Typography>
          ) : (
            <Typography variant="body2" sx={{ color: 'white' }}>
              Drag & drop your resume here or click to upload
            </Typography>
          )}
          <Typography variant="body2" sx={{ color: 'white', marginTop: '10px' }}>
            (PDF, DOC, DOCX formats only, max size: 1 MB)
          </Typography>
        </Box>

        <Button
          variant="contained"
          color="primary"
          onClick={handleApply}
          sx={{ marginTop: '20px' }}
        >
          Submit
        </Button>
      </Box>
    </Card>
  );

  return (
    <div>
      <Header />
      <Box
        sx={{
          // backgroundImage: `url(${DemoImage})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          minHeight: '100vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'center',
          padding: { xs: '20px', sm: '40px' },
          marginTop: 10,
        }}
      >
        <Typography
          variant="h3"
          component="h1"
          gutterBottom
          sx={{
            color: 'white',
            fontWeight: 'bold',
            fontSize: { xs: '3rem', sm: '4rem' },
            marginBottom: { xs: '5px', sm: '30px', md: '40px' },
            fontFamily: 'Times New Roman, serif'
          }}
        >
          Careers
        </Typography>

        <Box sx={{ marginTop: '40px', padding: '20px', maxWidth: '1200px', width: '100%' }}>
          <Typography variant="h4" sx={{ color: 'white', marginBottom: '40px', fontWeight: 'bold', textAlign: 'center', fontFamily: 'Times New Roman, serif' }}>
            Why Join Our Team at Anthrasync?
          </Typography>
          <Grid container spacing={4}>
            {[
              {
                title: 'Innovate with AI',
                description: 'You will work on cutting-edge AI technology that is transforming how people connect, share knowledge, and solve complex problems. Your contributions will have a real-world impact on industries and communities.',
              },
              {
                title: 'Startup Environment with Big Opportunities',
                description: 'As a government-certified startup, we offer a dynamic, fast-paced environment where creativity and innovation are encouraged. This is a chance to grow with the company and shape its direction from the ground up.',
              },
              {
                title: 'Career Growth & Learning',
                description: 'Whether you’re an intern or a seasoned professional, you’ll have opportunities for continuous learning, skill development, and career advancement. We invest in our team, offering mentorship, workshops, and hands-on experience in AI.',
              },
              {
                title: 'Collaborative and Inclusive Culture',
                description: 'We value diversity of thought and believe the best ideas come from collaboration. At AnthraSync, you’ll be part of an inclusive team where everyone’s voice matters.',
              },
              {
                title: 'Work on Meaningful Projects',
                description: 'Your work at Anthrasync won’t be just another job. You’ll be tackling challenges that matter—improving how people share knowledge and work together using the latest AI technologies.',
              },
              {
                title: 'Flexible and Supportive Work Environment',
                description: 'We offer a flexible work culture that encourages balance and supports your personal and professional growth.',
              },
            ].map((benefit, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
  <Card sx={{
    padding: '15px', // Reduced padding for a smaller card
    background: 'linear-gradient(145deg, #1e1e1e, #1111)', // Dark gradient for the card background
    borderRadius: '16px', // Smooth, rounded corners
    textAlign: 'center', // Center-align content
    height: '200px', // Reduced fixed height for the card
    display: 'flex', // Use flexbox for layout
    flexDirection: 'column', // Stack children vertically
    justifyContent: 'space-between', // Ensure space between the heading and description
    alignItems: 'center', // Center content horizontally
    boxShadow: '4px 4px 12px rgba(0, 0, 0, 0.4)', // Light shadow for depth in dark theme
    transition: 'transform 0.3s, box-shadow 0.3s', // Smooth transition for hover effect
    '&:hover': {
      transform: 'scale(1.05)', // Slightly scale the card on hover
      boxShadow: '0 0 25px rgba(0, 0, 0, 0.6)', // Stronger shadow on hover
    },
  }}>
    <Typography 
      variant="h6" 
      sx={{ 
        color: 'white', 
        fontWeight: 600, // Modern bold weight
        marginBottom: '10px', 
        fontFamily: 'Helvetica, sans-serif', // Use Helvetica
        fontSize: { xs: '0.9rem', sm: '1rem', md: '1.1rem' }, // Decreased font size for title
        textTransform: 'uppercase', // Uppercase text for modern touch
        letterSpacing: '0.5px', // Slightly spaced letters
        textAlign: 'center', // Keep heading centered
      }}
    >
      {benefit.title}
    </Typography>
    <Typography 
      variant="body2" 
      sx={{ 
        color: 'white', 
        fontFamily: 'Helvetica, sans-serif', // Set font to Helvetica
        fontSize: { xs: '0.75rem', sm: '0.85rem', md: '0.95rem' }, // Decreased font size for description
        textAlign: 'center', // Ensure the text is centered
        lineHeight: 1.6, // Improve line spacing for readability
        flexGrow: 1, // Allow description to take remaining space
      }}
    >
      {benefit.description}
    </Typography>
  </Card>
</Grid>

            ))}
          </Grid>
        </Box>


        {/* Application Section */}
        <Box sx={{ marginTop: '40px', maxWidth: '1200px', width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 10, mb: 10, mt: 10 }}>
          <Box sx={{
            textAlign: 'center', backgroundColor: 'black', borderRadius: '12px', padding: '20px', width: { xs: '90%', sm: '500px' }, display: 'flex', flexDirection: 'column', justifyContent: 'space-between',
          }}>
            <Typography variant="h5" sx={{ color: 'white', marginBottom: '10px', fontWeight: 'bold', fontFamily: 'Times New Roman, serif' }}>
              Internships
            </Typography>
            <img src={Internships} alt="Internship" style={{ width: '100%', height: 'auto', objectFit: 'cover', borderRadius: '8px' }} />
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                // Toggle form visibility
                if (showForm === 'internship') {
                  setShowForm(null); // Close the form if it is already open
                } else {
                  setShowForm('internship'); // Open the internship form
                }
              }}
              sx={{
                marginTop: '20px',
                width: '200px',
                alignSelf: 'center',
                transform: {
                  xs: 'scale(0.1)',  // For extra small screens
                  sm: 'scale(1.1)',    // For small screens
                  md: 'scale(1.15)',   // For medium screens
                  lg: 'scale(1.2)',    // For large screens
                },
              }}
            >
              Apply
            </Button>
            {showForm === 'internship' && renderForm()}

          </Box>

          <Box sx={{
            textAlign: 'center', backgroundColor: 'black', borderRadius: '12px', padding: '20px', width: { xs: '90%', sm: '500px' }, display: 'flex', flexDirection: 'column', justifyContent: 'space-between',mb:7,
          }}>
            <Typography variant="h5" sx={{ color: 'white', marginBottom: '10px', fontWeight: 'bold', fontFamily: 'Times New Roman, serif' }}>
              Jobs
            </Typography>
            <img src={Jobs} alt="Jobs" style={{ width: '100%', height: 'auto', objectFit: 'cover', borderRadius: '8px' }} />
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                // Toggle form visibility
                if (showForm === 'job') {
                  setShowForm(null); // Close the form if it is already open
                } else {
                  setShowForm('job'); // Open the job form
                }
              }}
              sx={{
                marginTop: '20px', width: '200px', alignSelf: 'center', '&:hover': {
                  transform: 'scale(1.1)',
                  boxShadow: '0 0 20px rgba(0, 0, 255, 0.7)',
                },
              }}
            >
              Apply
            </Button>
            {showForm === 'job' && renderForm()}

          </Box>
        </Box>
      </Box>
      <IconButton
        onClick={scrollToTop}
        sx={{ position: 'fixed', bottom: 16, right: 16, backgroundColor: 'white', color: 'black' }}
      >
        <ArrowUpwardIcon />
      </IconButton>
      <Footer2 />
    </div>
  );
}

export default Careers;
