import React, { useState, useEffect } from 'react';
import { Select, MenuItem, Button, Box, Typography, FormControl, InputLabel, CircularProgress } from '@mui/material';

const DeleteSection = ({ userId, onLogout }) => {
    const [sections, setSections] = useState([]);  // Initialize with an empty array
    const [selectedSection, setSelectedSection] = useState('');
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);

    // Fetch the list of sections from the backend
    useEffect(() => {
        const fetchSections = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/list_sections/`);
                const data = await response.json();

                if (response.ok && Array.isArray(data)) {
                    setSections(data);  // Set the sections array
                    setLoading(false);
                } else {
                    setError('Failed to fetch sections or sections are missing');
                    setLoading(false);
                }
            } catch (err) {
                setError('Error fetching sections');
                setLoading(false);
            }
        };

        fetchSections();
    }, []);

    const handleDeleteSection = async () => {
        if (!selectedSection) {
            setError('Please select a section to delete');
            return;
        }

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/delete_section/`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ id: selectedSection }),
            });

            if (response.ok) {
                // If the response status is 204 (No Content), no body will be returned
                if (response.status === 204) {
                    alert('Section deleted successfully');
                    setSelectedSection('');
                } else {
                    const data = await response.json();
                    alert(data.message || 'Section deleted successfully');
                    setSelectedSection('');
                }
            } else {
                const data = await response.json();
                setError(data.message || 'Failed to delete section');
            }
        } catch (err) {
            setError('Error deleting section');
        }
    };

    return (
        <Box
            sx={{
                p: 4,
                bgcolor: '#121212',
                borderRadius: 2,
                boxShadow: '0 4px 12px rgba(0, 0, 0, 0.5)',
            }}
        >
            <Typography variant="h5" sx={{ mb: 3, color: '#fff' }}>
                Delete Section
            </Typography>

            {/* Section Selection */}
            <FormControl fullWidth sx={{ mb: 2 }}>
                <InputLabel id="section-select-label" sx={{ color: '#aaa' }}>
                    Select Section
                </InputLabel>
                <Select
                    labelId="section-select-label"
                    id="section-select"
                    value={selectedSection}
                    onChange={(e) => setSelectedSection(e.target.value)}
                    label="Select Section"
                    sx={{
                        bgcolor: '#222',
                        color: '#fff',
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                borderColor: '#555',
                            },
                            '&:hover fieldset': {
                                borderColor: '#1E90FF',
                            },
                        },
                    }}
                >
                    {loading ? (
                        <MenuItem disabled>
                            <CircularProgress size={24} />
                        </MenuItem>
                    ) : (
                        sections.length > 0 ? (
                            sections.map((section) => (
                                <MenuItem key={section.id} value={section.id}>
                                    {section.name}
                                </MenuItem>
                            ))
                        ) : (
                            <MenuItem disabled>No Sections Available</MenuItem>  // Handle empty or undefined sections array
                        )
                    )}
                </Select>
            </FormControl>

            {error && (
                <Typography variant="body2" sx={{ color: '#f44336', mb: 2 }}>
                    {error}
                </Typography>
            )}

            <Button
                variant="contained"
                onClick={handleDeleteSection}
                sx={{
                    bgcolor: '#f44336',
                    '&:hover': { bgcolor: '#d32f2f' },
                    fontWeight: 'bold',
                    px: 4,
                    py: 1.5,
                }}
            >
                Delete Section
            </Button>
        </Box>
    );
};

export default DeleteSection;
