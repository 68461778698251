import React,{ useState } from 'react';
import { Link, Routes, Route, useNavigate } from 'react-router-dom';
import PostedClasses from './PostedClasses';
// import BookedClasses from '../GroupExchange';
// import RequestClass from '../RequestClass';
// import RequestedClasses from '../RequestedClasses';
// import MutualExchange from '../MutualExchange';
import Header from '../Header/Header1';
import Footer from '../Footer/Footer';
import CreatePost from './CreatePost';
// import PostMutualExchange from '../PostMutualExchange/PostMutualExchange';
// import PostGroupExchange from '../PostGroupExchange/PostGroupExchange';
// import RequestTopic from '../RequestTopic/RequestTopic';
// import GroupExchange from '../GroupExchange';
import PostedIcon from '../Icon/PostIcons/Posted.png';
import RequestedIcon from '../Icon/PostIcons/Requested.png';
// import MutualEchangeIcon from '../../Main/Icons/MutualExchangeBlue.png';
// import GroupExchangeIcon from '../../Main/Icons/GroupExchange.png';
import Create from '../Icon/PostIcons/Posted.png';
import Ask from '../Icon/PostIcons/ask-for-help.png';
import { useParams } from 'react-router-dom';
import MyPosts from './MyPosts';

function PostSection({userId,onLogout}) {

  const navigate = useNavigate();

  const { section_id } = useParams();

  const handlePostClick = () => {
    console.log(userId)
    // navigate(`/post/${section_id}/${userId}`);
    navigate(`/post/${userId}`);
  };

  const handleAskClick = () => {
    navigate(`/request-topic/${section_id}/${userId}`)
  }

    const [sectionVisibility, setSectionVisibility] = useState({
        postedClasses: true,
        post: false,
        postme: false,
        postge: false,
        request_topic: false,
        requestClass: false,
        requestedClasses: false,
        mutualExchange: false,
        groupExchange: false,
      });

      const handleSectionChange = (section) => {
        setSectionVisibility({
            ...sectionVisibility,
            [section]: true,
        });

    for (const key in sectionVisibility) {
        if (key !== section) {
          setSectionVisibility((prevState) => ({
            ...prevState,
            [key]: false,
          }));
        }
      }
    };

  return (
    <div className='class-main'>
       
    <Header userId={userId} onLogout={onLogout}/>

      <nav className='classes-sidebar'>
        <ul>
          <div className='classes-first-div'>
              <li>
                <a href="#" onClick={handlePostClick}>
                <img src={Create} alt='Logo'/>
                <span className="label">Post</span></a>
              </li>
            </div>
            <div className='classes-second-div'>
              <li><a href="#" onClick={() => handleSectionChange('postedClasses')}><img src={PostedIcon} alt="posted" className="icon"/> 
              <span className="label">Posted</span>
              </a></li>
              <li><a href="#" onClick={() => handleSectionChange('requestedClasses')}><img src={RequestedIcon} alt="Requested" className="icon"/>
              <span className="label">My Posts</span>
              </a></li>
              {/* <li><a href="#" onClick={() => handleSectionChange('mutualExchange')}><img src={MutualEchangeIcon} alt="Mutual Exchange" className="icon"/>
              <span className="label">One to One</span>
              </a></li> */}
            </div>
        </ul>
      </nav>

      <div className='classes-content'>

      <section id='post' style={{ display: sectionVisibility.post ? 'block' : 'none' }}>
        <CreatePost/>
      </section>

        {/* <section id='request_topic' style={{ display: sectionVisibility.request_topic ? 'block' : 'none' }}>
          <RequestTopic/>
        </section> */}

        <section id='postedClasses' style={{ display: sectionVisibility.postedClasses ? 'block' : 'none' }}>
          <PostedClasses userId = {userId} sectionId = {section_id}/>
        </section>


        <section id='requestedClasses' style={{ display: sectionVisibility.requestedClasses ? 'block' : 'none' }}>
          {/* <MyPosts userId = {userId} sectionId={section_id}/> */}
          <MyPosts userId = {userId}/>
        </section>

        {/* <section id='mutualExchange' style={{ display: sectionVisibility.mutualExchange ? 'block' : 'none' }}>
          <RequestClass userId={userId} sectionId={section_id}/>
        </section> */}
      </div>

      
      <Footer/>
    </div>
  )
}

export default PostSection
