import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Box, Typography, Grid, Button, Avatar, Paper, IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import Header1 from '../../Header/Header1';
import Footer from '../../Footer/Footer';

function UserDetails({ userId, onLogout }) {
  // Dummy user data
  const userData = {
    name: 'John Doe',
    about: 'Software Developer passionate about creating impactful solutions.',
    email: 'johndoe@example.com',
    contact: '123-456-7890',
    dob: '1990-01-01',
    gender: 'Male',
    address: '123 Main St, Springfield, USA',
    languages: 'English, Spanish',
    rating: 4.5,
    qualification: 'B.Sc. in Computer Science',
    professional_details: 'Full-Stack Developer at XYZ Corp.',
  };

  return (
    <Box>
      <Header1 userId={userId} onLogout={onLogout} />
      <Typography
        variant="h4"
        fontWeight="bold"
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          // height: '20vh', // Full viewport height to center vertically
          textAlign: 'center', // Center text within its container
          mt: 10, // Reset margin to avoid interference
          mb: 2
        }}
      >
        User Details
      </Typography>

      <Box
        sx={{
          height: 500, // Reduced height
          width: '90%', // Reduced width
          margin: '0 auto', // Center horizontally
          display: 'flex',
          alignItems: 'center', // Center vertically
          justifyContent: 'center', // Center horizontally
          position: 'relative',
          backgroundImage:
            'url(https://nimionlineadmission.in/blendedlearning/assets/wp-content/uploads/2024/06/virat-kohli-international-hundred_06e6bf9c7-768x432.jpg)',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          borderRadius: 4, // Optional for rounded corners
          boxShadow: '0 4px 12px rgba(0, 0, 0, 0.2)', // Optional for a slight shadow
        }}
      >
      </Box>


      <Box sx={{ p: 4 }}>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', mb: 2 }}>
          <IconButton component={Link} to="/userdetails/edit-user-details">
            <EditIcon sx={{ fontSize: 30, color: 'primary.main' }} />
          </IconButton>
        </Box>


        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100vh', // Ensures full viewport height
            textAlign: 'center', // Centers the text within the Box
            mt:20
          }}
        >
          <Grid container spacing={3} sx={{ mt: 2, maxWidth: 600, width: '100%' }}>

            {/* Name Field */}
            <Grid item xs={12}>
              <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2, padding: 2, border: '1px solid #ccc', borderRadius: 2 }}>
                <Typography variant="h6" fontWeight="bold" sx={{ mr: 2 }}>Name:</Typography>
                <Typography variant="body1">{userData.name}</Typography>
              </Box>
            </Grid>

            {/* About Field */}
            <Grid item xs={12}>
              <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2, padding: 2, border: '1px solid #ccc', borderRadius: 2 }}>
                <Typography variant="h6" fontWeight="bold" sx={{ mr: 2 }}>About:</Typography>
                <Typography variant="body1">{userData.about}</Typography>
              </Box>
            </Grid>

            {/* Email Field */}
            <Grid item xs={12}>
              <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2, padding: 2, border: '1px solid #ccc', borderRadius: 2 }}>
                <Typography variant="h6" fontWeight="bold" sx={{ mr: 2 }}>Email:</Typography>
                <Typography variant="body1">{userData.email}</Typography>
              </Box>
            </Grid>

            {/* Contact No Field */}
            <Grid item xs={12}>
              <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2, padding: 2, border: '1px solid #ccc', borderRadius: 2 }}>
                <Typography variant="h6" fontWeight="bold" sx={{ mr: 2 }}>Contact No:</Typography>
                <Typography variant="body1">{userData.contact}</Typography>
              </Box>
            </Grid>

            {/* Date of Birth Field */}
            <Grid item xs={12}>
              <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2, padding: 2, border: '1px solid #ccc', borderRadius: 2 }}>
                <Typography variant="h6" fontWeight="bold" sx={{ mr: 2 }}>Date of Birth:</Typography>
                <Typography variant="body1">{userData.dob}</Typography>
              </Box>
            </Grid>

            {/* Gender Field */}
            <Grid item xs={12}>
              <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2, padding: 2, border: '1px solid #ccc', borderRadius: 2 }}>
                <Typography variant="h6" fontWeight="bold" sx={{ mr: 2 }}>Gender:</Typography>
                <Typography variant="body1">{userData.gender}</Typography>
              </Box>
            </Grid>

            {/* Address Field */}
            <Grid item xs={12}>
              <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2, padding: 2, border: '1px solid #ccc', borderRadius: 2 }}>
                <Typography variant="h6" fontWeight="bold" sx={{ mr: 2 }}>Address:</Typography>
                <Typography variant="body1">{userData.address}</Typography>
              </Box>
            </Grid>

            {/* Languages Field */}
            <Grid item xs={12}>
              <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2, padding: 2, border: '1px solid #ccc', borderRadius: 2 }}>
                <Typography variant="h6" fontWeight="bold" sx={{ mr: 2 }}>Languages:</Typography>
                <Typography variant="body1">{userData.languages}</Typography>
              </Box>
            </Grid>

            {/* Rating Field */}
            <Grid item xs={12}>
              <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2, padding: 2, border: '1px solid #ccc', borderRadius: 2 }}>
                <Typography variant="h6" fontWeight="bold" sx={{ mr: 2 }}>Rating:</Typography>
                <Typography variant="body1">{userData.rating}</Typography>
              </Box>
            </Grid>

            {/* Educational Qualifications Field */}
            <Grid item xs={12}>
              <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2, padding: 2, border: '1px solid #ccc', borderRadius: 2 }}>
                <Typography variant="h6" fontWeight="bold" sx={{ mr: 2 }}>Educational Qualifications:</Typography>
                <Typography variant="body1">{userData.qualification}</Typography>
              </Box>
            </Grid>

            {/* Professional Details Field */}
            <Grid item xs={12}>
              <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2, padding: 2, border: '1px solid #ccc', borderRadius: 2 }}>
                <Typography variant="h6" fontWeight="bold" sx={{ mr: 2 }}>Professional Details:</Typography>
                <Typography variant="body1">{userData.professional_details}</Typography>
              </Box>
            </Grid>

          </Grid>
        </Box>

      </Box>

      <Footer />
    </Box>
  );
}

export default UserDetails;
