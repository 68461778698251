import React from 'react';
import { Grid, Box, Typography, IconButton, Card, CardContent } from '@mui/material';
import { FaDiscord, FaLinkedin, FaFacebook, FaInstagram, FaTwitter, FaYoutube } from 'react-icons/fa';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { useNavigate } from 'react-router-dom';
import Footer2 from '../Footer/Footer2';
import Header from '../Header/Header';
import Header1 from '../Header/Header1';

const Community = ({userId,loggedIn, onLogout }) => {

    const navigate = useNavigate();

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const scrollToSection = (id) => {
    document.getElementById(id).scrollIntoView({ behavior: 'smooth' });
  };

   // Handle logout
   const handleLogout = () => {
    onLogout(); // Call the passed logout function
    navigate('/login'); // Navigate to login page after logout
  };
  const socialLinks = [
    { icon: <FaDiscord size="2.5rem" />, label: 'Discord', url: 'https://discord.gg/9PFJf4gBJ8' },
    { icon: <FaLinkedin size="2.5rem" />, label: 'LinkedIn', url: 'https://www.linkedin.com/company/anthrasync-solutions-private-limited/' },
    { icon: <FaFacebook size="2.5rem" />, label: 'Facebook', url: 'https://www.facebook.com/share/15Bjr5WUt9/' },
    { icon: <FaInstagram size="2.5rem" />, label: 'Instagram', url: 'https://www.instagram.com/anthrasync/' },
    { icon: <FaTwitter size="2.5rem" />, label: 'X (formerly Twitter)', url: 'https://x.com/anthrasync' },
    { icon: <FaYoutube size="2.5rem" />, label: 'YouTube', url: 'https://www.youtube.com/@AnthrasyncMarketing' },
  ];

  const handleClick = (url) => {
    window.open(url, '_blank');
  };

  return (
    <div style={{ backgroundColor: 'black', minHeight: '100vh', paddingTop: '100px',paddingRight:'100px',paddingLeft:'100px',color: 'white', }}>
        {loggedIn ? (
                <Header1 style={{ display: 'flex', justifyContent: 'center', textAlign: 'center', alignItems: 'center' }} userId={userId} onLogout={onLogout} />
            ) : (
                <Header style={{ display: 'flex', justifyContent: 'center', textAlign: 'center', alignItems: 'center' }} />
            )}
      <Box sx={{ textAlign: 'center', mb: 6 }}>
        <Typography variant="h3" sx={{ fontWeight: 'bold', color: '#fff' }}>
          Join Our Community
        </Typography>
        <Typography variant="h6" sx={{ color: '#aaa', mt: 2 }}>
          Connect with us on various platforms and stay updated.
        </Typography>
      </Box>

      <Grid container spacing={4} justifyContent="center" sx={{mb:12}}>
        {socialLinks.map((social, index) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
            <Card
              sx={{
                background: '#1f1f1f', // Dark card background
                boxShadow: '0 4px 10px rgba(0, 0, 0, 0.2)',
                borderRadius: '12px',
                padding: '20px',
                textAlign: 'center',
                '&:hover': {
                  boxShadow: '0 6px 15px rgba(0, 0, 0, 0.3)',
                  transform: 'scale(1.05)',
                  transition: 'all 0.3s ease',
                },
              }}
            >
              <CardContent>
                <IconButton
                  sx={{
                    backgroundColor: '#1a73e8',
                    color: '#fff',
                    padding: '20px',
                    borderRadius: '50%',
                    fontSize: '2.5rem',
                    '&:hover': {
                      backgroundColor: '#155a8a',
                    },
                  }}
                  onClick={() => handleClick(social.url)}
                >
                  {social.icon}
                </IconButton>
                <Typography variant="h6" sx={{ mt: 2, fontWeight: 'bold', color: '#fff' }}>
                  {social.label}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
      <IconButton
        onClick={scrollToTop}
        sx={{ position: 'fixed', bottom: 16, right: 16, backgroundColor: 'white', color: 'black' }}
      >
        <ArrowUpwardIcon />
      </IconButton>
      <Footer2/>
    </div>
  );
};

export default Community;
