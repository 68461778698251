import React, { useState } from "react";
import { TextField, Button, Box, Typography, Rating, createTheme, ThemeProvider } from "@mui/material";
import Header1 from "../Header/Header1";
import Footer2 from "../Footer/Footer2";
import axios from "axios";
import { useParams } from "react-router-dom";

const API_URL = process.env.REACT_APP_API_URL;

// Create a dark theme using Material-UI's theme creator
const theme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#1E90FF", // Light blue primary color
    },
    background: {
      default: "#121212", // Dark background color
      paper: "#1E1E1E", // Slightly lighter background for paper elements
    },
    text: {
      primary: "#ffffff", // Light text color
      secondary: "#b0b0b0", // Lighter text color for secondary text
    },
    error: {
      main: "#f44336", // Red for error messages
    },
  },
});

const FeedbackForm = ({userId, onLogout}) => {

  const { id } = useParams(); 

  // State to handle form data
  const [feedback, setFeedback] = useState({
    rating: 0,
    comments: "",
  });

  // State to handle form submission status
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState(null);

  // Handle form change
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFeedback({
      ...feedback,
      [name]: value,
    });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);

    if (feedback.comments.trim() === "") {
        setError("Please provide a comment.");
        return;
      }
  

    // Prepare data for API call
    const data = {
      user_id: userId, // Pass the userId prop
      post_id: id, // Pass the postId prop
      comment: feedback.comments,
      flag: 'guide'
    };

    try {
      // Make the API call using Axios
      const response = await axios.post(`${API_URL}/create_user_feedback/`, data);

      // Handle successful response
      if (response.status === 200) {
        console.log("Feedback submitted:", response.data);
        setSubmitted(true); // Set submitted to true on successful submission
      } else {
        setError("Something went wrong, please try again later.");
      }
    } catch (err) {
      // Handle error during the API call
      setError("Failed to submit feedback. Please try again.");
      console.error(err);
    }
  };

  return (
    <div>
      <Box sx={{ marginBottom: "60px" }}>
        <Header1 userId={userId} onLogout={onLogout}/>
      </Box>

      <ThemeProvider theme={theme}>
        <Box
          sx={{
            maxWidth: 500,
            margin: "0 auto",
            padding: "20px",
            marginTop: '100px',
            borderRadius: "8px",
            boxShadow: 3,
            backgroundColor: "background.paper", // Dark background color for the box
          }}
        >
          <Typography variant="h4" gutterBottom color="text.primary">
            Knowledge Exchange Session Feedback
          </Typography>

          {submitted ? (
            <Typography variant="h6" color="primary">
              Thank you for your feedback!
            </Typography>
          ) : (
            <form onSubmit={handleSubmit}>

              <TextField
                label="Comments"
                name="comments"
                value={feedback.comments}
                onChange={handleChange}
                fullWidth
                multiline
                rows={4}
                sx={{ marginBottom: "1rem" }}
                InputLabelProps={{
                  style: { color: "white" },
                }}
                InputProps={{
                  style: { color: "white" },
                }}
              />

              {error && (
                <Typography variant="body2" color="error" sx={{ marginBottom: "1rem" }}>
                  {error}
                </Typography>
              )}

              <Button variant="contained" color="primary" type="submit" fullWidth>
                Submit Feedback
              </Button>
            </form>
          )}
        </Box>
      </ThemeProvider>

      <Footer2 />
    </div>
  );
};

export default FeedbackForm;
