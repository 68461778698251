import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { TextField, Button, Typography, Box, Grid, IconButton, CircularProgress, Snackbar, Alert } from '@mui/material';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import axios from 'axios';
import Footer2 from './Footer2';
import Header from '../Header/Header';

const API_URL = process.env.REACT_APP_API_URL;

function ContactUs() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    contact: '',
    message: '',
  });
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);  // Loading state
  const [statusMessage, setStatusMessage] = useState(null);  // Success/Error message
  const [openSnackbar, setOpenSnackbar] = useState(false);  // Snackbar visibility
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);

    const handlePopState = () => {
      navigate('/home');
    };

    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [navigate]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
    setErrors((prevErrors) => ({ ...prevErrors, [name]: '' }));
  };

  const validateForm = () => {
    const newErrors = {};
    if (formData.name.length > 20) newErrors.name = 'Name must be 20 characters or less.';
    if (!/\S+@\S+\.\S+/.test(formData.email)) newErrors.email = 'Email is invalid.';
    if (!/^\d{10}$/.test(formData.contact)) newErrors.contact = 'Contact number must be exactly 10 digits.';
    if (formData.message.length > 255) newErrors.message = 'Message must be 255 characters or less.';
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    setLoading(true);
    setStatusMessage(null);  // Reset the status message

    try {
      const response = await axios.post(`${API_URL}/create_query/`, formData, {
        headers: { 'Content-Type': 'application/json' },
      });

      if (response.status === 201) {
        setFormData({ name: '', email: '', contact: '', message: '' });
        setStatusMessage('Your message has been submitted successfully!');
        setOpenSnackbar(true);  // Show success snackbar
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      setStatusMessage('There was an error submitting your message. Please try again.');
      setOpenSnackbar(true);  // Show error snackbar
    } finally {
      setLoading(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh', backgroundColor: 'black' }}>
      <Header />
      <Box
        sx={{
          background: 'linear-gradient(135deg, black, black)', // Subtle gradient background
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundAttachment: 'fixed',
          flexGrow: 1,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: { xs: '20px', sm: '40px', md: '60px' },
          color: 'white',
          mt: { xs: 10, sm: 12, md: 15 },
          mb: { xs: 6, sm: 8 },
        }}
      >
        <Grid container justifyContent="center">
          <Grid item xs={12} sm={10} md={8} lg={6}>
            <form
              onSubmit={handleSubmit}
              style={{
                background: 'rgba(0, 0, 0, 0.7)',
                padding: '30px',
                borderRadius: '12px',
                boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.3)',
              }}
            >
              <Typography
                variant="h4"
                component="h1"
                gutterBottom
                textAlign="center"
                sx={{
                  fontSize: { xs: '1.6rem', sm: '2.2rem' },
                  fontWeight: 'bold',
                  color: '#ECF0F1',
                }}
              >
                Contact Us
              </Typography>
              <Box mb={2}>
                <TextField
                  fullWidth
                  label="Full Name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                  variant="outlined"
                  margin="normal"
                  error={!!errors.name}
                  helperText={errors.name}
                  InputLabelProps={{ style: { color: '#ECF0F1' } }}
                  InputProps={{ style: { color: '#ECF0F1' } }}
                  sx={{
                    fontSize: { xs: '1rem', sm: '1.1rem' },
                    borderRadius: '8px',
                    backgroundColor: '#2C3E50',
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: '#ECF0F1',
                      },
                      '&:hover fieldset': {
                        borderColor: '#ECF0F1',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: '#ECF0F1',
                      },
                    },
                  }}
                />
              </Box>
              <Box mb={2}>
                <TextField
                  fullWidth
                  label="Email"
                  name="email"
                  type="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                  variant="outlined"
                  margin="normal"
                  error={!!errors.email}
                  helperText={errors.email}
                  InputLabelProps={{ style: { color: '#ECF0F1' } }}
                  InputProps={{ style: { color: '#ECF0F1' } }}
                  sx={{
                    fontSize: { xs: '1rem', sm: '1.1rem' },
                    borderRadius: '8px',
                    backgroundColor: '#2C3E50',
                  }}
                />
              </Box>
              <Box mb={2}>
                <TextField
                  fullWidth
                  label="Mobile Number"
                  name="contact"
                  type="tel"
                  value={formData.contact}
                  onChange={handleChange}
                  required
                  variant="outlined"
                  margin="normal"
                  error={!!errors.contact}
                  helperText={errors.contact}
                  InputLabelProps={{ style: { color: '#ECF0F1' } }}
                  InputProps={{ style: { color: '#ECF0F1' } }}
                  sx={{
                    fontSize: { xs: '1rem', sm: '1.1rem' },
                    borderRadius: '8px',
                    backgroundColor: '#2C3E50',
                  }}
                />
              </Box>
              <Box mb={2}>
                <TextField
                  fullWidth
                  label="Message"
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  required
                  variant="outlined"
                  margin="normal"
                  error={!!errors.message}
                  helperText={errors.message}
                  multiline
                  rows={4}
                  InputLabelProps={{ style: { color: '#ECF0F1' } }}
                  InputProps={{ style: { color: '#ECF0F1' } }}
                  sx={{
                    fontSize: { xs: '1rem', sm: '1.1rem' },
                    borderRadius: '8px',
                    backgroundColor: '#2C3E50',
                  }}
                />
              </Box>
              <Box sx={{ width: '100%', margin: '0 auto' }}>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  fullWidth
                  sx={{ padding: '12px', fontSize: '1.1rem', borderRadius: '8px' }}
                  disabled={loading}
                >
                  {loading ? <CircularProgress size={24} color="inherit" /> : 'Submit'}
                </Button>
              </Box>
            </form>
          </Grid>
        </Grid>
      </Box>
      <IconButton
        onClick={scrollToTop}
        sx={{
          position: 'fixed',
          bottom: { xs: 16, md: 32 },
          right: { xs: 16, md: 32 },
          backgroundColor: '#ECF0F1',
          color: 'black',
          borderRadius: '50%',
          padding: '12px',
          boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.3)',
        }}
      >
        <ArrowUpwardIcon />
      </IconButton>
      <Footer2 />

      {/* Snackbar for Success/Error Message */}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
      >
        <Alert severity={statusMessage && statusMessage.includes('error') ? 'error' : 'success'} sx={{ width: '100%' }}>
          {statusMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default ContactUs;
