import React, { useState } from 'react';
import { TextField, Button, Box, Typography } from '@mui/material';
import Header1 from '../Header/Header1';
import Footer2 from '../Footer/Footer2';
import Header from '../Header/Header';

const API_URL = process.env.REACT_APP_API_URL;

function CustomerSupport({ userId, loggedIn, onLogout }) {
  const [query, setQuery] = useState('');
  const [emailOrPhone, setEmailOrPhone] = useState(''); // Track email or phone
  const [responseMessage, setResponseMessage] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!query) {
      setResponseMessage('Please enter a query.');
      return;
    }

    // If the user is not logged in, ensure that they provide an email or phone
    if (!loggedIn && !emailOrPhone) {
      setResponseMessage('Please provide your email or contact number.');
      return;
    }

    // Prepare the data to send to the backend
    const requestData = { query, ...(loggedIn ? {} : { contact: emailOrPhone }) };

    // Send query to the backend (Django)
    try {
      const response = await fetch(`${API_URL}/create_support_query/?user_id=${userId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestData),
      });

      const result = await response.json();
      if (response.ok) {
        setResponseMessage('Your query has been submitted successfully!');
        setQuery('');
        setEmailOrPhone(''); // Clear email/phone input when query is successful
      } else {
        setResponseMessage(result.message || 'An error occurred.');
      }
    } catch (error) {
      setResponseMessage('An error occurred while submitting the query.');
    }
  };

  return (
    <div>
      {loggedIn ? (
        <Header1 style={{ display: 'flex', justifyContent: 'center', textAlign: 'center', alignItems: 'center' }} userId={userId} onLogout={onLogout} />
      ) : (
        <Header style={{ display: 'flex', justifyContent: 'center', textAlign: 'center', alignItems: 'center' }} />
      )}

      <Box sx={{ maxWidth: '600px', margin: '0 auto', padding: 3, mt: 10 }}>
        <Typography variant="h4" sx={{ mb: 2, textAlign: 'center' }}>
          Customer Support
        </Typography>

        {!loggedIn && (
          <TextField
            label="Enter your email or contact number"
            fullWidth
            value={emailOrPhone}
            onChange={(e) => setEmailOrPhone(e.target.value)}
            sx={{
              mb: 2,
              color: 'white', // Text inside the input field
              backgroundColor: 'black',
              mt: 3,
              '& .MuiInputLabel-root': {
                color: 'white', // Make label text white
              },
              '& .MuiInputBase-input': {
                color: 'white', // Text inside the input field
              },
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: 'white', // Optional: Change the border color to white
              },
            }}
          />
        )}

        <TextField
          label="Enter your query"
          fullWidth
          multiline
          rows={4}
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          sx={{
            mb: 2,
            color: 'white', // Text inside the input field
            backgroundColor: 'black',
            mt: 3,
            '& .MuiInputLabel-root': {
              color: 'white', // Make label text white
            },
            '& .MuiInputBase-input': {
              color: 'white', // Text inside the input field
            },
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: 'white', // Optional: Change the border color to white
            },
          }}
        />

        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          sx={{ width: '100%', mt: 2, mb: 5 }}
        >
          Submit Query
        </Button>

        {responseMessage && (
          <Typography variant="body1" sx={{ mt: 2, color: '#0077ff' }}>
            {responseMessage}
          </Typography>
        )}
      </Box>

      <Footer2 />
    </div>
  );
}

export default CustomerSupport;
