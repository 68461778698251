import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Box,
  Divider,
  TextField,
  Button,
  IconButton,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Header from '../Header/Header';
import Header1 from '../Header/Header1';
import Footer2 from './Footer2';
import axios from 'axios';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';

const faqs = [
  {
    question: "What is Anthrasync?",
    answer: "Anthrasync is a knowledge exchange platform where people can share and gain knowledge irrespective of their locations and languages.",
  },
  {
    question: "Why Anthrasync?",
    answer: "Free flow of knowledge is crucial for growth and development of everyone. Anthrasync platform plays an important role in ensuring the same.",
  },
  {
    question: "Who can use the platform?",
    answer: "All people above age 15 are eligible to use this platform. Currently the platform has been built for Indian users. In the future, the platform will be available globally.",
  },
  {
    question: "Who can benefit?",
    answer: "People who are eager to gain and passionate to share knowledge in areas of their interests.",
  },
  {
    question: "Can users earn money from this platform?",
    answer: "Yes, all the verified users are eligible for earning. The users have to undergo a verification process to get verified.",
  },
  {
    question: "Can users exchange knowledge in any language?",
    answer: "Yes, the platform is multilingual.",
  },
];

const API_URL = process.env.REACT_APP_API_URL;

function Faqs({ userId, loggedIn, onLogout }) {
  const [userQuestion, setUserQuestion] = useState('');
  const navigate = useNavigate();

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  // Handle logout
  const handleLogout = () => {
    onLogout(); // Call the passed logout function
    navigate('/login'); // Navigate to login page after logout
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post(`${API_URL}/create_asked_questions/`, {
        question: userQuestion,
        user_id: userId
      });
      alert('Your question has been submitted successfully!');
      setUserQuestion(''); // Clear the input field after submission
    } catch (error) {
      console.error("Error submitting the question:", error);
      alert("There was an error submitting your question. Please try again.");
    }
  };

  // Scroll to top when the component mounts
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div style={{ backgroundColor: 'black', minHeight: '100vh' }}>
      {loggedIn ? (
        <Header1 style={{ display: 'flex', justifyContent: 'center', textAlign: 'center', alignItems: 'center' }} userId={userId} onLogout={onLogout} />
      ) : (
        <Header style={{ display: 'flex', justifyContent: 'center', textAlign: 'center', alignItems: 'center' }} />
      )}

      <Box
        sx={{
          maxWidth: 800,
          margin: 'auto',
          padding: { xs: 2, sm: 3, md: 4, lg: 5 },
          marginTop: { xs: 4, sm: 5, md: 5, lg: 6 },
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
        }}
      >
        <Typography
          variant="h4"
          gutterBottom
          align="center"
          sx={{
            fontFamily: 'Helvetica, sans-serif',
            color: '#fff',
            fontWeight: 'bold',
          }}
        >
          Frequently Asked Questions
        </Typography>

        {faqs.map((faq, index) => (
          <Box
            key={index}
            sx={{
              borderRadius: 2,
              overflow: 'hidden',
              boxShadow: 2,
              backgroundColor: '#1C1C1C',
              transition: 'border-color 0.3s',
              border: '2px solid transparent',
              '&:hover': {
                borderColor: '#1E90FF',
              },
            }}
          >
            <Accordion sx={{ backgroundColor: 'transparent', '&:before': { display: 'none' } }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon sx={{ color: '#fff' }} />}
                aria-controls={`panel${index}-content`}
                id={`panel${index}-header`}
                sx={{ backgroundColor: '#1C1C1C', color: '#fff' }}
              >
                <Typography variant="h6" sx={{ fontFamily: 'Arial, sans-serif' }}>
                  {faq.question}
                </Typography>
              </AccordionSummary>
              <Divider sx={{ backgroundColor: '#fff' }} />
              <AccordionDetails sx={{ backgroundColor: '#1C1C1C', color: '#fff' }}>
                <Typography variant="body1" sx={{ fontFamily: 'Arial, sans-serif', color: '#bbb' }}>
                  {faq.answer}
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Box>
        ))}
      </Box>

      {loggedIn && (
        <Box
          sx={{
            width: { xs: '80%', sm: '85%' },
            maxWidth: 800,
            margin: 'auto',
            padding: { xs: 2, sm: 4 },
            backgroundColor: '#1C1C1C',
            borderRadius: 2,
            marginTop: { xs: 2, sm: 4 },
            color: 'white',
            transition: 'border-color 0.3s',
            border: '2px solid transparent',
            '&:hover': {
              borderColor: '#1E90FF',
            },
            mb: 10,
          }}
        >
          <Typography variant="h6" gutterBottom sx={{ fontFamily: 'Arial, sans-serif', color: '#fff' }}>
            Ask Us Your Question
          </Typography>
          <form onSubmit={handleSubmit}>
            <TextField
              fullWidth
              label="Your Question"
              variant="outlined"
              value={userQuestion}
              onChange={(e) => setUserQuestion(e.target.value)}
              sx={{
                marginBottom: 2,
                backgroundColor: '#333',
                color: '#fff',
                '& .MuiInputLabel-root': { color: '#fff' },
                '& .MuiOutlinedInput-root': { color: '#fff' },
              }}
            />
            <Button
              type="submit"
              variant="contained"
              color="primary"
              sx={{
                width: { xs: '100%', sm: 'auto' },
                backgroundColor: '#1E90FF',
                '&:hover': {
                  backgroundColor: '#0066cc',
                },
              }}
            >
              Submit
            </Button>
          </form>
        </Box>
      )}

      <IconButton
        onClick={scrollToTop}
        sx={{
          position: 'fixed',
          bottom: 16,
          right: 16,
          backgroundColor: 'white',
          color: 'black',
        }}
      >
        <ArrowUpwardIcon />
      </IconButton>

      <Footer2 />
    </div>
  );
}

export default Faqs;
