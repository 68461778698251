import React, { useEffect, useState, useRef } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import { Container, Typography, Button, Card,Box, CardContent, Grid, Checkbox, FormControlLabel, IconButton } from '@mui/material';
import { AccessTime, Language, AttachMoney, Person, Event } from '@mui/icons-material';
import Logo from '../Logo/Dark/Full - Copy/1Asset 17@72x-8.png';
import Footer2 from '../Footer/Footer2';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';



const Payments = ({ userId, onLogout }) => {
  const [csrfToken, setCsrfToken] = useState('');
  const [courses, setCourses] = useState([]);
  const [isChecked, setIsChecked] = useState(false);

  const navigate = useNavigate();

  const params = useParams();
  const type = params.type;
  const post_id = params.id;

  const [bookingCreated, setBookingCreated] = useState(false);
  const hasCalledApi = useRef(false); // useRef to track whether API has been called


  const [userData, setUserData] = useState({
      "name": "",
      "about": "",
      "contact": "",
      "designation":"",
      "created_at": "",
      "dob": "",
      "email": "",
      "gender": "",
      "id": "",
      "image": "",
      "languages": "",
      "password": "",
      "rating": "",
      "role": "",
      "status": "",
      "surname": "",
      "city":"",
      "State":"",
      "Country":"",
      "Pincode":"",
      "updated_at": "",
      "facebook":"",
      "instagram":"",
      "x":"",
      "linkedin":"",
      "youtube":"",
      "currentLevel": "Advanced",
    "totalTimeLearning": "150 hrs",
    "totalTimeTeaching": "50 hrs",
    "totalSessions": "25",
    "ratings": "4.8/5",
      });

  
  const API_URL = process.env.REACT_APP_API_URL;



  const createUserPostBookings = async () => {
    try {
      const response = await fetch(`${API_URL}/create_user_post_booking/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          post_id: post_id,
          user_id: userId,
        }),
      });

      const data = await response.json();
      if (response.ok) {
        setBookingCreated(true); // Mark booking as created
        hasCalledApi.current = true; // Mark API as called
      }
    } catch (error) {
      console.error('Error creating booking:', error);
    }
  };


  useEffect(() => {
    if (!userId) {
      console.log('User ID is not defined!');
      return;
    }

    // Axios request to fetch user data
    axios
      .get(`${API_URL}/get_user_data/?user_id=${userId}`)
      .then((response) => {
        console.log("Hi this is testing", response.data);
        setUserData(response.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [userId]);

  const CreateTransactions = (transactionId, status, paymentReason, id) => {
    const postData = {
      user_id: userId,
      post_id: post_id,
      order_id: id,
      transaction_id: transactionId,
      amount: courses.price,
      status: status,
      payment_reason: paymentReason,
    };

    axios
      .post(`${API_URL}/create_transactions/`, postData)
      .then((response) => {
        console.log('Transaction saved:', response.data);
      })
      .catch((error) => {
        console.error('Error saving transaction:', error);
      });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${API_URL}/get_posted_data/?post_id=${post_id}`);
        console.log('Course data:', response.data[0]);
        setCourses(response.data[0]);
      } catch (error) {
        console.error('Error fetching course data:', error);
      }
    };
    fetchData();
  }, [post_id]);

  useEffect(() => {
    const fetchCsrfToken = async () => {
      try {
        const response = await axios.get(`${API_URL}/get-csrf-token/`, { withCredentials: true });
        setCsrfToken(response.data.csrfToken);
      } catch (error) {
        console.error('Error fetching CSRF token:', error);
      }
    };
    fetchCsrfToken();
  }, []);

  const handlePayment = async () => {
    console.log('CSRF Token:', csrfToken);

    if (!csrfToken || !courses.price || !courses.topic_name) {
      console.error('Missing necessary information for payment.');
      return;
    }

    try {
      const response = await axios.post(
        `${API_URL}/create-order/`,
        {
          amount: courses.price * 100, // Amount in paise
        },
        {
          headers: {
            'X-CSRFToken': csrfToken,
          },
          withCredentials: true,
        }
      );

      const { id } = response.data;
  
      console.log('Order response:', response.data);
      console.log('Testing for Order Id:', id);

      if (!id) {
        console.error('No order ID received.');
        return;
      }

      const options = {
        key: 'rzp_test_iLMcsP06UTqoWj', // Razorpay key ID
        amount: courses.price * 100, // Amount in paise
        currency: 'INR',
        name: 'Anthrasync',
        description: 'Test Transaction',
        order_id: id,
        handler: function (response) {
          CreateTransactions(response.razorpay_payment_id, 'success', 'Payment successful',id);
          createUserPostBookings();
          alert(`Payment successful: ${response.razorpay_payment_id}`);
          navigate(`/post-tab-details/${post_id}/${userId}`, { state: { paymentStatus: 'success', paymentId: response.razorpay_payment_id } });
        },
        modal: {
          ondismiss: function () {
            CreateTransactions(null, 'failed', 'Payment was cancelled',id);
            alert('Payment was cancelled');
            navigate(`/post-tab-details/${post_id}/${userId}`, { state: { paymentStatus: 'cancelled' } });
          },
        },
        prefill: {
          name: userData.name,
          email: userData.email,
          contact: userData.contact,
        },
        theme: {
          color: '#F37254',
        },
      };

      const paymentObject = new window.Razorpay(options);
      paymentObject.open();
    } catch (error) {
      console.error('Payment error:', error.response || error.message);
    }
  };

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
};

const formatTime = (timeString) => {
  const time = new Date(`1970-01-01T${timeString}:00Z`);  // Use a fixed date (1970) to parse time
  return time.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true });  // Format to 12-hour AM/PM
};

const formatDuration = (durationInMinutes) => {
  const hours = Math.floor(durationInMinutes / 60);
  const minutes = durationInMinutes % 60;
  
  // If duration is less than an hour, only show minutes
  if (hours > 0) {
    return `${hours} hour${hours > 1 ? 's' : ''} ${minutes} minute${minutes !== 1 ? 's' : ''}`;
  }
  
  return `${minutes} minute${minutes !== 1 ? 's' : ''}`;
};


  return (
    <div>
    <Container maxWidth="sm" sx={{ mt: 1, paddingBottom: 4 }}>
    <Card
  sx={{
    backgroundColor: 'black',
    boxShadow: 5,
    borderRadius: 2,
    padding: { xs: 3, sm: 4, md: 5 },  // Responsive padding
    transition: 'transform 0.3s ease-in-out',
    '&:hover': {
      transform: 'scale(1.05)',
    },
  }}
>
  <Box sx={{ textAlign: 'center', mb: { xs: 4, sm: 7, md: 10 } }}>
    <Link to="/home">
      <img
        src={Logo}
        alt="Logo"
        style={{
          width: '100%',
          maxWidth: '350px', // Cap the maximum size of the logo
          height: 'auto',
        }}
      />
    </Link>
  </Box>

  <CardContent>
    {/* Main Title */}
    <Typography
      variant="h4"
      gutterBottom
      sx={{
        textAlign: 'center',
        color: 'white',
        fontWeight: 'bold',
        letterSpacing: '1px',
        mb: { xs: 3, sm: 5, md: 7 }, // Adjusted for responsiveness
        fontSize: { xs: '1.8rem', sm: '2rem', md: '2.5rem' }, // Responsive font size
      }}
    >
      Booking and Order Details
    </Typography>

    {/* Payment Info Section */}
    <Grid container spacing={4}>
      <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center' }}>
        {/* <Person sx={{ color: '#1E90FF', mr: 3 }} /> */}
        <Typography variant="h6" sx={{ color: 'white', fontSize: { xs: '1rem', sm: '1.1rem', md: '1.2rem' } }}>
          Topic Name: {courses.topic_name}
        </Typography>
      </Grid>

      <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center' }}>
        {/* <AccessTime sx={{ color: '#FF6347', mr: 3 }} /> */}
        <Typography variant="h6" sx={{ color: 'white', fontSize: { xs: '1rem', sm: '1.1rem', md: '1.2rem' } }}>
          Session Conducted By: {courses.name} {courses.surname}
        </Typography>
      </Grid>

      <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center' }}>
        {/* <Language sx={{ color: '#FFD700', mr: 3 }} /> */}
        <Typography variant="h6" sx={{ color: 'white', fontSize: { xs: '1rem', sm: '1.1rem', md: '1.2rem' } }}>
          Language: {courses.language}
        </Typography>
      </Grid>

      <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center' }}>
        {/* <AttachMoney sx={{ color: '#32CD32', mr: 3 }} /> */}
        <Typography variant="h6" sx={{ color: 'white', fontSize: { xs: '1rem', sm: '1.1rem', md: '1.2rem' } }}>
          Price: ₹{courses.price}
        </Typography>
      </Grid>

      <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center' }}>
        {/* <Event sx={{ color: '#FF6347', mr: 3 }} /> */}
        <Typography variant="h6" sx={{ color: 'white', fontSize: { xs: '1rem', sm: '1.1rem', md: '1.2rem' } }}>
          Date: {courses.date}
        </Typography>
      </Grid>

      <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center' }}>
        {/* <AccessTime sx={{ color: '#FFD700', mr: 3 }} /> */}
        <Typography variant="h6" sx={{ color: 'white', fontSize: { xs: '1rem', sm: '1.1rem', md: '1.2rem' } }}>
          Start Time: {formatTime(courses.start_time)}
        </Typography>
      </Grid>

      <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center' }}>
        {/* <AccessTime sx={{ color: '#32CD32', mr: 3 }} /> */}
        <Typography variant="h6" sx={{ color: 'white', fontSize: { xs: '1rem', sm: '1.1rem', md: '1.2rem' } }}>
          Duration: {formatDuration(courses.duration)}
        </Typography>
      </Grid>
    </Grid>

    {/* Billing Information Section */}
    <Typography
      variant="h4"
      sx={{
        color: 'white',
        fontWeight: 'bold',
        mb: 5,
        mt: 15,
        textAlign: 'center',
        fontSize: { xs: '1.8rem', sm: '2rem', md: '2.5rem' }, // Responsive font size
      }}
    >
      Billing Information
    </Typography>

    <Grid container spacing={4}>
      <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center' }}>
        {/* <Person sx={{ color: '#1E90FF', mr: 3 }} /> */}
        <Typography variant="h6" sx={{ color: 'white', fontSize: { xs: '1rem', sm: '1.1rem', md: '1.2rem' } }}>
          Username: {userData.name} {userData.surname}
        </Typography>
      </Grid>

      <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography variant="h6" sx={{ color: 'white', fontSize: { xs: '1rem', sm: '1.1rem', md: '1.2rem' } }}>
          Email: {userData.email}
        </Typography>
      </Grid>

      <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography variant="h6" sx={{ color: 'white', fontSize: { xs: '1rem', sm: '1.1rem', md: '1.2rem' } }}>
          Contact: {userData.contact}
        </Typography>
      </Grid>

      <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography variant="h6" sx={{ color: 'white', fontSize: { xs: '1rem', sm: '1.1rem', md: '1.2rem' } }}>
          City: {userData.city}
        </Typography>
      </Grid>

      <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography variant="h6" sx={{ color: 'white', fontSize: { xs: '1rem', sm: '1.1rem', md: '1.2rem' } }}>
          State: {userData.State}
        </Typography>
      </Grid>

      <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography variant="h6" sx={{ color: 'white', fontSize: { xs: '1rem', sm: '1.1rem', md: '1.2rem' } }}>
          Country: {userData.Country}
        </Typography>
      </Grid>

      <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography variant="h6" sx={{ color: 'white', fontSize: { xs: '1rem', sm: '1.1rem', md: '1.2rem' } }}>
          Pincode: {userData.Pincode}
        </Typography>
      </Grid>
    </Grid>

    {/* Checkbox for confirmation */}
    <Grid item xs={12} sx={{ mt: 3 }}>
      <FormControlLabel
        control={
          <Checkbox
            checked={isChecked}
            onChange={handleCheckboxChange}
            sx={{
              color: 'white',
              '&.Mui-checked': {
                color: '#1E90FF',
              },
            }}
          />
        }
        label="I agree and confirm all the details are correct."
        sx={{
          color: 'white',
          '& .MuiFormControlLabel-label': {
            color: 'white',
          },
        }}
      />
    </Grid>

    {/* Pay Now Button */}
    <Grid item xs={12} sx={{ mt: 3 }}>
      <Button
        variant="contained"
        color="primary"
        onClick={handlePayment}
        fullWidth
        disabled={!isChecked}
        sx={{
          '&:hover': {
            transform: 'scale(1.05)',
            transition: 'transform 0.3s ease-in-out',
            backgroundColor: '#FF6347',
          },
          padding: '14px',
          backgroundColor: '#1E90FF',
          color: 'white',
          fontWeight: 'bold',
          borderRadius: '8px',
          fontSize: { xs: '1rem', sm: '1.2rem', md: '1.5rem' }, // Responsive font size
        }}
      >
        Pay Now
      </Button>
    </Grid>
  </CardContent>
</Card>

</Container>
<IconButton
                onClick={scrollToTop}
                sx={{ position: 'fixed', bottom: 16, right: 16, backgroundColor: 'white', color: 'black' }}>
                <ArrowUpwardIcon />
            </IconButton>
<Footer2/>
</div>

  );
};

export default Payments;
