import React, { useEffect, useState } from "react";
import axios from "axios";
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from "@mui/material";


const API_URL = process.env.REACT_APP_API_URL;

function PaymentDetails({ userId }) {
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchTransactions = async () => {
      try {
        const response = await axios.get(`${API_URL}/api/transactions/${userId}/`);
        setTransactions(response.data);
      } catch (err) {
        setError('No Payment Details available');
      } finally {
        setLoading(false);
      }
    };

    fetchTransactions();
  }, [userId]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <Box
  sx={{
    padding: { xs: "1rem", sm: "2rem" }, // Responsive padding
    backgroundColor: "#1111",
    color: "white",
    borderRadius: "12px",
    boxShadow: "0 10px 20px rgba(0, 0, 0, 0.25)",
    ml: { xs: 0, sm: 5, md: 0 }, // Adjust margin-left on small and medium screens
    overflow: "hidden",
    width: "100%",
    maxWidth: "100%",
  }}
>
  <TableContainer
    component={Paper}
    sx={{
      backgroundColor: "#1111",
      borderRadius: "10px",
      boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)",
      width: "100%",
      maxWidth: "100%",
      overflowX: "auto",
      marginBottom: "2rem",
    }}
  >
    <Table sx={{ tableLayout: "auto", width: "100%" }}>
      <TableHead>
        <TableRow>
          <TableCell
            sx={{
              color: "white",
              backgroundColor: "#2222",
              fontWeight: "bold",
              fontSize: { xs: "0.8rem", sm: "1rem" }, // Responsive font size
              textTransform: "uppercase",
              borderBottom: "1.5px solid #5555",
              textAlign: "center",
              fontFamily: "'Helvetica', sans-serif",
              "&:hover": { color: "#1E90FF", borderColor: "#1E90FF" },
            }}
          >
            S No.
          </TableCell>
          <TableCell
            sx={{
              color: "white",
              backgroundColor: "#2222",
              fontWeight: "bold",
              fontSize: { xs: "0.8rem", sm: "1rem" },
              textTransform: "uppercase",
              borderBottom: "1.5px solid #5555",
              textAlign: "center",
              fontFamily: "'Helvetica', sans-serif",
              "&:hover": { color: "#1E90FF", borderColor: "#1E90FF" },
            }}
          >
            Order ID
          </TableCell>
          <TableCell
            sx={{
              color: "white",
              backgroundColor: "#2222",
              fontWeight: "bold",
              fontSize: { xs: "0.8rem", sm: "1rem" },
              textTransform: "uppercase",
              borderBottom: "1.5px solid #5555",
              textAlign: "center",
              fontFamily: "'Helvetica', sans-serif",
              "&:hover": { color: "#1E90FF", borderColor: "#1E90FF" },
            }}
          >
            Date
          </TableCell>
          <TableCell
            sx={{
              color: "white",
              backgroundColor: "#2222",
              fontWeight: "bold",
              fontSize: { xs: "0.8rem", sm: "1rem" },
              textTransform: "uppercase",
              borderBottom: "1.5px solid #5555",
              textAlign: "center",
              fontFamily: "'Helvetica', sans-serif",
              "&:hover": { color: "#1E90FF", borderColor: "#1E90FF" },
            }}
          >
            Time
          </TableCell>
          <TableCell
            sx={{
              color: "white",
              backgroundColor: "#2222",
              fontWeight: "bold",
              fontSize: { xs: "0.8rem", sm: "1rem" },
              textTransform: "uppercase",
              borderBottom: "1.5px solid #5555",
              textAlign: "center",
              fontFamily: "'Helvetica', sans-serif",
              "&:hover": { color: "#1E90FF", borderColor: "#1E90FF" },
            }}
          >
            Transaction ID
          </TableCell>
          <TableCell
            sx={{
              color: "white",
              backgroundColor: "#2222",
              fontWeight: "bold",
              fontSize: { xs: "0.8rem", sm: "1rem" },
              textTransform: "uppercase",
              borderBottom: "1.5px solid #5555",
              textAlign: "center",
              fontFamily: "'Helvetica', sans-serif",
              "&:hover": { color: "#1E90FF", borderColor: "#1E90FF" },
            }}
          >
            Status
          </TableCell>
          <TableCell
            sx={{
              color: "white",
              backgroundColor: "#2222",
              fontWeight: "bold",
              fontSize: { xs: "0.8rem", sm: "1rem" },
              textTransform: "uppercase",
              borderBottom: "1.5px solid #5555",
              textAlign: "center",
              fontFamily: "'Helvetica', sans-serif",
              "&:hover": { color: "#1E90FF", borderColor: "#1E90FF" },
            }}
          >
            Cost
          </TableCell>
          <TableCell
            sx={{
              color: "white",
              backgroundColor: "#2222",
              fontWeight: "bold",
              fontSize: { xs: "0.8rem", sm: "1rem" },
              textTransform: "uppercase",
              borderBottom: "1.5px solid #5555",
              textAlign: "center",
              fontFamily: "'Helvetica', sans-serif",
              "&:hover": { color: "#1E90FF", borderColor: "#1E90FF" },
            }}
          >
            Remarks
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {transactions.map((transaction, index) => {
          const date = new Date(transaction.created_at);
          const formattedDate = date.toLocaleDateString();
          const formattedTime = date.toLocaleTimeString();

          return (
            <TableRow key={transaction.id}>
              <TableCell
                sx={{
                  color: "white",
                  textAlign: "center",
                  borderBottom: "1.5px solid #5555",
                  "&:hover": { color: "#1E90FF" },
                }}
              >
                {index + 1}
              </TableCell>
              <TableCell
                sx={{
                  color: "white",
                  textAlign: "center",
                  borderBottom: "1.5px solid #5555",
                  "&:hover": { color: "#1E90FF" },
                }}
              >
                {transaction.post_id}
              </TableCell>
              <TableCell
                sx={{
                  color: "white",
                  textAlign: "center",
                  borderBottom: "1.5px solid #5555",
                  "&:hover": { color: "#1E90FF" },
                }}
              >
                {formattedDate}
              </TableCell>
              <TableCell
                sx={{
                  color: "white",
                  textAlign: "center",
                  borderBottom: "1.5px solid #5555",
                  "&:hover": { color: "#1E90FF" },
                }}
              >
                {formattedTime}
              </TableCell>
              <TableCell
                sx={{
                  color: "white",
                  textAlign: "center",
                  borderBottom: "1.5px solid #5555",
                  "&:hover": { color: "#1E90FF" },
                }}
              >
                {transaction.transaction_id}
              </TableCell>
              <TableCell
                sx={{
                  color:
                    transaction.status === "Successful"
                      ? "#4caf50"
                      : transaction.status === "Failed"
                      ? "#f44336"
                      : "white",
                  fontWeight: "bold",
                  textTransform: "uppercase",
                  textAlign: "center",
                  borderBottom: "1.5px solid #5555",
                  "&:hover": { color: "#1E90FF" },
                }}
              >
                {transaction.status}
              </TableCell>
              <TableCell
                sx={{
                  color: "white",
                  textAlign: "center",
                  borderBottom: "1.5px solid #5555",
                  "&:hover": { color: "#1E90FF" },
                }}
              >
                {transaction.amount}
              </TableCell>
              <TableCell
                sx={{
                  color: "white",
                  textAlign: "center",
                  borderBottom: "1.5px solid #5555",
                  "&:hover": { color: "#1E90FF" },
                }}
              >
                {transaction.payment_reason}
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  </TableContainer>
</Box>

  );
}

export default PaymentDetails;
