import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Container,
  Card,
  CardContent,
  Typography,
  Grid,
  Divider,
  Box,
  CircularProgress,
  Button
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import Header1 from '../Header/Header1';

const API_URL = process.env.REACT_APP_API_URL;

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: '#121212',
    minHeight: '100vh',
    paddingTop: theme.spacing(8),
  },
  card: {
    backgroundColor: '#1C1C1C',
    color: '#fff',
    marginBottom: theme.spacing(3),
    borderRadius: '8px',
    boxShadow: '0 2px 10px rgba(0, 0, 0, 0.3)',
    '&:hover': {
      boxShadow: '0 6px 20px rgba(0, 0, 0, 0.5)',
    },
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
  },
  question: {
    fontSize: '1.2rem',
    fontWeight: 'bold',
    color: '#1E90FF',
  },
  answer: {
    fontSize: '1rem',
    color: '#bbb',
    marginBottom: theme.spacing(1),
  },
  username: {
    fontSize: '0.9rem',
    fontStyle: 'italic',
    color: '#ddd',
  },
  loading: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(4),
  },
  error: {
    color: '#ff4747',
    textAlign: 'center',
  },
  button: {
    backgroundColor: '#1E90FF',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#0066cc',
    },
  },
}));

function AdminFaqs({userId, onLogout}) {
  const [faqs, setFaqs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const classes = useStyles();

  const fetchFaqs = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${API_URL}/get_asked_questions/`);
      setFaqs(response.data); // Assuming the API response directly returns the list of questions
    } catch (error) {
      setError('Failed to fetch FAQs');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchFaqs();
  }, []);

  return (
    <div>
        <Header1 userId={userId} onLogout={onLogout}/>
    <Container className={classes.container} maxWidth="lg">
      <Typography variant="h4" align="center" gutterBottom style={{ color: '#fff' }}>
        Admin - Asked Questions
      </Typography>

      {loading ? (
        <div className={classes.loading}>
          <CircularProgress style={{ color: '#1E90FF' }} />
        </div>
      ) : error ? (
        <Typography className={classes.error}>{error}</Typography>
      ) : (
        <Grid container spacing={3} justifyContent="center">
          {faqs.length === 0 ? (
            <Typography variant="h6" align="center" style={{ color: '#fff' }}>
              No questions available.
            </Typography>
          ) : (
            faqs.map((faq) => (
              <Grid item xs={12} sm={6} md={4} key={faq.id}>
                <Card className={classes.card}>
                  <CardContent className={classes.cardContent}>
                    <Typography variant="h6" className={classes.question}>
                      {faq.question}
                    </Typography>
                    <Divider style={{ backgroundColor: '#555' }} />
                    {/* <Typography className={classes.answer}>
                      {faq.answer || 'No answer provided yet.'}
                    </Typography> */}
                    <Typography className={classes.username}>
                      Asked by: {faq.username}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))
          )}
        </Grid>
      )}

      <Box textAlign="center" mt={5}>
        <Button
          variant="contained"
          className={classes.button}
          onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
        >
          Scroll to Top
        </Button>
      </Box>
    </Container>
    </div>
  );
}

export default AdminFaqs;
