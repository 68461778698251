import React, { useState, useEffect } from 'react';
import { useParams, Link, useLocation } from 'react-router-dom';
import axios from 'axios';
import { Box, Grid, Typography, Card, Button, IconButton, Chip, Skeleton } from '@mui/material';
import { Facebook, Instagram, X, LinkedIn, YouTube } from '@mui/icons-material';
import Teacher from "../Icon/Divakar.png";  // Replace with actual teacher's profile image path
import { useNavigate } from 'react-router-dom';
import Header1 from '../Header/Header1';
import Footer from '../Footer/Footer';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import ReportIcon from '@mui/icons-material/Report';
import ShareButton from "../Share/ShareButton";
import Footer2 from '../Footer/Footer2';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import PostRecommendations from '../Recommendations/PostRecommendations';
import avatar from './default-avatar.png'
import ShareIcon from '@mui/icons-material/Share';
import FlagIcon from '@mui/icons-material/Flag';


const API_URL = process.env.REACT_APP_API_URL;


const PostTabDetails = ({ onLogout }) => {
    const { id, userId } = useParams();
    const navigate = useNavigate();
    const [course, setCourse] = useState(null);
    const [action, setAction] = useState(null);
    const location = useLocation();
    const paymentStatus = location.state?.paymentStatus || 'unknown';  // Default to 'unknown' if not provided
    // const likes = "25"
    // const dislikes = "0"
    // number of recommendations to fetch

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${API_URL}/get_posted_data/?post_id=${id}`);
                // console.log("Fetched course data:", response.data[0]);
                setCourse(response.data[0]);
                await createRecentActivity(userId, id);
                // Ensure that the view update is only triggered once when the component mounts
                UpdatePostViews();
                updateRecentViewedPost(userId, id);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, [id, userId]);  // Depend on 'id' and 'userId' to ensure the data and view count is fetched only once


    useEffect(() => {
        const getUserPostInteraction = async () => {
            try {
                const response = await axios.get(
                    `h${API_URL}/get_user_post_interaction/`,
                    {
                        params: {
                            user_id: userId,
                            post_id: id,
                        },
                    }
                );
                setAction(response.data.action); // Set the action (like, dislike, or null)
            } catch (error) {
                setAction(null);
                console.error("Error fetching user post interaction:", error);
            }
        };

        getUserPostInteraction();
    }, [userId, id]);

    const handleLogout = () => {
        onLogout();
        navigate('/login');
    };

    const updateRecentViewedPost = async (userId, postId) => {
        try {
            const response = await axios.patch(`${API_URL}/update-recent-viewed-post/`, {
                user_id: userId,
                post_id: postId,
            });

            // Handle success (you can return or handle the response data)
            console.log('Updated recent viewed post:', response.data);
        } catch (error) {
            // Handle error
            console.error('Error updating recent viewed post:', error.response ? error.response.data : error.message);
        }
    };



    const UpdatePostViews = async () => {
        try {
            const response = await axios.post(`${API_URL}/update_post_views/?post_id=${id}`);
            console.log("Post views updated:", response.data);
        } catch (error) {
            console.error('Error updating post views:', error);
        }
    };

    const createRecentActivity = async (userId, postId) => {
        try {
            const response = await fetch(`${API_URL}/create_recent_activity/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    user_id: userId,
                    post_id: postId,
                }),
            });

            const data = await response.json();
            if (response.ok) {
                console.log('Recent activity logged successfully:', data);
            } else {
                console.error('Error logging recent activity:', data);
            }
        } catch (error) {
            console.error('Error calling create_recent_activity API:', error);
        }
    };

    if (!course) {
        return <Typography variant="h6" sx={{ textAlign: "center", padding: "2rem" }}>Loading...</Typography>;
    }

    const formatStartTime = (time) => {
        const [hours, minutes] = time.split(':');
        const dateObj = new Date();
        dateObj.setHours(hours);
        dateObj.setMinutes(minutes);

        // Format using Intl.DateTimeFormat
        const formatter = new Intl.DateTimeFormat('en-US', {
            hour: '2-digit', minute: '2-digit', hour12: true
        });

        return formatter.format(dateObj); // returns time in AM/PM format
    }

    const formatDuration = (durationInMinutes) => {
        const hours = Math.floor(durationInMinutes / 60);
        const minutes = durationInMinutes % 60;

        // If duration is less than an hour, only show minutes
        if (hours > 0) {
            return `${hours} hour${hours > 1 ? 's' : ''} ${minutes} minute${minutes !== 1 ? 's' : ''}`;
        }

        return `${minutes} minute${minutes !== 1 ? 's' : ''}`;
    };


    const {
        topic_name, section_name, subsection_name, description, language, date, start_time,
        duration, seats_left, total_seats, name, surname, profile_picture,
        about, rating, experience, id: cardId, price,
        facebook, instagram, x, linkedin, youtube, likes, dislikes, specialized_fields, roles,
    } = course;

    // const durationFormatted = duration ? `${Math.floor(duration / 60)} hours ${duration % 60} minutes` : "N/A";
    const durationFormatted = formatDuration(duration)
    const teacherRating = rating || "Not rated yet";
    const formattedStartTime = formatStartTime(start_time);

    const UpdatePostLikes = async (post_id) => {
        try {
            const response = await axios.post(`h${API_URL}/update_post_likes/?post_id=${post_id}&user_id=${userId}`);
            setCourse(prevCourse => ({
                ...prevCourse,
                likes: response.data.likes,
                dislikes: response.data.dislikes
            }));
        } catch (error) {
            console.error('Error updating post views:', error);
        }
    };

    const handleLikeClick = (post_id) => {
        // Handle like click (send like data to the server or update state)
        UpdatePostLikes(post_id)
        setAction('like');
    };

    const UpdatePostDisLikes = async (post_id) => {
        try {
            const response = await axios.post(`${API_URL}/update_post_dislikes/?post_id=${post_id}&user_id=${userId}`);
            setCourse(prevCourse => ({
                ...prevCourse,
                likes: response.data.likes,
                dislikes: response.data.dislikes
            }));
        } catch (error) {
            console.error('Error updating post views:', error);
        }
    };


    const handleDisLikeClick = (post_id) => {
        // Handle like click (send like data to the server or update state)
        UpdatePostDisLikes(post_id)
        setAction('dislike');
    };


    const handleShareClick = () => {
        // Handle share click (open sharing options or logic)
        console.log('Share clicked');
    };

    const ReportPosts = async (post_id) => {
        try {
            const response = await axios.post(`${API_URL}/report_posts/?post_id=${post_id}&user_id=${userId}`);

        } catch (error) {
            console.error('Error updating post views:', error);
        }
    };


    const handleReportClick = (post_id) => {
        ReportPosts(post_id)
    };

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };


    return (
        <div>
            <Header1 userId={userId} onLogout={onLogout} />
            <Box sx={{ backgroundColor: "#000000", minHeight: "100vh", marginTop: "100px", marginBottom:"100px" }}>
                <Grid container spacing={3} sx={{ height: "100%", backgroundColor: '#000000', justifyContent: 'center' }}>
                    {/* <Grid >
                        <Card sx={{
                padding: { xs: "2rem", sm: "3rem" }, // Responsive padding
                backgroundColor: "#1a1a1a", // Darker background for modern look
                color: "#fff",
                borderRadius: "16px",
                height: "100%",
                boxSizing: "border-box",
                boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)",
                "&:hover": {
                    transform: "translateY(-5px)",
                    boxShadow: "0 8px 20px rgba(0, 0, 0, 0.3)",
                },
            }}>
                            
                        </Card>
                    </Grid> */}


                    <Grid container justifyContent="center">
                        <Box sx={{
                            padding: { xs: "2rem", sm: "3rem" }, // Responsive padding
                            backgroundColor: "#000000", // Darker background for modern look
                            color: "#fff",
                            borderRadius: "16px",
                            height: "100%",
                            boxSizing: "border-box",
                            boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)",
                            "&:hover": {
                                transform: "translateY(-5px)",
                                boxShadow: "0 8px 20px rgba(0, 0, 0, 0.3)",
                            },
                        }}>

                            {/* Container for section and subsection names */}
                            <Box sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                width: "100%",
                                mb: 3,
                            }}>
                                <Typography
                                    variant="h6"
                                    sx={{
                                        fontWeight: "bold",
                                        color: "#fff",
                                        textAlign: "center",
                                        fontFamily: "'Helvetica', sans-serif",
                                        padding: "0.5rem",
                                        borderRadius: "15px",
                                        "&:hover": {
                                            color: '#1E90FF',
                                            transform: "translateY(-3px)",
                                        },
                                    }}
                                >
                                    {section_name} &gt; {subsection_name}
                                </Typography>
                            </Box>

                            {/* Topic Name */}
                            <Typography
                                variant="h4"
                                sx={{
                                    fontWeight: "bold",
                                    marginBottom: "1.5rem",
                                    color: "#fff",
                                    textAlign: "center",
                                    fontFamily: "'Helvetica', sans-serif",
                                    "&:hover": {
                                        color: '#1E90FF',
                                    },
                                }}
                            >
                                {topic_name}
                            </Typography>



                            <Box sx={{ position: 'relative', display: 'flex', flexDirection: 'column', alignItems: 'center', paddingTop: "3rem" }}>
                                <img
                                    src={profile_picture ? `${API_URL}${profile_picture}` : avatar}
                                    alt="profile-picture"
                                    style={{
                                        width: "100%",           // Make the image take up the full width of its container
                                        maxWidth: "200px",       // Maximum size for larger screens
                                        height: "auto",          // Maintain aspect ratio
                                        objectFit: "cover",      // Crop image to fit within the circle
                                        borderRadius: "50%",     // Circular profile picture
                                        borderColor: "#5555",
                                        border: "1.5px solid #6666",
                                        transition: "transform 0.3s ease, border-color 0.3s ease", // Smooth transition on hover
                                    }}
                                    onMouseEnter={(e) => {
                                        // Enlarge image and change border color on hover
                                        e.target.style.transform = "scale(1.1)"; // Enlarge image to 110%
                                        e.target.style.borderColor = "#1E90FF";  // Change border color to light blue
                                    }}
                                    onMouseLeave={(e) => {
                                        // Reset image to original size and border color when hover ends
                                        e.target.style.transform = "scale(1)";   // Reset to original size
                                        e.target.style.borderColor = "#6666";    // Reset border color to original
                                    }}
                                />


                                <Box sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    mt: 2, // Margin top for some space between the image and the icons
                                }}>
                                    {facebook && (
                                        <a href={facebook} target="_blank" rel="noopener noreferrer">
                                            <IconButton sx={{
                                                color: 'white',
                                                "&:hover": {
                                                    transform: "translateY(-3px)",
                                                    color: '#1E90FF',
                                                },
                                                mx: 1, // Added horizontal margin to space the icons
                                            }}>
                                                <Facebook />
                                            </IconButton>
                                        </a>
                                    )}

                                    {instagram && (
                                        <a href={instagram} target="_blank" rel="noopener noreferrer">
                                            <IconButton sx={{
                                                color: 'white',
                                                "&:hover": {
                                                    transform: "translateY(-3px)",
                                                    color: '#1E90FF',
                                                },
                                                mx: 1,
                                            }}>
                                                <Instagram />
                                            </IconButton>
                                        </a>
                                    )}

                                    {x && (
                                        <a href={x} target="_blank" rel="noopener noreferrer">
                                            <IconButton sx={{
                                                color: 'white',
                                                "&:hover": {
                                                    transform: "translateY(-3px)",
                                                    color: '#1E90FF',
                                                },
                                                mx: 1,
                                            }}>
                                                <X />
                                            </IconButton>
                                        </a>
                                    )}

                                    {linkedin && (
                                        <a href={linkedin} target="_blank" rel="noopener noreferrer">
                                            <IconButton sx={{
                                                color: 'white',
                                                "&:hover": {
                                                    transform: "translateY(-3px)",
                                                    color: '#1E90FF',
                                                },
                                                mx: 1,
                                            }}>
                                                <LinkedIn />
                                            </IconButton>
                                        </a>
                                    )}

                                    {youtube && (
                                        <a href={youtube} target="_blank" rel="noopener noreferrer">
                                            <IconButton sx={{
                                                color: 'white',
                                                "&:hover": {
                                                    transform: "translateY(-3px)",
                                                    color: '#1E90FF',
                                                },
                                                mx: 1,
                                            }}>
                                                <YouTube />
                                            </IconButton>
                                        </a>
                                    )}
                                </Box>
                            </Box>


                            <Box sx={{ textAlign: 'center', marginTop: '2rem', padding: '1rem', width: '100%' }}>
                                {/* User's Full Name */}
                                <Typography
                                    variant="h6"
                                    sx={{
                                        fontWeight: "bold",
                                        color: "#fff",
                                        fontFamily: "'Helvetica', sans-serif",
                                        "&:hover": { color: '#1E90FF' },
                                        fontSize: { xs: '1.2rem', sm: '1.5rem', md: '1.8rem' },  // Responsive font size
                                    }}
                                >
                                    {name || "name"} {surname || "surname"}
                                </Typography>

                                {/* About Text */}
                                <Typography
                                    variant="body2"
                                    sx={{
                                        marginBottom: "0.8rem",  // Bottom margin for spacing between elements
                                        marginTop: "0.8rem",
                                        color: "#ccc",  // Light gray color for the text
                                        fontFamily: "'Helvetica', sans-serif",  // Helvetica font family for a clean, modern feel
                                        fontSize: { xs: '0.9rem', sm: '1rem', md: '1.1rem' },  // Responsive font size
                                        fontStyle: "italic",  // Make the font italic
                                        textAlign: "center",  // Center-align the text
                                        position: "relative",  // To position the quotes around the text
                                        display: "inline-block",  // Keep text inline but allow styling
                                        maxWidth: "80%",  // Limit width to avoid stretching on large screens
                                        marginLeft: "auto",  // Center horizontally
                                        marginRight: "auto",  // Center horizontally
                                        "&:hover": {
                                            color: "#fff",  // White color on hover to highlight the text
                                            cursor: "pointer",  // Change cursor to pointer to indicate interactiveness
                                            transform: "translateY(-2px)",  // Slight upward movement on hover for effect
                                            transition: "transform 0.3s ease, color 0.3s ease",  // Smooth transition effect
                                        },
                                    }}
                                >
                                    {about || "About the verified user"}
                                </Typography>



                            </Box>
                            <Grid container spacing={2} alignItems="center" sx={{ mt: 2 }}>
                                {/* Rating Section */}
                                <Grid item xs={12} sm={6}>
                                    <Typography
                                        variant="body2"
                                        sx={{
                                            color: "white",  // Blue color for text
                                            textAlign: "center",  // Center-align text for all screen sizes
                                            fontWeight: "bold",  // Bold text to make it stand out
                                            maxWidth: "300px",  // Limit width to keep the text from stretching too wide
                                            backgroundColor: "#1E90FF",  // White background for contrast
                                            fontFamily: "'Helvetica', sans-serif",  // Consistent font style
                                            fontSize: { xs: "0.9rem", sm: "1rem", md: "1.1rem" },  // Responsive font size
                                            margin: "0 auto",  // Center the element horizontally
                                            padding: "0.5rem",  // Add padding inside the element for spacing
                                            borderRadius: "8px",  // Rounded corners for a modern look
                                            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",  // Subtle shadow to create depth
                                            transition: "transform 0.3s ease, box-shadow 0.3s ease",  // Smooth hover transition
                                            "&:hover": {
                                                transform: "scale(1.05)",  // Slight zoom effect on hover
                                                boxShadow: "0px 6px 12px rgba(0, 0, 0, 0.2)",  // Deepen shadow on hover
                                            },
                                        }}
                                    >
                                        <strong>Rating:</strong> {teacherRating}
                                    </Typography>

                                </Grid>

                                {/* Experience Section */}
                                <Grid item xs={12} sm={6}>
                                    <Typography
                                        variant="body2"
                                        sx={{
                                            color: "white",  // Blue color for text
                                            textAlign: "center",  // Center-align text for all screen sizes
                                            fontWeight: "bold",  // Bold text to make it stand out
                                            maxWidth: "300px",  // Limit width to keep the text from stretching too wide
                                            backgroundColor: "#1E90FF",  // White background for contrast
                                            fontFamily: "'Helvetica', sans-serif",  // Consistent font style
                                            fontSize: { xs: "0.9rem", sm: "1rem", md: "1.1rem" },  // Responsive font size
                                            margin: "0 auto",  // Center the element horizontally
                                            padding: "0.5rem",  // Add padding inside the element for spacing
                                            borderRadius: "8px",  // Rounded corners for a modern look
                                            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",  // Subtle shadow to create depth
                                            transition: "transform 0.3s ease, box-shadow 0.3s ease",  // Smooth hover transition
                                            "&:hover": {
                                                transform: "scale(1.05)",  // Slight zoom effect on hover
                                                boxShadow: "0px 6px 12px rgba(0, 0, 0, 0.2)",  // Deepen shadow on hover
                                            },
                                        }}
                                    >
                                        <strong>Experience:</strong> {experience || "N/A"}
                                    </Typography>

                                </Grid>
                            </Grid>
                            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', mb: 5, mt: 3, width: '100%' }}>

  {/* Display Specialized Fields only if data is available */}
  {specialized_fields.length > 0 && (
    <Box sx={{ mb: 3, width: '100%', display: 'flex', justifyContent: 'center' }}>
      <Grid container spacing={2} alignItems="center" justifyContent="center">
        <Grid item xs="auto">
          <Typography variant="subtitle1" color="white" sx={{ fontWeight: 'bold' }}>
            Specialized Fields:
          </Typography>
        </Grid>
        <Grid item xs={8} sm={1.5}>
          <Grid container spacing={2} justifyContent="center">
            {specialized_fields.map((field, index) => (
              <Grid item key={index}>
                <Chip
                  label={field}
                  color="primary"
                  sx={{
                    fontWeight: 'bold',
                    borderRadius: '20px',
                    backgroundColor: '#1E90FF',
                    paddingX: '16px',
                    paddingY: '8px',
                    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                    '&:hover': {
                      boxShadow: '0px 6px 12px rgba(0, 0, 0, 0.2)',
                    },
                  }}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )}

  {/* Display Roles only if data is available */}
  {roles.length > 0 && (
    <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
      <Grid container spacing={2} alignItems="center" justifyContent="center">
        <Grid item xs="auto">
          <Typography variant="subtitle1" color="white" sx={{ fontWeight: 'bold' }}>
            Roles:
          </Typography>
        </Grid>
        <Grid item xs={4} sm={3}>
          <Grid container spacing={2} justifyContent="center">
            {roles.map((role, index) => (
              <Grid item key={index}>
                <Chip
                  label={role}
                  color="secondary"
                  sx={{
                    fontWeight: 'bold',
                    borderRadius: '20px',
                    backgroundColor: '#1E90FF',
                    paddingX: '16px',
                    paddingY: '8px',
                    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                    '&:hover': {
                      boxShadow: '0px 6px 12px rgba(0, 0, 0, 0.2)',
                    },
                  }}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )}

</Box>

                            {/* Description */}
                            <Typography
                                variant="body1"
                                sx={{
                                    marginBottom: "2rem",
                                    lineHeight: 1.8,
                                    color: "#ccc",
                                    textAlign: "center",
                                    fontFamily: "'Helvetica', sans-serif",
                                    fontSize: { xs: "0.9rem", sm: "1rem", md: "1.1rem" },
                                    maxWidth: "800px",  // Add a maxWidth to limit line length on larger screens
                                    margin: "0 auto",  // Center the text within its container
                                    padding: "0 1rem",  // Add padding for a more spacious look
                                    background: "linear-gradient(135deg, rgba(29, 233, 182, 0.2), rgba(33, 150, 243, 0.2))",  // Optional creative background effect
                                    borderRadius: "8px",  // Rounded corners for a modern feel
                                    boxShadow: "0px 10px 30px rgba(0, 0, 0, 0.1)",  // Slight shadow to give depth
                                    "&:hover": {
                                        transform: "scale(1.02)",  // Slight zoom effect on hover for interaction
                                        boxShadow: "0px 15px 40px rgba(0, 0, 0, 0.15)",  // Deepens shadow on hover
                                    },
                                    transition: "transform 0.3s ease, box-shadow 0.3s ease",  // Smooth transition for hover effects
                                }}
                            >
                                {description}
                            </Typography>



                            {/* Grid of details */}
                            <Grid container spacing={3} justifyContent="center" sx={{ mt: 5 }}>

                                {/* <Typography variant="body2" sx={{ fontWeight: "bold", color: "#fff" }}>Language: {language || "N/A"}</Typography> */}
                                <Typography
                                    variant="body2"
                                    sx={{
                                        fontWeight: "bold",
                                        color: "#fff",
                                        background: "linear-gradient(45deg, #6e7dff, #6b89ff)",
                                        borderRadius: "8px",
                                        padding: "6px 12px",
                                        mr: 1,
                                        mt: 1,
                                        textTransform: "uppercase",
                                        boxShadow: "0 4px 6px rgba(0, 0, 0, 0.2)",
                                        display: "inline-block",
                                        fontSize: "0.875rem", // Smaller size for compactness
                                        letterSpacing: "0.8px",
                                        transition: "transform 0.3s ease-in-out, background 0.3s ease-in-out",
                                        "&:hover": {
                                            background: "linear-gradient(45deg, #6e7dff, #6b89ff)",
                                            transform: "scale(1.05)",
                                        },
                                        "@media (max-width: 600px)": {
                                            fontSize: "0.75rem", // Make text smaller on mobile
                                            padding: "4px 10px", // Adjust padding for smaller screens
                                        },
                                    }}
                                >
                                    Language: {language || "N/A"}
                                </Typography>


                                {/* <Typography variant="body2" sx={{ fontWeight: "bold", color: "#fff" }}>Price: {`\u20B9`}{price}</Typography> */}
                                <Typography
                                    variant="body2"
                                    sx={{
                                        fontWeight: "bold",
                                        color: "#fff",
                                        background: "linear-gradient(45deg, #6e7dff, #6b89ff)",
                                        borderRadius: "8px",
                                        padding: "6px 12px",
                                        textTransform: "uppercase",
                                        boxShadow: "0 4px 6px rgba(0, 0, 0, 0.2)",
                                        display: "inline-block",
                                        mr: 1,
                                        mt: 1,
                                        fontSize: "0.875rem", // Smaller size for compactness
                                        letterSpacing: "0.8px",
                                        transition: "transform 0.3s ease-in-out, background 0.3s ease-in-out",
                                        "&:hover": {
                                            background: "linear-gradient(45deg, #6e7dff, #6b89ff)",
                                            transform: "scale(1.05)",
                                        },
                                        "@media (max-width: 600px)": {
                                            fontSize: "0.75rem", // Make text smaller on mobile
                                            padding: "4px 10px", // Adjust padding for smaller screens
                                        },
                                    }}
                                >
                                    Price: {`\u20B9`}{price}
                                </Typography>


                                {/* <Typography variant="body2" sx={{ fontWeight: "bold", color: "#fff" }}>Slots Left: {seats_left}</Typography> */}
                                <Typography
                                    variant="body2"
                                    sx={{
                                        fontWeight: "bold",
                                        color: "#fff",
                                        background: "linear-gradient(45deg, #6e7dff, #6b89ff)",
                                        borderRadius: "8px",
                                        padding: "6px 12px",
                                        mr: 1,
                                        mt: 1,
                                        textTransform: "uppercase",
                                        boxShadow: "0 4px 6px rgba(0, 0, 0, 0.2)",
                                        display: "inline-block",
                                        fontSize: "0.875rem", // Smaller size for compactness
                                        letterSpacing: "0.8px",
                                        transition: "transform 0.3s ease-in-out, background 0.3s ease-in-out",
                                        "&:hover": {
                                            background: "linear-gradient(45deg, #6e7dff, #6b89ff)",
                                            transform: "scale(1.05)",
                                        },
                                        "@media (max-width: 600px)": {
                                            fontSize: "0.75rem", // Make text smaller on mobile
                                            padding: "4px 10px", // Adjust padding for smaller screens
                                        },
                                    }}
                                >
                                    Slots Left: {seats_left}
                                </Typography>


                                {/* <Typography variant="body2" sx={{ fontWeight: "bold", color: "#fff" }}>Date: {date || "N/A"}</Typography> */}
                                <Typography
                                    variant="body2"
                                    sx={{
                                        fontWeight: "bold",
                                        color: "#fff",
                                        background: "linear-gradient(45deg, #6e7dff, #6b89ff)",
                                        borderRadius: "8px",
                                        padding: "6px 12px",
                                        textTransform: "uppercase",
                                        mr: 1,
                                        mt: 1,
                                        boxShadow: "0 4px 6px rgba(0, 0, 0, 0.2)",
                                        display: "inline-block",
                                        fontSize: "0.875rem", // Smaller size for compactness
                                        letterSpacing: "0.8px",
                                        transition: "transform 0.3s ease-in-out, background 0.3s ease-in-out",
                                        "&:hover": {
                                            background: "linear-gradient(45deg, #6e7dff, #6b89ff)",
                                            transform: "scale(1.05)",
                                        },
                                        "@media (max-width: 600px)": {
                                            fontSize: "0.75rem", // Make text smaller on mobile
                                            padding: "4px 10px", // Adjust padding for smaller screens
                                        },
                                    }}
                                >
                                    Date: {date || "N/A"}
                                </Typography>


                                {/* <Typography variant="body2" sx={{ fontWeight: "bold", color: "#fff" }}>Start Time: {formattedStartTime || "N/A"}</Typography> */}
                                <Typography
                                    variant="body2"
                                    sx={{
                                        fontWeight: "bold",
                                        color: "#fff",
                                        background: "linear-gradient(45deg, #6e7dff, #6b89ff)",
                                        borderRadius: "8px",
                                        padding: "6px 12px",
                                        textTransform: "uppercase",
                                        mr: 1,
                                        mt: 1,
                                        boxShadow: "0 4px 6px rgba(0, 0, 0, 0.2)",
                                        display: "inline-block",
                                        fontSize: "0.875rem", // Smaller size for compactness
                                        letterSpacing: "0.8px",
                                        transition: "transform 0.3s ease-in-out, background 0.3s ease-in-out",
                                        "&:hover": {
                                            background: "linear-gradient(45deg, #6e7dff, #6b89ff)",
                                            transform: "scale(1.05)",
                                        },
                                        "@media (max-width: 600px)": {
                                            fontSize: "0.75rem", // Make text smaller on mobile
                                            padding: "4px 10px", // Adjust padding for smaller screens
                                        },
                                    }}
                                >
                                    Start Time: {formattedStartTime || "N/A"}
                                </Typography>


                                {/* <Typography variant="body2" sx={{ fontWeight: "bold", color: "#fff" }}>Duration: {durationFormatted}</Typography> */}
                                <Typography
                                    variant="body2"
                                    sx={{
                                        fontWeight: "bold",
                                        color: "#fff",
                                        background: "linear-gradient(45deg, #6e7dff, #6b89ff)",
                                        borderRadius: "8px",
                                        padding: "6px 12px",
                                        textTransform: "uppercase",
                                        mr: 1,
                                        mt: 1,
                                        boxShadow: "0 4px 6px rgba(0, 0, 0, 0.2)",
                                        display: "inline-block",
                                        fontSize: "0.875rem", // Smaller size for compactness
                                        letterSpacing: "0.8px",
                                        transition: "transform 0.3s ease-in-out, background 0.3s ease-in-out",
                                        "&:hover": {
                                            background: "linear-gradient(45deg,  #6e7dff, #6b89ff)",
                                            transform: "scale(1.05)",
                                        },
                                        "@media (max-width: 600px)": {
                                            fontSize: "0.75rem", // Make text smaller on mobile
                                            padding: "4px 10px", // Adjust padding for smaller screens
                                        },
                                    }}
                                >
                                    Duration: {durationFormatted}
                                </Typography>



                            </Grid>

                            {/* Conditional payment status */}
                            {paymentStatus === 'success' ? (
                                <Box sx={{ textAlign: "center", mt: 3 }}>
                                    <Typography
  variant="h6"
  sx={{
    color: "#1E90FF",
    fontWeight: "600", // Slightly bold for a more modern feel
    textAlign: "center", // Center the text for a clean layout
    fontSize: {
      xs: "1.1rem", // Adjust font size for mobile
      sm: "1.3rem", // Medium size for small screens
      md: "1.5rem", // Larger size for desktop
    },
    lineHeight: 1.6, // Adjust line height for readability
    marginBottom: "1rem",
  }}
>
  Booking Confirmed! Thank you for your payment.
  <Link
    component={Link}
    to="/MyBookings"
    sx={{
      color: "#1E90FF",
      textDecoration: "underline",
      fontWeight: "600", // Make the link text slightly bold
      "&:hover": {
        color: "#3498db", // Lighter blue on hover
        textDecoration: "none", // Remove underline on hover for a cleaner look
        transition: "color 0.3s ease, text-decoration 0.3s ease", // Smooth hover transition
      },
    }}
  >
    View My Bookings
  </Link>
</Typography>

                                </Box>
                            ) : paymentStatus === 'cancelled' ? (
                                <>
                                    <Typography
  variant="h6"
  sx={{
    color: "#e74c3c", // Brighter red for better attention-grabbing
    textAlign: "center",
    mt: 3,
    fontWeight: "600", // Slightly bolder for emphasis
    fontSize: {
      xs: "1.1rem", // Adjust font size for small screens
      sm: "1.3rem", // Slightly larger for medium screens
      md: "1.5rem", // Larger font for desktop
    },
    lineHeight: 1.6, // Improved line height for readability
    background: "rgba(231, 76, 60, 0.1)", // Light background color for contrast
    borderRadius: "8px", // Rounded corners for a modern look
    padding: "0.8rem 1.5rem", // Extra padding for a more balanced layout
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // Soft shadow to make the message stand out
    transition: "all 0.3s ease", // Smooth transition for interactive effect
    "&:hover": {
      transform: "scale(1.05)", // Slight zoom effect on hover for interactivity
      boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)", // Larger shadow on hover
    },
  }}
>
  Payment was cancelled. Please try again.
</Typography>

                                    <Button
                                        component={Link}
                                        to={`/payments/post/${id}`}
                                        variant="contained"
                                        fullWidth
                                        sx={{
                                            marginTop: "2rem",
                                            backgroundColor: "#2980b9",
                                            color: "#fff",
                                            textTransform: "none",
                                            padding: "1rem",
                                            fontWeight: "bold",
                                        }}
                                    >
                                        Retry Payment
                                    </Button>
                                </>
                            ) : (
                                <>
                                    <Typography
                                        variant="h6"
                                        sx={{
                                            color: "#1E90FF",
                                            textAlign: "center",
                                            mt: 3,
                                            fontWeight: "600", // Make the font slightly bolder
                                            fontSize: {
                                                xs: "1.1rem", // Small font size on mobile devices
                                                sm: "1.3rem", // Medium font size on small screens
                                                md: "1.5rem", // Larger font size for desktop
                                            },
                                            letterSpacing: "1px",
                                            textTransform: "uppercase", // Slight emphasis with capitalized text
                                            lineHeight: 1.5, // Ensure line spacing is clean and readable
                                            padding: "8px 16px", // Add padding to make it more readable
                                            background: "linear-gradient(135deg, #000000, #000000)", // Add a gradient background for modern feel
                                            borderRadius: "12px", // Rounded corners
                                            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // Subtle shadow to make the text stand out
                                            transition: "all 0.3s ease",
                                            "&:hover": {
                                                background: "linear-gradient(135deg, #000000, #000000)", // Change gradient on hover for interactivity
                                                transform: "scale(1.05)", // Slight zoom effect on hover
                                            },
                                        }}
                                    >
                                        Please complete your payment to confirm booking.
                                    </Typography>

                                    <Button
                                        component={Link}
                                        to={`/payments/post/${id}`}
                                        variant="contained"
                                        fullWidth
                                        sx={{
                                            marginTop: "2rem",
                                            backgroundColor: "#2980b9",
                                            color: "#fff",
                                            textTransform: "none",
                                            padding: "1rem 2rem", // Add horizontal padding for better appearance
                                            fontWeight: "bold",
                                            fontSize: {
                                                xs: "1rem", // Smaller size for mobile
                                                sm: "1.1rem", // Medium size for tablets and small screens
                                                md: "1.2rem", // Larger size for desktops
                                            },
                                            borderRadius: "8px", // Rounded corners for modern look
                                            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)", // Subtle shadow for depth
                                            textAlign: "center",
                                            transition: "background-color 0.3s ease, transform 0.3s ease",
                                            "&:hover": {
                                                backgroundColor: "#3498db", // Lighten the button color on hover
                                                transform: "scale(1.05)", // Slight zoom effect on hover for interactivity
                                            },
                                            "@media (max-width: 600px)": {
                                                padding: "0.8rem 1.5rem", // Adjust padding for small screens
                                            },
                                        }}
                                    >
                                        Complete Payment
                                    </Button>

                                </>
                            )}
                        </Box>
                    </Grid>
                    <Grid container spacing={2} sx={{
                        justifyContent: "center", marginTop: "5px", backgroundColor: '#000000', borderColor: '#5555',
                        borderRadius: "15px",
                        // border: "1.5px solid #6666",
                        "&:hover": {
                            borderColor: '#1E90FF',
                        },
                    }}>



                        {/* Likes */}
                        <Grid item xs={2} sx={{ display: "flex", justifyContent: "center", alignItems: "center", }}>
                            <Link component="button" onClick={() => handleLikeClick(id)} sx={{ display: 'flex', alignItems: 'center' }}>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, justifyContent: 'center', }}>
  <ThumbUpIcon
    sx={{
      fontSize: { xs: '1.5rem', sm: '1.8rem', md: '2rem' }, // Responsive icon size
      color: action === 'like' ? '#3498db' : '#ffffff', // Blue when liked, white otherwise
      cursor: 'pointer', // Add pointer cursor for interactivity
      transition: 'transform 0.3s ease, color 0.3s ease', // Smooth transitions for hover effect
      "&:hover": {
        transform: 'scale(1.1)', // Slight zoom effect on hover
        color: '#3498db', // Blue color on hover, even when not liked
      },
    }}
  />
  <Typography
    variant="body2"
    sx={{
      fontSize: { xs: '1rem', sm: '1.2rem', md: '1.4rem' }, // Responsive text size
      color: "white",
      fontWeight: 500, // Slightly bolder font for emphasis
      transition: 'color 0.3s ease',
      "&:hover": {
        color: '#3498db', // Change text color on hover
      },
    }}
  >
    {likes}
  </Typography>
</Box>

                            </Link>
                        </Grid>
                        {/* Dislikes */}
                        <Grid item xs={2} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <Link component="button" onClick={() => handleDisLikeClick(id)} sx={{ display: 'flex', alignItems: 'center' }}>
                            <Box sx={{
    display: 'flex', 
    alignItems: 'center', 
    gap: 1, 
    justifyContent: 'center',
    cursor: 'pointer', // Adding cursor pointer for interactivity
    transition: 'all 0.3s ease', // Smooth transition for overall elements
    "&:hover": {
        transform: 'scale(1.05)', // Slight zoom effect when hovered
    }
  }}>
  <ThumbDownIcon
    sx={{
      fontSize: { xs: '1.5rem', sm: '1.8rem', md: '2rem' }, // Responsive icon size
      color: action === 'dislike' ? '#3498db' : '#ffffff', // Blue when disliked, white otherwise
      transition: 'transform 0.3s ease, color 0.3s ease', // Smooth transitions for hover effect
      "&:hover": {
        transform: 'scale(1.2)', // Slight zoom on hover for better interaction
        color: '#3498db', // Blue color on hover, even when not disliked
      },
    }}
  />
  <Typography
    variant="body2"
    sx={{
      fontSize: { xs: '1rem', sm: '1.2rem', md: '1.4rem' }, // Responsive text size
      color: 'white',
      fontWeight: 500, // Slightly bolder font for emphasis
      transition: 'color 0.3s ease',
      "&:hover": {
        color: '#3498db', // Change text color to blue on hover
      },
    }}
  >
    {dislikes}
  </Typography>
</Box>

                            </Link>
                        </Grid>
                        {/* Share */}
                        <Grid item xs={2} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            {/* <ShareButton postUrl={`${API_URL}/posts/${id}`} /> */}
                            <ShareButton 
  postUrl={`${API_URL}/posts/${id}`}
  sx={{
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#3498db', // Modern blue color for the button
    color: '#fff',
    padding: '10px 20px',
    borderRadius: '50px', // Rounded edges for a more modern, friendly look
    fontSize: {
      xs: '0.9rem', // Smaller font size on mobile
      sm: '3rem',   // Medium font size for tablets
      md: '4rem', // Larger font size for desktop
    },
    fontWeight: 'bold',
    cursor: 'pointer',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', // Subtle shadow for depth
    transition: 'all 0.3s ease', // Smooth transition for all properties
    '&:hover': {
      backgroundColor: '#2980b9', // Darker blue when hovered
      transform: 'scale(1.05)',    // Slight zoom effect on hover
      boxShadow: '0 6px 10px rgba(0, 0, 0, 0.2)', // Larger shadow on hover for emphasis
    },
    '@media (max-width: 600px)': {
      padding: '8px 16px', // Adjust padding for smaller screens
    },
  }}
>
  <ShareIcon sx={{ marginRight: '8px', fontSize: { xs: '1.2rem', sm: '1.5rem', md: '1.7rem' } }} />
  Share
</ShareButton>

                        </Grid>
                        {/* Report */}
                        <Grid item xs={2} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <Link component="button" onClick={() => handleReportClick(id)} sx={{ display: 'flex', alignItems: 'center' }}>
                                {/* <ReportIcon sx={{ fontSize: "1.8em", color: "white" }} /> */}
                                <FlagIcon
  sx={{
    fontSize: { xs: "3rem", sm: "3rem", md: "3rem" }, // Responsive icon size for different screens
    color: "white",
    transition: "all 0.3s ease", // Smooth transitions for hover effects
    cursor: "pointer", // Pointer cursor to indicate interactivity
    "&:hover": {
      color: "#e74c3c", // Change color on hover (using a red shade for attention)
      transform: "scale(1.1)", // Slight zoom effect for interactivity
    },
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.2)", // Add subtle shadow for depth
    borderRadius: "8px", // Smooth rounded corners for a modern look
    padding: "8px", // Padding to give space around the icon
    display: "inline-block", // Ensure it behaves like an inline element
  }}
/>


                            </Link>
                        </Grid>


                    </Grid>

                </Grid>

            </Box>
            <PostRecommendations postId={id} userId={userId} />
            <IconButton
                onClick={scrollToTop}
                sx={{ position: 'fixed', bottom: 16, right: 16, backgroundColor: 'white', color: 'black' }}>
                <ArrowUpwardIcon />
            </IconButton>
            <Footer2 />
        </div>
    );
};

export default PostTabDetails;