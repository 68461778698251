import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Typography, Container, IconButton, Avatar, AppBar, Toolbar, Button, useTheme, useMediaQuery } from '@mui/material';
import backgroundImage from '../Icon/Background2.png';
import Footer2 from './Footer2';
import Header from '../Header/Header';
import Header1 from '../Header/Header1';
import CertificateImage from '../Icon/Startup_India_Certificate.png';
import VisionBackground from '../Icon/vision5.png';
import MissionBackground from '../Icon/mission6.png';
import Slider from 'react-slick';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import {
  Grid,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Paper,
  Icon,
} from '@mui/material';
import {
  AiFillRobot,
  AiOutlineCode,
  AiOutlineGlobal,
  AiOutlineDeploymentUnit,
  AiOutlineBulb,
  AiOutlineFlag,
  AiOutlineSafety,
  AiOutlineUser,
  AiOutlineTeam,
  AiOutlineRocket,
  AiOutlineInfoCircle,
  AiOutlineTrophy,
} from 'react-icons/ai';
import { GoogleMap, LoadScript, Marker, InfoWindow } from '@react-google-maps/api';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { styled } from '@mui/material/styles';

const API_URL = process.env.REACT_APP_API_URL;



function AboutUs({userId,loggedIn, onLogout }) {
  const navigate = useNavigate();

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const scrollToSection = (id) => {
    document.getElementById(id).scrollIntoView({ behavior: 'smooth' });
  };

   // Handle logout
   const handleLogout = () => {
    onLogout(); // Call the passed logout function
    navigate('/login'); // Navigate to login page after logout
  };

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const HoverableCardHeader = styled(CardHeader)(({ theme }) => ({
    transition: 'color 0.3s',
    '&:hover .MuiCardHeader-title': {
      color: '#1E90FF', // Change title color to blue on hover
    },
  }));



  const address = "No 26, Laskar Hosur Road, Adugodi, Bangalore South, Bengaluru, Karnataka 560030, India";

  // Coordinates for the address (Approximate)
  // const location = {
  //   lat: 12.9353, // Latitude
  //   lng: 77.6171  // Longitude
  // };

  const location = {
    lat: 12.936373,  // Latitude
    lng: 77.611114 // Longitude
  };

  const MAP_LIBRARIES = ['marker'];

  const [selectedLocation, setSelectedLocation] = useState(null);
  const [mapLoaded, setMapLoaded] = useState(false);

  const darkModeStyles = [
    {
      "elementType": "geometry",
      "stylers": [
        { "color": "#212121" }
      ]
    },
    {
      "elementType": "labels.icon",
      "stylers": [
        { "visibility": "off" }
      ]
    },
    {
      "elementType": "labels.text.fill",
      "stylers": [
        { "color": "#757575" }
      ]
    },
    {
      "elementType": "labels.text.stroke",
      "stylers": [
        { "color": "#212121" }
      ]
    },
    {
      "featureType": "administrative",
      "elementType": "geometry",
      "stylers": [
        { "visibility": "off" }
      ]
    },
    {
      "featureType": "landscape",
      "elementType": "geometry",
      "stylers": [
        { "color": "#262626" }
      ]
    },
    {
      "featureType": "poi",
      "elementType": "geometry",
      "stylers": [
        { "color": "#212121" }
      ]
    },
    {
      "featureType": "water",
      "elementType": "geometry",
      "stylers": [
        { "color": "#000000" }
      ]
    },
    {
      "featureType": "road",
      "elementType": "geometry",
      "stylers": [
        { "color": "#2c2c2c" }
      ]
    },
    {
      "featureType": "road",
      "elementType": "labels.text.fill",
      "stylers": [
        { "color": "#8b8686" }
      ]
    },
  ];

  const options = {
    styles: darkModeStyles,
    disableDefaultUI: true, // Optional: Disable default UI
  };

  const mapContainerStyle = {
    width: '100%',
    height: '400px'
  };

  useEffect(() => {
    const checkGoogleMaps = () => {
      if (window.google && window.google.maps) {
        setMapLoaded(true);
      } else {
        console.error("Google Maps not loaded yet");
      }
    };

    // Add an event listener to check when the script is loaded
    window.addEventListener('load', checkGoogleMaps);

    // Clean up the event listener
    return () => {
      window.removeEventListener('load', checkGoogleMaps);
    };
  }, []);

  const technologies = [
    {
      title: 'Artificial Intelligence',
      icon: <AiFillRobot size={40} />,
      content: 'We are committed to build highly capable and safe to use AI Models',
    },
    {
      title: 'Machine Learning',
      icon: <AiOutlineBulb size={40} />,
      content: 'We are using ML techniques to enhance user experience and deliver valuable insights ',
    },
    {
      title: 'Natural Language Processing',
      icon: <AiOutlineGlobal size={40} />,
      content: 'We are doing intense research on NLP and its possibilities of implementation ',
    },
    {
      title: 'LLM/SLM',
      icon: <AiOutlineCode size={40} />,
      content: 'At present, we are using accurately fine-tuned LLM/SLM Models and we are planning to build our own Large and Small Language Models ',
    },
    {
      title: 'Translation Techniques',
      icon: <AiOutlineGlobal size={40} />,
      content: 'Intense research is going on towards developing real time translation techniques ',
    },
    {
      title: 'Deep Learning Techniques',
      icon: <AiOutlineBulb size={40} />,
      content: 'Deep Learning Techniques are used to deliver major breakthroughs ',
    },
    {
      title: 'RAG',
      icon: <AiFillRobot size={40} />,
      content: 'Retrieval-Augumented Generation systems are under development ',
    },
    {
      title: 'Data Science',
      icon: <AiOutlineCode size={40} />,
      content: 'Data science plays a very crucial role for AI Model fine tuning and all other fields involving data  ',
    },
    {
      title: 'Data Analytics',
      icon: <AiOutlineCode size={40} />,
      content: 'Data Analytics will be used for monitoring important trends and derive meaningful insights',
    },
    {
      title: 'Data Mining',
      icon: <AiOutlineCode size={40} />,
      content: 'Data Mining is very important for extracting data required for training LLMs ',
    },
    {
      title: 'Web Development',
      icon: <AiOutlineCode size={40} />,
      content: 'We are continuously updating to the most advanced and latest web technologies ',
    },
    {
      title: 'App Development',
      icon: <AiOutlineDeploymentUnit size={40} />,
      content: 'We are developing best in class applications which are compatible with all kinds of devices and users ',
    },
  ];

  const values = [
    {
      title: 'Freedom',
      icon: <AiOutlineFlag size={40} />,
      description: 'The power to act, speak or think as one wants without hindrance or restraint.',
    },
    {
      title: 'Discipline',
      icon: <AiOutlineSafety size={40} />,
      description: 'The practice of training people to obey rules or a code of behavior.',
    },
    {
      title: 'Equality',
      icon: <AiOutlineUser size={40} />,
      description: 'The state of being equal, especially in status, rights and opportunities.',
    },
  ];


  useEffect(() => {
    // Scroll to top when the component mounts
    window.scrollTo(0, 0);

    const handlePopState = (event) => {
      navigate('/home'); // If you want to navigate to home on back button press
    };

    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [navigate]);


  return (
    <div>
      {loggedIn ? (
                <Header1 style={{ display: 'flex', justifyContent: 'center', textAlign: 'center', alignItems: 'center' }} userId={userId} onLogout={onLogout} />
            ) : (
                <Header style={{ display: 'flex', justifyContent: 'center', textAlign: 'center', alignItems: 'center' }} />
            )}
      {/* <Header1 /> */}
      {/* <Header1 userId={userId} onLogout={onLogout} />  */}
      <div
        style={{
          minHeight: '100vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          alignItems: 'center',
          // backgroundImage: `url(${backgroundImage})`,
          backgroundColor: 'black',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          color: 'white',
          padding: '20px',
          marginTop: 10,
          paddingTop: '80px',
          paddingBottom: '100px',
        }}
      >
        <div
          style={{
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            padding: '0 20px', // Add horizontal padding
          }}
        >
          <Typography
            variant="h2"
            align="center"
            sx={{
              fontWeight: 'bold',
              fontFamily: 'Helvetica, sans-serif', // Changed to Helvetica
              fontSize: { xs: '1.5rem', sm: '2rem', md: '3rem' },
              '&:hover': {
                color: '#1E90FF', // Change font color to blue on hover
              }, // Responsive font sizes
            }}
          >
            About Us
          </Typography>

          <Box sx={{ display: 'flex', justifyContent: 'center', my: 2 }}>
            {/* Custom Navbar container */}
            <Box
                sx={{
                    backgroundColor: 'black',
                    borderRadius: '16px',
                    boxShadow: 'none',
                    width: '100%', // Full width for the Box container
                    border: '2px solid transparent', // Default border color
                    transition: 'border-color 0.3s', // Smooth transition for border color
                    '&:hover': {
                        borderColor: '#1E90FF', // Change border color on hover
                    },
                    display: 'flex',
                    flexDirection: isSmallScreen ? 'column' : 'row', // Change layout direction based on screen size
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexWrap: 'wrap', // Allow wrapping in small screens
                    padding: '8px', // Padding to give some space around buttons
                }}
            >
                {/* Buttons */}
                <Button
                    color="inherit"
                    onClick={() => scrollToSection('overview')}
                    sx={{
                        '&:hover': {
                            color: '#1E90FF', // Change font color to blue on hover
                        },
                        mb: isSmallScreen ? 1 : 0, // Add margin-bottom for spacing on small screens
                        width: isSmallScreen ? '100%' : 'auto', // Adjust width for small screens
                    }}
                >
                    Overview
                </Button>

                <Button
                    color="inherit"
                    onClick={() => scrollToSection('team')}
                    sx={{
                        '&:hover': {
                            color: '#1E90FF', // Change font color to blue on hover
                        },
                        mb: isSmallScreen ? 1 : 0,
                        width: isSmallScreen ? '100%' : 'auto',
                    }}
                >
                    Team
                </Button>

                <Button
                    color="inherit"
                    onClick={() => scrollToSection('values')}
                    sx={{
                        '&:hover': {
                            color: '#1E90FF',
                        },
                        mb: isSmallScreen ? 1 : 0,
                        width: isSmallScreen ? '100%' : 'auto',
                    }}
                >
                    Values
                </Button>

                <Button
                    color="inherit"
                    onClick={() => scrollToSection('technologies')}
                    sx={{
                        '&:hover': {
                            color: '#1E90FF',
                        },
                        mb: isSmallScreen ? 1 : 0,
                        width: isSmallScreen ? '100%' : 'auto',
                    }}
                >
                    Technologies
                </Button>

                <Button
                    color="inherit"
                    onClick={() => scrollToSection('future')}
                    sx={{
                        '&:hover': {
                            color: '#1E90FF',
                        },
                        mb: isSmallScreen ? 1 : 0,
                        width: isSmallScreen ? '100%' : 'auto',
                    }}
                >
                    Future Plans
                </Button>

                <Button
                    color="inherit"
                    onClick={() => scrollToSection('location')}
                    sx={{
                        '&:hover': {
                            color: '#1E90FF',
                        },
                        mb: isSmallScreen ? 1 : 0,
                        width: isSmallScreen ? '100%' : 'auto',
                    }}
                >
                    Location
                </Button>

                <Button
                    color="inherit"
                    onClick={() => scrollToSection('achievements')}
                    sx={{
                        '&:hover': {
                            color: '#1E90FF',
                        },
                        mb: isSmallScreen ? 1 : 0,
                        width: isSmallScreen ? '100%' : 'auto',
                    }}
                >
                    Achievements
                </Button>
            </Box>
        </Box>

          {/* <Box
            sx={{
              maxWidth: '700px',
              padding: { xs: '20px', sm: '30px' },
              margin: { xs: '30px', sm: '60px 20px' },
              backgroundColor: 'rgba(255, 255, 255, 0.1)',
              borderRadius: '20px',
              transition: 'all 0.3s ease', // Smooth transition for hover effects
              '&:hover': {
                transform: 'scale(1.1)',
                boxShadow: '0 0 20px rgba(0, 0, 255, 0.7)'
              },
            }}
          >
            <Typography
              variant="body1"
              align="left"
              sx={{
                fontFamily: 'Helvetica, sans-serif', // Changed to Helvetica
                fontSize: { xs: '0.9rem', sm: '1rem', md: '1.1rem' }, // Responsive font sizes
                lineHeight: { xs: '1.4', sm: '1.5' }, // Line height adjustments for readability
                padding: { xs: '10px', sm: '0' }, // Padding adjustments for small screens
                wordWrap: 'break-word', // Ensures long words wrap correctly
              }}
            >
              Anthrasync is the ultimate knowledge exchange platform, uniting people worldwide regardless of location or language. Discover a community where individuals of all backgrounds can come together, connecting with like-minded peers based on shared interests. Whether you seek to teach, learn or earn, Anthrasync provides the perfect space for expanding your horizons and making meaningful connections.
              This platform will transform learning by focusing on practicality, experience and direct human connections, rather than passive or impersonal content found elsewhere online. It will blend real-world learning with the convenience and accessibility of digital platforms.
            </Typography>
          </Box> */}

          <Container id="overview" sx={{ bgcolor: 'black', minHeight: '100vh', color: 'white', py: 4 }}>
            <Typography variant="h4" align="center" gutterBottom sx={{
              '&:hover': {
                color: '#1E90FF', // Change font color to blue on hover
              },
            }}>
              Overview
            </Typography>
            <Grid container spacing={4} justifyContent="center">
              <Grid item xs={12} sm={10} md={8}>
                <Card elevation={3} sx={{
                  backgroundColor: '#000000', color: 'white', border: '2px solid transparent', // Initial border
                  transition: 'border-color 0.3s ease', // Transition for border color
                  '&:hover': {
                    borderColor: '#1E90FF', // Change border color to blue on hover
                  },
                }}>
                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', p: 2 }}>
                    <AiOutlineInfoCircle size={50} style={{ color: '#1E90FF' }} />
                  </Box>
                  <CardContent>
                    <Typography variant="h6" align="center" sx={{ mb: 2,
                      '&:hover': {
                        color: '#1E90FF', // Change font color to blue on hover
                      },
                    }}>
                      Unlocking Knowledge for All
                    </Typography>
                    <Typography variant="body1" color="#e0e0e0" align="center" paragraph>
                      Anthrasync is an AI startup with a vision to make every kind of knowledge accessible for everyone. We are trying to break language barriers and ensure seamless flow of knowledge.
                      We are building a global knowledge exchange platform for everyone irrespective of their locations and languages. Currently, we are targeting Indian users and later expand globally.
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Container>

          <Container id="team" sx={{ bgcolor: 'black', minHeight: '100vh', color: 'white', py: 4 }}>
            <Typography variant="h4" align="center" gutterBottom sx={{
              '&:hover': {
                color: '#1E90FF', // Change font color to blue on hover
              },
            }}>
              Our Team
            </Typography>
            <Grid container spacing={4} justifyContent="center">
              <Grid item xs={12} sm={10} md={8}>
                <Card elevation={3} sx={{
                  backgroundColor: '#000000', color: 'white', border: '2px solid transparent', // Initial border
                  transition: 'border-color 0.3s ease', // Transition for border color
                  '&:hover': {
                    borderColor: '#1E90FF', // Change border color to blue on hover
                  },
                }}>
                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', p: 2 }}>
                    <AiOutlineTeam size={50} style={{ color: '#1E90FF' }} />
                  </Box>
                  <CardContent>
                    <Typography variant="h6" align="center" sx={{ mb: 2,
                      '&:hover': {
                        color: '#1E90FF', // Change font color to blue on hover
                      },
                    }}>
                      Passionate & Dedicated
                    </Typography>
                    <Typography variant="body1" color="#e0e0e0" align="center">
                      Our team at Anthrasync consists of highly passionate, dedicated and motivated individuals who are continuously working towards one common goal. We are always willing to take risks, face challenges and overcome them. We make sure that we are regularly updating our skills and are part of continuous process of learning and growth.
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Container>

          <Container id="values" sx={{ bgcolor: '#000000', minHeight: '40vh', color: 'white', py: 4, marginBottom:50 }}>
            <Typography variant="h4" align="center" gutterBottom sx={{
              '&:hover': {
                color: '#1E90FF', // Change font color to blue on hover
              },
            }}>
              Our Core Values
            </Typography>
            <Grid container spacing={4}>
              {values.map((value, index) => (
                <Grid item xs={12} sm={6} md={4} key={index}>
                  <Card
                    elevation={3}
                    sx={{
                      backgroundColor: '#000000',
                      color: 'white',
                      border: '2px solid transparent', // Initial border
                      transition: 'border-color 0.3s ease', // Transition for border color
                      '&:hover': {
                        borderColor: '#1E90FF', // Change border color to blue on hover
                      },
                    }}
                  >
                    <HoverableCardHeader
                      title={value.title}
                      avatar={
                        <Avatar sx={{ backgroundColor: 'transparent', color: '#1E90FF' }}>
                          {value.icon}
                        </Avatar>
                      }
                      titleTypographyProps={{ variant: 'h6', color: 'white' }}
                      sx={{
                        backgroundColor: '#000000',
                        textAlign: 'center',
                      }}
                    />
                    <CardContent>
                      <Typography variant="body2" sx={{ color: '#e0e0e0' }}>
                        {value.description}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Container>



          <Container id="technologies" sx={{ backgroundColor: '#000000', padding: 4, borderRadius: 2 }}>
            <Typography variant="h4" align="center" gutterBottom sx={{ color: 'white',
              '&:hover': {
                color: '#1E90FF', // Change font color to blue on hover
              },
            }}>
              Technologies We Deal With
            </Typography>
            <Grid container spacing={4}>
              {technologies.map((tech, index) => (
                <Grid item xs={12} sm={6} md={4} key={index}>
                  <Card
                    elevation={3}
                    sx={{
                      backgroundColor: '#000000',
                      color: 'white',
                      transition: 'border-color 0.3s ease',
                      border: '2px solid transparent', // Initial border
                      '&:hover': {
                        borderColor: '#1E90FF', // Light blue color on hover
                      },
                    }}
                  >
                    <HoverableCardHeader
                      title={tech.title}
                      avatar={
                        <Avatar sx={{ backgroundColor: '#000000', color: '#1E90FF' }}>
                          {tech.icon}
                        </Avatar>
                      }
                      titleTypographyProps={{ variant: 'h6', sx: { color: 'white' } }}
                      sx={{
                        backgroundColor: '#000000',
                        textAlign: 'center',
                      }}
                    />
                    <CardContent>
                      <Typography variant="body2" sx={{ color: 'white' }}>
                        {tech.content}.
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Container>






          {/* <Box
            sx={{
              maxWidth: '700px',
              padding: { xs: '20px', sm: '30px' },
              margin: { xs: '30px', sm: '60px 20px' },
              backgroundColor: 'rgba(255, 255, 255, 0.1)',
              borderRadius: '20px',
              transition: 'all 0.3s ease', // Smooth transition for hover effects
              '&:hover': {
                transform: 'scale(1.1)',
                boxShadow: '0 0 20px rgba(0, 0, 255, 0.7)'
              },
            }}
          >
            <Typography
              variant="body1"
              align="center"
              sx={{
                fontFamily: 'Helvetica, sans-serif', // Changed to Helvetica
                fontSize: { xs: '0.9rem', sm: '1rem', md: '1.1rem' }, // Responsive font sizes
                lineHeight: { xs: '1.4', sm: '1.5' }, // Line height adjustments for readability
                padding: { xs: '10px', sm: '0' }, // Padding adjustments for small screens
                wordWrap: 'break-word', // Ensures long words wrap correctly
              }}
            >
              This platform will transform learning by focusing on practicality, experience and direct human connections, rather than passive or impersonal content found elsewhere online. It will blend real-world learning with the convenience and accessibility of digital platforms.
            </Typography>
          </Box> */}

          {/* Anthrasync is the ultimate knowledge exchange platform, uniting people worldwide regardless of location or language. Discover a community where individuals of all backgrounds can come together, connecting with like-minded peers based on shared interests. Whether you seek to teach, learn or earn, Anthrasync provides the perfect space for expanding your horizons and making meaningful connections. */}





          {/* <Typography
            variant="h4"
            align="center"
            sx={{
              marginTop: '40px',
              fontWeight: 'bold',
              fontFamily: 'Times New Roman, serif',
              fontSize: { xs: '1.5rem', sm: '2rem' },
              '&:hover': {
                transform: 'scale(1.1)',
                boxShadow: '0 0 20px rgba(0, 0, 255, 0.7)'
                // textDecoration: 'underline' // Optional: Add underline on hover
              }
            }}
          >
            Our Features
          </Typography> */}
          {/* <Box sx={{ maxWidth: '600px', margin: { xs: '20px', sm: '20px' } }}>
            {[
              {
                title: "1. Teach and Learn in Any Language:",
                description: "The platform will allow users to teach or learn in their preferred language. It will support multilingual content for both written and video lessons, with translation tools or language-specific communities, eliminating language barriers for sharing knowledge."
              },
              {
                title: "2. Monetisation of Your Knowledge and Talent:",
                description: "Users can create live sessions in their areas of expertise and charge for access. Revenue models will include In app purchases (IAP), one-time purchases, subscriptions or live sessions."
              },
              {
                title: "3. Connect with People Sharing Similar Interests:",
                description: "The platform will feature forums, groups, or community spaces where users can discuss shared interests. These spaces will foster networking, mentorship, and collaborative learning experiences, enabling users to learn from peers or experts in their field."
              },
              {
                title: "4. Learn Critical, Non-Mainstream Skills:",
                description: "The platform will focus on niche topics or 'underground' knowledge that isn’t widely available online but is valuable for personal or professional growth, ranging from specialised career advice to personal development and culturally specific knowledge."
              },
            ].map((feature, index) => (
              <Box
                key={index}
                sx={{
                  padding: '20px',
                  marginBottom: '30px',
                  backgroundColor: 'rgba(255, 255, 255, 0.1)',
                  borderRadius: '10px',
                  '&:hover': {
                    transform: 'scale(1.1)',
                    boxShadow: '0 0 20px rgba(0, 0, 255, 0.7)'
                  }
                }}
              >
                <Typography
                  variant="body1"
                  sx={{
                    fontWeight: 'bold',
                    marginBottom: '10px',
                    fontFamily: 'Times New Roman, serif',
                  }}
                >
                  {feature.title}
                </Typography>
                <Typography variant="body2" sx={{ fontFamily: 'Calibri, sans-serif', }}>
                  {feature.description}
                </Typography>
              </Box>
            ))}
          </Box> */}

          <Container id="future" sx={{ bgcolor: 'black', minHeight: '100vh', color: 'white', py: 4, margin: 25 }}>
            <Typography variant="h4" align="center" gutterBottom sx={{
              '&:hover': {
                color: '#1E90FF', // Change font color to blue on hover
              },
            }}>
              Future Plans
            </Typography>
            <Grid container spacing={4} justifyContent="center">
              <Grid item xs={12} sm={10} md={8}>
                <Card elevation={3} sx={{
                  backgroundColor: '#000000', color: 'white', border: '2px solid transparent', // Initial border
                  transition: 'border-color 0.3s ease', // Transition for border color
                  '&:hover': {
                    borderColor: '#1E90FF', // Change border color to blue on hover
                  },
                }}>
                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', p: 2 }}>
                    <AiOutlineRocket size={50} style={{ color: '#1E90FF' }} />
                  </Box>
                  <CardContent>
                    <Typography variant="h6" align="center" sx={{
                      mb: 2, '&:hover': {
                        color: '#1E90FF', // Change font color to blue on hover
                      },
                    }}>
                      Launching Our Product
                    </Typography>
                    <Typography variant="body1" color="#e0e0e0" align="center">
                      In upcoming months, we will be launching our product in a stage-wise manner with continuous addition of cutting-edge features, initially as Beta, later as complete versions based on feedback from users.
                    </Typography>
                    <Divider sx={{ my: 2, bgcolor: 'grey.700' }} />
                    <Typography variant="body2" align="center" color="#e0e0e0">
                      Stay tuned for updates as we move forward with this exciting journey!
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Container>




          {/* <Typography
            variant="h4"
            align="center"
            sx={{
              marginTop: '40px',
              fontWeight: 'bold',
              color: '#B28B3E',
              fontFamily: 'Helvetica, sans-serif', // Changed to Helvetica
              fontSize: { xs: '2rem', sm: '3rem', md: '50px' }, // Responsive font sizes
            }}
          >
            Recognized by Startup India
          </Typography>
          <Box sx={{ margin: '20px', textAlign: 'center' }}>
            <img
              src={CertificateImage}
              alt="Startup India Certificate"
              style={{
                maxWidth: '100%', // Increase size for small screens
                width: '100%', // Ensures the image takes full width of the box
                height: 'auto',
                borderRadius: '10px',
                boxShadow: 'rgba(0, 0, 255, 0.4)',
                transition: 'transform 0.4s ease-in-out',
                animation: 'none' // Start with no animation
              }}
              onMouseEnter={(e) => e.currentTarget.style.animation = 'curveMove 1s ease-in-out forwards'}
              onMouseLeave={(e) => e.currentTarget.style.animation = 'none'}
            />
          </Box> */}

          <Box id="location" sx={{ padding: 3, backgroundColor: '#000000', color: 'white' }}>
            <Typography variant="h4" align="center" sx={{
              marginBottom: 2, '&:hover': {
                color: '#1E90FF', // Change font color to blue on hover
              },
            }}>
              Our Location
            </Typography>
            <Grid container spacing={2} justifyContent="center">
              <Grid item xs={12} md={6}>
                <Paper elevation={3} sx={{
                  padding: 3, backgroundColor: '#000000', textAlign: 'center', borderRadius: 2, border: '2px solid transparent',
                  transition: 'border-color 0.3s ease', '&:hover': {
                    borderColor: '#1E90FF',
                  },
                }}>
                  <LocationOnIcon sx={{ fontSize: 40, color: 'primary.main' }} />
                  <Typography variant="h6" sx={{
                    marginTop: 1, color: 'white', '&:hover': {
                      color: '#1E90FF', // Change font color to blue on hover
                    },
                  }}>Registered Address</Typography>
                  <Typography variant="body1" sx={{ marginTop: 1, color: 'white' }}>{address}</Typography>
                </Paper>
              </Grid>
              <Grid item xs={12} md={6}>
                <LoadScript googleMapsApiKey="AIzaSyASEtkoHNy8SPOS-4KBOH7QzCuBdSa8P_A" onError={() => console.error('Error loading Google Maps')}>
                  <GoogleMap
                    mapContainerStyle={mapContainerStyle}
                    center={location}
                    zoom={15}
                    options={options} // Apply your dark mode options here
                  >
                    <Marker position={location} />
                  </GoogleMap>
                </LoadScript>
              </Grid>
            </Grid>
          </Box>

          <Container
            id="achievements"
            sx={{
              bgcolor: 'black',
              minHeight: '100vh',
              color: 'white',
              py: 4,
              width: '100%',
              maxWidth: '1200px', // Set your desired max width here
              margin: 20,
            }}
          >
            <Typography
              variant="h4"
              align="center"
              gutterBottom
              sx={{
                '&:hover': {
                  color: '#1E90FF', // Change font color to blue on hover
                },
              }}
            >
              Achievements
            </Typography>
            <Grid container spacing={4} justifyContent="center">
              <Grid item xs={12} sm={10} md={6}>
                <Card
                  elevation={3}
                  sx={{
                    backgroundColor: '#000000',
                    color: 'white',
                    borderRadius: '16px',
                    border: '2px solid transparent', // Initial border
                    transition: 'border-color 0.3s ease', // Transition for border color
                    '&:hover': {
                      borderColor: '#1E90FF', // Change border color to blue on hover
                    },
                  }}
                >
                  <CardContent sx={{ textAlign: 'center' }}>
                    <Box sx={{ mb: 2 }}>
                      <AiOutlineTrophy size={50} style={{ color: '#1E90FF' }} />
                    </Box>
                    <Typography
                      variant="h5"
                      sx={{
                        '&:hover': {
                          color: '#1E90FF', // Change font color to blue on hover
                        },
                      }}
                    >
                      Certificate of Recognition
                    </Typography>
                    <Divider sx={{ my: 2, bgcolor: 'grey.700' }} />
                    <div
                      style={{
                        display: 'inline-block',
                        borderRadius: '10px',
                        overflow: 'hidden', // Ensures the border radius is respected
                        border: '2px solid transparent', // Initial border
                        transition: 'border-color 0.4s ease-in-out',
                      }}
                      onMouseEnter={(e) => {
                        e.currentTarget.style.borderColor = '#1E90FF';
                      }}
                      onMouseLeave={(e) => {
                        e.currentTarget.style.borderColor = 'transparent';
                      }}
                    >
                      <img
                        src={CertificateImage}
                        alt="Startup India Certificate"
                        style={{
                          maxWidth: '100%', // Increase size for small screens
                          width: '100%', // Ensures the image takes full width of the box
                          height: 'auto',
                          borderRadius: '10px',
                          boxShadow: 'rgba(0, 0, 255, 0.4) 0px 4px 10px',
                          transition: 'transform 0.4s ease-in-out',
                          animation: 'none', // Start with no animation
                        }}
                      />
                    </div>

                    <Box sx={{ mt: 3, textAlign: 'left', mx: 4 }}>
                      <Typography variant="body2" color="#e0e0e0">
                        <strong>Issued by:</strong> DPIIT
                      </Typography>
                      <Typography variant="body2" color="#e0e0e0">
                        <strong>Date:</strong> August 17, 2024
                      </Typography>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Container>


          <style>
            {`
              @keyframes curveMove {
                0% {
                  transform: scale(1) translateY(0px);
                }
                50% {
                  transform: scale(1.05) translateY(-10px) translateX(10px);
                }
                100% {
                  transform: scale(1) translateY(0px);
                }
              }
            `}
          </style>

          <Box sx={{ margin: '40px 0' }} /> {/* Spacer Box */}
        </div>
      </div>
      <IconButton
        onClick={scrollToTop}
        sx={{ position: 'fixed', bottom: 16, right: 16, backgroundColor: 'white', color: 'black' }}
      >
        <ArrowUpwardIcon />
      </IconButton>
      <Footer2 />
    </div>
  );
}

export default AboutUs;
