import React, { useState } from "react";
import { Button, Box, Typography, Grid } from "@mui/material";
import MyPosts from "../Post/MyPosts";
import MyBookings from "./MyBookings";
import PaymentDetails from "./PaymentDetails";
import RecentActivity from "./RecentActivity";
import Header1 from "../Header/Header1";

function MyContent({ userId, onLogout }) {
  const [activePage, setActivePage] = useState("posts"); // Default active page
  const [searchResults, setSearchResults] = useState([]);

  const handleSearch = (result) => {
    console.log("Search Results", result);
    setSearchResults(result);
  };

  const renderContent = () => {
    switch (activePage) {
      case "recent-activity":
        return <RecentActivity userId={userId} searchResults={searchResults} />;
      case "posts":
        return <MyPosts userId={userId} searchResults={searchResults} />;
      case "bookings":
        return <MyBookings userId={userId} searchResults={searchResults} />;
      case "pay":
        return <PaymentDetails userId={userId} searchResults={searchResults} />;
      default:
        return <Typography variant="h4" color="#ecf0f1">Welcome</Typography>;
    }
  };

  return (
    <div>
      {/* Navigation Bar */}
      <Header1 userId={userId} page_name={activePage} onSearch={handleSearch} onLogout={onLogout} />
      
      {/* Replaced AppBar with Box for Navbar */}
      <Box
  sx={{
    display: 'flex',
    flexDirection: { xs: 'column', sm: 'row' },  // Switch flexDirection to column for small screens, row for larger screens
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'black',
    padding: { xs: '0.5rem', sm: '1rem' },  // Smaller padding for small screens, larger padding for larger screens
    width: '100%',
    mt: { xs: 7, sm: 10 },  // Adjust margin-top based on screen size (smaller on small screens)
  }}
>
<Grid container spacing={2} justifyContent="center" sx={{ flexDirection: { xs: 'column', sm: 'row' }, textAlign: 'center' }}>
  <Grid item>
    <Button
      onClick={() => setActivePage("recent-activity")}
      sx={{
        color: activePage === "recent-activity" ? "#1E90FF" : "#ecf0f1",
        textTransform: "none",
        fontSize: { xs: '0.9rem', sm: '1rem' }, // Responsive font size
        width: { xs: '100%', sm: 'auto' }, // Full width on small screens, auto width on larger screens
        "&:hover": {
          backgroundColor: "#1111",
        },
        padding: { xs: '0.5rem', sm: '1rem' }, // Adjust padding for smaller screens
      }}
    >
      Recent Activity
    </Button>
  </Grid>
  <Grid item>
    <Button
      onClick={() => setActivePage("posts")}
      sx={{
        color: activePage === "posts" ? "#1E90FF" : "#ecf0f1",
        textTransform: "none",
        fontSize: { xs: '0.9rem', sm: '1rem' },
        width: { xs: '100%', sm: 'auto' },
        "&:hover": {
          backgroundColor: "#1111",
        },
        padding: { xs: '0.5rem', sm: '1rem' },
      }}
    >
      My Posts
    </Button>
  </Grid>
  <Grid item>
    <Button
      onClick={() => setActivePage("bookings")}
      sx={{
        color: activePage === "bookings" ? "#1E90FF" : "#ecf0f1",
        textTransform: "none",
        fontSize: { xs: '0.9rem', sm: '1rem' },
        width: { xs: '100%', sm: 'auto' },
        "&:hover": {
          backgroundColor: "#1111",
        },
        padding: { xs: '0.5rem', sm: '1rem' },
      }}
    >
      My Bookings
    </Button>
  </Grid>
  <Grid item>
    <Button
      onClick={() => setActivePage("pay")}
      sx={{
        color: activePage === "pay" ? "#1E90FF" : "#ecf0f1",
        textTransform: "none",
        fontSize: { xs: '0.9rem', sm: '1rem' },
        width: { xs: '100%', sm: 'auto' },
        "&:hover": {
          backgroundColor: "#1111",
        },
        padding: { xs: '0.5rem', sm: '1rem' },
      }}
    >
      Payment Details
    </Button>
  </Grid>
</Grid>

      </Box>

      {/* Dynamic Content */}
      <Box
  sx={{
    padding: { xs: "1rem", sm: "2rem" }, // Adjust padding for smaller and larger screens
    backgroundColor: "black",
    color: "white",
    minHeight: "calc(100vh - 64px)", // Keeps the height full minus any fixed header
    display: "flex",
    flexDirection: "column", // Ensure content is stacked vertically on small screens
    justifyContent: "center", // Centers content vertically
    alignItems: "center", // Centers content horizontally
    overflow: "hidden", // Prevents overflow on smaller screens
  }}
>
  {renderContent()}
</Box>

    </div>
  );
}

export default MyContent;
