import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';



const API_URL = process.env.REACT_APP_API_URL;
const PasswordReset = () => {
    const { uidb64, token } = useParams();
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        // You can validate the token here by hitting an API endpoint to check if it’s valid
        axios.post(`${API_URL}/check_token/`, { uidb64, token })
            .then(response => {
                if (!response.data.valid) {
                    setErrorMessage('Invalid or expired token.');
                }
            })
            .catch(error => {
                setErrorMessage('Error validating token.');
            });
    }, [uidb64, token]);

    const handleResetPassword = () => {
        if (password === confirmPassword) {
            axios.post(`${API_URL}/reset_password/`, { uidb64, token, password })
                .then(response => {
                    // Handle successful password reset
                    window.location.href = '/login';  // Redirect to login after reset
                })
                .catch(error => {
                    setErrorMessage('Error resetting password.');
                });
        } else {
            setErrorMessage('Passwords do not match.');
        }
    };

    return (
        <div>
            <h2>Reset Password</h2>
            {errorMessage && <p>{errorMessage}</p>}
            <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="New Password"
            />
            <input
                type="password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                placeholder="Confirm Password"
            />
            <button onClick={handleResetPassword}>Reset Password</button>
        </div>
    );
};

export default PasswordReset;
