import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams, useNavigate, useLoaderData } from 'react-router-dom';
import { Container, Grid, Card, CardContent, Typography, Box, Avatar, TextField, Button, Input, MenuItem, Select, InputLabel, FormControl, Snackbar, Alert, IconButton, Chip, Skeleton } from '@mui/material';
import { styled } from '@mui/system';
import { Edit, Save, AccountBalance, School, FileUpload, Email, Phone, Info, Language, Home, CalendarToday, LocationOn, Person, Lock, Delete, CheckCircle, Facebook, Instagram, Twitter, LinkedIn, YouTube, X } from '@mui/icons-material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Header1 from '../Header/Header1';
import Header3 from '../Header/Header3';
import Footer from '../Footer/Footer';
import { Link as MuiLink } from '@mui/material';
import GenderIcon from '@mui/icons-material/Male';
import { Link } from 'react-router-dom';
import VerificationForm from '../Verification/VerificationForm';
import DigilockerVerificationForm from '../Verification/DigilockerVerificationForm';
import Footer2 from '../Footer/Footer2';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import RecommendationSetup from '../Recommendations/RecommendationSetup';
import avatar from './default-avatar.png'
import { MonetizationOn, PostAdd, Event } from '@mui/icons-material';


const API_URL = process.env.REACT_APP_API_URL;




const theme = createTheme({
  palette: {
    primary: {
      main: '#1111',
    },
    secondary: {
      main: '#1111',
    },
    background: {
      paper: '#1111',
    },
  },
});


const ProfileHeader = styled(Box)(({ theme }) => ({
  background: 'linear-gradient(135deg, #55555, #77777)',
  color: 'white',
  padding: theme.spacing(4),
  borderRadius: '10px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginBottom: theme.spacing(4),
  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
}));

const ProfileCard = styled(Card)(({ theme }) => ({
  borderRadius: '15px',
  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
  color: 'white',
  padding: theme.spacing(4),
  backgroundColor: '#1111',
  marginBottom: theme.spacing(10),
  borderColor: '#5555',
  "&:hover": {
    transform: "translateY(-3px)",
    borderColor: '#1E90FF',
    boxShadow: "0 10px 20px rgba(0, 0, 0, 0.15)",
  },
}));

const ProfileAvatar = styled(Avatar)(({ theme }) => ({
  width: theme.spacing(40),
  height: theme.spacing(40),
  borderRadius: '50%',
  border: `4px solid ${theme.palette.primary.main}`,
  marginRight: theme.spacing(4),
  borderColor: '#5555',
  "&:hover": {
    transform: "translateY(-3px)",
    borderColor: '#1E90FF',
    boxShadow: "0 10px 20px rgba(0, 0, 0, 0.15)",
  },
}));

const SectionTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  fontSize: '1.1rem',
  marginBottom: theme.spacing(2),

}));

const InputField = styled(TextField)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  width: '100%',
  '& .MuiInputBase-root': {
    backgroundColor: '#2222',
    borderRadius: '10px',
    color: 'white',
  },
  '& .MuiInputBase-input': {
    color: 'white',
  },
  '& .MuiInputLabel-root': {
    color: 'white',
  },
  '& .MuiInputLabel-root.Mui-focused': {
    color: 'white',
  }
}));


const DarkForm = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: '#2222',
  padding: '24px',
  borderRadius: '8px',
  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.4)',
  width: '100%',
  maxWidth: '500px',
  margin: 'auto',
}));

const DarkFormControl = styled(FormControl)(({ theme }) => ({
  width: '100%',
  '& .MuiOutlinedInput-root': {
    backgroundColor: '#2222',
    color: 'white',
    '& fieldset': {
      borderColor: '#2222',
    },
    '&:hover fieldset': {
      borderColor: '#3333',
    },
  },
}));

const DarkMenuItem = styled(MenuItem)(({ theme }) => ({
  backgroundColor: '#2222',
  color: 'white',
  '&:hover': {
    backgroundColor: '#444',
  },
}));

const DarkSelect = styled(Select)(({ theme }) => ({
  '& .MuiSelect-select': {
    padding: theme.spacing(2),
    backgroundColor: '#2222',
    color: 'white',
    borderRadius: '10px',
  },
}));

const ActionButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#2222',
  color: 'black',
  padding: theme.spacing(1, 4),
  borderRadius: '25px',
  '&:hover': {
    backgroundColor: '#1E90FF',
  },
  marginTop: theme.spacing(2),
}));


function UserProfile({ onLogout }) {

  const { userId } = useParams();

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };


  const [language, setLanguage] = useState('en');
  const [newPassword, setNewPassword] = useState('');
  const [currentPassword, setCurrentPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showChangePassword, setShowChangePassword] = useState(false);
  const [languages, setLanguages] = useState([]);
  const [userStats, setUserStats] = useState({
    totalEarnings: 0,
    postsCount: 0,
    totalBookings: 0,
  });


  const navigate = useNavigate();


  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [userData, setUserData] = useState({
    "name": "",
    "about": "",
    "contact": "",
    "address": "",
    "created_at": "",
    "dob": "",
    "email": "",
    "gender": "",
    "id": "",
    "image": "",
    "languages": "",
    "password": "",
    "rating": "",
    "role": "",
    "status": "",
    "surname": "",
    "city": "",
    "state": "",
    "country": "",
    "pincode": "",
    "updated_at": "",
    "facebook": "",
    "instagram": "",
    "x": "",
    "linkedin": "",
    "youtube": "",
    "currentLevel": "Advanced",
    "totalTimeLearning": "150 hrs",
    "totalTimeTeaching": "50 hrs",
    "totalSessions": "25",
    "ratings": "4.8/5",
  });

  const [image, setImage] = useState(null);
  const [error, setError] = useState('');

  const handleImageChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      if (file.size > 2 * 1024 * 1024) {
        setError('File size exceeds the 2MB limit.');
        setImage(null);
      } else {
        setError('');
        setImage(file);
      }
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!image) {
      setError('Please select an image.');
      return;
    }

    const formData = new FormData();
    formData.append('profilePicture', image);
    formData.append('userId', userId);

    try {
      const response = await axios.patch(`${API_URL}/update_profile_picture/`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      alert('Profile picture updated successfully.');
      window.location.reload();
    } catch (error) {
      console.error('Error updating profile picture:', error);
      alert('There was an error updating your profile picture. Please try again later.');
    }
  };





  useEffect(() => {
    if (!userId) {
      console.log('User ID is not defined!');
      return;
    }

    axios
      .get(`${API_URL}/get_user_data/?user_id=${userId}`)
      .then((response) => {
        setUserData(response.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [userId]);


  const extractLanguages = (data) => {
    const allLanguages = [];

    data.forEach(item => {
      allLanguages.push({
        id: item.id,   // Use 'id' as the value
        name: item.name,  // Display the 'name' in the dropdown
      });
    });

    setLanguages(allLanguages);  // Set languages as an array of objects
  };

  useEffect(() => {
    const fetchSections = async () => {
      try {
        const response = await fetch(`${API_URL}/list_sections_and_subsections/`);
        const data = await response.json();
        extractLanguages(data.languages);
        console.log('data.languages', data.languages)
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchSections();
  }, []);


  useEffect(() => {
    // Fetch total earnings, posts, and bookings for the user
    const fetchUserStats = async () => {
      try {
        // Make the API call
        const response = await axios.get(`${API_URL}/get_user_stats/?user_id=${userId}`);

        // Set the response data in the state
        setUserStats({
          totalEarnings: response.data.total_earnings,
          totalPosts: response.data.posts_count,
          totalBookings: response.data.total_bookings_made,
        });
      } catch (err) {
        setError('Error fetching user stats');
      }
    };

    fetchUserStats();
  }, []);


  const handleLanguageChange = (e) => {
    setUserData({
      ...userData,
      language: e.target.value
    });
  };



  const handleLogout = () => {
    onLogout();
    navigate('/login');
  };



  const handleChangePassword = async () => {
    if (newPassword !== confirmPassword) {
      alert('New passwords do not match.');
      return;
    }

    try {
      const payload = { userId, newPassword };

      if (userData.password) {
        payload.currentPassword = currentPassword;
      }

      await axios.patch(`${API_URL}/change_user_password/`, payload);
      alert('Password changed successfully.');
      setShowChangePassword(false);
    } catch (error) {
      console.error('Error changing password:', error);
      alert('There was an error changing your password. Please try again later.');
    }
  };



  const [openSnackbar, setOpenSnackbar] = useState(false);


  const handleChange = (e, name) => {
    setUserData({
      ...userData,
      [name]: e.target.value
    });
  };

  const handleChangePreferences = () => {
    navigate(`/recommendation-setup`);
  };

  const [selectedSection, setSelectedSection] = useState('');
  const [selectedSubSection, setSelectedSubSection] = useState('');

  const [isEditing, setIsEditing] = useState(false);


  const toggleEditMode = async () => {
    if (isEditing) {
      try {
        const response = await axios.put(`${API_URL}/update_users/${userId}/`, userData, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}` // Assuming you're using JWT or any token for auth
          }
        });
        console.log('Data saved successfully:', response.data);
        alert('Profile updated successfully!');
        window.location.reload();
      } catch (error) {
        console.error('Error updating profile:', error);
        alert('Failed to update profile. Please try again.');
      }
    }
    setIsEditing(!isEditing);
  };


  const handleDeleteAccount = async () => {
    if (window.confirm('Are you sure you want to delete your account? This action cannot be undone.')) {
      try {
        await axios.patch(`${API_URL}/deactivate_user/${userId}/`);
        alert('Account deactivated successfully.');
        onLogout();
      } catch (error) {
        console.error('Error deactivating account:', error);
        alert('There was an error deactivating your account. Please try again later.');
      }
    }
  };

  const specializedFields = userData?.specialized_fields || [];
  const roles = userData?.roles || [];



  return (
    <div>
      <Header1 userId={userId} onLogout={onLogout} />
      {/* <Box sx={{ display: 'flex', flexDirection: 'column',flexWrap: 'wrap', minHeight: '100vh', backgroundColor: 'black' }}> */}
      <Box
  sx={{
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    minHeight: '100vh',
    backgroundColor: 'black',
    padding: 2,  // Add padding for spacing
    gap: 2,      // Add space between items in the column
    justifyContent: 'flex-start',
    alignItems: 'center',  // Center items horizontally in smaller screens
    width: '100%',  // Make sure it spans the full width of the screen
    '@media (min-width: 600px)': {
      flexDirection: 'row', // On larger screens, switch to row layout
      justifyContent: 'space-evenly', // Distribute space evenly between items
      alignItems: 'center', // Align items centrally along the row
    },
    '@media (min-width: 1024px)': {
      padding: 4, // Increase padding on larger screens
      gap: 3,     // Increase the gap for a more spacious layout
    },
  }}
>
        
<Grid item xs={12} sm={6} md={3} lg={2} sx={{ zIndex: 10 }}>
  <Box
    sx={{
      display: 'flex',
      flexDirection: { xs: 'column', sm: 'row' }, // Column on small screens, row on medium and up
      flexWrap: 'wrap', // Allow links to wrap if needed
      gap: 2, // Space between links
      backgroundColor: '#1111',
      mt: 10,
      alignItems: 'center',
      justifyContent: 'center',
      padding: { xs: 1, sm: 2, md: 3 }, // Adjust padding based on screen size
      borderRadius: 2,
      boxShadow: 2,
      borderColor: '#5555',
      "&:hover": {
        transform: "translateY(-3px)",
        borderColor: '#1E90FF',
        boxShadow: "0 10px 20px rgba(0, 0, 0, 0.15)",
      },
    }}
  >
    <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, gap: 1, flexWrap: 'wrap' }}>
      <MuiLink
        href="#profile-header"
        sx={{
          display: 'flex',
          alignItems: 'center',
          padding: 1,
          borderRadius: 1,
          color: '#f5f5f5',
          textDecoration: 'none',
          '&:hover': {
            backgroundColor: '#2222',
            color: '#1E90FF',
            transition: 'background-color 0.3s ease, color 0.3s ease',
          },
        }}
      >
        Profile
      </MuiLink>
      <MuiLink
        href="#performance"
        sx={{
          display: 'flex',
          alignItems: 'center',
          padding: 1,
          borderRadius: 1,
          color: '#f5f5f5',
          textDecoration: 'none',
          '&:hover': {
            backgroundColor: '#2222',
            color: '#1E90FF',
          },
        }}
      >
        Analytics
      </MuiLink>
      <MuiLink
        href="#preferences"
        sx={{
          display: 'flex',
          alignItems: 'center',
          padding: 1,
          borderRadius: 1,
          color: '#f5f5f5',
          textDecoration: 'none',
          '&:hover': {
            backgroundColor: '#2222',
            color: '#1E90FF',
          },
        }}
      >
        Preferences
      </MuiLink>

      <MuiLink
        href="#about-me"
        sx={{
          display: 'flex',
          alignItems: 'center',
          padding: 1,
          borderRadius: 1,
          color: '#f5f5f5',
          textDecoration: 'none',
          '&:hover': {
            backgroundColor: '#2222',
            color: '#1E90FF',
          },
        }}
      >
        About
      </MuiLink>

      <MuiLink
        href="#personal-info"
        sx={{
          display: 'flex',
          alignItems: 'center',
          padding: 1,
          borderRadius: 1,
          color: '#f5f5f5',
          textDecoration: 'none',
          '&:hover': {
            backgroundColor: '#2222',
            color: '#1E90FF',
          },
        }}
      >
        Personal
      </MuiLink>

      <MuiLink
        href="#contact-info"
        sx={{
          display: 'flex',
          alignItems: 'center',
          padding: 1,
          borderRadius: 1,
          color: '#f5f5f5',
          textDecoration: 'none',
          '&:hover': {
            backgroundColor: '#2222',
            color: '#1E90FF',
          },
        }}
      >
        Contacts
      </MuiLink>

      <MuiLink
        href="#social-media-info"
        sx={{
          display: 'flex',
          alignItems: 'center',
          padding: 1,
          borderRadius: 1,
          color: '#f5f5f5',
          textDecoration: 'none',
          '&:hover': {
            backgroundColor: '#2222',
            color: '#1E90FF',
          },
        }}
      >
        Social
      </MuiLink>

      <MuiLink
        href="#address-info"
        sx={{
          display: 'flex',
          alignItems: 'center',
          padding: 1,
          borderRadius: 1,
          color: '#f5f5f5',
          textDecoration: 'none',
          '&:hover': {
            backgroundColor: '#2222',
            color: '#1E90FF',
          },
        }}
      >
        Address
      </MuiLink>

      <MuiLink
        href="#language-selection"
        sx={{
          display: 'flex',
          alignItems: 'center',
          padding: 1,
          borderRadius: 1,
          color: '#f5f5f5',
          textDecoration: 'none',
          '&:hover': {
            backgroundColor: '#2222',
            color: '#1E90FF',
          },
        }}
      >
        Language
      </MuiLink>

      <MuiLink
        href="#change-password"
        sx={{
          display: 'flex',
          alignItems: 'center',
          padding: 1,
          borderRadius: 1,
          color: '#f5f5f5',
          textDecoration: 'none',
          '&:hover': {
            backgroundColor: '#2222',
            color: '#1E90FF',
          },
        }}
      >
        Password
      </MuiLink>

      <MuiLink
        href="#delete-account"
        sx={{
          display: 'flex',
          alignItems: 'center',
          padding: 1,
          borderRadius: 1,
          color: '#f5f5f5',
          textDecoration: 'none',
          '&:hover': {
            backgroundColor: '#2222',
            color: '#1E90FF',
          },
        }}
      >
        Delete
      </MuiLink>
    </Box>
  </Box>
</Grid>



      <ThemeProvider theme={theme}>
        <Container maxWidth="lg" sx={{ marginTop: 10, mb: 10 }}>
          <Grid container spacing={4} justifyContent="center">
            

              <Grid item xs={12} sm={10} md={8} sx={{ marginLeft: '50px' }}>
                <Box id="profile-header" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>


                    

                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                      <ProfileAvatar
                        src={userData.image ? `${API_URL}${userData.image}` : avatar}
                        alt={`${userData.name} ${userData.surname}`}
                        sx={{
                          width: { xs: 100, sm: 150, md: 200, lg: 250, xl: 300 }, // Responsive width
                          height: { xs: 100, sm: 150, md: 200, lg: 250, xl: 300 }, // Responsive height
                          // marginBottom: 2,
                          borderColor: '#5555',
                          // borderWidth: 2,
                          borderRadius: '50%',
                          alignItems: 'center',
                          justifyContent: 'center',
                          textAlign: 'center',
                          "&:hover": {
                            transform: "translateY(-3px)",
                            borderColor: '#1E90FF',
                            boxShadow: "0 10px 20px rgba(0, 0, 0, 0.15)",
                          },
                        }}
                      />
                      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', mt: 2 }}>
                        {userData.facebook && (
                          <a href={userData.facebook} target="_blank" rel="noopener noreferrer">
                            <IconButton sx={{ color: 'white', marginBottom: 1, '&:hover': { color: '#1E90FF' } }}>
                              <Facebook sx={{ fontSize: '2rem' }} />
                            </IconButton>
                          </a>
                        )}

                        {userData.instagram && (
                          <a href={userData.instagram} target="_blank" rel="noopener noreferrer">
                            <IconButton sx={{ color: 'white', marginBottom: 1, '&:hover': { color: '#1E90FF' } }}>
                              <Instagram sx={{ fontSize: '2rem' }} />
                            </IconButton>
                          </a>
                        )}

                        {userData.youtube && (
                          <a href={userData.youtube} target="_blank" rel="noopener noreferrer">
                            <IconButton sx={{ color: 'white', '&:hover': { color: '#1E90FF' } }}>
                              <YouTube sx={{ fontSize: '2.3rem' }} />
                            </IconButton>
                          </a>
                        )}

                        {userData.x && (
                          <a href={userData.x} target="_blank" rel="noopener noreferrer">
                            <IconButton sx={{ color: 'white', marginBottom: 1, '&:hover': { color: '#1E90FF' } }}>
                              <X sx={{ fontSize: '2rem' }} />
                            </IconButton>
                          </a>
                        )}

                        {userData.linkedin && (
                          <a href={userData.linkedin} target="_blank" rel="noopener noreferrer">
                            <IconButton sx={{ color: 'white', marginBottom: 1, '&:hover': { color: '#1E90FF' } }}>
                              <LinkedIn sx={{ fontSize: '2rem' }} />
                            </IconButton>
                          </a>
                        )}


                      </Box>



                      <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', mb: 5 }}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', mb: 5, mt: 3 }}>
                          <Typography variant="h4" fontWeight="bold" color="white">
                            <span>{userData.name}</span>
                            <span style={{ marginLeft: '10px' }}>{userData.surname}</span>
                          </Typography>
                        </Box>

                        {isEditing && (
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'center',
                              backgroundColor: '#5555',
                              padding: 1,
                              borderRadius: '8px',
                              boxShadow: 3,
                              width: '50%',
                            }}
                          >
                            <form onSubmit={handleSubmit} style={{ width: '100%' }}>
                              <Input
                                type="file"
                                accept="image/*"
                                onChange={handleImageChange}
                                fullWidth
                                sx={{
                                  backgroundColor: '#5555',
                                  color: 'white',
                                  '& input': {
                                    padding: '10px',
                                  },
                                }}
                              />
                              {/* {error && <p style={{ color: 'red' }}>{error}</p>} */}

                              <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                sx={{
                                  width: '100%',
                                  marginTop: '16px',
                                  backgroundColor: '#0077ff',
                                  '&:hover': { backgroundColor: '#005bb5' },
                                }}
                              >
                                Update Profile Picture
                              </Button>
                            </form>
                          </Box>
                        )}

                        {/* Edit Icon */}
                        <IconButton onClick={toggleEditMode} sx={{ color: 'white', marginTop: 2 }}>
                          {/* <Edit /> */}
                          {isEditing ? <Save /> : <Edit />}
                        </IconButton>
                      </Box>

                    </Box>
                  </Box>
                </Box>
                <Grid sx={{ zIndex: 10, mr: 4, mt: 11 }}>
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      background: 'linear-gradient(135deg, #4e73df 0%, #2a59f4 100%)',
      alignItems: 'center',
      padding: { xs: 3, sm: 5 }, // Adjust padding for different screen sizes
      borderRadius: 4,
      boxShadow: 2,
      borderColor: '#5555',
      transition: 'transform 0.3s ease, box-shadow 0.3s ease',
      '&:hover': {
        transform: 'translateY(-5px)',
        boxShadow: '0 8px 15px rgba(0, 0, 0, 0.2)',
      },
    }}
  >
    <Typography
      variant="h6"
      fontWeight="bold"
      color="white"
      sx={{
        marginBottom: 3,
        fontSize: { xs: '1rem', sm: '1.25rem' },
        textAlign: 'center',
      }}
    >
      Analytics
    </Typography>

    {/* Current Level */}
    <Box id="performance"
  sx={{
    display: 'grid',
    gridTemplateColumns: { xs: '1fr', sm: '1fr 1fr', md: '1fr 1fr 1fr', lg: '1fr 1fr 1fr 1fr' },
    gap: 2,  // Add space between items
    padding: 2,
    width: '100%',
    bgcolor: 'background.paper',
    borderRadius: 2,
    boxShadow: 1,
  }}
>
  <Typography variant="h7" sx={{  color: 'white' }}>
    Experience: {userData.Experience} Hours
  </Typography>
  <Typography variant="h7" sx={{  color: 'white' }}>
    User Ratings: {userData.rating}/5
  </Typography>
  <Typography variant="h7" sx={{  color: 'white' }}>
    Total Earnings: ₹{userStats.totalEarnings.toFixed(2)}
  </Typography>
  <Typography variant="h7" sx={{  color: 'white' }}>
    Total Posts: {userStats.totalPosts}
  </Typography>
  <Typography variant="h7" sx={{  color: 'white' }}>
    Total Bookings: {userStats.totalBookings}
  </Typography>
  <Typography variant="h7" sx={{  color: 'white' }}>
    Amount Payable: ₹{userStats.totalEarnings.toFixed(2)}
  </Typography>
</Box>

  </Box>
</Grid>


{/* <RecommendationSetup/> */}
<Box
  sx={{
    display: 'flex',          // Enable Flexbox
    justifyContent: 'center', // Center horizontally
    alignItems: 'center',     // Center vertically
    minHeight: '20vh',       // Ensure it takes the full viewport height
    padding: 2,               // Optional padding around the container
  }}
>
  <Button 
    onClick={handleChangePreferences}
    variant="contained"
    color="primary"
    sx={{
      padding: '10px 20px',
      fontSize: '16px',
      fontWeight: 'bold',
      backgroundColor: '#1E90FF',
      mt: 10,
      borderRadius: '30px',
      boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
      textTransform: 'none',
      transition: 'all 0.3s ease-in-out',
      '&:hover': {
        backgroundColor: '#1E90FF',
        transform: 'scale(1.05)',
        boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.2)',
      },
      '&:active': {
        backgroundColor: '#303f9f',
        boxShadow: 'none',
        transform: 'scale(0.98)',
      },
      '@media (max-width: 600px)': {
        padding: '8px 16px',
        fontSize: '14px',
        borderRadius: '25px',
      },
      '@media (max-width: 400px)': {
        padding: '6px 12px',
        fontSize: '12px',
        borderRadius: '20px',
      },
    }}
    
  >
    Change My Preferences
  </Button>
</Box >

<Box id="preferences" sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', mb: 5, mt: 3, width: '100%' }}>
  
  {/* Display Specialized Fields only if data is available */}
  {specializedFields.length > 0 && (
    <Box sx={{ mb: 3, width: '100%', display: 'flex', justifyContent: 'center' }}>
      <Grid container spacing={2} alignItems="center" justifyContent="center">
        <Grid item xs="auto">
          <Typography variant="subtitle1" color="white" sx={{ fontWeight: 'bold' }}>
            Specialized Fields:
          </Typography>
        </Grid>
        <Grid item xs={6} sm={2}>
          <Grid container spacing={2} justifyContent="center">
            {specializedFields.map((field, index) => (
              <Grid item key={index}>
                <Chip
                  label={field}
                  color="primary"
                  sx={{
                    fontWeight: 'bold',
                    borderRadius: '20px',
                    backgroundColor: '#1E90FF',
                    paddingX: '16px',
                    paddingY: '8px',
                    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                    '&:hover': {
                      boxShadow: '0px 6px 12px rgba(0, 0, 0, 0.2)',
                    },
                  }}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )}

  {/* Display Roles only if data is available */}
  {roles.length > 0 && (
    <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
      <Grid container spacing={2} alignItems="center" justifyContent="center">
        <Grid item xs="auto">
          <Typography variant="subtitle1" color="white" sx={{ fontWeight: 'bold' }}>
            Roles:
          </Typography>
        </Grid>
        <Grid item xs={8} sm={4}>
          <Grid container spacing={2} justifyContent="center">
            {roles.map((role, index) => (
              <Grid item key={index}>
                <Chip
                  label={role}
                  color="secondary"
                  sx={{
                    fontWeight: 'bold',
                    borderRadius: '20px',
                    backgroundColor: '#1E90FF',
                    paddingX: '16px',
                    paddingY: '8px',
                    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                    '&:hover': {
                      boxShadow: '0px 6px 12px rgba(0, 0, 0, 0.2)',
                    },
                  }}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )}

</Box>


                {/* About Me Card */}
                <ProfileCard id="about" >
                  <CardContent>
                    <SectionTitle>About Me</SectionTitle>
                    {isEditing ? (
                      <InputField
                        name="aboutMe"
                        value={userData.about}
                        onChange={(e) => handleChange(e, 'about')}
                        label="About Me"
                        color="black"
                        backgroundColor="white"
                        variant="outlined"
                        multiline
                        rows={4}
                        fullWidth
                      />
                    ) : (
                      <Typography variant="body1" color='white'>{userData.about}</Typography>
                    )}
                  </CardContent>
                </ProfileCard>

                {/* Personal Information Card */}
                <ProfileCard id="personal-info">
                  <CardContent>
                    <SectionTitle><Person /> Personal Info</SectionTitle>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                      {!isEditing && ( <Typography variant="body1">
                           First Name: {userData.name}
                        </Typography>)}
                        {isEditing && (
                          <InputField
                            name="name"
                            value={userData.name}
                            onChange={(e) => handleChange(e, 'name')}
                            label="First Name"
                            variant="outlined"
                            fullWidth
                            color='white'
                          />
                        )}
                      </Grid>

                      <Grid item xs={12} sm={6}>
                      {!isEditing && (<Typography variant="body1">
                          Last Name: {userData.surname}
                        </Typography>)}
                        {isEditing && (
                          <InputField
                            name="surname"
                            value={userData.surname}
                            onChange={(e) => handleChange(e, 'surname')}
                            label="Last Name"
                            variant="outlined"
                            fullWidth
                            color='white'
                          />
                        )}
                      </Grid>

                      <Grid item xs={12} sm={6}>
                      {!isEditing && (<Typography variant="body1">
                          Date of Birth: {userData.dob}
                        </Typography>)}
                        {isEditing && (
                          <InputField
                            name="dob"
                            value={userData.dob}
                            onChange={(e) => handleChange(e, 'dob')}
                            label="Date of Birth"
                            variant="outlined"
                            type="date"
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                          />
                        )}
                      </Grid>

                      <Grid item xs={12} sm={6}>
                      {!isEditing && (<Typography variant="body1">
                          Gender: {userData.gender}
                        </Typography>)}
                        {isEditing && (
                          <DarkSelect
                            name="gender"
                            value={userData.gender}
                            onChange={(e) => handleChange(e, 'gender')}
                            label="Gender"
                            variant="outlined"
                            fullWidth
                          >
                            <DarkMenuItem value="Male">Male</DarkMenuItem>
                            <DarkMenuItem value="Female">Female</DarkMenuItem>
                            <DarkMenuItem value="Other">Other</DarkMenuItem>
                          </DarkSelect>
                        )}
                      </Grid>
                    </Grid>
                  </CardContent>
                </ProfileCard>


                {/* Contact Information Card */}
                <ProfileCard id="contact-info">
                  <CardContent>
                    <SectionTitle>Contact Info</SectionTitle>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                      {!isEditing && (<Typography variant="body1">
                          <Email /> Email: {userData.email}
                        </Typography>)}
                        {isEditing && (
                          <InputField
                            name="email"
                            value={userData.email}
                            onChange={(e) => handleChange(e, 'email')}
                            label="Email"
                            variant="outlined"
                            fullWidth
                          />
                        )}
                      </Grid>

                      <Grid item xs={12} sm={6}>
                      {!isEditing && (<Typography variant="body1">
                          <Phone /> Contact: {userData.contact}
                        </Typography>)}
                        {isEditing && (
                          <InputField
                            name="contact"
                            value={userData.contact}
                            onChange={(e) => handleChange(e, 'contact')}
                            label="Phone Number"
                            variant="outlined"
                            fullWidth
                          />
                        )}
                      </Grid>
                    </Grid>
                  </CardContent>
                </ProfileCard>
                {/* <VerificationForm/>
      <DigilockerVerificationForm/> */}

                {/* Social Media Profiles Info Card */}
                <ProfileCard id="social-media-info">
                  <CardContent>
                    <SectionTitle>Social Media Profiles</SectionTitle>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                      {!isEditing && ( <Typography variant="body1">
                          <Facebook /> Facebook: {userData.facebook}
                        </Typography>)}
                        {isEditing && (
                          <InputField
                            name="facebook"
                            value={userData.facebook}
                            onChange={(e) => handleChange(e, 'facebook')}
                            label="Facebook URL"
                            variant="outlined"
                            fullWidth
                          />
                        )}
                      </Grid>

                      <Grid item xs={12} sm={6}>
                      {!isEditing && (<Typography variant="body1">
                          <Instagram /> Instagram: {userData.instagram}
                        </Typography>)}
                        {isEditing && (
                          <InputField
                            name="instagram"
                            value={userData.instagram}
                            onChange={(e) => handleChange(e, 'instagram')}
                            label="Instagram URL"
                            variant="outlined"
                            fullWidth
                          />
                        )}
                      </Grid>

                      <Grid item xs={12} sm={6}>
                      {!isEditing && (<Typography variant="body1">
                          <Twitter /> X (formerly Twitter): {userData.x}
                        </Typography>)}
                        {isEditing && (
                          <InputField
                            name="x"
                            value={userData.x}
                            onChange={(e) => handleChange(e, 'x')}
                            label="X URL"
                            variant="outlined"
                            fullWidth
                          />
                        )}
                      </Grid>

                      <Grid item xs={12} sm={6}>
                      {!isEditing && ( <Typography variant="body1">
                          <LinkedIn /> LinkedIn: {userData.linkedin}
                        </Typography>)}
                        {isEditing && (
                          <InputField
                            name="linkedin"
                            value={userData.linkedin}
                            onChange={(e) => handleChange(e, 'linkedin')}
                            label="LinkedIn URL"
                            variant="outlined"
                            fullWidth
                          />
                        )}
                      </Grid>

                      <Grid item xs={12} sm={6}>
                      {!isEditing && (<Typography variant="body1">
                          <YouTube /> YouTube: {userData.youtube}
                        </Typography>)}
                        {isEditing && (
                          <InputField
                            name="youtube"
                            value={userData.youtube}
                            onChange={(e) => handleChange(e, 'youtube')}
                            label="YouTube URL"
                            variant="outlined"
                            fullWidth
                          />
                        )}
                      </Grid>
                    </Grid>
                  </CardContent>
                </ProfileCard>

                {/* Address Information Card */}
                <ProfileCard id="address-info">
                  <CardContent>
                    <SectionTitle><LocationOn /> Address Info</SectionTitle>
                    <Grid container spacing={2}>
                      {/* New Address Line Field */}
      <Grid item xs={12}>
      {!isEditing && (<Typography variant="body1">
           Address Line: {userData.address}
        </Typography>)}
        <Typography variant="body2" color="gray">
          (Max 100 characters)
        </Typography>
        {isEditing && (
          <InputField
            name="address"
            value={userData.address}
            onChange={(e) => handleChange(e, 'address')}
            label="Address Line"
            variant="outlined"
            fullWidth
            inputProps={{ maxLength: 100 }} // Set max length to 100 characters
          />
        )}
      </Grid>
                      <Grid item xs={12} sm={6}>
                      {!isEditing && (<Typography variant="body1">
                         City: {userData.city}
                        </Typography>)}
                        {isEditing && (
                          <InputField
                            name="city"
                            value={userData.city}
                            onChange={(e) => handleChange(e, 'city')}
                            label="City"
                            variant="outlined"
                            fullWidth
                          />
                        )}
                      </Grid>
                      <Grid item xs={12} sm={6}>
                      {!isEditing && (<Typography variant="body1">
                           State: {userData.state}
                        </Typography>)}
                        {isEditing && (
                          <InputField
                            name="state"
                            value={userData.state}
                            onChange={(e) => handleChange(e, 'state')}
                            label="State"
                            variant="outlined"
                            fullWidth
                          />
                        )}
                      </Grid>
                      <Grid item xs={12} sm={6}>
                      {!isEditing && (<Typography variant="body1">
                           Country: {userData.country}
                        </Typography>)}
                        {isEditing && (
                          <InputField
                            name="Country"
                            value={userData.country}
                            onChange={(e) => handleChange(e, 'country')}
                            label="Country"
                            variant="outlined"
                            fullWidth
                          />
                        )}
                      </Grid>
                      <Grid item xs={12} sm={6}>
                      {!isEditing && ( <Typography variant="body1">
                           Pincode: {userData.pincode}
                        </Typography>)}
                        {isEditing && (
                          <InputField
                            name="Pincode"
                            value={userData.pincode}
                            onChange={(e) => handleChange(e, 'pincode')}
                            label="Pincode"
                            variant="outlined"
                            fullWidth
                          />
                        )}
                      </Grid>
                    </Grid>
                  </CardContent>
                </ProfileCard>

                {/* Language Selection Card */}
                <ProfileCard id="language-selection">
                  <CardContent>
                    <SectionTitle>Preferred Languages</SectionTitle>
                    <DarkFormControl fullWidth sx={{ color: 'white' }}>
                      <InputLabel sx={{ color: 'white' }}>Choose Language</InputLabel>
                      <DarkSelect
                        value={userData.languages}
                        onChange={handleLanguageChange}
                        label="Choose Language"
                        fullWidth
                        sx={{ color: 'white' }}
                      >
                        {languages.length > 0 ? (
                          languages.map((lang, index) => (
                            <DarkMenuItem key={index} sx={{ color: 'white' }} value={lang.code}>
                              {lang.name}
                            </DarkMenuItem>
                          ))
                        ) : (
                          <DarkMenuItem sx={{ color: 'white' }} disabled>
                            No Languages Available
                          </DarkMenuItem>
                        )}
                      </DarkSelect>
                    </DarkFormControl>
                  </CardContent>
                </ProfileCard>

                {/* Change Password Card */}
                <ProfileCard id="change-password">
                  <CardContent>
                    <SectionTitle>Change Password</SectionTitle>
                    {userData.password && (
                      <InputField
                        label="Current Password"
                        type="password"
                        variant="outlined"
                        value={currentPassword}
                        onChange={(e) => setCurrentPassword(e.target.value)}
                        fullWidth

                      />
                    )}
                    <InputField
                      label="New Password"
                      type="password"
                      variant="outlined"
                      value={newPassword}
                      onChange={(e) => setNewPassword(e.target.value)}
                      fullWidth
                    />
                    <InputField
                      label="Confirm New Password"
                      type="password"
                      variant="outlined"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      fullWidth
                    />
                    <Button onClick={handleChangePassword} variant="contained" color="black" fullWidth>
                      Change Password
                    </Button>
                  </CardContent>
                </ProfileCard>

                {/* Delete Account Card */}
                <ProfileCard id="delete-account">
                  <CardContent>
                    <SectionTitle>Delete Account</SectionTitle>
                    <Typography variant="body2" color="textSecondary">
                      Deleting your account is permanent and cannot be undone.
                    </Typography>
                    <Button onClick={handleDeleteAccount} variant="contained" color="error" fullWidth>
                      Delete Account
                    </Button>
                  </CardContent>
                </ProfileCard>
              </Grid>
            </Grid>
          </Container>
        </ThemeProvider>

        {/* Snackbar for feedback messages */}
        <Snackbar
          open={openSnackbar}
          autoHideDuration={6000}
          onClose={() => setOpenSnackbar(false)}
        >
          <Alert severity="success" sx={{ width: '100%' }}>
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </Box>
      <IconButton
        onClick={scrollToTop}
        sx={{ position: 'fixed', bottom: 16, right: 16, backgroundColor: 'white', color: 'black' }}>
        <ArrowUpwardIcon />
      </IconButton>
      <Footer2 />
    </div>

  );
}

export default UserProfile;