import React, { useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom'; // Replace useHistory with useNavigate
import axios from 'axios';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Logo from '../Logo/Dark/Full - Copy/1Asset 17@72x-8.png';
import GoogleSignInDark from './web_dark_rd_ctn@4x.png'
import {
  Container,
  Box,
  Typography,
  TextField,
  Button,
  Checkbox,
  FormControlLabel,
  Snackbar,
  IconButton,
  InputAdornment,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Footer2 from '../Footer/Footer2';

const styles = {
  glassBox: {
    backdropFilter: 'blur(15px)',
    backgroundColor: '#1111',
    borderRadius: '16px',
    padding: '1.5rem',
    boxShadow: '0 4px 30px rgba(0, 0, 0, 0.2)',
    border: '1px solid rgba(255, 255, 255, 0.3)',
    maxWidth: '400px',
    textAlign: 'center',
    '&:hover': {
      borderColor: '#1E90FF',
    },
  },
  logo: {
    width: '450px',
    height: 'auto',
    objectFit: 'contain',
  },
  logoBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  slogan: {
    marginTop: '8px',
    fontSize: '1.2rem',
    color: '#FFFFF0',
  },
  text: {
    color: 'white',
    '&:hover': {
      color: '#1E90FF',
    },
  },
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
  },
  input: {
    marginBottom: '1rem',
    '& .MuiInputBase-input': {
      color: 'white',
    },
    '& .MuiInputLabel-root': {
      color: 'rgba(255, 255, 255, 0.7)',
    },
    '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      borderColor: 'rgba(255, 255, 255, 0.5)',
    },
    '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: '#1E90FF',
    },
  },
  button: {
    backgroundColor: '#1E90FF',
    color: 'white',
    marginTop: '1rem',
  },
  iconButton: {
    margin: '0.5rem 0',
    color: 'white',
    borderColor: 'rgba(255, 255, 255, 0.5)',
    '&:hover': {
      borderColor: 'white',
    },
  },
  link: {
    color: '#1E90FF',

    textDecoration: 'none',
    '&:hover': {
      color: '#1E90FF',
    },
  },
};

const API_URL = process.env.REACT_APP_API_URL;

const Login = ({ onLogin }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [forgotPasswordDialogOpen, setForgotPasswordDialogOpen] = useState(false);
  const [forgotPasswordEmail, setForgotPasswordEmail] = useState('');
  const navigate = useNavigate();

  // Load user data from localStorage if "Remember Me" is checked
  useEffect(() => {
    if (localStorage.getItem('rememberMe') === 'true') {
      const savedUsername = localStorage.getItem('username');
      const savedPassword = localStorage.getItem('password');
      setUsername(savedUsername);
      setPassword(savedPassword);
      setRememberMe(true);
    }
  }, []);

  const handleClickShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  const handleLogin = async () => {
    try {
      const response = await axios.post(`${API_URL}/login/`, { username, password });
      if (response.data['response'] !== 'Successful') {
        throw new Error('Invalid Credentials');
      }
      onLogin(response.data['user_id']);
      // Save credentials in localStorage if "Remember Me" is checked
      if (rememberMe) {
        localStorage.setItem('rememberMe', 'true');
        localStorage.setItem('username', username);
        localStorage.setItem('password', password);
      }
      navigate('/platform');
    } catch (error) {
      setErrorMessage('Invalid Credentials');
      setSnackbarOpen(true);
    }
  };

  const handleGoogleLoginSuccess = (response) => {
    console.log({ token: response.credential });
    axios.post(`${API_URL}/api/auth/google/`, { token: response.credential })
      .then((res) => {
        if (res.status === 200) {
          onLogin(res.data.user_id);
        }
      })
      .catch((err) => {
        setErrorMessage('Google Login Failed');
        setSnackbarOpen(true);
      });
  };

  const handleGoogleLoginFailure = (error) => {
    setErrorMessage('Google Login failed');
    setSnackbarOpen(true);
  };

  const handleForgotPassword = async () => {
    try {
      const response = await axios.post(`${API_URL}/forgot-password/`, { email: forgotPasswordEmail });
      setForgotPasswordDialogOpen(false);
      alert('Password reset link sent to your email.');
    } catch (error) {
      setErrorMessage('Error sending reset link');
      setSnackbarOpen(true);
    }
  };

  return (
    <div>
      <GoogleOAuthProvider clientId="758812043329-08r7ufdk067bb09pe4jeclv4nls96o58.apps.googleusercontent.com">
        <Container maxWidth="lg" sx={styles.root}>
          <Grid container spacing={2}>
            {/* Left Side: Logo and Slogan */}
            <Grid 
  item 
  xs={12} 
  sm={6} 
  className="logoBox" 
  sx={{
    ...styles.logoBox,
    textAlign: { xs: 'center', sm: 'left' }, // Center the content on small screens, left-align on larger screens
    padding: { xs: '1rem', sm: '2rem' }, // Add responsive padding for better spacing
    display: 'flex',
    flexDirection: 'column', // Stack logo and slogan vertically on small screens
    alignItems: 'center', // Center-align elements on small screens
    justifyContent: 'center', // Vertically center the items
  }}
>
  <Link to="/home">
    <img 
      src={Logo} 
      alt="Logo" 
      style={{
        ...styles.logo,
        width: '100%', // Ensure the logo scales with the container width
        maxWidth: '500px', // Limit the logo size for larger screens
      }} 
    />
  </Link>
  <Typography 
    variant="h6" 
    sx={{
      ...styles.slogan,
      marginTop: { xs: '1rem', sm: '0.5rem' }, // Add spacing between logo and slogan on small screens
      fontSize: { xs: '1rem', sm: '1.25rem' }, // Adjust the font size for better readability
      textAlign:'center',
    }}
  >
    Unlocking Potential Through Knowledge
  </Typography>
</Grid>


            {/* Right Side: Login Box */}
            <Grid item xs={12} sm={6} container justifyContent="center" alignItems="center">
              <Box sx={styles.glassBox}>
                <Typography variant="h6" sx={{ color: 'white', fontSize: '25px', '&:hover': { color: '#1E90FF' } }}>
                  Login
                </Typography>

                <TextField
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  label="Email or Phone Number"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  sx={styles.input}
                />

                <TextField
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  label="Password"
                  type={showPassword ? 'text' : 'password'}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  sx={styles.input}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          edge="end"
                        >
                          {showPassword ? <Visibility sx={{ color: 'black' }} /> : <VisibilityOff sx={{ color: 'black' }} />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />

                <FormControlLabel
                  control={<Checkbox checked={rememberMe} onChange={(e) => setRememberMe(e.target.checked)} sx={{ color: 'white' }} />}
                  label={<Typography sx={styles.text}>Remember me</Typography>}
                />

                <Button
                  variant="contained"
                  sx={styles.button}
                  fullWidth
                  onClick={handleLogin}
                >
                  Login
                </Button>

                <Button
                  variant="text"
                  onClick={() => setForgotPasswordDialogOpen(true)}
                  sx={{
                    color: '#1E90FF',
                    marginTop: '1rem',
                  }}
                >
                  <Typography sx={{ ...styles.text, fontSize: '0.7rem' }}>
                    Forgot Password?
                  </Typography>
                </Button>

                {/* <Box my={2}>
                  <Typography variant="body2" sx={styles.text}>or</Typography>
                </Box> */}

                {/* <GoogleLogin
      onSuccess={handleGoogleLoginSuccess}
      onError={handleGoogleLoginFailure}
      useOneTap
      size="large"
      theme="dark"  // Dark theme for Google button
      shape="rectangular"
      text="signin_with"
      width="100%" // Make the button full width
      style={{
        borderRadius: '4px',
        padding: '10px 0',  // Optional: Add padding for better appearance
      }}
    /> */}


<Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 2 }}>
<Typography variant="body2" sx={styles.text}>
You will be able to create an account and start using the platform shortly.
  </Typography>
  {/* <Typography variant="body2" sx={styles.text}>
    Don't have an account?
  </Typography> */}
  {/* <Link to="/signup" style={{ ...styles.link, marginLeft: '8px' }}>
    Create account
  </Link> */}
</Box>

              </Box>
            </Grid>
          </Grid>

          <Snackbar
            open={snackbarOpen}
            autoHideDuration={6000}
            message={errorMessage}
            action={
              <IconButton size="small" aria-label="close" color="inherit" onClick={() => setSnackbarOpen(false)}>
                <CloseIcon fontSize="small" />
              </IconButton>
            }
          />

          {/* Forgot Password Dialog */}
          <Dialog
            open={forgotPasswordDialogOpen}
            onClose={() => setForgotPasswordDialogOpen(false)}
            maxWidth="sm"
            fullWidth
            sx={{
              '& .MuiDialog-paper': {
                backgroundColor: '#111111',
                color: 'white',
                borderRadius: '8px',
                boxShadow: '0 4px 10px rgba(0, 0, 0, 0.3)',
                padding: '2rem',
              },
            }}
          >
            <DialogTitle
              sx={{
                color: 'white',
                fontSize: '1.4rem',
                fontWeight: 'bold',
                marginBottom: '1rem',
              }}
            >
              Reset Password
            </DialogTitle>
            <DialogContent>
              <TextField
                fullWidth
                label="Enter your email"
                value={forgotPasswordEmail}
                onChange={(e) => setForgotPasswordEmail(e.target.value)}
                sx={{
                  backgroundColor: '#1111',
                  input: {
                    color: 'white',
                  },
                  label: {
                    color: 'rgba(255, 255, 255, 0.7)',
                  },
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: 'rgba(255, 255, 255, 0.3)',
                    },
                    '&:hover fieldset': {
                      borderColor: '#1E90FF',
                    },
                  },
                }}
              />
            </DialogContent>
            <DialogActions
              sx={{
                backgroundColor: '#1111',
                borderTop: '1px solid rgba(255, 255, 255, 0.2)',
                padding: '1rem',
              }}
            >
              <Button
                onClick={() => setForgotPasswordDialogOpen(false)}
                color="primary"
                sx={{
                  color: 'white',
                  '&:hover': {
                    backgroundColor: '#1111',
                  },
                }}
              >
                Cancel
              </Button>
              <Button
                onClick={handleForgotPassword}
                color="primary"
                sx={{
                  color: 'white',
                  '&:hover': {
                    backgroundColor: '#1E90FF',
                  },
                }}
              >
                Send Reset Link
              </Button>
            </DialogActions>
          </Dialog>

        </Container>
      </GoogleOAuthProvider>
      <Footer2 />
    </div>
  );
};

export default Login;
