import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Box, Typography, Button, CircularProgress, Grid } from '@mui/material';
import { styled } from '@mui/system';
import Header1 from '../Header/Header1';
import Footer2 from '../Footer/Footer2';

const API_URL = process.env.REACT_APP_API_URL;

// Custom styled components for a professional black background design
const InterviewContainer = styled(Box)({
  backgroundColor: '#212121',  // Slightly off-black background for container
  padding: '25px',
  borderRadius: '10px',
  marginBottom: '20px',
  boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.5)',  // Subtle deep shadow
  transition: 'box-shadow 0.3s ease-in-out',
  '&:hover': {
    boxShadow: '0px 6px 25px rgba(0, 0, 0, 0.7)',  // Stronger hover shadow
  },
});

const JoinButton = styled(Button)({
  backgroundColor: '#4caf50',  // Green color for "Join Now" to stand out
  color: '#fff',
  padding: '12px 24px',
  borderRadius: '8px',
  fontWeight: '600',
  fontSize: '1rem',
  textTransform: 'none',
  '&:hover': {
    backgroundColor: '#66bb6a',  // Slightly lighter green on hover
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.4)',  // Subtle shadow on hover
  },
  transition: 'background-color 0.3s ease, box-shadow 0.3s ease',
});

const InterviewPanel = ({ userId, onLogout }) => {
  const [interviews, setInterviews] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchInterviews = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${API_URL}/list_interviews/?user_id=${userId}`);
        if (response.data) {
          setInterviews(response.data); // Set the fetched interview data
        }
      } catch (error) {
        setError('Error fetching interviews.');
      } finally {
        setLoading(false);
      }
    };

    if (userId) {
      fetchInterviews();
    }
  }, [userId]);

  return (
    <div>
      <Header1 userId={userId} onLogout={onLogout} />
      <Box sx={{ backgroundColor: '#000', padding: '40px', minHeight: '100vh', marginTop: 8 }}>
        <Typography variant="h4" color="white" textAlign="center" gutterBottom>
          Scheduled Interviews
        </Typography>

        {loading ? (
          <Grid container justifyContent="center" alignItems="center" height="60vh">
            <CircularProgress size={50} color="inherit" />
          </Grid>
        ) : error ? (
          <Typography variant="body1" color="error" textAlign="center" sx={{ fontSize: '1.2rem' }}>
            {error}
          </Typography>
        ) : interviews.length === 0 ? (
          <Typography variant="body1" color="white" textAlign="center" sx={{ fontSize: '1.2rem' }}>
            No scheduled interviews found.
          </Typography>
        ) : (
          interviews.map((interview) => (
            <InterviewContainer key={interview.id}>
              <Typography variant="h6" color="white" gutterBottom>
                Name: <span style={{ color: '#64b5f6' }}>{interview.user_name}</span>
              </Typography>

              <Typography variant="h6" color="white" gutterBottom>
                Section: <span style={{ color: '#90caf9' }}>{interview.subsection_name}</span>
              </Typography>

              <Typography variant="body1" color="white" sx={{ fontSize: '1rem', marginBottom: '15px' }}>
                Interview Slot: {new Date(interview.interview_slot).toLocaleString()}
              </Typography>

              {/* Join Button with Google Meet link */}
              <Grid container justifyContent="flex-end" marginTop="15px">
                <JoinButton 
                  variant="contained" 
                  onClick={() => window.open(interview.meeting_link, '_blank')}
                >
                  Join Now
                </JoinButton>
              </Grid>
            </InterviewContainer>
          ))
        )}
      </Box>
      <Footer2/>
    </div>
  );
};

export default InterviewPanel;
