import React from 'react'
import { Link } from 'react-router-dom'
import { useEffect, useState } from 'react';
import axios from 'axios';
import Teacher from '../Icon/PostIcons/Teacher.png'

function PostedClasses({userId, sectionId}) {
  
  const [courses, setCourses] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`http://127.0.0.1:8000/list_posted_data/?user_id=${userId}&section_id=${sectionId}`);
        setCourses(response.data)
        console.log({userId})
        console.log(response.data)
        
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, []);

  
  const handleBookNow = async (course) => {
    try {
      const response = await axios.post('http://127.0.0.1:8000/create_user_post_bookings/', {
          user_id: userId,
          post_id: course.id,
      });
      console.log('Booking successful:', response.data);
      
  } catch (error) {
      console.error('Error booking course:', error);
      
  }
  }

  if(!courses){
    return("Stay tuned, coming soon")
  }

  return (
    <div className='recommended'>
      {courses.map((course) => (
            <Link to={`/real/subjects/course/tabs/${course.id}`} className="course-card_">
                <img src={Teacher} alt="Course Image" />
                <div className="content_">
                    <h2>{course.topic_name}</h2>
                    <p className="language">English</p>
                    {/* <p className="rating">{course.max_limit}</p> */}
                    <p className="price">INR 20</p>
                    {/* <p className="rating">20</p> */}
                    <p className="price">{course.startTime} To {course.endTime}</p>
                    {/* <p className="price">{course.endTime}</p> */}
                    <p className="price">{course.selectedDate}</p>
                    <Link to="#" className="book-now" onClick={()=>handleBookNow(course)}>Book Now</Link>
                </div>
            </Link>
          ))}
    </div>
  )
}

export default PostedClasses;


