import React, { useState } from 'react';
import { TextField, Button, Grid, Paper, Typography, Box } from '@mui/material';
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;




function CreateSpecializedFields() {
  const [roleName, setRoleName] = useState('');
  const [specializedField, setSpecializedField] = useState('');

  const handleRoleSubmit = async (e) => {
    e.preventDefault();
    const data = { name: roleName, specialized_field: specializedField };
    try {
      const response = await axios.post(`${API_URL}/create_role/`, data);
      alert(`Role "${response.data.name}" in "${response.data.specialized_field}" created successfully!`);
      setRoleName('');
      setSpecializedField('');
    } catch (error) {
      console.error('Error creating role', error);
    }
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh', backgroundColor: 'black' }}>
      <Box sx={{ flexGrow: 1, marginTop: '80px' }}>
        <Paper sx={{ maxWidth: 600, margin: '20px auto', padding: 4, background: 'linear-gradient(45deg, #6a1b9a, #2196f3)', borderRadius: 2, boxShadow: 8 }}>
          <Typography variant="h4" align="center" color="white" fontWeight="bold" gutterBottom>
            Create Role with Specialized Field
          </Typography>

          <form onSubmit={handleRoleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  label="Role Name"
                  variant="outlined"
                  fullWidth
                  value={roleName}
                  onChange={(e) => setRoleName(e.target.value)}
                  required
                  InputLabelProps={{ style: { color: '#e1bee7' } }}
                  InputProps={{ style: { color: 'white' } }}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '&:hover fieldset': { borderColor: '#ba68c8' },
                      '&.Mui-focused fieldset': { borderColor: '#8e24aa' },
                    },
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  label="Specialized Field"
                  variant="outlined"
                  fullWidth
                  value={specializedField}
                  onChange={(e) => setSpecializedField(e.target.value)}
                  required
                  InputLabelProps={{ style: { color: '#e1bee7' } }}
                  InputProps={{ style: { color: 'white' } }}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '&:hover fieldset': { borderColor: '#ba68c8' },
                      '&.Mui-focused fieldset': { borderColor: '#8e24aa' },
                    },
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <Button
                  type="submit"
                  variant="contained"
                  fullWidth
                  sx={{
                    background: 'linear-gradient(45deg, #8e24aa, #2196f3)',
                    padding: '12px',
                    fontSize: '16px',
                    color: 'white',
                    '&:hover': {
                      background: 'linear-gradient(45deg, #7b1fa2, #1976d2)',
                      boxShadow: 8,
                    },
                  }}
                >
                  Create Role
                </Button>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Box>
    </Box>
  );
}

export default CreateSpecializedFields;
