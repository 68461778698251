import React, { useState } from 'react';
import { Box, Typography, TextField, Button, Grid, Paper } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const API_URL = process.env.REACT_APP_API_URL;

function AdminLogin({ onLoginSuccess }) {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();

    const handleLogin = async (e) => {
        e.preventDefault();

        // Clear any previous error messages
        setErrorMessage('');

        // Make an API call to validate the credentials
        try {
            const response = await fetch(`${API_URL}/admin/login/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    email,
                    password,
                }),
            });

            const data = await response.json();

            if (response.ok) {
                // Call the onLoginSuccess callback to set the user session or state
                onLoginSuccess(data);  // Pass the data (e.g., user info) to the parent component

                // Redirect to the Admin Portal
                navigate('/admin-portal');
            } else {
                setErrorMessage(data.error || 'Invalid credentials. Please try again.');
            }
        } catch (error) {
            setErrorMessage('An error occurred. Please try again.');
        }
    };

    return (
        <Box sx={{ minHeight: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center', bgcolor: '#111' }}>
            <Paper sx={{ p: 4, width: { xs: '90%', sm: '400px' }, backgroundColor: '#222', borderRadius: 2 }}>
                <Typography variant="h5" sx={{ mb: 3, textAlign: 'center', color: 'white' }}>
                    Admin Login
                </Typography>

                {/* Error Message */}
                {errorMessage && (
                    <Typography variant="body2" sx={{ mb: 2, color: 'red', textAlign: 'center' }}>
                        {errorMessage}
                    </Typography>
                )}

                {/* Login Form */}
                <form onSubmit={handleLogin}>
                    <TextField
                        label="Email"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        sx={{ input: { color: 'white' } }}
                    />
                    <TextField
                        label="Password"
                        type="password"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        sx={{ input: { color: 'white' } }}
                    />

                    <Button
                        type="submit"
                        variant="contained"
                        fullWidth
                        sx={{
                            bgcolor: '#1E90FF',
                            mt: 2,
                            '&:hover': {
                                bgcolor: '#0066cc',
                            },
                        }}
                    >
                        Login
                    </Button>
                </form>
            </Paper>
        </Box>
    );
}

export default AdminLogin;
