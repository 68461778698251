import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Box, Typography, TextField, Button, Grid, Paper } from '@mui/material';
import Header1 from '../Header/Header1';
import Footer from '../Footer/Footer';

const API_URL = process.env.REACT_APP_API_URL;

function AddSubSections({ userId }) {
  const [sections, setSections] = useState([]);
  const [selectedSectionId, setSelectedSectionId] = useState('');
  const [subsectionName, setSubsectionName] = useState('');
  const [subsectionImageUrl, setSubsectionImageUrl] = useState('');

  // Fetch existing sections when the component mounts
  useEffect(() => {
    const fetchSections = async () => {
      try {
        const response = await axios.get(`${API_URL}/list_sections/`);
        setSections(response.data);
        // console.log('sections', )
      } catch (error) {
        console.error('Error fetching sections:', error);
      }
    };

    fetchSections();
  }, [userId]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!selectedSectionId) {
      alert('Please select a section.');
      return;
    }

    const newSubsection = {
      name: subsectionName,
    //   image_url: subsectionImageUrl,
    };

    try {
      const response = await axios.post(`${API_URL}/add_subsections/${selectedSectionId}/`, newSubsection);
      console.log('Subsection added successfully:', response.data);
      alert('Subsection added successfully!');
      // Optionally refresh the sections or clear the form
      setSubsectionName('');
      setSubsectionImageUrl('');
    } catch (error) {
      console.error('Error adding subsection:', error);
      alert('Error adding subsection. Please try again.');
    }
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh', backgroundColor: 'black' }}>
      {/* Main Content Area */}
      {/* <Header1/> */}
      <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop:'80px', marginBottom:'40px' }}>
        <Paper
          sx={{
            padding: 4,
            width: '100%',
            maxWidth: 700,
            background: 'linear-gradient(45deg, #6a1b9a, #2196f3)',
            borderRadius: 2,
            boxShadow: 8,
          }}
        >
          {/* Title inside the Paper box */}
          <Typography variant="h4" align="center" color="white" fontWeight="bold" marginBottom={3}>
            ADD SUBSECTION
          </Typography>

          <form onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              {/* Select Section */}
              <Grid item xs={12}>
                <Typography variant="body1" color="white">Select Section</Typography>
                <TextField
                  select
                  value={selectedSectionId}
                  onChange={(e) => setSelectedSectionId(e.target.value)}
                  fullWidth
                  required
                  variant="outlined"
                  SelectProps={{
                    native: true,
                  }}
                  InputLabelProps={{
                    style: { color: '#e1bee7' },
                  }}
                  InputProps={{
                    style: { color: 'white' },
                  }}
                  sx={{
                    marginTop: 2,
                    '& .MuiOutlinedInput-root': {
                      '&:hover fieldset': {
                        borderColor: '#ba68c8',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: '#8e24aa',
                      },
                    },
                  }}
                >
                  <option value="">Select a Subsection</option>
                  {sections.map((section) => (
                    <option key={section.id} value={section.id}>
                      {section.name}
                    </option>
                  ))}
                </TextField>
              </Grid>

              {/* Subsection Name */}
              <Grid item xs={12}>
                <TextField
                  label="Subsection Name"
                  variant="outlined"
                  value={subsectionName}
                  onChange={(e) => setSubsectionName(e.target.value)}
                  fullWidth
                  required
                  InputLabelProps={{
                    style: { color: '#e1bee7' },
                  }}
                  InputProps={{
                    style: { color: 'white' },
                  }}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '&:hover fieldset': {
                        borderColor: '#ba68c8',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: '#8e24aa',
                      },
                    },
                  }}
                />
              </Grid>

              {/* Subsection Image URL */}
              <Grid item xs={12}>
                <TextField
                  label="Subsection Image URL"
                  variant="outlined"
                  value={subsectionImageUrl}
                  onChange={(e) => setSubsectionImageUrl(e.target.value)}
                  fullWidth
                  InputLabelProps={{
                    style: { color: '#e1bee7' },
                  }}
                  InputProps={{
                    style: { color: 'white' },
                  }}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '&:hover fieldset': {
                        borderColor: '#ba68c8',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: '#8e24aa',
                      },
                    },
                  }}
                />
              </Grid>

              {/* Submit Button */}
              <Grid item xs={12}>
                <Button
                  type="submit"
                  variant="contained"
                  fullWidth
                  sx={{
                    background: 'linear-gradient(45deg, #8e24aa, #2196f3)',
                    padding: '12px',
                    fontSize: '16px',
                    color: 'white',
                    '&:hover': {
                      background: 'linear-gradient(45deg, #7b1fa2, #1976d2)',
                      boxShadow: 8,
                    },
                  }}
                >
                  Add Subsection
                </Button>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Box>

      {/* <Footer/> */}
    </Box>
  );
}

export default AddSubSections;
