import React, { useState } from 'react';
import { Grid, Card, Typography, Button, Box, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

function DataAnalytics() {

    // Sample data for the charts, now with added fields
    const data = [
        { name: 'Jan', revenue: 4000, spent: 1200, sessionsTaken: 30, sessionsAttended: 28, teachingHours: 40, minutes: 120, postsPosted: 100, postsConverted: 80, userBookings: 50, usersReached: 200 },
        { name: 'Feb', revenue: 3000, spent: 900, sessionsTaken: 25, sessionsAttended: 24, teachingHours: 35, minutes: 100, postsPosted: 90, postsConverted: 70, userBookings: 45, usersReached: 180 },
        { name: 'Mar', revenue: 2000, spent: 800, sessionsTaken: 20, sessionsAttended: 18, teachingHours: 30, minutes: 80, postsPosted: 80, postsConverted: 60, userBookings: 40, usersReached: 150 },
        { name: 'Apr', revenue: 2780, spent: 1000, sessionsTaken: 28, sessionsAttended: 27, teachingHours: 45, minutes: 90, postsPosted: 95, postsConverted: 75, userBookings: 55, usersReached: 190 },
        { name: 'May', revenue: 1890, spent: 600, sessionsTaken: 18, sessionsAttended: 16, teachingHours: 40, minutes: 110, postsPosted: 70, postsConverted: 50, userBookings: 35, usersReached: 170 },
        { name: 'Jun', revenue: 2390, spent: 1100, sessionsTaken: 22, sessionsAttended: 20, teachingHours: 42, minutes: 95, postsPosted: 85, postsConverted: 65, userBookings: 48, usersReached: 160 },
        { name: 'Jul', revenue: 3490, spent: 1300, sessionsTaken: 35, sessionsAttended: 33, teachingHours: 50, minutes: 130, postsPosted: 120, postsConverted: 100, userBookings: 60, usersReached: 210 },
    ];
    const [timeFrame, setTimeFrame] = useState('monthly');

    const handleTimeFrameChange = (event) => {
        setTimeFrame(event.target.value);
    };

    return (
        <div>
            <Box sx={{ padding: 4, backgroundColor: '#121212', minHeight: '100vh' }}>
                <Typography variant="h4" gutterBottom align="center" sx={{ color: '#fff' }}>
                    Data Analytics Dashboard
                </Typography>

                {/* Timeframe Selector */}
                <Grid container spacing={3} justifyContent="center">
                    <Grid item xs={12} md={4}>
                        <Card sx={{ padding: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center', backgroundColor: '#1e1e1e' }}>
                            <Typography variant="h6" sx={{ color: '#fff' }}>Select Time Frame</Typography>
                            <FormControl fullWidth>
                                <InputLabel id="timeframe-select" sx={{ color: '#fff' }}>Timeframe</InputLabel>
                                <Select
                                    labelId="timeframe-select"
                                    value={timeFrame}
                                    label="Time Frame"
                                    onChange={handleTimeFrameChange}
                                    sx={{
                                        '& .MuiSelect-root': { color: '#fff' },
                                        '& .MuiInputLabel-root': { color: '#fff' },
                                        '& .MuiOutlinedInput-notchedOutline': { borderColor: '#fff' },
                                        backgroundColor: '#333',
                                    }}
                                >
                                    <MenuItem value="monthly" sx={{ color: '#000' }}>Monthly</MenuItem>
                                    <MenuItem value="quarterly" sx={{ color: '#000' }}>Quarterly</MenuItem>
                                    <MenuItem value="yearly" sx={{ color: '#000' }}>Yearly</MenuItem>
                                </Select>
                            </FormControl>
                        </Card>
                    </Grid>
                </Grid>

                {/* Analytics Cards */}
                <Grid container spacing={3} marginTop={3}>
                    {/* Money Earned vs Month */}
                    <Grid item xs={12} md={4}>
                        <Card sx={{ padding: 3, backgroundColor: '#1e1e1e' }}>
                            <Typography variant="h6" sx={{ color: '#fff' }}>Money Earned</Typography>
                            <Typography variant="h4" color="primary" marginBottom={2} sx={{ color: '#fff' }}>
                                $45,000
                            </Typography>
                            <ResponsiveContainer width="100%" height={250}>
                                <LineChart data={data}>
                                    <CartesianGrid strokeDasharray="3 3" stroke="#444" />
                                    <XAxis dataKey="name" stroke="#fff" />
                                    <YAxis stroke="#fff" />
                                    <Tooltip contentStyle={{ backgroundColor: '#333' }} />
                                    <Legend wrapperStyle={{ color: '#fff' }} />
                                    <Line type="monotone" dataKey="revenue" stroke="#8884d8" />
                                </LineChart>
                            </ResponsiveContainer>
                        </Card>
                    </Grid>

                    {/* Money Spent vs Month */}
                    <Grid item xs={12} md={4}>
                        <Card sx={{ padding: 3, backgroundColor: '#1e1e1e' }}>
                            <Typography variant="h6" sx={{ color: '#fff' }}>Money Spent</Typography>
                            <Typography variant="h4" color="primary" marginBottom={2} sx={{ color: '#fff' }}>
                                $12,000
                            </Typography>
                            <ResponsiveContainer width="100%" height={250}>
                                <LineChart data={data}>
                                    <CartesianGrid strokeDasharray="3 3" stroke="#444" />
                                    <XAxis dataKey="name" stroke="#fff" />
                                    <YAxis stroke="#fff" />
                                    <Tooltip contentStyle={{ backgroundColor: '#333' }} />
                                    <Legend wrapperStyle={{ color: '#fff' }} />
                                    <Line type="monotone" dataKey="spent" stroke="#82ca9d" />
                                </LineChart>
                            </ResponsiveContainer>
                        </Card>
                    </Grid>

                    {/* Number of Sessions Taken vs Month */}
                    <Grid item xs={12} md={4}>
                        <Card sx={{ padding: 3, backgroundColor: '#1e1e1e' }}>
                            <Typography variant="h6" sx={{ color: '#fff' }}>Sessions Taken</Typography>
                            <Typography variant="h4" color="primary" marginBottom={2} sx={{ color: '#fff' }}>
                                30 (Avg.)
                            </Typography>
                            <ResponsiveContainer width="100%" height={250}>
                                <LineChart data={data}>
                                    <CartesianGrid strokeDasharray="3 3" stroke="#444" />
                                    <XAxis dataKey="name" stroke="#fff" />
                                    <YAxis stroke="#fff" />
                                    <Tooltip contentStyle={{ backgroundColor: '#333' }} />
                                    <Legend wrapperStyle={{ color: '#fff' }} />
                                    <Line type="monotone" dataKey="sessionsTaken" stroke="#ffc658" />
                                </LineChart>
                            </ResponsiveContainer>
                        </Card>
                    </Grid>

                    {/* Number of Sessions Attended vs Month */}
                    <Grid item xs={12} md={4}>
                        <Card sx={{ padding: 3, backgroundColor: '#1e1e1e' }}>
                            <Typography variant="h6" sx={{ color: '#fff' }}>Sessions Attended</Typography>
                            <Typography variant="h4" color="primary" marginBottom={2} sx={{ color: '#fff' }}>
                                28 (Avg.)
                            </Typography>
                            <ResponsiveContainer width="100%" height={250}>
                                <LineChart data={data}>
                                    <CartesianGrid strokeDasharray="3 3" stroke="#444" />
                                    <XAxis dataKey="name" stroke="#fff" />
                                    <YAxis stroke="#fff" />
                                    <Tooltip contentStyle={{ backgroundColor: '#333' }} />
                                    <Legend wrapperStyle={{ color: '#fff' }} />
                                    <Line type="monotone" dataKey="sessionsAttended" stroke="#ff7300" />
                                </LineChart>
                            </ResponsiveContainer>
                        </Card>
                    </Grid>

                    {/* Number of Hours of Teaching Experience vs Month */}
                    <Grid item xs={12} md={4}>
                        <Card sx={{ padding: 3, backgroundColor: '#1e1e1e' }}>
                            <Typography variant="h6" sx={{ color: '#fff' }}>Teaching Experience (Hours)</Typography>
                            <Typography variant="h4" color="primary" marginBottom={2} sx={{ color: '#fff' }}>
                                40 hours (Avg.)
                            </Typography>
                            <ResponsiveContainer width="100%" height={250}>
                                <LineChart data={data}>
                                    <CartesianGrid strokeDasharray="3 3" stroke="#444" />
                                    <XAxis dataKey="name" stroke="#fff" />
                                    <YAxis stroke="#fff" />
                                    <Tooltip contentStyle={{ backgroundColor: '#333' }} />
                                    <Legend wrapperStyle={{ color: '#fff' }} />
                                    <Line type="monotone" dataKey="teachingHours" stroke="#8884d8" />
                                </LineChart>
                            </ResponsiveContainer>
                        </Card>
                    </Grid>

                    {/* Number of Posts Posted vs Posts Converted to Sessions */}
                    <Grid item xs={12} md={4}>
                        <Card sx={{ padding: 3, backgroundColor: '#1e1e1e' }}>
                            <Typography variant="h6" sx={{ color: '#fff' }}>Posts Posted vs Converted to Sessions</Typography>
                            <ResponsiveContainer width="100%" height={250}>
                                <LineChart data={data}>
                                    <CartesianGrid strokeDasharray="3 3" stroke="#444" />
                                    <XAxis dataKey="name" stroke="#fff" />
                                    <YAxis stroke="#fff" />
                                    <Tooltip contentStyle={{ backgroundColor: '#333' }} />
                                    <Legend wrapperStyle={{ color: '#fff' }} />
                                    <Line type="monotone" dataKey="postsPosted" stroke="#ff7300" />
                                    <Line type="monotone" dataKey="postsConverted" stroke="#82ca9d" />
                                </LineChart>
                            </ResponsiveContainer>
                        </Card>
                    </Grid>

                    {/* Number of User Bookings vs Month */}
                    <Grid item xs={12} md={4}>
                        <Card sx={{ padding: 3, backgroundColor: '#1e1e1e' }}>
                            <Typography variant="h6" sx={{ color: '#fff' }}>User Bookings</Typography>
                            <Typography variant="h4" color="primary" marginBottom={2} sx={{ color: '#fff' }}>
                                50 (Avg.)
                            </Typography>
                            <ResponsiveContainer width="100%" height={250}>
                                <LineChart data={data}>
                                    <CartesianGrid strokeDasharray="3 3" stroke="#444" />
                                    <XAxis dataKey="name" stroke="#fff" />
                                    <YAxis stroke="#fff" />
                                    <Tooltip contentStyle={{ backgroundColor: '#333' }} />
                                    <Legend wrapperStyle={{ color: '#fff' }} />
                                    <Line type="monotone" dataKey="userBookings" stroke="#8884d8" />
                                </LineChart>
                            </ResponsiveContainer>
                        </Card>
                    </Grid>

                    {/* Number of Users Reached vs Month */}
                    <Grid item xs={12} md={4}>
                        <Card sx={{ padding: 3, backgroundColor: '#1e1e1e' }}>
                            <Typography variant="h6" sx={{ color: '#fff' }}>Users Reached</Typography>
                            <Typography variant="h4" color="primary" marginBottom={2} sx={{ color: '#fff' }}>
                                200 (Avg.)
                            </Typography>
                            <ResponsiveContainer width="100%" height={250}>
                                <LineChart data={data}>
                                    <CartesianGrid strokeDasharray="3 3" stroke="#444" />
                                    <XAxis dataKey="name" stroke="#fff" />
                                    <YAxis stroke="#fff" />
                                    <Tooltip contentStyle={{ backgroundColor: '#333' }} />
                                    <Legend wrapperStyle={{ color: '#fff' }} />
                                    <Line type="monotone" dataKey="usersReached" stroke="#ff7300" />
                                </LineChart>
                            </ResponsiveContainer>
                        </Card>
                    </Grid>

                    {/* Minutes Spent on Website Card */}
                    <Grid item xs={12} md={4}>
                        <Card sx={{ padding: 3, backgroundColor: '#1e1e1e' }}>
                            <Typography variant="h6" sx={{ color: '#fff' }}>Minutes Spent on Website</Typography>
                            <Typography variant="h4" color="primary" marginBottom={2} sx={{ color: '#fff' }}>
                                120 minutes (Avg.)
                            </Typography>
                            <ResponsiveContainer width="100%" height={250}>
                                <LineChart data={data}>
                                    <CartesianGrid strokeDasharray="3 3" stroke="#444" />
                                    <XAxis dataKey="name" stroke="#fff" />
                                    <YAxis stroke="#fff" />
                                    <Tooltip contentStyle={{ backgroundColor: '#333' }} />
                                    <Legend wrapperStyle={{ color: '#fff' }} />
                                    <Line type="monotone" dataKey="minutes" stroke="#ff7300" />
                                </LineChart>
                            </ResponsiveContainer>
                        </Card>
                    </Grid>
                </Grid>

                {/* Action Buttons */}
                <Grid container justifyContent="center" spacing={2} marginTop={4}>
                    <Grid item>
                        <Button variant="contained" color="primary" size="large" onClick={() => alert("Download report!")} sx={{ backgroundColor: '#1976d2' }}>
                            Download Report
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button variant="outlined" color="secondary" size="large" sx={{ borderColor: '#bbb', color: '#fff' }}>
                            View Details
                        </Button>
                    </Grid>
                </Grid>
            </Box>

        </div>
    )
}

export default DataAnalytics
