import React from "react";
import { Box, Typography, Grid, Card, CardMedia, CardContent, Button } from "@mui/material";
import valmik_image from "../Icon/profile_image.png"
import Header1 from "../Header/Header1";
import Footer from "../Footer/Footer";
import { Link } from "react-router-dom";

// Single section data
const section = {
    name: "subection 1",
    cards: [
        {
            title: "Card 1A",
            rating: "4.5",
            price: "INR 100",
            image: valmik_image,
            language: "English",
            date: "2024-11-20",
            starttime: "10:00 AM",
            duration: "2 hours",
        },
        {
            title: "Card 1B",
            rating: "3.8",
            price: "INR 200",
            image: valmik_image,
            language: "English",
            date: "2024-11-21",
            starttime: "11:00 AM",
            duration: "1.5 hours",
        },
        {
            title: "Card 1A",
            rating: "4.5",
            price: "INR 100",
            image: valmik_image,
            language: "English",
            date: "2024-11-20",
            starttime: "10:00 AM",
            duration: "2 hours",
        },
        {
            title: "Card 1B",
            rating: "3.8",
            price: "INR 200",
            image: valmik_image,
            language: "English",
            date: "2024-11-21",
            starttime: "11:00 AM",
            duration: "1.5 hours",
        },
        {
            title: "Card 1A",
            rating: "4.5",
            price: "INR 100",
            image: valmik_image,
            language: "English",
            date: "2024-11-20",
            starttime: "10:00 AM",
            duration: "2 hours",
        },
        {
            title: "Card 1B",
            rating: "3.8",
            price: "INR 200",
            image: valmik_image,
            language: "English",
            date: "2024-11-21",
            starttime: "11:00 AM",
            duration: "1.5 hours",
        },
        {
            title: "Card 1A",
            rating: "4.5",
            price: "INR 100",
            image: valmik_image,
            language: "English",
            date: "2024-11-20",
            starttime: "10:00 AM",
            duration: "2 hours",
        },
        {
            title: "Card 1B",
            rating: "3.8",
            price: "INR 200",
            image: valmik_image,
            language: "English",
            date: "2024-11-21",
            starttime: "11:00 AM",
            duration: "1.5 hours",
        },
        {
            title: "Card 1A",
            rating: "4.5",
            price: "INR 100",
            image: "https://via.placeholder.com/150",
            language: "English",
            date: "2024-11-20",
            starttime: "10:00 AM",
            duration: "2 hours",
        },
        {
            title: "Card 1B",
            rating: "3.8",
            price: "INR 200",
            image: "https://via.placeholder.com/150",
            language: "English",
            date: "2024-11-21",
            starttime: "11:00 AM",
            duration: "1.5 hours",
        },
        {
            title: "Card 1A",
            rating: "4.5",
            price: "INR 100",
            image: "https://via.placeholder.com/150",
            language: "English",
            date: "2024-11-20",
            starttime: "10:00 AM",
            duration: "2 hours",
        },
        {
            title: "Card 1B",
            rating: "3.8",
            price: "INR 200",
            image: "https://via.placeholder.com/150",
            language: "English",
            date: "2024-11-21",
            starttime: "11:00 AM",
            duration: "1.5 hours",
        },
        {
            title: "Card 1A",
            rating: "4.5",
            price: "INR 100",
            image: "https://via.placeholder.com/150",
            language: "English",
            date: "2024-11-20",
            starttime: "10:00 AM",
            duration: "2 hours",
        },
        {
            title: "Card 1B",
            rating: "3.8",
            price: "INR 200",
            image: "https://via.placeholder.com/150",
            language: "English",
            date: "2024-11-21",
            starttime: "11:00 AM",
            duration: "1.5 hours",
        },
        {
            title: "Card 1A",
            rating: "4.5",
            price: "INR 100",
            image: "https://via.placeholder.com/150",
            language: "English",
            date: "2024-11-20",
            starttime: "10:00 AM",
            duration: "2 hours",
        },
        {
            title: "Card 1B",
            rating: "3.8",
            price: "INR 200",
            image: "https://via.placeholder.com/150",
            language: "English",
            date: "2024-11-21",
            starttime: "11:00 AM",
            duration: "1.5 hours",
        },
        {
            title: "Card 1A",
            rating: "4.5",
            price: "INR 100",
            image: "https://via.placeholder.com/150",
            language: "English",
            date: "2024-11-20",
            starttime: "10:00 AM",
            duration: "2 hours",
        },
        {
            title: "Card 1B",
            rating: "3.8",
            price: "INR 200",
            image: "https://via.placeholder.com/150",
            language: "English",
            date: "2024-11-21",
            starttime: "11:00 AM",
            duration: "1.5 hours",
        },
        {
            title: "Card 1A",
            rating: "4.5",
            price: "INR 100",
            image: "https://via.placeholder.com/150",
            language: "English",
            date: "2024-11-20",
            starttime: "10:00 AM",
            duration: "2 hours",
        },
        {
            title: "Card 1B",
            rating: "3.8",
            price: "INR 200",
            image: "https://via.placeholder.com/150",
            language: "English",
            date: "2024-11-21",
            starttime: "11:00 AM",
            duration: "1.5 hours",
        },
    ],
};

const FullSection = () => {
    if (!section || !section.cards) {
        return (
            <Box sx={{ padding: "3rem" }}>
                <Typography variant="h5" color="error">
                    Section not found!
                </Typography>
            </Box>
        );
    }

    return (
        <div>
            <Header1 />
            <Box sx={{ padding: "3rem", marginTop: "3rem" }}> {/* Added marginTop */}
                {/* Section Name at Top Left */}
                <Box
                    sx={{
                        display: "inline-block",
                        backgroundColor: "#2980b9", // Blue background for the section box
                        color: "white", // White text
                        padding: "0.3rem 1rem", // Smaller padding for compactness
                        borderRadius: "8px", // Rounded corners
                        fontSize: "1em", // Smaller font size
                        fontWeight: 600,
                        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)", // Lighter shadow for smaller effect
                        transition: "all 0.3s ease", // Smooth transition for animation
                        marginBottom: "1rem", // Smaller margin
                        "&:hover": {
                            transform: "scale(1.05)", // Slight scale on hover
                            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)", // Slightly deeper shadow on hover
                            backgroundColor: "#1f6481", // Darker background on hover
                        },
                    }}
                >
                    Section Name
                </Box>

                <Typography variant="h4" gutterBottom sx={{ textAlign: "center", marginBottom: "2rem" }}>
                    {section.name}
                </Typography>
    
                <Grid container spacing={4} justifyContent="center">
                    {section.cards.map((card, cardIndex) => (
                        <Grid item xs={12} sm={6} md={2.6} key={cardIndex}>
                            <Link to={`/post-tab-details/${cardIndex}`} style={{ textDecoration: "none" }}>
                            <Card
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    backgroundColor: "#fff",
                                    borderRadius: "12px",
                                    overflow: "hidden",
                                    boxShadow: "0 10px 20px rgba(0, 0, 0, 0.1)",
                                    transition: "transform 0.3s, box-shadow 0.3s",
                                    textDecoration: "none",
                                    color: "inherit",
                                    height: "370px", // Fixed card height
                                    "&:hover": {
                                        transform: "translateY(-10px)",
                                        boxShadow: "0 20px 40px rgba(0, 0, 0, 0.2)",
                                    },
                                }}
                            >
                                {/* <CardMedia
                                    component="img"
                                    height="160" // Fixed image height
                                    image={card.image}
                                    alt={card.title}
                                    sx={{
                                        objectFit: "cover",
                                    }}
                                /> */}
                                <CardContent
                                    sx={{
                                        padding: "10px",
                                        display: "flex",
                                        flexDirection: "column",
                                        flex: 1,
                                        justifyContent: "space-between", // Distribute content
                                    }}
                                >
                                    {/* Title Centered */}
                                    <Typography
                                        variant="h6"
                                        sx={{
                                            fontSize: "1.1em",
                                            fontWeight: 700,
                                            textAlign: "center", // Title centered
                                            marginBottom: "8px",
                                            color: "#2C3E50",
                                        }}
                                    >
                                        {card.title}
                                    </Typography>
                                    {/* Rating Left and Price Right */}
                                    <Grid container spacing={1} sx={{ marginBottom: "8px" }}>
                                        <Grid item xs={6} sx={{ display: "flex", alignItems: "center" }}>
                                            <Typography variant="body2" sx={{ fontSize: "0.9em", color: "#7F8C8D" }}>
                                                <strong>Rating:</strong> {card.rating} ★
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6} sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                                            <Typography
                                                variant="body1"
                                                sx={{
                                                    fontSize: "1.1em",
                                                    fontWeight: 600,
                                                    color: "#27ae60",
                                                }}
                                            >
                                                {card.price}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    {/* Language, Date, Time */}
                                    <Grid container spacing={1} sx={{ marginBottom: "8px" }}>
                                        <Grid item xs={12} sx={{ display: "flex", justifyContent: "space-between" }}>
                                            <Typography variant="body2" sx={{ fontSize: "0.8em", color: "#34495E" }}>
                                                <strong>Language:</strong> {card.language}
                                            </Typography>
                                            <Typography variant="body2" sx={{ fontSize: "0.8em", color: "#34495E" }}>
                                                <strong>Date:</strong> {card.date}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} sx={{ display: "flex", justifyContent: "space-between" }}>
                                            <Typography variant="body2" sx={{ fontSize: "0.8em", color: "#34495E" }}>
                                                <strong>Start Time:</strong> {card.starttime}
                                            </Typography>
                                            <Typography variant="body2" sx={{ fontSize: "0.8em", color: "#34495E" }}>
                                                <strong>Duration:</strong> {card.duration}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    {/* Button at the Bottom */}
                                    <Button
                                        variant="contained"
                                        size="small"
                                        sx={{
                                            backgroundColor: "#2980b9",
                                            color: "white",
                                            textTransform: "none",
                                            borderRadius: "4px",
                                            padding: "8px 12px",
                                            fontSize: "0.9em",
                                            marginTop: "auto", // Push the button to the bottom
                                            "&:hover": { backgroundColor: "#1f6481" },
                                        }}
                                        onClick={() => console.log(`Book Now: ${card.title}`)}
                                    >
                                        Book Now
                                    </Button>
                                </CardContent>
                            </Card>
                            </Link>
                        </Grid>
                    ))}
                </Grid>
            </Box>
            <Footer/>
        </div>
    ); 
};

export default FullSection;
