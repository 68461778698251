import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { TextField, Button, Grid, Typography, Container, MenuItem, Select, InputLabel, FormControl, Box, Snackbar, Alert,FormHelperText } from '@mui/material';
import { useParams } from 'react-router-dom';
import GenerateMeetingLinkButton from '../GoogleMeet/GenerateMeetingLinkButton';
import { format } from 'date-fns';
import GenerateZoomMeetingButton from '../Zoom/GenerateZoomMeetingButton';
import ZoomAuthButton from '../Zoom/ZoomAuthButton';
import GenerateMicrosoftTeamsLink from '../Microsoft/GenerateMicrosoftTeamsLink';

const API_URL = process.env.REACT_APP_API_URL;

const styles = {
    glassBox: {
        backdropFilter: 'blur(15px)',
        backgroundColor: '#000000',
        borderRadius: '16px',
        padding: '2.5rem',
        boxShadow: '0 4px 30px rgba(0, 0, 0, 0.2)',
        border: '1px solid rgba(255, 255, 255, 0.3)',
        width: '100%',
        maxWidth: '100%',
        textAlign: 'center',
    },
    logo: {
        width: '160px',
        height: '160px',
        margin: '0 auto 1rem',
    },
    text: {
        color: 'white',
    },
    root: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        backgroundColor: 'rgba(0, 0, 0, 0.8)',
        width: '100%',
    },
    input: {
        marginBottom: '1rem',
        '& .MuiInputBase-input': {
            color: 'white',
        },
        '& .MuiInputLabel-root': {
            color: 'rgba(255, 255, 255, 0.7)',
            transition: 'color 0.3s',
        },
        '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
            borderColor: 'rgba(255, 255, 255, 0.5)',
        },
        '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: '#1E90FF',
        },
        '&:hover .MuiInputLabel-root': {
            color: '#1E90FF',
        },
        '& .MuiSelect-root:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: '#1E90FF',
        },
    },
    dateInput: {
        marginBottom: '1rem',
        '& .MuiInputBase-input': {
            color: 'white',
        },
        '& .MuiInputBase-input::placeholder': {
            color: 'transparent',
            transition: 'color 0.3s',
        },
        '& .MuiInputLabel-root': {
            color: 'rgba(255, 255, 255, 0.7)',
            transition: 'color 0.3s',
        },
        '& .Mui-focused .MuiInputBase-input::placeholder': {
            color: 'white',
        },
        '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
            borderColor: 'rgba(255, 255, 255, 0.5)',
        },
        '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#1E90FF',
        },
        '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: '#1E90FF',
        },
    },
    button: {
        backgroundColor: '#1E90FF',
        color: 'white',
        marginTop: '1.5rem',
    },
    remainingPosts: {
        position: 'relative',
        maxWidth:'200px',
        alignItems:'right',
        justifyContent:'right',
        mt:5,
        ml:5,
        // top: '60px',
        // right: '20px',
        backgroundColor: '#1E90FF',
        padding: '0.5rem 1rem',
        borderRadius: '8px',
        color: '#fff',
        fontSize: '1rem',
    },
};

const CreatePost = ({ userId, onLogout }) => {
    const [startTime, setStartTime] = useState('');
    const [duration, setDuration] = useState('');
    const [selectedDate, setSelectedDate] = useState('');
    const [maxLimit, setMaxLimit] = useState('');
    const [price, setPrice] = useState('');
    const [topicName, setTopicName] = useState('');
    const [description, setDescription] = useState('');
    const [sectionId, setSectionId] = useState('');
    const [subsectionId, setSubsectionId] = useState('');
    const [sections, setSections] = useState([]);
    const [subsections, setSubsections] = useState([]);
    const [error, setError] = useState('');
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [language, setLanguage] = useState('');
    const [googleMeetLink, setGoogleMeetLink] = useState('');
    const [remainingPosts, setRemainingPosts] = useState(0);
    const [languages, setLanguages] = useState([]);  // State for languages

    const { id } = useParams();

    useEffect(() => {
        const fetchRemainingPosts = async () => {
            try {
                const response = await axios.get(`${API_URL}/get_user_remaining_posts/`, {
                    params: { user_id: userId }
                });
                setRemainingPosts(response.data.remaining_posts);
            } catch (error) {
                console.error('Error fetching remaining posts:', error);
            }
        };

        fetchRemainingPosts();
    }, [userId]);

    useEffect(() => {
        axios.get(`${API_URL}/list_sections_and_subsections/?user_id=${userId}`)
            .then((response) => {
                const transformedSections = Object.entries(response.data.sections).map(([sectionName, sectionData]) => ({
                    sectionId: sectionData.section_id,
                    sectionName,
                    subsections: sectionData.subsections,
                }));
                setSections(transformedSections);
 
 
                if (response.data.languages) {
                    setLanguages(response.data.languages);
                }
            })
            .catch((error) => {
                console.error('Error fetching sections:', error);
            });
    }, [userId]);



    // useEffect(() => {
    //     // Fetch sections and subsections data from the backend
    //     axios.get(`${API_URL}/list_sections_and_subsections/`)
    //         .then((response) => {
    //             console.log("Fetched Sections Data:", response.data);

    //             if (response.data && response.data.sections) {
    //                 const fetchedSections = Object.entries(response.data.sections).map(([sectionName, sectionData]) => ({
    //                     sectionName,
    //                     sectionId: sectionData.section_id,
    //                     subsections: sectionData.subsections,
    //                 }));

    //                 console.log("Mapped Sections:", fetchedSections);
    //                 setSections(fetchedSections);
    //             } else {
    //                 console.error("Sections data is missing in the response!");
    //             }
    //         })
    //         .catch((error) => {
    //             console.error('Error fetching sections:', error);
    //         });
    // }, []);



    const handleSectionChange = (event) => {
        const selectedSectionId = event.target.value;
        setSectionId(selectedSectionId);

        const selectedSection = sections.find(section => section.sectionId === selectedSectionId);
        setSubsections(selectedSection ? selectedSection.subsections : []);
        setSubsectionId('');
    };

    const handleSubsectionChange = (event) => {
        setSubsectionId(event.target.value);
    };

    const handleDateChange = (event) => {
        setSelectedDate(event.target.value);
    };

    const handlePost = () => {
        if (selectedDate) {
            const year = format(selectedDate, 'yyyy');
            if (year.length !== 4) {
                setError('The year must be exactly 4 digits.');
                setOpenSnackbar(true);
                return;
            }
        }

        if (duration > 60) {
            setError('Duration cannot exceed 60 minutes.');
            setOpenSnackbar(true);
            return;
        }

        if (maxLimit > 100) {
            setError('Max Limit cannot exceed 100.');
            setOpenSnackbar(true);
            return;
        }

        if (price > 100) {
            setError('Price cannot exceed 100.');
            setOpenSnackbar(true);
            return;
        }

        const selectedSection = sections.find(section => section.sectionId === sectionId);
        const sectionName = selectedSection ? selectedSection.sectionName : '';

        const selectedSubsection = subsections.find(subsection => subsection.id === subsectionId);
        const subsectionName = selectedSubsection ? selectedSubsection.name : '';

        const postData = {
            user_id: userId,
            section_id: sectionId,
            section_name: sectionName,
            subsection_id: subsectionId,
            subsection_name: subsectionName,
            startTime: startTime,
            duration: duration,
            selectedDate: selectedDate,
            max_limit: maxLimit,
            price: price,
            topic_name: topicName,
            description: description,
            language: language,
            meeting_link: googleMeetLink
        };

        axios.post(`${API_URL}/create_post/`, postData).then((response) => {
            console.log('Post successful:', response.data);
            window.alert('Post was successful!');
            window.location.reload();
        }).catch((error) => {
            console.error('Error posting data:', error);
            if (error.response && error.response.status === 403) {
                setError('You need an active subscription to post.');
                setOpenSnackbar(true);
            } else {
                setError('An error occurred while posting. Please try again later.');
                setOpenSnackbar(true);
            }
        });
    };

    const handleChange = (event) => {
        setLanguage(event.target.value);
    };

     // Optionally, add an error check (for example, if no language is selected)
//   const hasError = language === '';

    return (
        <div style={{ backgroundColor: '#000000', minHeight: '100vh', color: '#ffffff' }}>
            
            <Container maxWidth="lg" sx={{
                marginTop: { xs: '8rem', sm: '10rem', lg: '5rem' },
                marginBottom: { xs: '2rem', sm: '3rem', lg: '5rem' },
                padding: '0 2rem',
            }}>
                <Typography variant="h6" sx={styles.remainingPosts}>
                    Remaining Posts: {remainingPosts}
                </Typography>

                <Box sx={{
                    padding: '2rem',
                    borderRadius: '8px',
                    backgroundColor: '#000000',
                    transition: 'border-color 0.3s ease',
                    '&:hover': {
                        borderColor: '#1E90FF',
                    },
                    paddingBottom: '1.5rem',
                }}>
                    <Typography variant="h4" sx={{
                        marginBottom: '1.5rem',
                        marginTop: '1.5rem',
                        textAlign: 'center',
                        color: '#ffffff',
                        '&:hover': {
                            color: '#1E90FF',
                        },
                    }}>
                        Create a New Post
                    </Typography>

                    <FormControl fullWidth sx={{ marginBottom: '1.5rem' }}>
                        <InputLabel sx={{ color: 'rgba(255, 255, 255, 0.7)' }}>Select Category</InputLabel>
                        <Select
                            value={sectionId}
                            onChange={handleSectionChange}
                            label="Select Section"
                            // sx={{
                            //     color: 'rgba(255, 255, 255, 0.7)',
                            //     border: "2px solid #9999",
                            // }}
                        >
                            {sections.map((section) => (
                                <MenuItem key={section.sectionId} value={section.sectionId}>
                                    {section.sectionName}
                                </MenuItem>
                            ))}
                        </Select>

                        {sections.length === 0 && (
                            <Box sx={{ color: 'orange', marginTop: '0.5rem', fontSize: '0.875rem' }}>
                                <Typography variant="body2">
                                    You don't have any approved sections to post.
                                </Typography>
                            </Box>
                        )}
                    </FormControl>

                    {subsections.length > 0 && (
                        <FormControl fullWidth sx={{ marginBottom: '1.5rem' }}>
                            <InputLabel sx={{ color: 'rgba(255, 255, 255, 0.7)' }}>Select Subcategory</InputLabel>
                            <Select
                                value={subsectionId}
                                onChange={handleSubsectionChange}
                                label="Select Subsection"
                                // sx={{
                                //     color: 'rgba(255, 255, 255, 0.7)',
                                //     border: "2px solid #9999",
                                // }}
                            >
                                {subsections.map((subsection) => (
                                    <MenuItem key={subsection.id} value={subsection.id}>
                                        {subsection.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    )}

                    <TextField
                        label="Topic Name"
                        variant="outlined"
                        fullWidth
                        value={topicName}
                        onChange={(e) => {
                            if (e.target.value.length <= 100) {
                                setTopicName(e.target.value);
                            }
                        }}
                        sx={styles.input}
                        inputProps={{
                            maxLength: 100,
                        }}
                        helperText="Please enter the topic name on which you want to share knowledge (Maximum 100 characters are allowed)"
                    />

                    <TextField
                        label="Description"
                        variant="outlined"
                        fullWidth
                        multiline
                        rows={4}
                        value={description}
                        onChange={(e) => {
                            if (e.target.value.length <= 500) {
                                setDescription(e.target.value);
                            }
                        }}
                        sx={styles.input}
                        inputProps={{
                            maxLength: 500,
                        }}
                        helperText="Please enter a short description about what exactly you will be sharing in the session. It will help users to understand better (Max 500 characters allowed)"
                    />


<FormControl fullWidth sx={{ marginBottom: '1.5rem' }}>
  <InputLabel id="language-select-label">Language</InputLabel>
  <Select
    labelId="language-select-label"
    id="language-select"
    value={language}
    onChange={handleChange}
    label="Language"
  >
    {languages.map((lang) => (
      <MenuItem key={lang.id} value={lang.name}>
        {lang.name}
      </MenuItem>
    ))}
  </Select>

  {/* Helper Text with color */}
  <FormHelperText sx={{ color: '#9999' }}>
  Please select the language in which you will be communicating during the session.
  </FormHelperText>
</FormControl>


                    

                    <TextField
                        label="Duration (in minutes)"
                        type="number"
                        variant="outlined"
                        fullWidth
                        value={duration}
                        onChange={(e) => setDuration(e.target.value)}
                        sx={styles.input}
                        inputProps={{
                            max: 60,
                            min: 1,
                        }}
                        helperText="Please enter the duration of session (Please Note: Duration cannot exceed 60 minutes)"
                    />

                    <TextField
                        label="Max Limit (in Numbers)"
                        type="number"
                        variant="outlined"
                        fullWidth
                        value={maxLimit}
                        onChange={(e) => setMaxLimit(e.target.value)}
                        sx={styles.input}
                        inputProps={{
                            max: 100,
                            min: 1,
                        }}
                        helperText="Max limit is the maximum number of users who can book your post, it cannot exceed 100."
                    />

                    <TextField
                        label="Price in INR"
                        type="number"
                        variant="outlined"
                        fullWidth
                        value={price}
                        onChange={(e) => setPrice(e.target.value)}
                        sx={styles.input}
                        inputProps={{
                            max: 100,
                            min: 0,
                        }}
                        helperText="Price value you enter will be the price of booking your post per user (Please note: Price cannot exceed INR 100)"
                    />
                    <FormControl fullWidth sx={{ marginBottom: '1.5rem' }}>
                    <TextField
                        label="Select Date"
                        type="date"
                        variant="outlined"
                        fullWidth
                        value={selectedDate}
                        onChange={handleDateChange}
                        required
                        InputLabelProps={{ shrink: true }}
                        sx={{
                            // marginBottom: '1.5rem',
                            input: { color: '#ffffff' },
                            '& .MuiOutlinedInput-root': {
                                backgroundColor: '#5555',
                                '& fieldset': {
                                    borderColor: '#555555',
                                },
                            },
                            '& .MuiInputLabel-root': {
                                color: '#aaaaaa',
                            },
                            '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
                                borderColor: '#1E90FF',
                            }
                        }}
                    /><FormHelperText sx={{ color: '#aaaaaa' }}>
                    Please select a date for the session.
                  </FormHelperText>
                </FormControl>
                <FormControl fullWidth sx={{ marginBottom: '1.5rem' }}>
                    <TextField
                        label="Start Time"
                        type="time"
                        variant="outlined"
                        fullWidth
                        value={startTime}
                        onChange={(e) => setStartTime(e.target.value)}
                        required
                        InputLabelProps={{ shrink: true }}
                        sx={{
                            // marginBottom: '1.5rem',
                            input: { color: '#ffffff' },
                            '& .MuiOutlinedInput-root': {
                                backgroundColor: '#5555',
                                '& fieldset': {
                                    borderColor: '#555555',
                                },
                            },
                            '& .MuiInputLabel-root': {
                                color: '#aaaaaa',
                            },
                            '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
                                borderColor: '#1E90FF',
                            }
                        }}
                    /><FormHelperText sx={{ color: '#aaaaaa' }}>
                    Please select time for the session.
                  </FormHelperText>
                </FormControl>

                <FormControl fullWidth sx={{ marginBottom: '1.5rem' }}>
  {googleMeetLink === '' && (
    <>
      {/* <GenerateMeetingLinkButton userId={userId} onLinkGenerated={(link) => setGoogleMeetLink(link)} /> */}
      <GenerateMeetingLinkButton 
        userId={userId} 
        onLinkGenerated={(link) => setGoogleMeetLink(link)} 
      />
      {/* Helper Text */}
      <FormHelperText sx={{ color: '#aaaaaa' }}>
        Click the button to generate a Meet link and wait for the button to disappear.
      </FormHelperText>
    </>
  )}
</FormControl>


                    <Button
                        variant="contained"
                        onClick={handlePost}
                        sx={styles.button}
                    >
                        Submit
                    </Button>
                </Box>

                <Snackbar
                    open={openSnackbar}
                    autoHideDuration={6000}
                    onClose={() => setOpenSnackbar(false)}
                >
                    <Alert
                        severity="error"
                        sx={{ width: '100%' }}
                        onClose={() => setOpenSnackbar(false)}
                    >
                        {error}
                    </Alert>
                </Snackbar>
                
            </Container>
            
        </div>
    );
};

export default CreatePost;
