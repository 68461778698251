import React, { useState, useEffect } from 'react';
import { Box, Typography, FormControl, Select, MenuItem, InputLabel, Button } from '@mui/material';

const API_URL = process.env.REACT_APP_API_URL;

function DeleteSubscription({ userId, onLogout }) {
    const [subscriptions, setSubscriptions] = useState([]);
    const [selectedSubscription, setSelectedSubscription] = useState('');
    const [loading, setLoading] = useState(false);

    // Fetch subscriptions on page load
    useEffect(() => {
        const fetchSubscriptions = async () => {
            try {
                const response = await fetch(`${API_URL}/list_subscriptions_data/`);
                const data = await response.json();
                setSubscriptions(data);
            } catch (error) {
                console.error('Error fetching subscriptions:', error);
            }
        };

        fetchSubscriptions();
    }, []);

    // Handle the subscription selection
    const handleSelectSubscription = (event) => {
        const subscriptionId = event.target.value;
        setSelectedSubscription(subscriptionId);
    };

    // Handle delete
    const handleDeleteSubscription = async () => {
        if (!selectedSubscription) {
            alert('Please select a subscription to delete');
            return;
        }

        const confirmDelete = window.confirm('Are you sure you want to delete this subscription?');

        if (!confirmDelete) {
            return;
        }

        setLoading(true);
        try {
            const response = await fetch(`${API_URL}/delete_subscription/${selectedSubscription}/`, {
                method: 'DELETE',
            });

            if (response.ok) {
                alert('Subscription deleted successfully!');
                // Remove the deleted subscription from the list
                setSubscriptions(subscriptions.filter(sub => sub.id !== selectedSubscription));
                setSelectedSubscription('');
            } else {
                const errorData = await response.json();
                alert(`Error deleting subscription: ${errorData.message}`);
            }
        } catch (error) {
            console.error('Error deleting subscription:', error);
            alert('An error occurred while deleting the subscription.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <Box sx={{ p: 4, backgroundColor: '#111', borderRadius: 2, maxWidth: '600px', margin: 'auto', color: 'white' }}>
            <Typography variant="h5" sx={{ mb: 3, color: '#1E90FF', fontWeight: 'bold' }}>
                Delete Subscription
            </Typography>

            <FormControl fullWidth sx={{ mb: 3, backgroundColor: '#222', borderRadius: 2 }}>
                <InputLabel id="subscription-select-label" sx={{ color: 'white' }}>Select Subscription</InputLabel>
                <Select
                    labelId="subscription-select-label"
                    value={selectedSubscription}
                    label="Select Subscription"
                    onChange={handleSelectSubscription}
                    sx={{
                        backgroundColor: '#333',
                        color: 'white',
                        '& .MuiSelect-icon': { color: 'white' },
                    }}
                >
                    {subscriptions.map((sub) => (
                        <MenuItem key={sub.id} value={sub.id}>
                            {sub.plan_name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>

            <Button
                variant="contained"
                onClick={handleDeleteSubscription}
                sx={{
                    bgcolor: '#FF6347',
                    '&:hover': { bgcolor: '#FF4500' },
                    width: '100%',
                    mt: 2,
                }}
                disabled={loading}
            >
                {loading ? 'Deleting...' : 'Delete Subscription'}
            </Button>
        </Box>
    );
}

export default DeleteSubscription;
