import React, { useState } from 'react';
import { Box, Drawer, List, ListItem, ListItemIcon, ListItemText, Container, Dialog, DialogActions, DialogContent, DialogTitle, Button, IconButton , Typography} from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import WhatshotIcon from '@mui/icons-material/Whatshot';
import CategoryIcon from '@mui/icons-material/Category';
import StarIcon from '@mui/icons-material/Star';
import PostAddIcon from '@mui/icons-material/PostAdd';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import CancelIcon from '@mui/icons-material/Cancel';
import DoorBackIcon from '@mui/icons-material/DoorBack';
import Header1 from '../Header/Header1';
import Posted from '../Post/Posted';
import Categories from './Categories';
import CreatePost from '../Post/CreatePost';
import MyContent from './MyContent';
import RecentActivity from './RecentActivity';
import AISync from '../AI Sync/AISync';
import Footer2 from '../Footer/Footer2';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';


const Platform = ({ onLogout }) => {
  const [selectedSection, setSelectedSection] = useState('Posted');
  const [hoveredItem, setHoveredItem] = useState(null);
  const { userId } = useParams();
  const navigate = useNavigate();

  const [openPostDialog, setOpenPostDialog] = useState(false);
  const [isMinimized, setIsMinimized] = useState(false);

  // Sidebar options
  const sidebarOptions = [
    { text: 'Recommended', icon: <AssignmentIndIcon />, section: 'Posted' },
    { text: 'Categories', icon: <CategoryIcon />, section: 'Category' },
    { text: 'My Sync', icon: <StarIcon />, section: 'MyContent' },
    { text: 'AI Sync', icon: <WhatshotIcon />, section: 'AISync' },
  ];

  const handleLogout = () => {
    onLogout();
    navigate('/login');
  };

  const togglePostDialog = () => {
    setOpenPostDialog((prev) => !prev);
    setIsMinimized(false);
  };

  const toggleMinimize = () => {
    setIsMinimized((prev) => !prev);
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <div>
      <Box sx={{ display: 'flex', minHeight: '100vh', flexDirection: 'column' }}>
        {/* Sidebar Drawer */}
        <Drawer
          variant="permanent"
          sx={{
            width: { xs: 60, sm: 100 },  // Responsive width: 60px on mobile, 200px on larger screens
            flexShrink: 0,
            ml:1,
            [`& .MuiDrawer-paper`]: {
              width: { xs: 40, sm: 40 }, // Adjusting the drawer paper width
              boxSizing: 'border-box',
              position: 'fixed',
              top: 70,
              backgroundColor: 'transparent',
              transition: 'width 0.3s ease-in-out, border 0.3s ease-in-out',
              borderRight: '2px solid transparent',
            },
            '&:hover': {
              [`& .MuiDrawer-paper`]: {
                width: 80,  // Drawer expands on hover for larger screens
              },
            },
          }}
        >
          <Box
  sx={{
    display: 'flex',
    flexDirection: 'column',  // Stack icons and text vertically
    alignItems: 'center',     // Center items horizontally
    padding: '10px 0',        // Optional padding for spacing
  }}
>
  <IconButton
    onClick={togglePostDialog}
    sx={{
      display: 'flex',
      flexDirection: 'column',  // Stack icon and text vertically
      alignItems: 'center',     // Center items horizontally
      padding: '10px 0',        // Optional padding to control spacing
      '&:hover': {
        '& .text': {
          visibility: 'visible',  // Show text on hover
          opacity: 1,              // Make text fully opaque
        },
      },
    }}
  >
    <PostAddIcon sx={{ color: '#9999', '&:hover': {
      color: '#1E90FF',
    }, }} />
    <Typography
      className="text"
      sx={{
        color: hoveredItem === 'Create Post' || selectedSection === 'Create Post' ? '#1E90FF' : 'white',
        visibility: 'hidden',  // Hide text by default
        opacity: 0,            // Initially transparent
        transition: 'opacity 0.3s ease',  // Smooth transition for text visibility
        // marginTop: '8px',      // Adds spacing between the icon and text
        textAlign: 'center',   // Centers the text under the icon
        '&:hover': {
          color: '#1E90FF',
        },
        fontSize: {
          xs: '0.8rem',  // Smaller font size for mobile
          sm: '1rem',    // Slightly larger font size for tablets
          md: '1.2rem',  // Medium font size for larger screens
          lg: '0.7rem',  // Larger font size for desktops
        },
      }}
    >
      Create Post
    </Typography>
  </IconButton>

  {sidebarOptions.map((option) => (
    <Box
      key={option.text}
      onClick={() => {
        setSelectedSection(option.section);
        setHoveredItem(option.text);
      }}
      onMouseEnter={() => setHoveredItem(option.text)}
      onMouseLeave={() => setHoveredItem(null)}
      sx={{
        display: 'flex',
        flexDirection: 'column',  // Stack icon and text vertically
        alignItems: 'center',     // Center items horizontally
        padding: '10px 0',        // Padding for spacing
        '&:hover': {
          '& .text': {
            visibility: 'visible',  // Show text on hover
            opacity: 1,              // Make text fully opaque
          },
        },
      }}
    >
      <IconButton sx={{ color: '#9999', '&:hover': {
      color: '#1E90FF',
    }, }}>
        {option.icon}
      </IconButton>

      <Typography
        className="text"
        sx={{
          color: hoveredItem === option.text || selectedSection === option.section ? '#1E90FF' : 'white',
          visibility: 'hidden',  // Hide text by default
          opacity: 0,            // Initially transparent
          transition: 'opacity 0.3s ease',  // Smooth transition for text visibility
          // marginTop: '8px',      // Adds space between the icon and text
          textAlign: 'center',   // Centers the text under the icon
          fontSize: {
            xs: '0.8rem',  // Smaller font size for mobile
            sm: '1rem',    // Slightly larger font size for tablets
            md: '1.2rem',  // Medium font size for larger screens
            lg: '0.7rem',  // Larger font size for desktops
          },
        }}
      >
        {option.text}
      </Typography>
    </Box>
  ))}
</Box>
        </Drawer>

        {/* Main Content */}
        {/* <Container sx={{ marginTop: '5rem', marginLeft: { xs: '60px', sm: '200px' }, padding: '0 15px', width: '100%',justifyContent: 'center', // Center content horizontally
    alignItems: 'center',marginX: 'auto', }}> */}
          {selectedSection === 'Posted' && <Posted userId={userId} onLogout={onLogout} />}
          {selectedSection === 'Category' && <Categories userId={userId} onLogout={onLogout} />}
          {selectedSection === 'MyContent' && <MyContent userId={userId} onLogout={onLogout} />}
          {selectedSection === 'AISync' && <AISync userId={userId} onLogout={onLogout} />}
        {/* </Container> */}
      </Box>

      {/* Post Dialog */}
      <Dialog
        open={openPostDialog}
        onClose={togglePostDialog}
        fullScreen
        sx={{
          position: isMinimized ? 'fixed' : 'absolute',
          top: isMinimized ? 'auto' : '50%',
          left: isMinimized ? 'auto' : '50%',
          transform: isMinimized ? 'none' : 'translate(-50%, -50%)',
          bottom: isMinimized ? '5%' : '10%',
          right: isMinimized ? '5%' : 'auto',
          width: isMinimized ? '100px' : '90%',
          maxWidth: '1000px',
          height: isMinimized ? '100px' : '90vh',
          backgroundColor: 'black',
          color: '#ffffff',
          borderRadius: '8px',
          transition: 'all 0.3s ease',
          overflow: isMinimized ? 'hidden' : 'auto',
          '@media (max-width: 600px)': {
            width: '95%',
            height: isMinimized ? '80px' : '80vh',
          },
          '@media (max-width: 400px)': {
            width: '100%',
            height: isMinimized ? '60px' : '70vh',
          },
        }}
      >
        <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', backgroundColor: 'black', padding: '1rem' }}>
          <Box sx={{ display: 'flex', gap: '8px' }}>
            <Button
              onClick={toggleMinimize}
              sx={{ color: '#FF6347', fontSize: '1.5rem', position: 'absolute', right: '70px', top: '-7px' }}
            >
              {isMinimized ? <AddCircleOutlineIcon /> : <RemoveCircleOutlineIcon />}
            </Button>
            <Button
              onClick={togglePostDialog}
              sx={{ color: '#FF6347', fontSize: '1.5rem', position: 'absolute', right: '10px', top: '-7px' }}
            >
              <CancelIcon />
            </Button>
          </Box>
        </DialogTitle>

        <DialogContent sx={{ padding: '0.1rem', overflowY: 'auto', backgroundColor: 'black' }}>
          {!isMinimized && <CreatePost userId={userId} onClose={togglePostDialog} />}
        </DialogContent>

        <DialogActions sx={{ padding: '8px', backgroundColor: 'black' }}>
          <Button onClick={togglePostDialog} sx={{ color: '#FF6347' }}>
            Close
          </Button>
        </DialogActions>
      </Dialog>

      {/* Scroll to Top Button */}
      <IconButton
        onClick={scrollToTop}
        sx={{ position: 'fixed', bottom: 16, right: 16, backgroundColor: 'white', color: 'black', zIndex: '10' }}
      >
        <ArrowUpwardIcon />
      </IconButton>

      <Footer2 />
    </div>
  );
};

export default Platform;
