import React, { useState, useEffect } from 'react';
import { Select, MenuItem, Button, Box, Typography, FormControl, InputLabel, CircularProgress } from '@mui/material';

const DeleteLanguage = ({ userId, onLogout }) => {
    const [languages, setLanguages] = useState([]);
    const [selectedLanguage, setSelectedLanguage] = useState('');
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);

    // Fetch the list of languages from the backend
    useEffect(() => {
        const fetchLanguages = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/list_languages/`);
                const data = await response.json();
                if (response.ok) {
                    setLanguages(data);
                    setLoading(false);
                } else {
                    setError('Failed to fetch languages');
                    setLoading(false);
                }
            } catch (err) {
                setError('Error fetching languages');
                setLoading(false);
            }
        };

        fetchLanguages();
    }, []);

    const handleDeleteLanguage = async () => {
        if (!selectedLanguage) {
            setError('Please select a language to delete');
            return;
        }
    
        const response = await fetch(`${process.env.REACT_APP_API_URL}/delete_language/`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ id: selectedLanguage }),
        });
    
        // Handle response without assuming JSON
        if (response.ok) {
            // If the response status is 204 (No Content), no body will be returned
            if (response.status === 204) {
                alert('Language deleted successfully');
                setSelectedLanguage('');
            } else {
                // If there’s any response body, we parse it as JSON
                const data = await response.json();
                alert(data.message || 'Language deleted successfully');
                setSelectedLanguage('');
            }
        } else {
            const data = await response.json();
            setError(data.message || 'Failed to delete language');
        }
    };

    return (
        <Box
            sx={{
                p: 4,
                bgcolor: '#121212',
                borderRadius: 2,
                boxShadow: '0 4px 12px rgba(0, 0, 0, 0.5)',
            }}
        >
            <Typography variant="h5" sx={{ mb: 3, color: '#fff' }}>
                Delete Language
            </Typography>

            {/* Language Selection */}
            <FormControl fullWidth sx={{ mb: 2 }}>
                <InputLabel id="language-select-label" sx={{ color: '#aaa' }}>
                    Select Language
                </InputLabel>
                <Select
                    labelId="language-select-label"
                    id="language-select"
                    value={selectedLanguage}
                    onChange={(e) => setSelectedLanguage(e.target.value)}
                    label="Select Language"
                    sx={{
                        bgcolor: '#222',
                        color: '#fff',
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                borderColor: '#555',
                            },
                            '&:hover fieldset': {
                                borderColor: '#1E90FF',
                            },
                        },
                    }}
                >
                    {loading ? (
                        <MenuItem disabled>
                            <CircularProgress size={24} />
                        </MenuItem>
                    ) : (
                        languages.map((language) => (
                            <MenuItem key={language.id} value={language.id}>
                                {language.name}
                            </MenuItem>
                        ))
                    )}
                </Select>
            </FormControl>

            {error && (
                <Typography variant="body2" sx={{ color: '#f44336', mb: 2 }}>
                    {error}
                </Typography>
            )}

            <Button
                variant="contained"
                onClick={handleDeleteLanguage}
                sx={{
                    bgcolor: '#f44336',
                    '&:hover': { bgcolor: '#d32f2f' },
                    fontWeight: 'bold',
                    px: 4,
                    py: 1.5,
                }}
            >
                Delete Language
            </Button>
        </Box>
    );
};

export default DeleteLanguage;
