import React from 'react';
import { Link } from 'react-router-dom';
import { Box, Typography, Grid } from '@mui/material';

function Footer2() {
    return (
        <Box
            sx={{
                backgroundColor: '#1111',
                color: '#9999',
                padding: '40px 20px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                mt: 'auto',
            }}
        >
            <Grid
                container
                justifyContent="center"
                spacing={4}
                sx={{
                    flexWrap: 'wrap',
                    justifyContent: 'center',
                    // Ensure proper alignment across screen sizes
                    alignItems: 'center',
                }}
            >
                {/* Quick Links Section with all links in one row */}
                <Grid item xs={12} sm={6} md={8} sx={{ display: 'flex', justifyContent: 'center', gap: 4, flexWrap: 'wrap' }}>
                    <Link to="/about-us" style={{ color: '#9999', textDecoration: 'none', padding: '5px' }}>
                        About Us
                    </Link>
                    <Link to="/faqs" style={{ color: '#9999', textDecoration: 'none', padding: '5px' }}>
                        FAQs
                    </Link>
                    <Link to="/careers" style={{ color: '#9999', textDecoration: 'none', padding: '5px' }}>
                        Careers
                    </Link>
                    <Link to="/contact-us" style={{ color: '#9999', textDecoration: 'none', padding: '5px' }}>
                        Contact Us
                    </Link>
                    {/* <Link to="/community" style={{ color: '#9999', textDecoration: 'none', padding: '5px' }}>
                        Community
                    </Link> */}
                    <Link to="/customer-support" style={{ color: '#9999', textDecoration: 'none', padding: '5px' }}>
                        Help
                    </Link>
                    <a
                        href="/Terms_of_Service.html"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ textDecoration: 'none', color: 'inherit', padding: '5px' }}
                    >
                        Terms of Service
                    </a>
                    <a
                        href="/Cookies_policy.html"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ textDecoration: 'none', color: 'inherit', padding: '5px' }}
                    >
                        Cookies Policy
                    </a>
                    <a
                        href="/Privacy_policy.html"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ textDecoration: 'none', color: 'inherit', padding: '5px' }}
                    >
                        Privacy Policy
                    </a>
                </Grid>

                {/* Copyright Section */}
                <Grid
                    item
                    xs={12} sm={12} md={8}
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: 2,
                        flexDirection: { xs: 'column', sm: 'row' }, // Stack on small screens
                        textAlign: 'center',
                    }}
                >
                    <Typography
                        variant="body2"
                        sx={{
                            fontSize: '1rem',
                            '&:hover': {
                                color: 'white', // Change font color to blue on hover
                            },
                        }}
                    >
                        © 2025 Anthrasync Inc. All rights reserved.
                    </Typography>
                </Grid>
            </Grid>
        </Box>
    );
}

export default Footer2;
