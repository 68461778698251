import React, { useState } from 'react';
import { Grid, Box, IconButton, Typography, Paper, Link } from '@mui/material';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/X';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import YouTubeIcon from '@mui/icons-material/YouTube';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CloseIcon from '@mui/icons-material/Close';
import ShareIcon from '@mui/icons-material/Share';



const ShareButton = ({ postUrl }) => {
  const [showShareIcons, setShowShareIcons] = useState(false);
  const [copyMessage, setCopyMessage] = useState('');

  // Handle share button click
  const handleShareClick = () => {
    setShowShareIcons(!showShareIcons);
  };

  // Handle social media redirection
  const handleRedirect = (platform) => {
    const shareMessage = "Check out this post!";
    let shareUrl = '';

    switch (platform) {
      case 'whatsapp':
        shareUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(shareMessage)}%20${encodeURIComponent(postUrl)}`;
        break;
      case 'facebook':
        shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(postUrl)}`;
        break;
      case 'twitter':
        shareUrl = `https://x.com/intent/tweet?url=${encodeURIComponent(postUrl)}&text=${encodeURIComponent(shareMessage)}`;
        break;
      case 'linkedin':
        shareUrl = `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(postUrl)}&title=${encodeURIComponent(shareMessage)}`;
        break;
      case 'youtube':
        shareUrl = `https://www.youtube.com/results?search_query=${encodeURIComponent(postUrl)}`;
        break;
      default:
        return;
    }

    window.open(shareUrl, '_blank');
  };

  // Handle copying to clipboard
  const handleCopyClick = () => {
    navigator.clipboard.writeText(postUrl).then(() => {
      setCopyMessage('Link copied!');
      setTimeout(() => setCopyMessage(''), 2000);
    }).catch(err => {
      console.error('Failed to copy: ', err);
      setCopyMessage('Failed to copy!');
    });
  };

  return (
    <Grid container spacing={3} sx={{ justifyContent: 'center', alignItems: 'center' }}>
      {/* Main Share Button */}
      <Grid item xs={12} sm={2} sx={{ display: "flex", justifyContent: "center", alignItems: "center", position: 'relative' }}>
        <Link component="button" onClick={handleShareClick} sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
          <ShareIcon sx={{ fontSize: { xs: '2rem', sm: '2.5rem', md: '3rem' }, color: '#fff', transition: 'transform 0.3s', '&:hover': { transform: 'scale(1.1)' } }} />
          {/* <Typography variant="body2" sx={{ fontSize: "0.8em", color: "white", marginLeft: '5px' }}>Share</Typography> */}
        </Link>
      </Grid>

      {/* Share Icons (conditionally displayed) */}
      {showShareIcons && (
        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', position: 'absolute', top: '100%', left: '50%', transform: 'translateX(-50%)' }}>
          <Paper sx={{
            padding: '2rem',
            backgroundColor: '#ffffff',
            borderRadius: '10px',
            boxShadow: 3,
            display: 'flex',
            flexDirection: 'column',
            gap: 3,
            maxWidth: '350px',
            width: '100%',
            textAlign: 'center',
            justifyContent: 'center',
          }}>
            <Box sx={{ display: 'flex', justifyContent: 'center', gap: 3, flexWrap: 'wrap' }}>
              {/* WhatsApp Icon */}
              <IconButton onClick={() => handleRedirect('whatsapp')} sx={{ color: '#25D366', '&:hover': { color: '#128C7E' }, transition: 'color 0.3s ease' }}>
                <WhatsAppIcon sx={{ fontSize: { xs: '2rem', sm: '2.5rem', md: '3rem' } }} />
              </IconButton>

              {/* Facebook Icon */}
              <IconButton onClick={() => handleRedirect('facebook')} sx={{ color: '#3b5998', '&:hover': { color: '#1877F2' }, transition: 'color 0.3s ease' }}>
                <FacebookIcon sx={{ fontSize: { xs: '2rem', sm: '2.5rem', md: '3rem' } }} />
              </IconButton>

              {/* Twitter Icon */}
              <IconButton onClick={() => handleRedirect('twitter')} sx={{ color: '#000000', '&:hover': { color: '#00ACEE' }, transition: 'color 0.3s ease' }}>
                <TwitterIcon sx={{ fontSize: { xs: '2rem', sm: '2.5rem', md: '3rem' } }} />
              </IconButton>

              {/* LinkedIn Icon */}
              <IconButton onClick={() => handleRedirect('linkedin')} sx={{ color: '#0077B5', '&:hover': { color: '#005C80' }, transition: 'color 0.3s ease' }}>
                <LinkedInIcon sx={{ fontSize: { xs: '2rem', sm: '2.5rem', md: '3rem' } }} />
              </IconButton>

              {/* YouTube Icon */}
              <IconButton onClick={() => handleRedirect('youtube')} sx={{ color: '#FF0000', '&:hover': { color: '#C4302B' }, transition: 'color 0.3s ease' }}>
                <YouTubeIcon sx={{ fontSize: { xs: '2rem', sm: '2.5rem', md: '3rem' } }} />
              </IconButton>

              {/* Copy to Clipboard */}
              <IconButton onClick={handleCopyClick} sx={{ color: '#999', '&:hover': { color: '#616161' }, transition: 'color 0.3s ease' }}>
                <ContentCopyIcon sx={{ fontSize: { xs: '2rem', sm: '2.5rem', md: '3rem' } }} />
              </IconButton>

              {/* Close Icon to hide share options */}
              <IconButton onClick={() => setShowShareIcons(false)} sx={{ color: '#999', '&:hover': { color: '#616161' }, transition: 'color 0.3s ease' }}>
                <CloseIcon sx={{ fontSize: { xs: '2rem', sm: '2.5rem', md: '3rem' } }} />
              </IconButton>
            </Box>

            {/* Copy message */}
            {copyMessage && (
              <Typography variant="body2" sx={{ color: 'green', marginTop: '10px', fontSize: { xs: '0.9rem', sm: '1rem' } }}>
                {copyMessage}
              </Typography>
            )}
          </Paper>
        </Grid>
      )}
    </Grid>
  );
};

export default ShareButton;
