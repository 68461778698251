import React, { useState, useEffect } from "react";
import { Box, Typography, Grid, Card, CardContent, Button } from "@mui/material";
import { useNavigate, Link } from "react-router-dom";
import axios from 'axios'; // If using axios for API requests


const API_URL = process.env.REACT_APP_API_URL;




function RecentActivity({ userId, searchResults }) {
 const navigate = useNavigate();


 const [recentTabs, setRecentTabs] = useState([]);  // State to store activity data
 const [loading, setLoading] = useState(true);  // State for loading status
 const [error, setError] = useState(null);  // State for error handling


//  const user_id = '465141d4-fd35-4d18-9d3d-474f931e1ba2'


 // Fetching recent activity from backend API
 useEffect(() => {
   const fetchRecentActivity = async () => {
     try {
       // Make API request to fetch the recent activities, passing user_id as query parameter
       const response = await axios.get(`${API_URL}/list_recent_activity/`, {
         params: { user_id: userId },  // Send user_id as query param
       });
       console.log("Testing Recent Activity",response.data);
       // Assuming the response data structure matches what you showed
       console.log("Testing response data",response.data)
       setRecentTabs(response.data);  // Update state with the fetched data
     } catch (err) {
       setError("Failed to load recent activities");
       console.error("Error fetching data:", err);
     } finally {
       setLoading(false);  // Set loading to false once the API call is complete
     }
   };


   fetchRecentActivity();
 }, [userId]);  // Add userId as dependency so that it refetches when userId changes


 useEffect(() => {
  if (searchResults && searchResults.length > 0) {
    setRecentTabs(searchResults);  // Update courses with searchResults
    console.log('hi div',searchResults)
  }
}, [searchResults]);


 // Loading and error handling
 if (loading) {
   return <Typography variant="h6" align="center" sx={{ color: "#f5f5f5" }}>Loading recent activities...</Typography>;
 }


 if (error) {
   return <Typography variant="h6" align="center" sx={{ color: "#f44336" }}>{error}</Typography>;
 }


 return (
   <Box
     sx={{
       padding: "2rem",
       backgroundColor: "#000000",
       minHeight: "100vh",
       
     }}
   >
     {/* <Typography
       variant="h4"
       align="center"
       gutterBottom
       sx={{
         marginBottom: "2rem",
         fontWeight: "bold",
         color: "#f5f5f5",
       }}
     >
       Recent Activity
     </Typography> */}


     <Grid container spacing={5}>
       {recentTabs.length > 0 ? (
         recentTabs.map((tab) => (
           <Grid item xs={12} sm={6} md={4} key={tab.id}>
            <Link to={`/post-tab-details/${tab.post_id}/${userId}`} style={{ textDecoration: "none" }}>
            <Box
  sx={{
    backgroundColor: "#000000",
    color: "white",
    borderRadius: "16px",
    overflow: "hidden",
    boxShadow: "0 4px 10px rgba(0,0,0,0.3)",
    transition: "transform 0.3s, box-shadow 0.3s",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center", // Center content vertically
    alignItems: "center", // Center content horizontally
    border: '1px solid #1E90FF',
    height: {
      xs: "250px",  // Smaller height for mobile screens
      sm: "300px",  // Default height for small screens
      md: "350px",  // Medium height for medium screens
      lg: "400px",  // Larger height for larger screens
    },
    maxHeight: "400px",  // Maximum height to ensure it doesn't get too tall
    "&:hover": {
      borderColor: '#1E90FF',
      transform: "scale(1.05)",
      boxShadow: "0 6px 15px rgba(0,0,0,0.5)",
    },
  }}
>
               {/* <Box
                 sx={{
                   backgroundColor: "#1111",
                   padding: "1rem",
                   textAlign: "center",
                 }}
               >
                 <Typography variant="body2" sx={{ color: "#90caf9" }}>
                   Section: {tab.section_name}
                 </Typography>
                 <Typography
                   variant="body1"
                   sx={{ fontWeight: "bold", color: "#f5f5f5" }}
                 >
                   Subsection: {tab.subsection_name}
                 </Typography>
               </Box> */}


               <Box
                 sx={{
                   flexGrow: 1,
                   padding: "1rem",
                 }}
               >
                 <Typography
  variant="h8"
  sx={{
    fontWeight: "bold",
    marginBottom: {
      xs: "0.25rem",  // Smaller margin for mobile screens
      sm: "0.5rem",   // Slightly larger margin for small screens
      md: "0.75rem",  // Default margin for medium screens
      lg: "1rem",     // Larger margin for larger screens
    },
    color: "#ffffff",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign:"center",
    fontSize: {
      xs: "0.8rem",    // Smaller font size for mobile screens
      sm: "1.4rem",    // Slightly larger font size for small screens
      md: "1.6rem",    // Default font size for medium screens
      lg: "1.8rem",    // Larger font size for larger screens
    },
  }}
>
  {tab.topic_name}
</Typography>

                 <Typography
  variant="body2"
  sx={{
    color: "#b0bec5",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: {
      xs: "0.75rem",  // Smaller font size for mobile screens
      sm: "0.875rem", // Slightly larger font size for small screens
      md: "1rem",     // Default font size for medium screens
      lg: "1.125rem", // Larger font size for large screens
    },
    textAlign: {
      xs: "center",   // Center text for mobile screens
      sm: "center",   // Keep it centered for small screens
      md: "center",   // Center text for medium screens
      lg: "center",   // Center text for large screens
    },
    padding: {
      xs: "0.5rem",   // Add padding for mobile screens
      sm: "0.75rem",  // Slightly more padding for small screens
      md: "1rem",     // Default padding for medium screens
      lg: "1.25rem",  // More padding for larger screens
    },
  }}
>
  {tab.description}
</Typography>

               </Box>


               {/* <Box
  sx={{
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: {
      xs: "0.5rem",   // Smaller padding for mobile
      sm: "1rem",     // Default padding for small screens and up
      md: "1.5rem",   // Larger padding for medium screens and up
    },
    backgroundColor: "#3333",
    width: "100%",  // Ensure the Box takes up full width
  }}
>
  <Button
    size="small"
    sx={{
      color: "#42a5f5",
      fontSize: {
        xs: "0.8rem",    // Smaller font size for mobile
        sm: "1rem",      // Default font size for small screens
        md: "1.2rem",    // Medium font size for medium screens
        lg: "1.4rem",    // Larger font size for large screens
      },
      "&:hover": {
        color: "#90caf9",
      },
    }}
    onClick={() => navigate(`/post-tab-details/${tab.post_id}/${userId}`)} // Navigate to the specified path
  >
    Open Tab
  </Button>
</Box> */}

             </Box>
             </Link>
           </Grid>
         ))
       ) : (
        <Typography
        variant="body1"
        align="center"
        sx={{
          color: "#bdbdbd",     // Set the text color
          width: "100%",         // Ensure the text takes up the full width
          fontSize: {
            xs: "0.9rem",        // Smaller font size for mobile
            sm: "1rem",          // Default font size for small screens
            md: "1.2rem",        // Medium font size for larger screens
            lg: "1.4rem",        // Larger font size for desktop
          },
          padding: {
            xs: "1rem",          // Padding for small screens
            sm: "1.5rem",        // Padding for medium screens and up
          },
        }}
      >
        No recent activity found.
      </Typography>
      
       )}
     </Grid>
   </Box>
 );
}


export default RecentActivity;

