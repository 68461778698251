import React, { useState, useEffect } from 'react';
import { Box, Typography, Grid, Paper, Button } from '@mui/material';
import Header1 from '../Header/Header1';

const API_URL = process.env.REACT_APP_API_URL;

function CustomerQueriesResolve({ userId, onLogout }) {
  // State to hold support queries data
  const [supportQueries, setSupportQueries] = useState([]);

  // Fetch support queries data on component mount
  useEffect(() => {
    const fetchSupportQueries = async () => {
      try {
        const response = await fetch(`${API_URL}/list_support_query/`);
        const data = await response.json();
        setSupportQueries(data);
      } catch (error) {
        console.error("Error fetching support queries:", error);
      }
    };

    fetchSupportQueries();
  }, []);

  // Resolve query function (you can extend this as needed)
  const handleResolve = async (queryId) => {
    try {
      const response = await fetch(`${API_URL}/update_support_query/${queryId}/`, {
        method: 'POST', // Assuming POST or PUT to resolve the query
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ is_resolved: true }), // Adjust based on your API structure
      });
      
      if (response.ok) {
        // Optionally, update the UI after the query is resolved
        setSupportQueries((prevQueries) =>
          prevQueries.map((query) =>
            query.id === queryId ? { ...query, status: 'resolved' } : query
          )
        );
        alert('Query resolved!');
      } else {
        alert('Failed to resolve the query.');
      }
    } catch (error) {
      console.error("Error resolving query:", error);
      alert('An error occurred while resolving the query.');
    }
  };

  return (
    <div>
      <Header1 userId={userId} onLogout={onLogout} />
      <Box sx={{ bgcolor: '#1111', minHeight: '100vh', py: 5, px: { xs: 2, sm: 10 }, marginTop: 8 }}>
        <Typography variant="h5" sx={{ mb: 3, color: 'white', textAlign: 'center' }}>
          Customer Support Queries
        </Typography>

        <Grid container spacing={3}>
          {supportQueries.map((query) => (
            <Grid item xs={12} sm={6} md={4} key={query.id}>
              <Paper elevation={3} sx={{ p: 3, backgroundColor: '#111', color: 'white' }}>
                <Typography variant="body1" sx={{ mb: 1 }}>
                  <strong>Query:</strong> {query.query}
                </Typography>
                <Typography variant="body2" sx={{ mb: 1 }}>
                  <strong>Submitted At:</strong> {new Date(query.submitted_at).toLocaleString()}
                </Typography>
                <Typography variant="body2" sx={{ mb: 1 }}>
                  <strong>Email:</strong> {query.email}
                </Typography>

                {/* Conditionally render 'Resolved' or the 'Resolve' button */}
                {query.status === 'resolved' ? (
                  <Typography variant="body2" sx={{ mt: 2, color: 'green', fontWeight: 'bold' }}>
                    Resolved
                  </Typography>
                ) : (
                  <Button
                    variant="contained"
                    color="success"
                    onClick={() => handleResolve(query.id)} // Call resolve function with the query ID
                    sx={{
                      mt: 2,
                      bgcolor: '#28a745',
                      '&:hover': {
                        bgcolor: '#218838',
                      },
                    }}
                  >
                    Resolve
                  </Button>
                )}
              </Paper>
            </Grid>
          ))}
        </Grid>
      </Box>
    </div>
  );
}

export default CustomerQueriesResolve;
