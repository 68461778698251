import React from 'react'

function MeetingInstructions() {
  return (
    <div>
      Meeting Instructions
    </div>
  )
}

export default MeetingInstructions
