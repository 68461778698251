import React, { useState, useEffect } from 'react';
import {
    Button,
    TextField,
    Checkbox,
    FormControlLabel,
    Grid,
    Typography,
    Box,
    FormControl,
    Select,
    MenuItem,
    InputLabel,
    CircularProgress,
    createTheme,
    ThemeProvider,
    Card,
    CardContent,
    Snackbar
} from '@mui/material';
import axios from 'axios';
import Header1 from '../Header/Header1';
import Footer2 from '../Footer/Footer2';
import { styled } from '@mui/system';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Fade } from '@mui/material';

const API_URL = process.env.REACT_APP_API_URL;


const SectionContainer = styled(Box)({
    background: '#000000',
    borderRadius: '20px',
    padding: '20px', // Default padding for larger screens
    marginBottom: '20px',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.3)',
    color: '#ffffff',
    
    // Mobile first approach
    '@media (max-width: 600px)': {
        padding: '16px', // Smaller padding for mobile screens
        marginBottom: '15px', // Adjust margin for mobile
    },

    '@media (min-width: 600px) and (max-width: 960px)': {
        padding: '24px', // Slightly larger padding for tablets or medium screens
    },

    '@media (min-width: 960px)': {
        padding: '30px', // Larger padding for desktops and larger screens
    },
});


const CustomInputLabel = styled(InputLabel)({
    color: 'rgba(255, 255, 255, 0.7)',
});


const CustomButton = styled(Button)({
    backgroundColor: '#E90FF',
    color: '#fff',
    padding: '10px 20px',
    borderRadius: '6px',
    '&:hover': {
        backgroundColor: '#E90FF',
    },
});


const darkTheme = createTheme({
    palette: {
        mode: 'dark',
        primary: {
            main: '#000000',
        },
        secondary: {
            main: '#f48fb1',
        },
        background: {
            default: '#000000',
            paper: '#000000',
        },
        text: {
            primary: '#ffffff',
            secondary: '#b0bec5',
        },
    },
});


const Verification = ({ onLogout }) => {

    const navigate = useNavigate();


    const location = useLocation();

    const queryParams = new URLSearchParams(location.search);
    const userId = queryParams.get('userId');

    const [formData, setFormData] = useState({
        user_id: userId,
        aadhar: '',
        pan: '',
        bank_account: '',
        aadhar_file: null,
        pan_file: null,
        bank_account_file: null,
        ifsc: '',
        interview_slots: [],
        terms_accepted: false,
    });


    const [loading, setLoading] = useState(false);
    const [submittedData, setSubmittedData] = useState(null);
    const [dataExists, setDataExists] = useState(false);


    const [sectionId, setSectionId] = useState('');
    const [subsectionId, setSubsectionId] = useState('');
    const [sections, setSections] = useState([]);
    const [subsections, setSubsections] = useState([]);
    const [error, setError] = useState([])


    const [interviewSlot1, setInterviewSlot1] = useState('');
    const [interviewSlot2, setInterviewSlot2] = useState('');
    const [interviewSlot3, setInterviewSlot3] = useState('');



    const [email, setEmail] = useState('');
    const [emailCode, setEmailCode] = useState('');
    const [emailVerified, setEmailVerified] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const theme = useTheme();


  
    const [role, setRole] = useState('');
  
    useEffect(() => {
      const fetchUserRole = async () => {
        try {
          // Make GET request to your backend API with the user_id
          const response = await axios.get(`${API_URL}/get-user-role/`, {
            params: { user_id: userId },
          });
  
          // Set the role from the response
          setRole(response.data.role);
        } catch (err) {
          // Handle error
          if (err.response) {
            setError(err.response.data.error);
          } else {
            setError("Something went wrong");
          }
        }
      };
  
      fetchUserRole();
    }, [userId]);



  const handleSubmitEmail = async () => {
    try {
      const response = await axios.post(`${API_URL}/send_email_code/`, { email });
      alert(response.data.message);
    } catch (error) {
      setErrorMessage(error.response.data.error);
    }
  };

  const handleVerifyEmail = async () => {
    try {
      const response = await axios.post(`${API_URL}/verify_email_code/`, { email, code: emailCode,user_id:userId });
    //   setEmailVerified(response.data.role);
      setEmailVerified(response.data.is_verified);

    } catch (error) {
      setErrorMessage(error.response.data.error);
    }
  };

    useEffect(() => {
        const fetchVerificationData = async () => {
            try {
                const response = await axios.get(`${API_URL}/check_user_verification/?user_id=${userId}`);
                if (response.data.success) {
                    setFormData(response.data.data);  // Populate form with existing data
                    setSubmittedData(response.data.data);  // Store the existing data for display
                    setDataExists(true);  // Set flag indicating data exists
                }
            } catch (error) {
                setDataExists(false);  // No data found for the user
            }
        };


        fetchVerificationData();
    }, [userId]);


    useEffect(() => {
        // Fetch sections and subsections data from the backend
        axios.get(`${API_URL}/list_sections_and_subsections/?user_id=${userId}&available_sections_is_true=${true}`)
            .then((response) => {
                console.log("Fetched Sections Data:", response.data);


                if (response.data && response.data.sections) {
                    const fetchedSections = Object.entries(response.data.sections).map(([sectionName, sectionData]) => ({
                        sectionName,
                        sectionId: sectionData.section_id,
                        subsections: sectionData.subsections,
                    }));


                    console.log("Mapped Sections:", fetchedSections);
                    setSections(fetchedSections);
                } else {
                    console.error("Sections data is missing in the response!");
                }
            })
            .catch((error) => {
                console.error('Error fetching sections:', error);
            });
    }, []);




    const handlePost = () => {
        const selectedSection = sections.find(section => section.sectionId === sectionId);
        const sectionName = selectedSection ? selectedSection.sectionName : '';


        const selectedSubsection = subsections.find(subsection => subsection.id === subsectionId);
        const subsectionName = selectedSubsection ? selectedSubsection.name : '';


        const postData = {
            user_id: userId,
            subsection_id: subsectionId,
            is_active: true,
            status: 'pending',
            interview_slot_1: interviewSlot1,
            interview_slot_2: interviewSlot2,
            interview_slot_3: interviewSlot3
            // section_id: sectionId,
            // section_name: sectionName,
            // subsection_name: subsectionName,
        };


        axios.post(`${API_URL}/create_user_section_verification/`, postData).then((response) => {
            console.log('Post successful:', response.data);
            window.alert('Applied for section verification successfully!');
            window.location.reload();
        })
            .catch((error) => {
                console.error('Error in section apply for verification:', error);
                setError('An error occurred while applying. Please try again later.');
                // setOpenSnackbar(true);
            });
    };


    const handleChange = (event) => {
        const { name, value, type, checked, files } = event.target;
        if (type === 'file') {
            setFormData({
                ...formData,
                [name]: files[0], // Save the file object
            });
        } else {
            setFormData({
                ...formData,
                [name]: type === 'checkbox' ? checked : value,
            });
        }
    };


    const handleSlotChange = (event) => {
        const { value } = event.target;
        setFormData({
            ...formData,
            interview_slots: value, // This will be an array of selected slots
        });
    };




    const validateAadhar = (aadhar) => {
        // Aadhaar number should be a 12-digit number
        const regex = /^[2-9]{1}[0-9]{11}$/;
        return regex.test(aadhar);
    };


    const validatePan = (pan) => {
        // PAN number should be of format: AAAAA9999A (5 alphabets, 4 digits, 1 alphabet)
        const regex = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
        return regex.test(pan);
    };


    const validateBankAccount = (bankAccount) => {
        // Bank account number should be a 10-18 digit number
        const regex = /^\d{10,18}$/;
        return regex.test(bankAccount);
    };


    const validateIfsc = (ifsc) => {
        // IFSC code should be in the format: XXXX0XXXXX (4 letters, a 0, and 6 digits)
        const regex = /^[A-Za-z]{4}0[A-Za-z0-9]{6}$/;
        return regex.test(ifsc);
    };


    const handleSubmit = async (event) => {
        event.preventDefault();


        const errors = [];


        if (!validateAadhar(formData.aadhar)) {
            errors.push('Invalid Aadhaar number. It should be a 12-digit number.');
        }


        if (!validatePan(formData.pan)) {
            errors.push('Invalid PAN number. It should be in the format AAAAA9999A.');
        }


        if (!validateBankAccount(formData.bank_account)) {
            errors.push('Invalid Bank Account number. It should be between 10 to 18 digits.');
        }


        if (!validateIfsc(formData.ifsc)) {
            errors.push('Invalid IFSC code. It should be in the format XXXX0XXXXX.');
        }


        // If there are errors, show them and don't submit the form
        if (errors.length > 0) {
            setError(errors); // Update error state to display messages
            return;
        }


        setLoading(true);


        const formDataToSubmit = new FormData();
        for (const key in formData) {
            if (formData[key] instanceof File) {
                formDataToSubmit.append(key, formData[key]);  // Append file fields
            } else if (Array.isArray(formData[key])) {
                formDataToSubmit.append('interview_slots', JSON.stringify(formData[key])); // Serialize array to JSON string
            } else {
                formDataToSubmit.append(key, formData[key]);  // Handle other fields
            }
        }


        try {
            const response = await axios.post(`${API_URL}/create_user_verification/?user_id=${userId}`, formDataToSubmit, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });


            if (response.data.success) {
                window.alert('Applied for verification successfully!');
                window.location.reload();
                //    setFormData(response.data.data);  // Update the form with the submitted data
                //    setSubmittedData(response.data.data);  // Store the data after successful submission
            } else {
                alert('Verification failed. Please try again.');
            }
        } catch (error) {
            alert('An error occurred. Please try again later.');
        }

        setLoading(false);
    };

    // const handleLogout = () => {
    //     if (onLogout) {
    //         onLogout(); // Call the onLogout function here
    //     }
    // };




    const handleSectionChange = (event) => {
        const selectedSectionId = event.target.value;
        setSectionId(selectedSectionId);


        const selectedSection = sections.find(section => section.sectionId === selectedSectionId);
        setSubsections(selectedSection ? selectedSection.subsections : []);
        setSubsectionId('');
    };


    const handleSubsectionChange = (event) => {
        setSubsectionId(event.target.value);
    };

    if (role === 'admin') {
        setRole('emvuser'); // Use context setter to change role
    }



    return (
        <div>
            <Header1 userId={userId} onLogout={onLogout} />
           {role !== 'emvuser' &&( <Box
  sx={{
    bgcolor: theme.palette.background.default,
    color: theme.palette.text.primary,
    mt: { xs: 20, sm: 10, md: 20 }, // Responsive top margin
    borderRadius: 2,
    boxShadow: 3,
    p: { xs: 3, sm: 4, md: 6 }, // Responsive padding
    maxWidth: 600,
    width: '100%',
    mx: 'auto',
    my: 3,
    '&:hover': {
      transform: 'translateY(-5px)', // Slightly increased hover effect
      boxShadow: 10,
      transition: 'transform 0.3s, box-shadow 0.3s', // Smooth transition
    },
    '@media (max-width: 600px)': {
      maxWidth: '90%',
      p: 2,
    },
  }}
>
  <Typography
    variant="h5"
    sx={{
      textAlign: 'center',
      mb: 3,
      color: theme.palette.primary.main,
      fontWeight: 'bold',
      fontSize: { xs: '1.2rem', sm: '1.5rem', md: '2rem' },
    }}
  >
    Verify Your Email
  </Typography>

  {/* Email Input Section */}
  {role !== 'emvuser'  && (
    <Fade in={!emailVerified} timeout={500}>
      <div>
        <TextField
          label="Email"
          type="email"
          fullWidth
          margin="normal"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          sx={{
            input: {
              color: theme.palette.text.primary,
              backgroundColor: theme.palette.background.paper,
            },
            '& .MuiInputBase-root': {
              borderColor: theme.palette.text.primary,
              borderRadius: 1.5,
            },
            '&:hover .MuiInputBase-root': {
              borderColor: theme.palette.primary.main,
            },
          }}
        />
        <Button
          variant="contained"
          fullWidth
          onClick={handleSubmitEmail}
          sx={{
            mb: 2,
            color: 'white',
            backgroundColor: theme.palette.primary.main,
            '&:hover': {
              backgroundColor: theme.palette.primary.dark,
            },
          }}
        >
          Send Email Code
        </Button>
        <TextField
          label="Email Code"
          fullWidth
          margin="normal"
          value={emailCode}
          onChange={(e) => setEmailCode(e.target.value)}
          sx={{
            input: {
              color: theme.palette.text.primary,
              backgroundColor: theme.palette.background.paper,
            },
            '& .MuiInputBase-root': {
              borderColor: theme.palette.text.primary,
              borderRadius: 1.5,
            },
            '&:hover .MuiInputBase-root': {
              borderColor: theme.palette.primary.main,
            },
          }}
        />
        <Button
          variant="contained"
          fullWidth
          onClick={handleVerifyEmail}
          sx={{
            mb: 2,
            color: 'white',
            backgroundColor: theme.palette.primary.main,
            '&:hover': {
              backgroundColor: theme.palette.primary.dark,
            },
          }}
        >
          Verify Email
        </Button>
      </div>
    </Fade>
  )}

  {/* Error Message */}
  {errorMessage && (
    <Typography color="error" variant="body2" align="center" sx={{ mt: 2 }}>
      {errorMessage}
    </Typography>
  )}

  {/* Success Messages */}
  {emailVerified && (
    <Fade in={emailVerified} timeout={500}>
      <Typography
        color="primary"
        variant="body2"
        align="center"
        sx={{ mt: 2, fontWeight: 'bold' }}
      >
        Email Verified Successfully!
      </Typography>
    </Fade>
  )}
</Box>)}

            <ThemeProvider theme={darkTheme}>
            {role=='emvuser' && (<Box
    sx={{
        width: { xs: '100%', sm: '70%', md: '50%' }, // Responsive width for different screen sizes
        margin: 'auto',
        padding: { xs: '15px', sm: '20px', md: '30px' }, // Adjust padding based on screen size
        mt: { xs: 15, sm: 10, md: 7 }, // Adjust margin-top for better spacing on different screen sizes
    }}
>
                <Typography
    variant="h4"
    textAlign="center"
    gutterBottom
    color="text.primary"
    sx={{
        fontSize: { xs: '1.5rem', sm: '2rem', md: '2.5rem', lg: '3rem' }, // Adjust font size for different screen sizes
        marginBottom: { xs: '1rem', sm: '1.5rem', md: '2rem' }, // Adjust margin-bottom for different screen sizes
    }}
>
    Verification
</Typography>


                    



                    {dataExists ? (
                        // If data exists, show submitted data
                        <div>
                            <SectionContainer>
                            <Typography
    variant="h5"
    color="text.primary"
    gutterBottom
    sx={{
        fontSize: { xs: '1.2rem', sm: '1.5rem', md: '1.8rem', lg: '2rem' }, // Responsive font size
        marginBottom: { xs: '0.8rem', sm: '1rem', md: '1.5rem' }, // Adjust margin-bottom for different screen sizes
        textAlign: 'center', // Center align the text
    }}
>
    Submitted Data
</Typography>

                                <Box sx={{
    marginBottom: '2rem',
    padding: { xs: '1rem', sm: '1.5rem', md: '2rem' }, // Responsive padding
    backgroundColor: '#1e1e1e',
    borderRadius: '12px',
    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
    color: 'white',
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem', // Spacing between each item
}}>


<Typography
    variant="body1"
    color="text.primary"
    paragraph
    sx={{
        fontSize: { xs: '0.9rem', sm: '1rem', md: '1.1rem', lg: '1.2rem' }, // Adjust font size for different screen sizes
        marginBottom: { xs: '0.5rem', sm: '1rem', md: '1.5rem' }, // Adjust margin-bottom for different screen sizes
        lineHeight: { xs: 1.4, sm: 1.5, md: 1.6 }, // Adjust line-height for readability on smaller screens
    }}
>
    <strong>Aadhar:</strong> {submittedData.aadhar || "Not Provided"}
</Typography>

<Typography
    variant="body1"
    color="text.primary"
    paragraph
    sx={{
        fontSize: { xs: '0.9rem', sm: '1rem', md: '1.1rem', lg: '1.2rem' }, // Adjust font size for different screen sizes
        marginBottom: { xs: '0.5rem', sm: '1rem', md: '1.5rem' }, // Adjust margin-bottom for different screen sizes
        lineHeight: { xs: 1.4, sm: 1.5, md: 1.6 }, // Adjust line-height for readability on smaller screens
    }}
>
<strong>Pan:</strong> {submittedData.pan || "Not Provided"}
</Typography>


<Typography
    variant="body1"
    color="text.primary"
    paragraph
    sx={{
        fontSize: { xs: '0.9rem', sm: '1rem', md: '1.1rem', lg: '1.2rem' }, // Adjust font size for different screen sizes
        marginBottom: { xs: '0.5rem', sm: '1rem', md: '1.5rem' }, // Adjust margin-bottom for different screen sizes
        lineHeight: { xs: 1.4, sm: 1.5, md: 1.6 }, // Adjust line-height for readability on smaller screens
    }}
>
<strong>Bank Account:</strong> {submittedData.bank_account || "Not Provided"}
</Typography>

<Typography
    variant="body1"
    color="text.primary"
    paragraph
    sx={{
        fontSize: { xs: '0.9rem', sm: '1rem', md: '1.1rem', lg: '1.2rem' }, // Adjust font size for different screen sizes
        marginBottom: { xs: '0.5rem', sm: '1rem', md: '1.5rem' }, // Adjust margin-bottom for different screen sizes
        lineHeight: { xs: 1.4, sm: 1.5, md: 1.6 }, // Adjust line-height for readability on smaller screens
    }}
>
<strong>IFSC:</strong> {submittedData.ifsc || "Not Provided"}
</Typography>

<Typography
    variant="body1"
    color="text.primary"
    paragraph
    sx={{
        fontSize: { xs: '0.9rem', sm: '1rem', md: '1.1rem', lg: '1.2rem' }, // Adjust font size for different screen sizes
        marginBottom: { xs: '0.5rem', sm: '1rem', md: '1.5rem' }, // Adjust margin-bottom for different screen sizes
        lineHeight: { xs: 1.4, sm: 1.5, md: 1.6 }, // Adjust line-height for readability on smaller screens
    }}
>
<strong>Approved Sections:</strong> {submittedData.approved_sections || "Not Provided"}
</Typography>

<Typography
    variant="body1"
    color="text.primary"
    paragraph
    sx={{
        fontSize: { xs: '0.9rem', sm: '1rem', md: '1.1rem', lg: '1.2rem' }, // Adjust font size for different screen sizes
        marginBottom: { xs: '0.5rem', sm: '1rem', md: '1.5rem' }, // Adjust margin-bottom for different screen sizes
        lineHeight: { xs: 1.4, sm: 1.5, md: 1.6 }, // Adjust line-height for readability on smaller screens
    }}
>
<strong>Pending Sections:</strong> {submittedData.pending_sections || "Not Provided"}
</Typography>


</Box>

                                {submittedData.pending_sections.length === 0 ? (
                                    <Box>
                                        <Typography 
  sx={{
    fontSize: { xs: '1rem', sm: '1.2rem', md: '1.5rem', lg: '1.7rem' },  // Adjust font size for different screen sizes
    lineHeight: 1.5,  // Optional: You can adjust the line height to ensure text is readable
    padding: { xs: '10px', sm: '15px', md: '20px' },  // Adjust padding for responsiveness
    textAlign: 'center'  // Optional: Center the text on all screens (if desired)
  }}
>
  Please select one category and subcategory for which posting access is required.
</Typography>


                                        {/* Section Selector */}
                                        <FormControl fullWidth sx={{ marginBottom: '1.5rem',marginTop: '1.5rem' }}>
                                            <CustomInputLabel>Select Category</CustomInputLabel>
                                            <Select
                                                value={sectionId}
                                                onChange={handleSectionChange}
                                                label="Select Category"
                                                sx={{
                                                    color: 'rgba(255, 255, 255, 0.7)',
                                                    border: '2px solid #9999',
                                                }}
                                            >
                                                {sections.map((section) => (
                                                    <MenuItem key={section.sectionId} value={section.sectionId}>
                                                        {section.sectionName}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>


                                        {/* Subsection Selector */}
                                        {subsections.length > 0 && (
                                            <Box sx={{ marginBottom: '1.5rem' }}>
                                                <FormControl fullWidth sx={{ marginBottom: '1.5rem', marginTop: '1rem' }}>
                                                    <CustomInputLabel>Select Subcategory</CustomInputLabel>
                                                    <Select
                                                        value={subsectionId}
                                                        onChange={handleSubsectionChange}
                                                        label="Select Subcategory"
                                                        sx={{
                                                            color: 'rgba(255, 255, 255, 0.7)',
                                                            border: '2px solid #9999',
                                                        }}
                                                    >
                                                        {subsections.map((subsection) => (
                                                            <MenuItem key={subsection.id} value={subsection.id}>
                                                                {subsection.name}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>




                                                <Typography 
  sx={{
    fontSize: { xs: '1rem', sm: '1.2rem', md: '1.5rem', lg: '1.7rem' },  // Adjust font size for different screen sizes
    lineHeight: 1.5,  // Optional: You can adjust the line height to ensure text is readable
    padding: { xs: '10px', sm: '15px', md: '20px' },  // Adjust padding for responsiveness
    textAlign: 'center'  // Optional: Center the text on all screens (if desired)
  }}
>
Please select any three available date and time slots for a short online meeting for verification.
</Typography>



                                                {/* Interview Slot 1 */}
                                                <TextField
  type="datetime-local"
  value={interviewSlot1}
  onChange={(e) => setInterviewSlot1(e.target.value)}
  fullWidth
  margin="normal"
  InputLabelProps={{
    style: { color: '#ffffff' }, // Label color
  }}
  InputProps={{
    style: { color: '#ffffff', backgroundColor: '#808080' }, // Input text color and background color (gray)
  }}
  sx={{
    '& .MuiInputBase-root': {
      backgroundColor: '#808080', // Sets the background color of the input field itself
    }
  }}
/>



                                                {/* Interview Slot 2 */}
                                                <TextField
                                                    // label="Interview Slot 2"
                                                    type="datetime-local"
                                                    value={interviewSlot2}
                                                    onChange={(e) => setInterviewSlot2(e.target.value)}
                                                    fullWidth
                                                    margin="normal"
                                                    InputLabelProps={{
                                                        style: { color: '#ffffff' }, // Label color
                                                      }}
                                                      InputProps={{
                                                        style: { color: '#ffffff', backgroundColor: '#808080' }, // Input text color and background color (gray)
                                                      }}
                                                      sx={{
                                                        '& .MuiInputBase-root': {
                                                          backgroundColor: '#808080', // Sets the background color of the input field itself
                                                        }
                                                      }}
                                                />


                                                {/* Interview Slot 3 */}
                                                <TextField
                                                    // label="Interview Slot 3"
                                                    type="datetime-local"
                                                    value={interviewSlot3}
                                                    onChange={(e) => setInterviewSlot3(e.target.value)}
                                                    fullWidth
                                                    margin="normal"
                                                    InputLabelProps={{
                                                        style: { color: '#ffffff' }, // Label color
                                                      }}
                                                      InputProps={{
                                                        style: { color: '#ffffff', backgroundColor: '#808080' }, // Input text color and background color (gray)
                                                      }}
                                                      sx={{
                                                        '& .MuiInputBase-root': {
                                                          backgroundColor: '#808080', // Sets the background color of the input field itself
                                                        }
                                                      }}
                                                />
                                            </Box>
                                        )}


                                        {/* Submit Button */}
                                        <Grid container justifyContent="center">
                                            <CustomButton onClick={handlePost} disabled={loading}>
                                                {loading ? <CircularProgress size={24} color="inherit" /> : 'Submit'}
                                            </CustomButton>
                                        </Grid>


                                    </Box>
                                ) : (
                                    <Box>
                                        {submittedData.interview_details && submittedData.interview_details.length > 0 ? (
                                            <Card variant="outlined">
                                                <CardContent>
                                                    <Typography variant="body1" gutterBottom>
                                                        <strong>Interview Date:</strong> {new Date(submittedData.interview_details[0].interview_slot).toLocaleDateString('en-US', {
                                                            year: 'numeric',
                                                            month: 'long',
                                                            day: 'numeric',
                                                        })}
                                                    </Typography>


                                                    {/* Interview Time (AM/PM format) */}
                                                    <Typography variant="body1" gutterBottom>
                                                        <strong>Interview Time:</strong> {new Date(submittedData.interview_details[0].interview_slot).toLocaleTimeString('en-US', {
                                                            hour: '2-digit',
                                                            minute: '2-digit',
                                                            hour12: true  // Ensures AM/PM format
                                                        })}
                                                    </Typography>
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        href={submittedData.interview_details[0].meeting_link}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        Join Now
                                                    </Button>
                                                </CardContent>
                                            </Card>
                                        ) : (
                                            ''
                                        )}


<Typography
    variant="h6"
    color="warning.main"
    gutterBottom
    sx={{
        fontSize: { xs: '1rem', sm: '1.2rem', md: '1.5rem' }, // Adjusts font size based on screen width
        lineHeight: { xs: 1.4, sm: 1.5, md: 1.6 }, // Adjusts line height for readability
        paddingX: { xs: 2, sm: 3, md: 4 }, // Adds horizontal padding for mobile responsiveness
        textAlign: { xs: 'center', sm: 'left' }, // Centers text on small screens and aligns left on larger ones
    }}
>
    You have pending sections to complete. Once verified, you can apply for the next section verification.
</Typography>

                                    </Box>
                                )
                                }
                            </SectionContainer>


                        </div>
                    ) : (

                        <form onSubmit={handleSubmit}>
                            <TextField
                                fullWidth
                                label="Aadhar Number"
                                name="aadhar"
                                value={formData.aadhar}
                                onChange={handleChange}
                                margin="normal"
                                required
                                InputLabelProps={{
                                    style: { color: '#ffffff' },
                                }}
                                InputProps={{
                                    style: { color: '#ffffff' },
                                }}
                            />
                            {/* Display error if Aadhaar is invalid */}
                            {error && error.some(e => e.includes('Aadhaar')) && (
                                <Typography color="error" variant="body2">
                                    {error.find(e => e.includes('Aadhaar'))}
                                </Typography>
                            )}


                            <input
                                type="file"
                                name="aadhar_file"
                                onChange={handleChange}
                                accept="application/pdf,image/*"
                                required
                            />
                            <TextField
                                fullWidth
                                label="Pan Number"
                                name="pan"
                                value={formData.pan}
                                onChange={handleChange}
                                margin="normal"
                                required
                                InputLabelProps={{
                                    style: { color: '#ffffff' },
                                }}
                                InputProps={{
                                    style: { color: '#ffffff' },
                                }}
                            />
                            {/* Display error if PAN is invalid */}
                            {error && error.some(e => e.includes('PAN')) && (
                                <Typography color="error" variant="body2">
                                    {error.find(e => e.includes('PAN'))}
                                </Typography>
                            )}


                            <input
                                type="file"
                                name="pan_file"
                                onChange={handleChange}
                                accept="application/pdf,image/*"
                                required
                            />
                            <TextField
                                fullWidth
                                label="Bank Account Number"
                                name="bank_account"
                                value={formData.bank_account}
                                onChange={handleChange}
                                margin="normal"
                                required
                                InputLabelProps={{
                                    style: { color: '#ffffff' },
                                }}
                                InputProps={{
                                    style: { color: '#ffffff' },
                                }}
                            />
                            {/* Display error if Bank Account is invalid */}
                            {error && error.some(e => e.includes('Bank Account')) && (
                                <Typography color="error" variant="body2">
                                    {error.find(e => e.includes('Bank Account'))}
                                </Typography>
                            )}


                            <input
                                type="file"
                                name="bank_account_file"
                                onChange={handleChange}
                                accept="application/pdf,image/*"
                                required
                            />
                            <TextField
                                fullWidth
                                label="IFSC Code"
                                name="ifsc"
                                value={formData.ifsc}
                                onChange={handleChange}
                                margin="normal"
                                required
                                InputLabelProps={{
                                    style: { color: '#ffffff' },
                                }}
                                InputProps={{
                                    style: { color: '#ffffff' },
                                }}
                            />
                            {/* Display error if IFSC is invalid */}
                            {error && error.some(e => e.includes('IFSC')) && (
                                <Typography color="error" variant="body2">
                                    {error.find(e => e.includes('IFSC'))}
                                </Typography>
                            )}


                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={formData.terms_accepted}
                                        onChange={handleChange}
                                        name="terms_accepted"
                                        sx={{
                                            color: '#ffffff',
                                        }}
                                    />
                                }
                                label="I agree to the terms and conditions and payment policies"
                                sx={{
                                    color: '#ffffff',
                                }}
                            />

                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                fullWidth
                                disabled={!formData.terms_accepted || loading}  // Disable if not checked or if loading
                                sx={{
                                    marginTop: 2,
                                    backgroundColor: '#90caf9',
                                    '&:hover': {
                                        backgroundColor: '#64b5f6',
                                    },
                                }}
                            >
                                {loading ? <CircularProgress size={24} /> : 'Submit'}
                            </Button>
                        </form>
                    )}
                </Box>)}
            </ThemeProvider>
            <Footer2 />
        </div>
    );
};


export default Verification;