import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Box, Typography, CircularProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button } from '@mui/material';

const API_URL = process.env.REACT_APP_API_URL;

function UserPayments() {
  const [userPayments, setUserPayments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch the payable amount data for the current month
  useEffect(() => {
    const fetchUserPayments = async () => {
      try {
        const response = await axios.get(`${API_URL}/list_payables/`);
        setUserPayments(response.data);
      } catch (err) {
        setError("Failed to fetch data. Please try again later.");
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchUserPayments();
  }, []);

  // Handle the payment process
  const handlePay = async (userId) => {
    try {
      const response = await axios.post(`${API_URL}/mark_as_paid/`, { user_id: userId });
      if (response.status === 200) {
        // Update the payment status to reflect the changes in the UI
        setUserPayments((prevData) =>
          prevData.map((user) =>
            user.user_id === userId
              ? { ...user, payable_amount: 0, payment_status: 'paid' }
              : user
          )
        );
      }
    } catch (err) {
      console.error("Error processing payment:", err);
      setError("Failed to process payment. Please try again.");
    }
  };

  // Loading state
  if (loading) {
    return (
      <Box sx={{ textAlign: 'center', marginTop: 5 }}>
        <CircularProgress />
        <Typography variant="h6">Loading payments data...</Typography>
      </Box>
    );
  }

  // Error state
  if (error) {
    return (
      <Box sx={{ textAlign: 'center', marginTop: 5 }}>
        <Typography variant="h6" color="error">{error}</Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ width: '90%', margin: 'auto', paddingTop: 5 }}>
      <Typography variant="h4" gutterBottom align="center" sx={{ fontWeight: 'bold', color: '#3f51b5' }}>
        User Payments for Current Month
      </Typography>

      <TableContainer component={Paper} elevation={3}>
        <Table sx={{ minWidth: 650 }} aria-label="user payments table">
          <TableHead>
            <TableRow>
              <TableCell sx={styles.tableHeader}><strong>User Name</strong></TableCell>
              <TableCell align="left" sx={styles.tableHeader}><strong>Email</strong></TableCell>
              <TableCell align="left" sx={styles.tableHeader}><strong>Bank Account Number</strong></TableCell>
              <TableCell align="left" sx={styles.tableHeader}><strong>IFSC Code</strong></TableCell>
              <TableCell align="left" sx={styles.tableHeader}><strong>Payable Amount</strong></TableCell>
              <TableCell align="left" sx={styles.tableHeader}><strong>Payment Status</strong></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {userPayments.map((user) => (
              <TableRow key={user.user_id} sx={styles.tableRow}>
                <TableCell sx={styles.tableCell}>{user.user_name}</TableCell>
                <TableCell sx={styles.tableCell}>{user.user_email}</TableCell>
                <TableCell sx={styles.tableCell}>{user.bank_account_number}</TableCell> {/* Bank Account */}
                <TableCell sx={styles.tableCell}>{user.ifsc_code}</TableCell> {/* IFSC Code */}
                <TableCell sx={styles.tableCell}>{user.payable_amount}</TableCell>
                <TableCell sx={styles.tableCell}>
                  {user.payment_status === 'not paid' ? (
                    <Button
                      variant="contained"
                      color="primary"
                      sx={styles.payButton}
                      onClick={() => handlePay(user.user_id)}
                    >
                      Pay
                    </Button>
                  ) : (
                    <Typography variant="body2" sx={styles.paidText}>
                      Paid
                    </Typography>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

// Styles
const styles = {
  tableHeader: {
    backgroundColor: '#3f51b5',
    color: '#fff',
    fontWeight: 'bold',
    padding: '15px',
  },
  tableCell: {
    padding: '15px',
    borderBottom: '1px solid #ddd',
  },
  tableRow: {
    '&:hover': {
      backgroundColor: '#00BFFF', // Lighter gray for hover
      cursor: 'pointer',
    },
  },
  payButton: {
    backgroundColor: '#4caf50',
    '&:hover': {
      backgroundColor: '#45a049',
    },
  },
  paidText: {
    color: 'green',
    fontWeight: 'bold',
  }
};

export default UserPayments;
