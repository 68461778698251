import React, { useState, useEffect } from 'react';
import { Box, Typography, Grid, Paper, Button, MenuItem, FormControl, Select, InputLabel, TextField } from '@mui/material';

const API_URL = process.env.REACT_APP_API_URL;

function UpdateSubscription({ userId, onLogout }) {
    const [subscriptions, setSubscriptions] = useState([]);
    const [selectedSubscription, setSelectedSubscription] = useState('');
    const [numberOfPosts, setNumberOfPosts] = useState('');
    const [price, setPrice] = useState('');
    const [loading, setLoading] = useState(false);

    // Fetch subscriptions on page load
    useEffect(() => {
        const fetchSubscriptions = async () => {
            try {
                const response = await fetch(`${API_URL}/list_subscriptions_data/`);
                const data = await response.json();
                setSubscriptions(data);
            } catch (error) {
                console.error('Error fetching subscriptions:', error);
            }
        };

        fetchSubscriptions();
    }, []);

    // Handle the subscription selection
    const handleSelectSubscription = (event) => {
        const subscriptionId = event.target.value;
        setSelectedSubscription(subscriptionId);

        // Find the subscription details by ID to populate the fields
        const selected = subscriptions.find(sub => sub.id === subscriptionId);
        if (selected) {
            setNumberOfPosts(selected.post_count);
            setPrice(selected.price);
        }
    };

    // Handle the update
    const handleUpdateSubscription = async () => {
        if (!selectedSubscription) {
            alert('Please select a subscription');
            return;
        }

        setLoading(true);

        // Create an object to hold the updated data
        const updatedData = {};

        // Only send fields that are not empty
        if (numberOfPosts !== '') {
            updatedData.post_count = numberOfPosts;
        }
        if (price !== '') {
            updatedData.price = price;
        }

        // Send the update request to the backend
        try {
            console.log('valm',updatedData)
            const response = await fetch(`${API_URL}/update_subscription/${selectedSubscription}/`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(updatedData),
            });

            if (response.ok) {
                alert('Subscription updated successfully!');
                setNumberOfPosts('');
                setPrice('');
                setSelectedSubscription('');
            } else {
                const errorData = await response.json();
                alert(`Error updating subscription: ${errorData.message}`);
            }
        } catch (error) {
            console.error('Error updating subscription:', error);
            alert('An error occurred while updating the subscription.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <Box sx={{ p: 4, backgroundColor: '#111', borderRadius: 2, maxWidth: '600px', margin: 'auto', color: 'white' }}>
            <Typography variant="h5" sx={{ mb: 3, color: '#1E90FF', fontWeight: 'bold' }}>
                Update Subscription
            </Typography>

            <FormControl fullWidth sx={{ mb: 3, backgroundColor: '#222', borderRadius: 2 }}>
                <InputLabel id="subscription-select-label" sx={{ color: 'white' }}>Select Subscription</InputLabel>
                <Select
                    labelId="subscription-select-label"
                    value={selectedSubscription}
                    label="Select Subscription"
                    onChange={handleSelectSubscription}
                    sx={{
                        backgroundColor: '#333',
                        color: 'white',
                        '& .MuiSelect-icon': { color: 'white' },
                    }}
                >
                    {subscriptions.map((sub) => (
                        <MenuItem key={sub.id} value={sub.id}>
                            {sub.plan_name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>

            <TextField
                label="Number of Posts"
                variant="outlined"
                fullWidth
                type="number"
                value={numberOfPosts}
                onChange={(e) => setNumberOfPosts(e.target.value)}
                sx={{
                    mb: 3,
                    backgroundColor: '#333',
                    '& .MuiInputBase-root': { color: 'white' },
                    '& .MuiOutlinedInput-notchedOutline': { borderColor: '#1E90FF' },
                }}
            />

            <TextField
                label="Price"
                variant="outlined"
                fullWidth
                value={price}
                onChange={(e) => setPrice(e.target.value)}
                sx={{
                    mb: 3,
                    backgroundColor: '#333',
                    '& .MuiInputBase-root': { color: 'white' },
                    '& .MuiOutlinedInput-notchedOutline': { borderColor: '#1E90FF' },
                }}
            />

            <Button
                variant="contained"
                onClick={handleUpdateSubscription}
                sx={{
                    bgcolor: '#1E90FF',
                    '&:hover': { bgcolor: '#0066cc' },
                    width: '100%',
                }}
                disabled={loading}
            >
                {loading ? 'Updating...' : 'Update Subscription'}
            </Button>
        </Box>
    );
}

export default UpdateSubscription;
