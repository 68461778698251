import React, { useRef, useState, useEffect } from 'react';
import { Box, Typography, Container, Button, Grid, AppBar, Toolbar, useTheme, useMediaQuery, CardContent, ThemeProvider, createTheme, Card, Snackbar } from '@mui/material';
import { useNavigate, Link } from 'react-router-dom';
import LogoIcon from '../Icon/v6.mp4'; // Ensure this video file is accessible
import How_to_make_post from './How_to_make_post.mp4'
import Logo from '../Logo/Dark/Full - Copy/1Asset 17@72x-8.png';
import Header from '../Header/Header';
import Header1 from '../Header/Header1';
import Header2 from '../Header/Header2';
import Footer from '../Footer/Footer';
import Teach from '../Icon/Teach.png';
import Learn from '../Icon/Learn4.png';
import Monetize from '../Icon/Monitize2.png';
import Growth from '../Icon/Growth.png';
import Announcement from '../Icon/Announce3.png';
import Collaborative from '../Icon/Collaborate2.png';
import Creative from '../Icon/Creative.png';
import AI from '../Icon/AI.png';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from 'react-slick';
import Real_world from '../Icon/Real_world.png';
import Practical_skill from '../Icon/Practical_skill.png';
import Learning from '../Icon/Learning.png';
import Time from '../Icon/Time.png';
import VisionBackground from '../Icon/Vission_latest.png';
import MissionBackground from '../Icon/mi1.png';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { IconButton } from '@mui/material';
import { FaCogs, FaDiscord } from 'react-icons/fa';
import { motion } from 'framer-motion';  // For animations
import { AiOutlineRobot } from 'react-icons/ai';
import { FaBrain, FaAndroid } from 'react-icons/fa';
import {
    Language, Lock, LockOpen, Lightbulb, Handshake, Settings, Share, Verified, Chat, AccessTime,
    Public, AccountCircle, ShoppingCart, Star, PhoneAndroid, School, MonetizationOn, Group,
    TrendingUp, DeviceHub, Dashboard, Article, LibraryBooks, Assistant, Close as CloseIcon
} from '@mui/icons-material';
import DemoVideoSection from './DemoVideoSection';



const API_URL = process.env.REACT_APP_API_URL;



function Home({ userId, loggedIn, onLogout }) {
    const logoIconRef = useRef(null);
    const [activeIndex, setActiveIndex] = useState(0); // State to track the active box


    const [isSurveyCardOpen, setIsSurveyCardOpen] = useState(false); // State to manage visibility of the card

  // Check if the user has already seen the survey by checking localStorage
  useEffect(() => {
    const hasSeenSurvey = localStorage.getItem('hasSeenSurvey');
    if (!hasSeenSurvey) {
      setIsSurveyCardOpen(true); // Show the survey card if not seen before
    }
  }, []);

  const handleCloseCard = () => {
    setIsSurveyCardOpen(false); // Close the floating card
    localStorage.setItem('hasSeenSurvey', 'true'); // Mark that the user has seen the survey
  };

  const handleSurveyLink = () => {
    window.open('https://forms.gle/XC8VRUjwie1Ex5ev6', '_blank'); // Opens the survey link in a new tab
    handleCloseCard(); // Close the floating card once the link is clicked
  };



    const features1 = [
        {
            title: 'First of its Kind',
            description: 'Our platform is completely unique.',
            icon: <Assistant />,
        },
        {
            title: 'Easy to Use',
            description: 'Designed with simplicity in mind for a seamless experience.',
            icon: <Settings />,
        },
        {
            title: 'Safe & Secure',
            description: 'Top-notch security measures to keep your data safe and private.',
            icon: <Lock />,
        },
        {
            title: 'Multilingual',
            description: 'Supports multiple languages, breaking barriers for both global and local native users.',
            icon: <Language />,
        },
        {
            title: '3As (Anytime, Anywhere, & Anyone)',
            description: 'Anyone can access the platform from anywhere, at any time, on any device.',
            icon: <Public />,
        },


        {
            title: 'No Limits on Knowledge Sharing',
            description: 'Share and access knowledge without restrictions.',
            icon: <Lightbulb />,
        },
        {
            title: 'Built for All Sections of Society',
            description: 'Our platform is accessible and inclusive for everyone.',
            icon: <Handshake />,
        },

        {
            title: 'Freedom to Choose',
            description: 'Take control of your experience with personalized options.',
            icon: <LockOpen />,
        },

        {
            title: 'Standard User Verification',
            description: 'Ensure trust and authenticity with reliable verification methods.',
            icon: <Verified />,
        },
        {
            title: 'Connect with Like-Minded',
            description: 'Find and engage with others who share your interests and goals.',
            icon: <Chat />,
        },
        {
            title: 'Save Time & Money',
            description: 'Efficient solutions that help you make the most of your time and resources.',
            icon: <AccessTime />,
        },
        {
            title: 'Learn',
            description: 'Use our platform to expand your skills at your own pace.',
            icon: <School />,
        },
        {
            title: 'Share',
            description: 'Share your experiences, knowledge, and content to expand your network and increase your impact.',
            icon: <Share />,
        },
        {
            title: 'Earn',
            description: 'Gain income, rewards, recognition, and opportunities by sharing your talent and knowledge through your contributions and activities.',
            icon: <MonetizationOn />,
        },

        // {
        //     title: 'Connect with Humans',
        //     description: 'Our platform focuses on fostering genuine human connections.',
        //     icon: <AccountCircle />,
        // },



        {
            title: 'AI Agents',
            description: ' Intelligent virtual agents, fine-tuned for specific use cases, designed to enhance your learning experience.',
            icon: <DeviceHub />,
        },
        {
            title: 'AI Assistants',
            description: 'Leverage AI-powered assistants to help with tasks, suggestions, and more.',
            icon: <Assistant />,
        },
        {
            title: 'ML-based Recommendations',
            description: 'Machine learning algorithms deliver personalized recommendations to enhance your experience.',
            icon: <Dashboard />,
        },
        {
            title: 'Chatbots',
            description: 'Leverage intelligent chatbots to provide instant support, answer questions, and enhance user engagement.',
            icon: <Assistant />,
        },
        {
            title: 'Data Visuals',
            description: 'Interactive and insightful data visuals that help you understand key metrics and trends.',
            icon: <TrendingUp />,
        },

        {
            title: 'Secure Payment Options',
            description: 'Reliable and encrypted payment options for safe and smooth transactions.',
            icon: <LibraryBooks />,
        },
        {
            title: 'Standard User Ratings',
            description: 'Empowering users to provide consistent, reliable feedback to help maintain quality and trust.',
            icon: <Star />,
        },
        {
            title: 'Compatible with Any Device',
            description: 'No matter which device you use, our platform works seamlessly.',
            icon: <PhoneAndroid />,
        },
        {
            title: 'Customer Support',
            description: 'Experience exceptional customer support with fast, personalized assistance to resolve your issues and ensure satisfaction.',
            icon: <Assistant />,
        },
        {
            title: 'Experience-Based Upgradation',
            description: 'Upgrade your access and features based on your usage and level of engagement.',
            icon: <TrendingUp />,
        },
        {
            title: 'Real-time Notifications',
            description: 'Receive instant updates on activities, events, or changes.',
            icon: <Article />,
        },
        {
            title: 'One-Stop Solution',
            description: 'Access all your knowledge needs in one place, with ease and user-friendly design.',
            icon: <ShoppingCart />,
        },

        {
            title: 'Subscription Plans',
            description: 'Flexible and easy-to-understand subscription plans that suit your needs and budget.',
            icon: <MonetizationOn />,
        },


        // New features with Material UI icons
        {
            title: 'Communities',
            description: 'Join and interact with communities based on shared interests and goals.',
            icon: <Group />,
        },
        {
            title: 'Blogs',
            description: 'Read and contribute to insightful blogs on various topics of interest.',
            icon: <Article />,
        },
        {
            title: 'Resources',
            description: 'Access a variety of resources designed to help you grow and succeed.',
            icon: <LibraryBooks />,
        },




    ];



    // Dark Theme Configuration
    const darkTheme = createTheme({
        palette: {
            mode: 'dark', // Set the theme to dark
            primary: {
                main: '#90caf9', // Light blue for primary color
            },
            background: {
                default: '#121212', // Dark background color
                paper: '#1e1e1e', // Card background
            },
            text: {
                primary: '#fff', // White text on dark background
                secondary: '#b0b0b0', // Light gray text for secondary content
            },
        },
        typography: {
            h6: {
                fontWeight: 600,
            },
            body2: {
                color: '#b0b0b0', // Light gray text for body text
            },
        },
    });




    // Your Discord invite URL (replace with your actual invite link)
    const discordInviteURL = 'https://discord.gg/9PFJf4gBJ8';

    const announcements = [
        {
            title: 'Launch Update',
            message: 'The platform has been launched on 26th January 2025.',
            fullMessage: 'The platform will be available to all users shortly. We will be continuosly updating the platform with new features.',
            link: '#'
        },
        {
            title: 'Scheduled Maintenance',
            message: 'The website will be under maintenance on 2nd February 2025 at 1 AM IST.',
            fullMessage: 'During this period, all services will be temporarily unavailable. We expect the maintenance to take around 2 hours.',
            link: '#'
        },
        {
            title: 'Hiring Update',
            message: 'The careers page is live now.',
            fullMessage: 'All the interested people can apply for various roles based on your interests and availability.',
            link: '#'
        }
    ];

    const [expandedIndex, setExpandedIndex] = useState(null); // Track which card is expanded

    const handleToggleExpand = (index) => {
        // Toggle expand state for the clicked card
        setExpandedIndex(expandedIndex === index ? null : index);
    };

    const [open, setOpen] = useState(true);

    const handleClose = () => setOpen(false);


    const scrollToSection = (id) => {
        document.getElementById(id).scrollIntoView({ behavior: 'smooth' });
    };
    // const phrase = "Anthrasync Welcomes You!";
    // const words = ["Anthrasync", "Welcomes", "You!"];

    // const renderLetters = (word) => {
    //     return word.split("").map((char, index) => (
    //         <Typography
    //             key={index}
    //             variant="h3"
    //             className="letter"
    //             style={{ animationDelay: `${index * 0.1}s` }} // Delay for each letter
    //         >
    //             {char}
    //         </Typography>
    //     ));
    // };

    const navigate = useNavigate(); // Initialize navigate

    const handleLearnMoreClick = () => {
        navigate('/home'); // Replace '/content' with the path to your content page
    };


    useEffect(() => {
        console.log(loggedIn)
        console.log(userId)
        // Add animation logic here if needed
    }, []);

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1, // Show one box at a time
        slidesToScroll: 1,
        arrows: false, // You can set to true if you want navigation arrows
        afterChange: (current) => {
            console.log("Current slide: ", current);
        },
        // Add custom CSS for the 'floating wave' effect
        responsive: [
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    const features = [
        {
            title: 'Teach',
            description: "Teaching what you are passionate about in language of your choice can be incredibly rewarding.",
            image: Teach,
        },
        {
            title: 'Learn',
            description: "Learn what you want in language of your choice at your own pace and time.",
            image: Learn,
        },
        {
            title: 'Monetize',
            description: "Monetizing your knowledge can open up new doors of opportunities and enable financial freedom.",
            image: Monetize,
        },
        {
            title: 'Connect',
            description: "You can connect with like-minded individuals based on your interests, hence become part of thriving community.",
            image: Collaborative,
        },
        {
            title: 'Grow',
            description: "Sharing your knowledge helps others and reinforces your own understanding.",
            image: Growth,
        },
        {
            title: 'Crucial Learning',
            description: "Learning through hands-on experiences and real-life examples is crucial for personal growth.",
            image: Creative,
        },]

    const benefits = [
        {
            title: "Real-World Info",
            description: "Learn from people who have hands-on experience, not just theoretical knowledge.",
            image: Real_world, // Add an image for each benefit
        },
        {
            title: "Utilise Time",
            description: "Direct mentorship and personalised learning paths eliminate unnecessary internet searching.",
            image: Time,
        },
        {
            title: "Be Practical",
            description: "Focussing on what works in the real world, makes you more capable in your field.",
            image: Practical_skill,
        },
        {
            title: "Passion-Driven",
            description: "Bring your passion to life. As a passionate educator you can offer more engaging and valuable sessions.",
            image: Learning,
        },
    ];

    const sliderSettings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 3000,
        appendDots: dots => (
            <div
                style={{
                    position: 'absolute',
                    bottom: window.innerWidth < 600 ? '-20px' : window.innerWidth < 960 ? '-25px' : '-30px',
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                }}
            >
                <ul style={{
                    margin: '0px',
                    padding: '0px',
                    display: 'flex',
                    gap: window.innerWidth < 600 ? '1px' : '5px' // Smaller gap for small screens
                }}>
                    {dots}
                </ul>
            </div>
        ),

        customPaging: () => (
            <div
                style={{
                    width: '12px',
                    height: '12px',
                    borderRadius: '50%',
                    backgroundColor: 'white',
                    opacity: 0.5,
                }}
            />
        )
    };

    return (
        <div style={{backgroundColor:"black"}}>
            {/* Conditional rendering of Header or Header1 */}
            {loggedIn ? (
                <Header1 style={{ display: 'flex', justifyContent: 'center', textAlign: 'center', alignItems: 'center' }} userId={userId} onLogout={onLogout} />
            ) : (
                <Header style={{ display: 'flex', justifyContent: 'center', textAlign: 'center', alignItems: 'center' }} />
            )}
            <div style={{
                backgroundColor: 'black',
                height: '100%',
                // minHeight: '100vh',
                position: 'fixed',
                width: '100%',
                zIndex: -1,
            }}></div>
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{
                    height: {
                        xs: '60vh',
                        sm: '80vh',
                        md: '90vh',
                        lg: '90vh',
                        xl: '90vh',
                    },
                    position: 'relative',
                    zIndex: 1,
                    textAlign: 'center',
                    overflow: 'hidden',
                    mb: 12,
                    marginTop: '100px',
                }}
            >
                <Box
                    ref={logoIconRef}
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: {
                            xs: '80%',
                            sm: '70%',
                            md: '60%',
                        },
                        maxHeight: '80%',
                        height: 'auto',
                        opacity: 1,
                        transition: 'opacity 0.5s ease, box-shadow 0.3s ease',
                        zIndex: 2,
                        borderRadius: '30px', // Increased curvature
                        overflow: 'hidden', // Ensures the video fits the border radius
                        '&:hover': {
                            boxShadow: '0 0 15px 5px #1E90FF', // Blue glow on hover
                        },
                    }}
                >

                    <img
                                                    src={Logo}
                                                    alt="Logo"
                                                    style={{ width: '600px', height: 'auto', maxWidth: '100%' }}
                                                />
                    {/* <video
                        src={LogoIcon}
                        autoPlay
                        muted
                        loop
                        style={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover',
                            border: 'none',
                            outline: 'none',
                        }}
                    /> */}
                </Box>
            </Box>
            {/* <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '50px' }}>
                {words.map((word, index) => (
                    <div key={index} style={{ margin: '0 20px' }}> 
                        <div style={{ display: 'inline-flex' }}>
                            {renderLetters(word)}
                        </div>
                    </div>
                ))}
                <div style={{ height: '500px' }} />
            </div> */}


            {/* <Container sx={{ my: 4, display: 'flex', flexDirection: 'column', alignItems: 'center', color: 'black' }}> */}
            {/* Mission Box */}
            <Typography
                variant="h5"
                component="h5"
                gutterBottom
                sx={{
                    mb: 4,
                    fontFamily: 'Helvetica, sans-serif', // Changed to Helvetica
                    color: 'white',
                    textAlign: 'center',
                    // marginTop: '10px',
                    marginBottom: '10px',
                    letterSpacing: 2,
                    // margin:'150',
                    fontSize: { xs: '28px', sm: '36px' }, // Responsive font size
                    '&:hover': {
                        color: '#1E90FF', // Change font color to blue on hover
                    },
                }}
            >
                Anthrasync welcomes you!
            </Typography>




            <Box sx={{ display: 'flex', justifyContent: 'center', my: 2 }}>
            {/* Custom Navbar container */}
            <Box
                sx={{
                    backgroundColor: 'black',
                    borderRadius: '16px',
                    boxShadow: 'none',
                    width: isSmallScreen ? '60%' : '37%', // Responsive width
                    border: '2px solid transparent', // Default border color
                    transition: 'border-color 0.3s', // Smooth transition for border color
                    '&:hover': {
                        borderColor: '#1E90FF', // Change border color on hover
                    },
                    display: 'flex',
                    flexDirection: isSmallScreen ? 'column' : 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexWrap: 'wrap', // Allow the items to wrap in small screens
                    padding: '8px', // Add some padding around the buttons
                }}
            >
                {/* Buttons */}
                <Button
                    color="inherit"
                    onClick={() => scrollToSection('mission')}
                    sx={{
                        letterSpacing: 1,
                        '&:hover': {
                            color: '#1E90FF', // Change font color to blue on hover
                            mb: isSmallScreen ? 1 : 0, // Add margin-bottom for spacing in small screens
                            width: isSmallScreen ? '100%' : 'auto', // Adjust width for small screens
                        },
                        marginBottom: isSmallScreen ? 1 : 0,
                    }}
                >
                    Mission & Vision
                </Button>

                <Button
                    color="inherit"
                    onClick={() => scrollToSection('glimpse')}
                    sx={{
                        letterSpacing: 1,
                        '&:hover': {
                            color: '#1E90FF', // Change font color to blue on hover
                            mb: isSmallScreen ? 1 : 0, // Add margin-bottom for spacing in small screens
                            width: isSmallScreen ? '100%' : 'auto', // Adjust width for small screens
                        },
                        marginBottom: isSmallScreen ? 1 : 0,
                    }}
                >
                    Glimpse
                </Button>

                <Button
                    color="inherit"
                    onClick={() => scrollToSection('ai')}
                    sx={{
                        letterSpacing: 1,
                        '&:hover': {
                            color: '#1E90FF', // Change font color to blue on hover
                        },
                        marginBottom: isSmallScreen ? 1 : 0,
                        width: isSmallScreen ? '100%' : 'auto', // Adjust width for small screens
                    }}
                >
                    AI Sync
                </Button>

                <Button
                    color="inherit"
                    onClick={() => scrollToSection('announcements')}
                    sx={{
                        letterSpacing: 1,
                        '&:hover': {
                            color: '#1E90FF', // Change font color to blue on hover
                        },
                        marginBottom: isSmallScreen ? 1 : 0,
                        width: isSmallScreen ? '100%' : 'auto', // Adjust width for small screens
                    }}
                >
                    Announcements
                </Button>

                <Button
                    color="inherit"
                    onClick={() => scrollToSection('community')}
                    sx={{
                        letterSpacing: 1,
                        '&:hover': {
                            color: '#1E90FF', // Change font color to blue on hover
                        },
                        marginBottom: isSmallScreen ? 1 : 0,
                        width: isSmallScreen ? '100%' : 'auto', // Adjust width for small screens
                    }}
                >
                    Community
                </Button>
            </Box>
        </Box>







            <Container
                sx={{
                    my: { xs: 40, sm: 8, md: 12 },
                    color: 'black',
                    maxWidth: '1000px',
                    margin: '0 auto',
                    marginBottom: '300px',
                    width: '100%',
                    padding: { xs: 1, sm: 2, md: 3 },
                }}
            >

                <Typography
                    variant="h5"
                    sx={{
                        color: 'white',
                        marginBottom: theme.spacing(3),
                        fontWeight: 400,
                        textAlign: 'center',
                        letterSpacing: 2,
                        '&:hover': {
                            color: '#1E90FF',
                        },
                    }}
                >
                    MISSION & VISSION
                </Typography>
                <Slider {...sliderSettings} style={{ width: '100%', padding: 0, margin: 0 }}>
                    {/* Mission Box */}
                    <Box id="mission"
                        sx={{
                            backgroundImage: `url(${MissionBackground})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                            padding: { xs: '6px', sm: '12px', md: '24px' },
                            color: 'white',
                            width: { xs: '50%', sm: '50%', md: '100%' },
                            height: { xs: '180px', sm: '250px', md: '400px' },
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                            overflow: 'hidden',
                            transition: 'border 0.3s ease',
                            margin: '0 auto',
                        }}
                    >
                        <Box sx={{ flexGrow: 1 }} />
                        <Grid container spacing={2} sx={{ pb: { xs: '5px', sm: '10px' }, justifyContent: 'center', alignItems: 'center' }}>
                            <Grid item xs={12} sm={6} sx={{ textAlign: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center', gap: 3, mt: { xs: 2, sm: 4, md: 6, lg: 8 } }}>
                                <Typography
                                    variant="h5"
                                    sx={{
                                        fontFamily: 'Helvetica, sans-serif',
                                        fontSize: {
                                            xs: '1.3rem',
                                            sm: '1.8rem',
                                            md: '2.2rem',
                                            lg: '2.6rem',    // Larger font size for large screens
                                            xl: '3rem'       // Extra-large font size for extra-large screens
                                        },
                                        // Increased font size for header
                                        mb: { xs: 0.5, sm: 1 },
                                        mt: { xs: 0.5, sm: 1 },
                                        textAlign: 'center',
                                        fontWeight: 'bold',
                                    }}
                                >
                                    Mission
                                </Typography>
                                <Typography
                                    variant="body1"
                                    sx={{
                                        fontFamily: 'Helvetica, sans-serif',
                                        fontSize: {
                                            xs: '0.9rem',
                                            sm: '1.1rem',
                                            md: '1.3rem',
                                            lg: '1.6rem',    // Larger font size for large screens
                                            xl: '1.8rem'     // Extra-large font size for extra-large screens
                                        },
                                        // Increased font size for body text
                                        textAlign: 'center',
                                    }}
                                >
                                    To provide a platform for every individual to access and share all kinds of knowledge.
                                </Typography>
                            </Grid>
                        </Grid>
                    </Box>

                    {/* Vision Box */}
                    <Box
                        sx={{
                            backgroundImage: `url(${VisionBackground})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                            padding: { xs: '6px', sm: '12px', md: '24px' },
                            color: 'white',
                            width: { xs: '50%', sm: '50%', md: '100%' },
                            height: { xs: '180px', sm: '250px', md: '400px' },
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                            overflow: 'hidden',
                            transition: 'border 0.3s ease',
                            margin: '0 auto',
                        }}
                    >
                        <Box sx={{ flexGrow: 1 }} />
                        <Grid container spacing={2} sx={{ pb: { xs: '5px', sm: '10px' }, justifyContent: 'center', alignItems: 'center' }}>
                            <Grid item xs={12} sm={6} sx={{ textAlign: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center', gap: 3, mt: { xs: 2, sm: 4, md: 6, lg: 8 } }}>
                                <Typography
                                    variant="h4"
                                    sx={{
                                        fontFamily: 'Helvetica, sans-serif',
                                        fontSize: {
                                            xs: '1.3rem',
                                            sm: '1.8rem',
                                            md: '2.2rem',
                                            lg: '2.6rem',    // Larger font size for large screens
                                            xl: '3rem'       // Extra-large font size for extra-large screens
                                        }, // Increased font size for header
                                        mb: { xs: 0.5, sm: 1 },
                                        mt: { xs: 0.5, sm: 1 },
                                        textAlign: 'center',
                                        fontWeight: 'bold',
                                    }}
                                >
                                    Vision
                                </Typography>
                                <Typography
                                    variant="body1"
                                    sx={{
                                        fontFamily: 'Helvetica, sans-serif',
                                        fontSize: {
                                            xs: '0.9rem',
                                            sm: '1.1rem',
                                            md: '1.3rem',
                                            lg: '1.6rem',    // Larger font size for large screens
                                            xl: '1.8rem'     // Extra-large font size for extra-large screens
                                        }, // Increased font size for body text
                                        textAlign: 'center',
                                    }}
                                >
                                    To make every kind of knowledge accessible for everyone.
                                </Typography>
                            </Grid>
                        </Grid>
                    </Box>
                </Slider>


            </Container>

            {/* To make every kind of knowledge accessible for everyone. */}
            {/* <div style={{
                position: 'relative',
                minHeight: '100vh',
                overflowY: 'scroll',
                paddingTop: '10vh',
                display: 'flex',
                justifyContent: 'center', // Center horizontally
                alignItems: 'center', // Center vertically
                flexDirection: 'column',
            }}> */}
            {/* <Container sx={{ my: 4, display: 'flex', flexDirection: 'column', alignItems: 'center', color: 'black', margin: 10 }}>
                    <Container id="features"
                        sx={{
                            my: 4,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            color: 'black',
                            width: '80%',
                            maxWidth: '800px',
                            margin: '0 auto',
                        }}
                    > */}
            {/* <Typography
                            variant="h4"
                            component="h4"
                            gutterBottom
                            sx={{
                                mb: 4,
                                fontFamily: 'Helvetica, sans-serif', // Changed to Helvetica
                                color: 'white',
                                textAlign: 'center',
                                marginTop: '50px',
                                fontSize: { xs: '28px', sm: '36px' }, // Responsive font size
                                '&:hover': {
                                    color: '#1E90FF', // Change font color to blue on hover
                                },
                            }}
                        >
                            Anthrasync Welcomes You!
                        </Typography> */}

            {/* Grid for Features */}
            {/* <Grid container spacing={4} justifyContent="center">
                            {features.map((feature) => (
                                <Grid item key={feature.title} xs={12} sm={6} md={4}>
                                    <Card
                                        sx={{
                                            padding: '20px',
                                            borderRadius: '12px',
                                            boxShadow: '0 4px 15px rgba(0, 0, 0, 0.1)',
                                            textAlign: 'center',
                                            height: '400px',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'space-between',
                                            backgroundColor: '#1C1C1C',
                                            color: 'white',
                                            border: '2px solid transparent',
                                            '&:hover': {
                                                transform: 'scale(1.05)',
                                                borderColor: '#1E90FF',
                                                boxShadow: '0 4px 15px rgba(0, 0, 255, 0.5)',
                                            },
                                            transition: 'transform 0.3s ease, border-color 0.3s ease',
                                        }}
                                    >
                                        {feature.image && (
                                            <img
                                                src={feature.image}
                                                alt={feature.title}
                                                loading="lazy"
                                                style={{
                                                    width: '100%',
                                                    height: '200px',
                                                    objectFit: 'cover',
                                                    borderRadius: '8px',
                                                    marginBottom: '15px',
                                                }}
                                            />
                                        )}
                                        <Typography
                                            variant="h5"
                                            sx={{
                                                fontFamily: 'Helvetica, sans-serif', // Changed to Helvetica
                                                mt: 2,
                                                color: 'white',
                                                fontSize: { xs: '20px', sm: '24px' }, // Responsive font size
                                                textAlign: 'center',
                                                flexGrow: 0,
                                                '&:hover': {
                                                    color: '#1E90FF', // Change font color to blue on hover
                                                },
                                            }}
                                        >
                                            {feature.title}
                                        </Typography>
                                        <Typography
                                            variant="body2"
                                            sx={{
                                                fontFamily: 'Helvetica, sans-serif', // Changed to Helvetica
                                                color: 'white',
                                                flexGrow: 1,
                                                display: 'flex',
                                                justifyContent: 'left',
                                                alignItems: 'left',
                                                fontSize: { xs: '0.875rem', sm: '1rem' }, // Responsive font size
                                                textAlign: 'left',
                                                padding: '0 10px',
                                            }}
                                        >
                                            {feature.description}
                                        </Typography>
                                    </Card>
                                </Grid>
                            ))}
                        </Grid>
                    </Container>
                </Container>
            </div>

            <Typography id="benefits"
                variant="h4"
                sx={{
                    mt: { xs: 10, sm: 15, md: 22 }, // Responsive top margin
                    mb: { xs: 4, sm: 6, md: 8 }, // Responsive bottom margin
                    fontFamily: 'Helvetica, sans-serif', // Changed to Helvetica
                    color: 'white',
                    textAlign: 'center',
                    fontSize: { xs: '24px', sm: '36px', md: '48px' }, // Responsive font size
                    '&:hover': {
                        color: '#1E90FF', // Change font color to blue on hover
                    },

                }}
            >
                Benefits
            </Typography> */}

            {/* Horizontal Sliding Benefit Boxes */}
            {/* <Container sx={{ mb: 20, width: '80%', mx: 'auto' }}>
                <Grid container spacing={{ xs: 6, sm: 8, md: 10 }} justifyContent="center"> {/* Increased spacing for more gap */}
            {/* {benefits.map((benefit, index) => (
                <Grid item xs={12} sm={6} md={4} lg={3} key={index}> {/* Adjusted for larger screens */}
            {/* <Box
                        onMouseEnter={() => setActiveIndex(index)}
                        sx={{
                            backgroundColor: '#1C1C1C',
                            color: 'white',
                            borderRadius: '8px',
                            padding: { xs: '10px', sm: '15px', md: '20px' },
                            boxShadow: activeIndex === index
                                ? '0 0 15px 5px #1E90FF'
                                : '0 4px 15px #1E90FF',
                            textAlign: 'left',
                            width: { xs: '90%', sm: '80%', md: '75%', lg: '95%', xl: '95%' }, // Increase width for larger screens
                            height: { xs: 'auto', sm: 'auto', md: 'auto', lg: '90%', xl: '90%' },
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            transition: 'all 0.3s ease',
                            transform: activeIndex === index ? 'scale(1.05)' : 'scale(1)',
                            position: 'relative',
                            zIndex: activeIndex === index ? 1 : 0,
                            margin: { xs: '5px auto', sm: '10px auto', md: '15px auto' }, // Centering with auto margin
                        }}
                    > */}
            {/* Image */}
            {/* <img
                                    src={benefit.image}
                                    alt={`${benefit.title} Image`}
                                    style={{
                                        width: '100%',
                                        maxHeight: '120px',
                                        objectFit: 'cover',
                                        borderRadius: '8px',
                                        marginBottom: '20px',
                                    }}
                                /> */}

            {/* Title */}
            {/* <Typography variant="h6" sx={{
                                    fontFamily: 'Helvetica, sans-serif', mb: 1, textAlign: 'center',
                                    '&:hover': {
                                        color: '#1E90FF', // Change font color to blue on hover
                                    },
                                }}>
                                    {benefit.title}
                                </Typography> */}

            {/* Description */}
            {/* <Typography variant="body2" sx={{ fontFamily: 'Helvetica, sans-serif', fontSize: '1rem', textAlign: 'left' }}>
                                    {benefit.description}
                                </Typography>
                            </Box>
                        </Grid>
                    ))}
                </Grid>
            </Container> */}

            <DemoVideoSection/>



            <ThemeProvider theme={darkTheme}>
                <Box
                    id="glimpse"
                    sx={{
                        flexGrow: 1,
                        padding: { xs: 2, sm: 5 }, // Adjust padding for different screen sizes
                        backgroundColor: '#000000',
                        margin: { xs: 2, sm: 10 },
                        marginTop: { xs: 2, sm: 50 }, // Adjust margin-top for smaller screens
                    }}
                >
                    {/* Section Heading */}
                    <Typography
                        variant="h4"
                        sx={{
                            color: 'white',
                            textAlign: 'center',
                            marginBottom: 4,
                            letterSpacing: 5,
                            '&:hover': {
                                color: '#1E90FF', // Change font color to blue on hover
                            },
                        }}
                    >
                        GLIMPSE OF OUR PLATFORM OFFERINGS
                    </Typography>

                    {/* Feature Grid */}
                    <Grid container spacing={4}>
                        {features1.map((feature, index) => (
                            <Grid item xs={12} sm={6} md={4} key={index}>
                                <Card
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        borderRadius: 2,
                                        boxShadow: 3,
                                        border: '2px solid transparent',
                                        transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                                        backgroundColor: '#000000',
                                        '&:hover': {
                                            transform: 'translateY(-10px)',
                                            boxShadow: 6,
                                            borderColor: '#1E90FF',
                                        },
                                    }}
                                >
                                    <CardContent sx={{ textAlign: 'center' }}>
                                        <Typography variant="h4" sx={{ color: '#1E90FF', marginBottom: 2 }}>
                                            {feature.icon}
                                        </Typography>
                                        <Typography
                                            variant="h5"
                                            sx={{
                                                marginBottom: 1,
                                                color: 'white',
                                                letterSpacing: 1,
                                                '&:hover': {
                                                    color: '#1E90FF', // Change font color to blue on hover
                                                },
                                            }}
                                        >
                                            {feature.title}
                                        </Typography>
                                        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                            {feature.description}
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                </Box>
            </ThemeProvider>



            {/* <Container
                maxWidth="lg"
                disableGutters
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    alignItems: "center",
                    height: "80vh", // Reduced height
                    width: "90%", // Reduced width
                    backgroundImage: `url(${AI})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    color: "#fff",
                    textAlign: "center",
                    position: "relative",
                    overflow: "hidden",
                    mb: 20,
                    border: '2px solid transparent', // Default border
                    transition: 'border-color 0.3s ease',
                    // '&:hover': {
                    //     borderColor: '#1E90FF', // Blue border on hover
                    // },
                }}
            > */}
            {/* Overlay */}
            {/* <Box
                    sx={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        backgroundColor: "rgba(0, 0, 0, 0.6)", // Dark overlay for contrast
                        zIndex: 1,
                    }}
                ></Box> */}

            {/* Content */}
            {/* <Box id="ai" sx={{ position: "relative", zIndex: 2, padding: 3, flex: 1, display: "flex", flexDirection: "column", justifyContent: "center" }}>
                    <Typography variant="h2" sx={{
                        fontFamily: 'Helvetica, sans-serif', fontWeight: "bold", mb: 2,
                        '&:hover': {
                            color: '#1E90FF', // Change font color to blue on hover
                        },
                    }}>
                        AI Sync
                    </Typography>
                    <Typography variant="h5" sx={{
                        fontFamily: 'Helvetica, sans-serif', mb: 4, '&:hover': {
                            color: '#1E90FF', // Change font color to blue on hover
                        },
                    }}>
                        Experience AI Like Never Before.
                    </Typography>
                </Box> */}

            {/* Button at the bottom */}
            {/* Uncomment if you want to add a button */}
            {/* <Box sx={{ padding: 3, mb: 2, position: 'relative', zIndex: 2 }}>
                <Link to="/ai-content" style={{ textDecoration: 'none' }}>
                    <Box
                        component="span"
                        sx={{
                            display: "inline-block",
                            backgroundColor: "#fff",
                            color: "#000",
                            fontWeight: "bold",
                            padding: "10px 30px",
                            borderRadius: "25px",
                            ":hover": { backgroundColor: "#ddd" },
                            cursor: "pointer",
                        }}
                    >
                        Learn more
                    </Box>
                </Link>
            </Box> */}
            {/* </Container> */}





            <Box id="ai"
                sx={{
                    backgroundColor: '#000000', // Dark background for AI section
                    padding: theme.spacing(5),
                    borderRadius: '10px',
                    color: 'white',
                    boxShadow: 5,
                    marginTop: 50,
                    marginBottom: theme.spacing(50), // Space after the section
                }}
            >
                {/* Heading Section */}
                <motion.div
                    initial={{ opacity: 0, y: -50 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 1 }}
                >
                    <Typography
                        variant="h5"
                        sx={{
                            fontWeight: 500,
                            textAlign: 'center',
                            fontSize: '3rem',
                            // color: 'linear-gradient(135deg, #5e4b8b, #7c4dff)',
                            // background: 'linear-gradient(135deg, #5e4b8b, #7c4dff)',
                            WebkitBackgroundClip: 'text',
                            // color: 'transparent',
                            color: 'white',
                            letterSpacing: 4,
                            letterSpacing: 2,
                            marginBottom: theme.spacing(2),
                            '&:hover': {
                                color: '#1E90FF',
                            },
                        }}
                    >
                        AI SYNC
                    </Typography>
                </motion.div>

                {/* Subheading */}
                <motion.div
                    initial={{ opacity: 0, y: -30 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 1, delay: 0.3 }}
                >
                    <Typography
                        variant="h6"
                        sx={{
                            fontWeight: 400,
                            letterSpacing: 2,
                            textAlign: 'center',
                            fontSize: '1.5rem',
                            // color: '#B3B3B3',
                            color: 'white',
                            marginBottom: theme.spacing(3),
                            '&:hover': {
                                color: '#1E90FF',
                            },
                        }}
                    >
                        Experience AI Like Never Before
                    </Typography>
                </motion.div>

                {/* Content Section with Image/Icon */}
                <Grid container spacing={4} justifyContent="center" alignItems="center">

                    <Grid item xs={12} sm={6} md={6}>
                        <Box
                            sx={{
                                backgroundColor: '#000000',
                                padding: theme.spacing(4),
                                borderRadius: '10px',
                                textAlign: 'center',
                                boxShadow: 3,
                                border: '2px solid transparent',
                                '&:hover': {
                                    borderColor: '#1E90FF',
                                },
                            }}
                        >
                            {/* <AiOutlineRobot size={150} color="#1E90FF" /> */}
                            <FaCogs size={150} color="#1E90FF" />
                            <Typography
                                variant="h6"
                                sx={{
                                    color: '#fff',
                                    marginTop: theme.spacing(2),
                                    letterSpacing: 1,
                                    fontWeight: 400,
                                    '&:hover': {
                                        color: '#1E90FF',
                                    },
                                }}
                            >
                                Generative AI
                            </Typography>
                            <Typography
                                variant="body1"
                                sx={{
                                    color: '#B3B3B3',
                                    marginTop: theme.spacing(1),
                                }}
                            >
                                Different AI Models and AI Systems are useful for a variety of applications. Both Large and small language models are necessary to meet the diverse needs of all users. Adding an additional semantic AI layer to these models will provide more meaningful insights.
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        <Box
                            sx={{
                                backgroundColor: '#000000',
                                padding: theme.spacing(4),
                                borderRadius: '10px',
                                textAlign: 'center',
                                boxShadow: 3,
                                border: '2px solid transparent',
                                '&:hover': {
                                    borderColor: '#1E90FF',
                                },
                            }}
                        >
                            <AiOutlineRobot size={150} color="#1E90FF" />
                            <Typography
                                variant="h6"
                                sx={{
                                    color: '#fff',
                                    marginTop: theme.spacing(2),
                                    fontWeight: 400,
                                    letterSpacing: 1,
                                    '&:hover': {
                                        color: '#1E90FF',
                                    },
                                }}
                            >
                                AI Agents and Assistants
                            </Typography>
                            <Typography
                                variant="body1"
                                sx={{
                                    color: '#B3B3B3',
                                    marginTop: theme.spacing(1),
                                }}
                            >
                                Unlock the true potential of AI to enhance both your work and life with cutting-edge technology at your fingertips. Our AI Agents are precisely fine-tuned for specific subject areas and our AI Assistants are tailored to meet user expectations and enhance their overall experience.
                            </Typography>
                        </Box>
                    </Grid>


                </Grid>

                {/* CTA Button */}
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 1, delay: 0.6 }}
                >
                    {/* <Box sx={{ textAlign: 'center', marginTop: theme.spacing(5) }}>
                        <Button
                            variant="contained"
                            color="primary"
                            sx={{
                                padding: theme.spacing(2, 6),
                                fontSize: '1rem',
                                fontWeight: 700,
                                textTransform: 'none',
                                boxShadow: 3,
                                background: '#0A0A0A',
                                '&:hover': {
                                    backgroundColor: '#1E90FF',
                                    boxShadow: 8,
                                },
                            }}
                        >
                            Explore AI Now
                        </Button>
                    </Box> */}
                </motion.div>
            </Box>








            {/* Announcement Section */}
            {/* <Box id="announcements"
                onMouseEnter={() => setActiveIndex(-1)} // Optional: deselect benefits when hovering over announcements
                sx={{
                    backgroundColor: '#1C1C1C',
                    color: 'white',
                    borderRadius: '12px',
                    padding: { xs: '10px', sm: '15px', md: '20px' },
                    boxShadow: '0 4px 15px rgba(0, 0, 0, 0.2)',
                    textAlign: 'center',
                    width: { xs: '90%', sm: '70%', md: '50%' },
                    maxWidth: '800px',
                    margin: '20px auto',
                    display: 'flex',
                    flexDirection: 'column',
                    transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                    height: { xs: 'auto', sm: '450px', md: '500px' },
                    mb: { xs: 4, sm: 6, md: 8 }, // Additional bottom margin for spacing
                    '&:hover': {
                        transform: 'scale(1.05)',
                        boxShadow: '0 0 20px #1E90FF',
                    },
                }}
            >
                {/* Image at the upper part of the box */}
            {/* <img
                    src={Announcement} // Replace with your image path
                    alt="Announcement"
                    style={{
                        width: '100%',
                        maxHeight: '180px',
                        objectFit: 'cover',
                        borderRadius: '8px',
                        marginBottom: '10px',
                    }}
                /> */}

            {/* Text content at the lower side of the box */}
            {/* <Box
                    sx={{
                        textAlign: 'center',
                        width: '100%',
                        flexGrow: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <Typography
                        variant="h4"
                        component="h4"
                        gutterBottom
                        sx={{
                            fontFamily: 'Helvetica, sans-serif',
                            mb: { xs: 1, sm: 2 },
                            fontSize: { xs: '1.5rem', sm: '1.8rem', md: '2rem' },
                            '&:hover': {
                                color: '#1E90FF', // Change font color to blue on hover
                            },

                        }}
                    >
                        Announcements
                    </Typography>
                    <Typography
                        variant="body1"
                        sx={{
                            fontFamily: 'Helvetica, sans-serif',
                            mt: { xs: '8px', sm: '10px' },
                            fontSize: { xs: '0.9rem', sm: '1rem' },
                            textAlign: 'center',
                        }}
                    >
                        <strong>Exciting updates ahead!</strong>
                        <p style={{ fontSize: '1rem', margin: '10px 0', textAlign: 'center', fontFamily: 'Helvetica, sans-serif' }}>
                            New features are coming soon! Stay tuned.
                        </p>
                        <p style={{ fontSize: '1rem', textAlign: 'center', fontFamily: 'Helvetica, sans-serif' }}>
                            We can’t wait to share what’s next!
                        </p>
                    </Typography>
                </Box>
            </Box> */}

            <Box id="announcements"
                sx={{
                    backgroundColor: '#000000', // Dark background color for the page
                    minHeight: '50vh',
                    padding: theme.spacing(3),
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    marginBottom: 50,
                }}
            >
                {/* Heading Section */}
                <Typography
                    variant="h5"
                    sx={{
                        color: 'white',
                        marginBottom: theme.spacing(3),
                        fontWeight: 400,
                        textAlign: 'center',
                        letterSpacing: 2,
                        '&:hover': {
                            color: '#1E90FF',
                        },
                    }}
                >
                    ANNOUNCEMENTS
                </Typography>

                {/* Announcements Cards */}
                <Grid container spacing={3} justifyContent="center">
                    {announcements.map((announcement, index) => (
                        <Grid item xs={12} sm={6} md={4} key={index}>
                            <Card
                                variant="outlined"
                                sx={{
                                    // backgroundColor: theme.palette.grey[900], // Dark card background
                                    backgroundColor: '#000000',
                                    color: 'white', // Light text for contrast
                                    boxShadow: 3, // Card shadow for depth
                                    border: '0.5px solid #1E90FF',
                                    '&:hover': {
                                        boxShadow: 10, // Increase shadow on hover for effect
                                        borderColor: '#1E90FF',
                                    },
                                    transition: 'box-shadow 0.3s ease-in-out',
                                }}
                            >
                                <CardContent>
                                    {/* Center the title using Flexbox */}
                                    <Typography
                                        variant="h6"
                                        gutterBottom
                                        sx={{
                                            fontWeight: 'bold',
                                            textAlign: 'center', // Center the title text horizontally
                                            marginBottom: 2, // Optional: Add space below the title
                                            letterSpacing: 1,
                                            '&:hover': {
                                                color: '#1E90FF',
                                            },
                                        }}
                                    >
                                        {announcement.title}
                                    </Typography>

                                    {/* Show the brief message (this doesn't change) */}
                                    <Typography variant="body2" color="white" paragraph>
                                        {announcement.message}
                                    </Typography>

                                    {/* Show full content when expanded */}
                                    {expandedIndex === index && (
                                        <Typography variant="body2" color="white" paragraph>
                                            {announcement.fullMessage}
                                        </Typography>
                                    )}

                                    {/* Toggle button to expand/collapse the card */}
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => handleToggleExpand(index)}
                                        sx={{
                                            textTransform: 'none',
                                            marginTop: theme.spacing(1),
                                            borderRadius: '20px',
                                            padding: '8px 16px',
                                        }}
                                    >
                                        {expandedIndex === index ? 'Read Less' : 'Read More'}
                                    </Button>
                                </CardContent>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </Box>


            <Box id="community" sx={{ padding: 3, backgroundColor: 'black', borderRadius: '8px', marginBottom: theme.spacing(25) }}>
                <Typography
                    variant="h5"
                    sx={{
                        fontWeight: 400,
                        color: 'white',
                        textAlign: 'center',
                        marginBottom: theme.spacing(4),
                        textTransform: 'uppercase',
                        letterSpacing: 2,
                        '&:hover': {
                            color: '#1E90FF',
                        },
                    }}
                >
                    Join Our Community
                </Typography>
                <Grid container spacing={3} justifyContent="center" alignItems="center">
                    <Grid item xs={12} sm={6} md={6}>
                        <Card
                            sx={{
                                backgroundColor: '#7289DA', // Discord's official color
                                color: 'white',
                                boxShadow: 3,
                                borderRadius: '12px',
                                padding: theme.spacing(3),
                                textAlign: 'center',
                            }}
                        >
                            <CardContent>
                                <Typography variant="h4" sx={{ fontWeight: 600, marginBottom: theme.spacing(2) }}>
                                    Discord
                                </Typography>
                                <Typography variant="body1" paragraph sx={{ marginBottom: theme.spacing(3) }}>
                                    Stay connected, share ideas, ask questions and engage with fellow community members.
                                    Join us on Discord to be part of our growing community!
                                </Typography>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    href={discordInviteURL}
                                    target="_blank"
                                    startIcon={<FaDiscord />}  // Use the FaDiscord icon here
                                    sx={{
                                        padding: theme.spacing(1.5, 5),
                                        fontSize: '16px',
                                        fontWeight: 600,
                                        borderRadius: '50px',
                                        boxShadow: 3,
                                        '&:hover': {
                                            boxShadow: 8,
                                        },
                                    }}
                                >
                                    Join Now
                                </Button>
                            </CardContent>
                        </Card>
                    </Grid>

                    <Grid item xs={12} sm={6} md={6} >
                        <Typography variant="h6" sx={{ color: 'white', fontWeight: 600 }}>
                            Why Join?
                        </Typography>
                        <Typography variant="body1" sx={{ color: 'white' }}>
                            - Participate in discussions, events and activities.
                            <br />
                            - Get help and support from community members.
                            <br />
                            - Be the first to know about important updates and news.
                        </Typography>
                    </Grid>
                </Grid>
            </Box>


            <IconButton
                onClick={scrollToTop}
                sx={{ position: 'fixed', bottom: 16, right: 16, backgroundColor: 'white', color: 'black' }}>
                <ArrowUpwardIcon />
            </IconButton>

            <>
  {/* Blurred background overlay */}
  {isSurveyCardOpen && (
    <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent background
        backdropFilter: 'blur(10px)', // Apply the blur effect
        zIndex: 9998, // Ensure the overlay stays behind the card
      }}
    />
  )}

  {/* Floating Information Card */}
  {isSurveyCardOpen && (
    <Card
      sx={{
        position: 'fixed',
        top: 80, // Positioned at the top of the screen
        left: '50%', // Centered horizontally
        transform: 'translateX(-50%)', // Ensures it is centered
        maxWidth: 500, // Max width for the card
        width: '90%', // Ensures the card takes up 90% of the screen width on smaller screens
        boxShadow: 3,
        padding: 2,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center', // Center the content vertically
        backgroundColor: '#1111',
        borderRadius: 2,
        zIndex: 9999, // Ensure it's on top of other content
        border: '2px solid #9999', // Initial border
        transition: 'transform 0.3s ease, box-shadow 0.3s ease', // Smooth transition for transform and box-shadow
        transformOrigin: 'center', // Ensure scaling happens from the center
        '&:hover': {
          boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.3)', // Increase the shadow on hover
          borderColor: '#1E90FF', // Change border color on hover
        },
        // Media queries for responsiveness
        '@media (max-width: 600px)': {
          top: 20, // Position it higher on very small screens
          maxWidth: 400, // Make the card smaller on small screens
        },
        '@media (max-width: 400px)': {
          maxWidth: 300, // Further reduce size on very small screens
          padding: 1, // Reduce padding on small screens
        },
      }}
    >
      <CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
        <Typography
          variant="h6"
          color="#1E90FF"
          align="center"
          sx={{
            '&:hover': {
              color: '#1E90FF', // Light blue color on hover
            },
          }}
          gutterBottom
        >
          Anthrasync Survey
        </Typography>
        <Typography variant="body2" color="white" align="center">
        We are conducting a worldwide survey to identify the major barriers people face during knowledge exchange.
         Your insights will help us shape a better platform. Please take a moment to complete the survey.
        </Typography>

        {/* Centered Button */}
        <Button
          variant="contained"
          color="primary"
          sx={{ mt: 2, alignSelf: 'center' }} // Button will be centered
          onClick={handleSurveyLink}
        >
          Take the Survey
        </Button>
      </CardContent>

      {/* Close Icon Button */}
      <IconButton
        color="white"
        sx={{
          position: 'absolute',
          top: 5,
          right: 5,
          color: 'white',
        }}
        onClick={handleCloseCard} // Closes the card when clicked
      >
        <CloseIcon />
      </IconButton>
    </Card>
  )}
</>


            <Footer />
        </div >
    );
}

export default Home;
