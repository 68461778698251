// AdminVerification.js or another React component

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Box, Typography, CircularProgress, Card, CardContent } from '@mui/material';
import Header1 from '../Header/Header1';
import Footer2 from '../Footer/Footer2';
import Header from '../Header/Header';

const API_URL = process.env.REACT_APP_API_URL;

const DatabaseSizeDisplay = () => {
  const [sizeData, setSizeData] = useState(null);
  const [loading, setLoading] = useState(true);

  // Fetch the database and media size
  useEffect(() => {
    const fetchDatabaseSize = async () => {
      try {
        const response = await axios.get(`${API_URL}/api/database-size/`);
        setSizeData(response.data);
      } catch (error) {
        console.error('Error fetching database size data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchDatabaseSize();
  }, []);

  if (loading) {
    return (
      <Box sx={{ textAlign: 'center', padding: '20px' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <div>
        <Header1/>
    
    <Box sx={{ width: '90%', margin: 'auto', padding: '20px' }}>
      <Typography variant="h4" gutterBottom color="text.primary">
        Database and Media Size
      </Typography>
      
      <Card sx={{ marginBottom: '20px' }}>
        <CardContent>
          <Typography variant="h6" color="text.secondary">
            Database Size:
          </Typography>
          <Typography variant="body1">{sizeData.database_size}</Typography>
        </CardContent>
      </Card>
      
      <Card>
        <CardContent>
          <Typography variant="h6" color="text.secondary">
            Total Media Size:
          </Typography>
          <Typography variant="body1">{sizeData.media_size}</Typography>
        </CardContent>
      </Card>
    </Box>
    <Footer2/>
    </div>
  );
};

export default DatabaseSizeDisplay;
