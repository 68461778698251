import React from 'react'
import ComingSoon from '../Header/ComingSoon'
import { useNavigate } from 'react-router-dom';
import Header3 from '../Header/Header3';
import Header1 from '../Header/Header1';

function AISync({userId, onLogout}) {

    const navigate = useNavigate();

    const handleLogout = () => {
        onLogout();
        navigate('/login');
    };
  return (
    <div>
      {/* <Header3/> */}
      <Header1 userId={userId} onLogout={onLogout} />
      <ComingSoon/>
    </div>
  )
}

export default AISync
