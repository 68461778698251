import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Container,
  Typography,
  Grid,
  Card,
  CardContent,
  Divider,
  Box,
  CircularProgress,
  Button,
} from '@mui/material';
import Header1 from '../Header/Header1';

const API_URL = process.env.REACT_APP_API_URL;

function AdminContactUs({userId, onLogout}) {
  const [contactMessages, setContactMessages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch contact us details from the backend
  const fetchContactUsMessages = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${API_URL}/get_contact_us_details/`);
      setContactMessages(response.data); // Set the data from the API
    } catch (error) {
      setError('Failed to fetch contact messages');
    } finally {
      setLoading(false);
    }
  };

  // Fetch data when component mounts
  useEffect(() => {
    fetchContactUsMessages();
  }, []);

  return (
    <div>
        <Header1 userId={userId} onLogout={onLogout}/>
    <Container sx={{ backgroundColor: '#121212', minHeight: '100vh', pt: 8 }} maxWidth="lg">
      <Typography variant="h4" align="center" gutterBottom sx={{ color: '#fff' }}>
        Admin - Contact Us Messages
      </Typography>

      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
          <CircularProgress sx={{ color: '#1E90FF' }} />
        </Box>
      ) : error ? (
        <Typography sx={{ color: '#ff4747', textAlign: 'center' }}>{error}</Typography>
      ) : (
        <Grid container spacing={3}>
          {contactMessages.length === 0 ? (
            <Typography variant="h6" align="center" sx={{ color: '#fff' }}>
              No contact messages available.
            </Typography>
          ) : (
            contactMessages.map((message) => (
              <Grid item xs={12} sm={6} md={4} key={message.id}>
                <Card
                  sx={{
                    backgroundColor: '#1C1C1C',
                    color: '#fff',
                    mb: 3,
                    borderRadius: 2,
                    boxShadow: 3,
                    '&:hover': {
                      boxShadow: 6,
                    },
                  }}
                >
                  <CardContent>
                    <Typography variant="h6" sx={{ fontSize: '1.2rem', fontWeight: 'bold', color: '#1E90FF' }}>
                      {message.name}
                    </Typography>
                    <Divider sx={{ backgroundColor: '#555' }} />
                    <Typography sx={{ fontSize: '1rem', color: '#bbb', marginTop: 1 }}>
                      <strong>Email:</strong> {message.email}
                    </Typography>
                    <Typography sx={{ fontSize: '1rem', color: '#bbb' }}>
                      <strong>Contact:</strong> {message.contact}
                    </Typography>
                    <Typography sx={{ fontSize: '1rem', color: '#bbb' }}>
                      <strong>Message:</strong> {message.message}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))
          )}
        </Grid>
      )}
    </Container>
    </div>
  );
}

export default AdminContactUs;
