import React from 'react';
import ReactDOM from 'react-dom/client';
import { CssBaseline, ThemeProvider, createTheme } from '@mui/material';
import App from './App';
import reportWebVitals from './reportWebVitals';

// Create a modern dark theme using Material UI's createTheme function
const darkTheme = createTheme({
  palette: {
    mode: 'dark', // Set the palette to 'dark' mode
    primary: {
      main: '#1E90FF', // Soft blue for primary, more vibrant and modern
    },
    secondary: {
      main: '#000000', // Teal for secondary to create a nice contrast with primary
    },
    background: {
      default: '#000000', // Deep black background for the app
       // Slightly lighter background for paper components
    },
    text: {
      primary: '#EAEAEA', // Lighter text for better contrast
      secondary: '#A1A1A1', // Subtle secondary text color
    },
  },
  typography: {
    fontFamily: ['"Roboto"', 'sans-serif'].join(','), // Clean, modern font
    h1: {
      fontSize: '2.5rem', // Bigger font size for headers
      fontWeight: 700,
      color: '#EAEAEA', // Lighter header text
    },
    h2: {
      fontSize: '2rem',
      fontWeight: 600,
      color: '#EAEAEA',
    },
    h3: {
      fontSize: '1.75rem',
      fontWeight: 500,
      color: '#EAEAEA',
    },
    body1: {
      fontSize: '1rem',
      color: '#B0B0B0', // Slightly lighter text for body content
    },
  },
  components: {
    // Customizing Material UI components globally
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: 'black', // Ensure the AppBar has a black background
          color: 'white',           // Set text/icons to white for contrast
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '12px', // More rounded button corners
          textTransform: 'none', // Keep text case normal
          padding: '12px 24px', // Spacious buttons
        },
      },
    },
  },
});

// Create root element and render the app
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    {/* Apply the dark theme using ThemeProvider */}
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <App />
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
