import React, { useState, useEffect } from 'react';
import { Container, Grid, Typography, Box, Button, IconButton, Drawer } from '@mui/material';
import { styled } from '@mui/system';
import { Link, useNavigate } from "react-router-dom";
import Header1 from '../Header/Header1';
import { Info as InfoIcon } from '@mui/icons-material'; // Icon for viewing all sections

// Styled components for cards
const HoverCard = styled(Box)(({ borderColor }) => ({
  transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
  padding: '20px',
  borderRadius: '10px',
  background: '#1111',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'center',
  textAlign: 'center',
  boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.2)',
  height: '250px',
  width: '100%',
  maxWidth: '240px',
  marginBottom: '20px',
  marginRight: '5px',
  marginLeft: '5px',
  '&:hover': {
    transform: 'scale(1.05)',
    boxShadow: '0px 6px 18px rgba(0, 0, 0, 0.3)',
    border: `2px solid ${borderColor}`,
  },
}));

const Title = styled(Typography)(({ hoverColor }) => ({
  fontSize: '1.1rem',
  fontWeight: 'bold',
  color: '#fff',
  marginBottom: '8px',
  textTransform: 'uppercase',
  letterSpacing: '1px',
  '&:hover': {
    color: hoverColor,
  },
}));

const Description = styled(Typography)(({ borderColor }) => ({
  fontSize: '0.85rem',
  color: '#fff',
  marginTop: '8px',
  fontStyle: 'italic',
  opacity: 0, // Initially hidden
  transition: 'opacity 0.3s ease-in-out',
  '&:hover': {
    opacity: 1, // Show on hover
    color: borderColor,
  },
}));

// Pagination Button (Next & Previous)
const PaginationButton = styled(Button)(({ borderColor, position }) => ({
  position: 'absolute',
  top: '50%',
  [position]: '20px',
  transform: 'translateY(-50%)',
  backgroundColor: 'transparent',
  color: borderColor,
  padding: '10px',
  minWidth: 'auto',
  height: 'auto',
  fontSize: '2rem',
  boxShadow: 'none',
  border: 'none',
  '&:hover': {
    backgroundColor: 'transparent',
    color: borderColor,
  },
  transition: 'all 0.3s ease-in-out',
}));

// "View All" Button
const ViewAllButton = styled(Button)(({ borderColor }) => ({
  backgroundColor: '#2222',
  color: borderColor,
  padding: '8px 20px',
  borderRadius: '5px',
  textTransform: 'uppercase',
  fontWeight: 'bold',
  '&:hover': {
    backgroundColor: '#2222',
    color: borderColor,
  },
}));

// Drawer styles for sections panel
const SectionsPanel = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(3),
  backgroundColor: '#111', // Gradient background for a deeper, modern look
  color: '#fff',
  width: '320px',
  height: '100vh',
  overflowY: 'auto',
  boxShadow: '5px 0px 15px rgba(0, 0, 0, 0.3)', // Subtle shadow to separate panel from the rest of the page
  borderTopRightRadius: '10px',
  borderBottomRightRadius: '10px', // Rounded corners for a sleek look
  transition: 'transform 0.3s ease, box-shadow 0.3s ease', // Smooth transition for hover effects
  '&:hover': {
    transform: 'translateX(-10px)', // Slight movement on hover for interactivity
    boxShadow: '10px 0px 20px rgba(0, 0, 0, 0.5)', // Increased shadow on hover for depth
  },
}));

const SectionsPanelContent = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(2),
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
}));

const SectionItem = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
  backgroundColor: '#222', // Dark background for section items
  borderRadius: '8px',
  padding: theme.spacing(1),
  transition: 'transform 0.3s ease, background-color 0.3s ease',
  '&:hover': {
    transform: 'scale(1.05)', // Slight zoom-in effect on hover
    backgroundColor: '#444444', // Lighter background on hover
  },
}));

const SectionBar = styled(Box)(({ sectionColor }) => ({
  backgroundColor: sectionColor,
  height: '8px', // Smaller bar for a cleaner look
  borderRadius: '4px', // Rounded bar edges for a modern feel
  width: '100%',
}));

const SectionTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  fontSize: '0.7rem',
  color: '#fff',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden', // To handle long section names
}));

const API_URL = process.env.REACT_APP_API_URL;

function Categories({ userId, onLogout }) {
  const [sections, setSections] = useState([]);
  const [currentPage, setCurrentPage] = useState({});
  // const [itemsPerPage] = useState(4);
  const [itemsPerPage, setItemsPerPage] = useState(4);
  const [loading, setLoading] = useState(true);
  const [viewAll, setViewAll] = useState(null);
  const [openPanel, setOpenPanel] = useState(false); // Panel open state
  const navigate = useNavigate();

  const handleLogout = () => {
    onLogout();
    navigate('/login');
  };

  useEffect(() => {
    // Set responsive itemsPerPage based on window size
    const handleResize = () => {
      if (window.innerWidth <= 600) {
        setItemsPerPage(1); // Mobile devices
      } else if (window.innerWidth <= 960) {
        setItemsPerPage(2); // Tablets
      } else {
        setItemsPerPage(3); // Desktop
      }
    };

    // Initial check and on window resize
    handleResize();
    window.addEventListener('resize', handleResize);
    
    // Clean up event listener on component unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const fetchSections = async () => {
      try {
        setLoading(true);
        const response = await fetch(`${API_URL}/list_sections_and_subsections/`);
        const data = await response.json();
        console.log('section', data)

        const formattedSections = Object.keys(data.sections).map((key) => ({
          sectionName: key,
          sectionColor: data.sections[key].subsections[0]?.color || '#cccccc',
          subsections: data.sections[key].subsections || [],
        }));

        setSections(formattedSections);

        const initialPageState = formattedSections.reduce((acc, section, index) => {
          acc[index] = 1;
          return acc;
        }, {});
        setCurrentPage(initialPageState);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchSections();
  }, []);

  const paginate = (subsections, sectionIndex) => {
    const startIndex = (currentPage[sectionIndex] - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return subsections.slice(startIndex, endIndex);
  };

  const handleSearch = (result) => {
    const data = result;
    const formattedSections = Object.keys(data).map((key) => ({
      sectionName: key,
      sectionColor: data[key].subsections[0].color || '#cccccc',
      subsections: data[key].subsections || [],
    }));
    setSections(formattedSections);
  };

  const handlePagination = (direction, sectionIndex) => {
    setCurrentPage((prev) => {
      const newPage = { ...prev };
      const subsectionsLength = sections[sectionIndex].subsections.length;
      if (direction === 'next') {
        if ((newPage[sectionIndex] * itemsPerPage) < subsectionsLength) {
          newPage[sectionIndex] = newPage[sectionIndex] + 1;
        }
      } else if (direction === 'prev') {
        if (newPage[sectionIndex] > 1) {
          newPage[sectionIndex] = newPage[sectionIndex] - 1;
        }
      }
      return newPage;
    });
  };

  const handleViewAll = (sectionIndex) => {
    setViewAll((prev) => (prev === sectionIndex ? null : sectionIndex));
  };

  const togglePanel = () => {
    setOpenPanel((prev) => !prev); // Toggle panel visibility
  };

  return (
    <div >
      <Header1 userId={userId} page_name="category" onSearch={handleSearch} onLogout={onLogout} />
      <Container sx={{ backgroundColor: 'black', minHeight: '100vh', width:'100%', padding: '30px', mt:10  }}>
        <Typography variant="h4" align="center" fontSize={{ xs: '2rem', sm: '2.5rem', md: '3rem' }} color="white" gutterBottom>
          Explore
        </Typography>

        {/* Icon to open the panel */}
        <IconButton
          onClick={togglePanel}
          sx={{
            position: 'fixed',
            top: '70px', // Added margin from top to avoid header overlap
            right: '20px',
            color: '#fff',
            '&:hover': {
              color: '#1E90FF',
            },
          }}
        >
          <InfoIcon fontSize="large" />
        </IconButton>

        {loading ? (
          <Typography variant="h5" align="center" color="white">
            Loading...
          </Typography>
        ) : (
          sections.map((section, idx) => (
            (viewAll === null || viewAll === idx) && (
              <Box key={idx} mb={4} position="relative">
                <Typography
                  variant="h4"
                  sx={{
                    color: section.sectionColor,
                    textAlign:'center',
                    '&:hover': {
                      color: section.sectionColor,
                    },
                  }}
                  gutterBottom
                >
                  {section.sectionName}
                </Typography>

                <Box sx={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center', mr: 2 }}>

                  <Grid container spacing={3} justifyContent="center">
                    {viewAll === null && (
                      <PaginationButton
                        borderColor={section.sectionColor}
                        position="left"
                        onClick={() => handlePagination('prev', idx)}
                        disabled={currentPage[idx] === 1}
                      >
                        &#10094;
                      </PaginationButton>
                    )}

                    {viewAll === null ? (
                      paginate(section.subsections, idx).map((subsection, index) => (
                        <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                          <Link to={`/sub-categories-posts/${subsection.id}`} style={{ textDecoration: "none" }}>
                            <HoverCard borderColor={section.sectionColor}>
                              <Box sx={{ width: '100%', height: '10px', backgroundColor: section.sectionColor }} />
                              <Title hoverColor={section.sectionColor}>{subsection.name}</Title>
                              <Description borderColor={section.sectionColor}>{subsection.description}</Description>
                            </HoverCard>
                          </Link>
                        </Grid>
                      ))
                    ) : (
                      section.subsections.map((subsection, index) => (
                        <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                          <Link to={`/sub-categories-posts/${subsection.id}`} style={{ textDecoration: "none" }}>
                            <HoverCard borderColor={section.sectionColor}>
                              <Box sx={{ width: '100%', height: '10px', backgroundColor: section.sectionColor }} />
                              <Title hoverColor={section.sectionColor}>{subsection.name}</Title>
                              <Description borderColor={section.sectionColor} sx={{ mb: 2 }} >{subsection.description}</Description>
                            </HoverCard>
                          </Link>
                        </Grid>
                      ))
                    )}

                    {viewAll === null && (
                      <PaginationButton
                        borderColor={section.sectionColor}
                        position="right"
                        onClick={() => handlePagination('next', idx)}
                        disabled={(currentPage[idx] * itemsPerPage) >= section.subsections.length}
                      >
                        &#10095;
                      </PaginationButton>
                    )}
                  </Grid>
                </Box>

                <Box sx={{ textAlign: 'center', mt: 2 }}>
                  <ViewAllButton
                    borderColor={section.sectionColor}
                    onClick={() => handleViewAll(idx)}
                  >
                    {viewAll === idx ? 'View Less' : 'View All'}
                  </ViewAllButton>
                </Box>
              </Box>
            )
          ))
        )}
      </Container>

      {/* Drawer (Panel) for viewing all sections */}
      <Drawer anchor="right" open={openPanel} onClose={togglePanel}>
        <SectionsPanel>
          <Typography variant="h5" textAlign="center" color="white" mb={2}>
            All Categories
          </Typography>
          <SectionsPanelContent>
            {sections.map((section, idx) => (
              <SectionItem key={idx}>
                <SectionTitle>{section.sectionName}</SectionTitle>
                <SectionBar sectionColor={section.sectionColor} />
              </SectionItem>
            ))}
          </SectionsPanelContent>
        </SectionsPanel>
      </Drawer>
    </div>
  );
}

export default Categories;
