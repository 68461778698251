import React, { useState } from 'react';
import { AppBar, Toolbar, Typography, Box, Button, Card, CardContent, Grid, TextField, Container, CssBaseline, Paper } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { GitHub, Facebook, Twitter } from '@mui/icons-material';

function Demo() {

    const [feedback, setFeedback] = useState('');

    const handleFeedbackChange = (event) => {
        setFeedback(event.target.value);
    };

    const handleSubmitFeedback = () => {
        alert('Thank you for your feedback!');
        setFeedback('');
    };

    const theme = createTheme({
        palette: {
            mode: 'dark', // Dark mode for the whole page
            primary: {
                main: '#1976d2', // Blue primary color
            },
            secondary: {
                main: '#f50057', // Pink accent color
            },
            background: {
                default: '#121212', // Dark background for the page
                paper: '#1f1f1f',  // Dark background for Paper components (cards, sections)
            },
            text: {
                primary: '#fff',  // White text for main content
                secondary: '#bbb', // Lighter gray text for secondary elements
            },
        },
        components: {
            MuiButton: {
                styleOverrides: {
                    root: {
                        color: '#fff', // White text on buttons
                        '&:hover': {
                            backgroundColor: '#333', // Darker hover effect for buttons
                        },
                    },
                },
            },
        },
    });


    return (
        <div>

            <ThemeProvider theme={theme}>
                <CssBaseline />

                {/* Hero Section */}
                <Box
                    sx={{
                        backgroundImage: 'url("https://via.placeholder.com/1500x800")',
                        backgroundSize: 'cover',
                        height: '100vh',
                        color: '#fff',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        textAlign: 'center',
                        backdropFilter: 'blur(4px)', // Blur the background image for a better overlay effect
                    }}
                >
                    <Box>
                        <Typography variant="h2" gutterBottom>
                            Welcome to Our Demo Page
                        </Typography>
                        <Typography variant="h5" paragraph>
                            Explore the features and see what we have to offer. This demo showcases how you can interact with the application.
                        </Typography>
                        <Button variant="contained" color="primary" size="large">
                            Get Started
                        </Button>
                    </Box>
                </Box>

                {/* Demo Features Section */}
                <Container sx={{ py: 8 }}>
                    <Typography variant="h3" align="center" gutterBottom color="textPrimary">
                        Demo Features
                    </Typography>
                    <Grid container spacing={4}>
                        <Grid item xs={12} sm={6} md={4}>
                            <Card sx={{ backgroundColor: '#333', transition: 'transform 0.3s', '&:hover': { transform: 'scale(1.05)' } }}>
                                <CardContent>
                                    <Typography variant="h5" gutterBottom color="textPrimary">
                                        Feature 1
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary">
                                        This is a description of feature 1. It showcases how the system works and provides valuable insights.
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <Card sx={{ backgroundColor: '#333', transition: 'transform 0.3s', '&:hover': { transform: 'scale(1.05)' } }}>
                                <CardContent>
                                    <Typography variant="h5" gutterBottom color="textPrimary">
                                        Feature 2
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary">
                                        This feature allows you to manage your settings and preferences. Easily customize your experience.
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <Card sx={{ backgroundColor: '#333', transition: 'transform 0.3s', '&:hover': { transform: 'scale(1.05)' } }}>
                                <CardContent>
                                    <Typography variant="h5" gutterBottom color="textPrimary">
                                        Feature 3
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary">
                                        Our application provides advanced analytics for all the tasks you perform. Get detailed reports!
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </Container>

                {/* Feedback Section */}
                <Box sx={{ backgroundColor: '#1f1f1f', py: 8 }}>
                    <Container>
                        <Typography variant="h4" color="white" gutterBottom align="center">
                            We'd Love to Hear Your Feedback
                        </Typography>
                        <TextField
                            value={feedback}
                            onChange={handleFeedbackChange}
                            label="Share your thoughts"
                            variant="outlined"
                            fullWidth
                            multiline
                            rows={4}
                            sx={{ backgroundColor: '#fff', borderRadius: '8px', mb: 2 }}
                            InputLabelProps={{
                                style: { color: '#333' }, // Dark color for label text
                            }}
                        />
                        <Box display="flex" justifyContent="center">
                            <Button variant="contained" color="secondary" onClick={handleSubmitFeedback}>
                                Submit Feedback
                            </Button>
                        </Box>
                    </Container>
                </Box>

                {/* Footer */}
                <Paper sx={{ backgroundColor: '#111', color: '#fff', py: 4 }} square>
                    <Container>
                        <Box display="flex" justifyContent="space-between" alignItems="center">
                            <Typography variant="body2">&copy; 2024 Demo Application</Typography>
                            <Box display="flex" gap={2}>
                                <GitHub color="inherit" />
                                <Facebook color="inherit" />
                                <Twitter color="inherit" />
                            </Box>
                        </Box>
                    </Container>
                </Paper>
            </ThemeProvider>

        </div>
    )
}

export default Demo
