import React, { useState, useEffect } from 'react';
import { Typography, Box, CircularProgress, createTheme, ThemeProvider, Button, TextField, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import axios from 'axios';
import Header1 from '../Header/Header1';
import Footer2 from '../Footer/Footer2';
import GenerateMeetingLinkButton from '../GoogleMeet/GenerateMeetingLinkButton';

const API_URL = process.env.REACT_APP_API_URL;

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#90caf9',
    },
    secondary: {
      main: '#f48fb1',
    },
    background: {
      default: '#121212',
      paper: '#333333',
    },
    text: {
      primary: '#ffffff',
      secondary: '#b0bec5',
    },
  },
  typography: {
    fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
  },
});

const AdminVerification = ({ userId, onLogout }) => {
  const [usersData, setUsersData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [password, setPassword] = useState('');
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [openScheduleDialog, setOpenScheduleDialog] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [selectedSlot, setSelectedSlot] = useState('');
  const [error, setError] = useState([]);
  const [googleMeetLink, setGoogleMeetLink] = useState(null);
  const [remarks, setRemarks] = useState(''); 

  const correctPassword = 'admin123';  // Set the password here

  // Fetch all users verification data
  useEffect(() => {
    const fetchAllUsersData = async () => {
      try {
        const response = await axios.get(`${API_URL}/get_all_users_verification/`);
        setUsersData(response.data);
      } catch (error) {
        console.error("Error fetching user verification data", error);
        alert("Error fetching verification data");
      }
      setLoading(false);
    };

    if (isAuthenticated) {
      fetchAllUsersData();
    }
  }, [isAuthenticated]);

  const handleLogin = () => {
    if (password === correctPassword) {
      setIsAuthenticated(true);
    } else {
      alert("Incorrect password");
    }
  };

  const handleSchedule = (user) => {
    setCurrentUser(user);
    setOpenScheduleDialog(true);
    setGoogleMeetLink(null);
  };

  const handleSelectSlot = (slot) => {
    setSelectedSlot(slot);
  };

  const handleSubmitSlot = async () => {
    if (!selectedSlot) {
      alert("Please select a slot");
      return;
    }

    if (!googleMeetLink) {
      alert("Please generate a Google Meet link");
      return;
    }


    const postData = {
      user_id: userId,
      user_section_verification_id: currentUser.user_section_verification_id, // Assuming `id` is the user section verification ID
      interview_slot: selectedSlot,
      meet_link: googleMeetLink,
    };

    try {
      console.log('post data', postData)
      await axios.post(`${API_URL}/create_schedule_interview/`, postData);
      alert("Interview scheduled successfully!");
      setUsersData((prevUsers) =>
        prevUsers.map((user) =>
          user.id === currentUser.id ? { ...user, scheduled: true } : user
        )
      );
      setOpenScheduleDialog(false); // Close the dialog after scheduling
    } catch (error) {
      console.error("Error scheduling interview", error);
      alert("Failed to schedule interview. Please try again.");
    }
  };

  const handleApprove = (userId, subsection_id) => {
    const postData = {
      user_id: userId,
      subsection_id: subsection_id,
      verification_status: 'approved',
      remarks:remarks
    };

    axios.post(`${API_URL}/update_user_section_verification/`, postData).then((response) => {
      window.alert('Approved section successfully!');
      window.location.reload();
    })
      .catch((error) => {
        console.error('Error in section apply for verification:', error);
        setError('An error occurred while applying. Please try again later.');
      });
  };

  const handleReject = (userId, subsection_id) => {

    if (!remarks.trim()) {
      alert("Remarks are required before rejecting!");
      return;
    }

    const postData = {
      user_id: userId,
      subsection_id: subsection_id,
      verification_status: 'rejected',
      remarks: remarks, 
    };

    axios.post(`${API_URL}/update_user_section_verification/`, postData).then((response) => {
      window.alert('Rejected section successfully!');
      window.location.reload();
    })
      .catch((error) => {
        console.error('Error in section apply for verification:', error);
        setError('An error occurred while applying. Please try again later.');
      });
  };

   const handleRemarksChange = (e) => {
    setRemarks(e.target.value);  // Update remarks state as user types
  };

  if (!isAuthenticated) {
    return (
      <div>
        <ThemeProvider theme={darkTheme}>
          <Box sx={{ width: '80%', margin: 'auto', padding: '20px', mt: 7, textAlign: 'center' }}>
            <Typography variant="h4" gutterBottom color="text.primary">
              Enter Password to Access Admin Page
            </Typography>
            <TextField
              variant="outlined"
              type="password"
              label="Password"
              fullWidth
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              sx={{ marginBottom: '20px' }}
            />
            <Button variant="contained" color="primary" onClick={handleLogin}>
              Login
            </Button>
          </Box>
        </ThemeProvider>
      </div>
    );
  }

  if (loading) {
    return (
      <div>
        <Header1 userId={userId} onLogout={onLogout} />
        <ThemeProvider theme={darkTheme}>
          <Box sx={{ width: '80%', margin: 'auto', padding: '20px', mt: 7, textAlign: 'center' }}>
            <Typography variant="h4" gutterBottom color="text.primary">
              Loading Users Data...
            </Typography>
            <CircularProgress />
          </Box>
        </ThemeProvider>
        <Footer2 />
      </div>
    );
  }

  return (
    <div>
      <Header1 userId={userId} onLogout={onLogout} />
      <ThemeProvider theme={darkTheme}>
        <Box sx={{ width: '90%', margin: 'auto', padding: '20px', mt: 7 }}>
          <Typography variant="h4" textAlign="center" gutterBottom color="text.primary">
            User Verification Data
          </Typography>

          <Box sx={{ overflowX: 'auto' }}>
            <table style={{ width: '100%', borderCollapse: 'collapse', textAlign: 'center' }}>
              <thead>
                <tr>
                  <th style={styles.tableHeader}>#</th>
                  <th style={styles.tableHeader}>User Name</th>
                  <th style={styles.tableHeader}>Languages</th>
                  <th style={styles.tableHeader}>Subsection name</th>
                  <th style={styles.tableHeader}>Aadhar</th>
                  <th style={styles.tableHeader}>Aadhar Number</th>
                  <th style={styles.tableHeader}>PAN</th>
                  <th style={styles.tableHeader}>PAN Number</th>
                  <th style={styles.tableHeader}>Bank Account</th>
                  <th style={styles.tableHeader}>Bank Account Number</th>
                  <th style={styles.tableHeader}>IFSC</th>
                  <th style={styles.tableHeader}>Actions</th>
                  <th style={styles.tableHeader}>Remarks</th> {/* Move remarks column to last */}
                </tr>
              </thead>
              <tbody>
                {usersData.map((user, index) => (
                  <tr key={user.id}>
                    <td style={styles.tableCell}>{index + 1}</td>
                    <td style={styles.tableCell}>{user.user_name}</td>
                    <td style={styles.tableCell}>{user.languages}</td>
                    <td style={styles.tableCell}>{user.subsection_name}</td>
                    <td style={styles.tableCell}>
                      {user.aadhar_url ? (
                        <a href={`${API_URL}${user.aadhar_url}`} target="_blank" rel="noopener noreferrer" style={styles.link}>
                          View Aadhar
                        </a>
                      ) : (
                        'Not uploaded'
                      )}
                    </td>
                    <td style={styles.tableCell}>{user.aadhar_number}</td>
                    <td style={styles.tableCell}>
                      {user.pan_url ? (
                        <a href={`${API_URL}${user.pan_url}`} target="_blank" rel="noopener noreferrer" style={styles.link}>
                          View PAN
                        </a>
                      ) : (
                        'Not uploaded'
                      )}
                    </td>
                    <td style={styles.tableCell}>{user.pan_number}</td>
                    <td style={styles.tableCell}>
                      {user.bank_account_url ? (
                        <a href={`${API_URL}${user.bank_account_url}`} target="_blank" rel="noopener noreferrer" style={styles.link}>
                          View Bank Account
                        </a>
                      ) : (
                        'Not uploaded'
                      )}
                    </td>
                    <td style={styles.tableCell}>{user.bank_account_number}</td>
                    <td style={styles.tableCell}>{user.ifsc}</td>
                    <td style={styles.tableCell}>
                      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={() => handleSchedule(user)}
                          style={styles.button}
                          disabled={user.scheduled} // Disable the button if already scheduled
                        >
                          {user.scheduled ? 'Scheduled' : 'Schedule'}
                        </Button>
                        <Button variant="contained" color="success" onClick={() => handleApprove(user.user_id, user.subsection_id)} style={styles.button}>
                          Approve
                        </Button>
                        <Button variant="contained" color="error" onClick={() => handleReject(user.user_id, user.subsection_id)} style={styles.button}>
                          Reject
                        </Button>
                      </Box>
                    </td>
                    <td style={styles.tableCell}>
                      <TextField
                        variant="outlined"
                        size="small"
                        fullWidth
                        multiline
                        rows={4}
                        placeholder="Enter remarks"
                        onChange={(e) => handleRemarksChange(e)}
                        sx={{ width: '300px' }} // Increased width of remarks field
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Box>
        </Box>

        {/* Dialog for Slot Selection */}
        <Dialog open={openScheduleDialog} onClose={() => setOpenScheduleDialog(false)}>
          <DialogTitle>Select Interview Slot</DialogTitle>
          <DialogContent>
            <Box>
              {currentUser && currentUser.interview_slots && currentUser.interview_slots.map((slot) => (
                <Button
                  key={slot}
                  variant="outlined"
                  color="primary"
                  onClick={() => handleSelectSlot(slot)}
                  style={styles.button}
                >
                  {slot}
                </Button>
              ))}
                <GenerateMeetingLinkButton onLinkGenerated={(link) => setGoogleMeetLink(link)} />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenScheduleDialog(false)} color="primary">
              Cancel
            </Button>
            <Button onClick={handleSubmitSlot} color="primary">
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      </ThemeProvider>
      <Footer2 />
    </div>
  );
};

// Inline styles for the table
const styles = {
  tableHeader: {
    padding: '12px',
    borderBottom: '2px solid #ddd',
    backgroundColor: '#111',
    color: '#fff',
  },
  tableCell: {
    padding: '12px',
    borderBottom: '1px solid #ddd',
    color: '#fff',
  },
  link: {
    color: '#90caf9',
    textDecoration: 'none',
  },
  button: {
    margin: '8px 0',
    width: '150px',
  },
};

export default AdminVerification;
