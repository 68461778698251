import React, { useState, useEffect } from 'react';
import { Box, Typography, Button, Checkbox, FormControlLabel, Dialog, DialogActions, DialogContent, DialogTitle, FormGroup, Card, Grid } from '@mui/material';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const RecommendationSetup = ({ userId }) => {
  const [sections, setSections] = useState({});
  const [Languages, setLanguages] = useState([]);
  const [selectedSections, setSelectedSections] = useState([]);
  const [selectedSubsections, setSelectedSubsections] = useState([]);
  const [selectedLanguages, setSelectedLanguages] = useState([]);
  const [specializedFields, setSpecializedFields] = useState([]);
  const [selectedFields, setSelectedFields] = useState([]);
  const [roles, setRoles] = useState([]);
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [open, setOpen] = useState(true); // Popup dialog state
  const [step, setStep] = useState(0); // Track the current step/question

  const navigate = useNavigate();
  
  const API_URL = process.env.REACT_APP_API_URL;



  const fetchSectionsAndFields = async () => {
    try {
      // Fetch sections (subsections will be handled after sections are selected)
      const sectionsResponse = await axios.get(`${API_URL}/list_sections_and_subsections/`);

      setSections(sectionsResponse.data.sections);
      setLanguages(sectionsResponse.data.languages);

      // Fetch specialized fields
      const fieldsResponse = await axios.get(`${API_URL}/api/specialized_fields/`);
      setSpecializedFields(fieldsResponse.data);
    } catch (error) {
      console.error('Error fetching sections, subsections, and specialized fields', error);
    }
  };

  useEffect(() => {
    fetchSectionsAndFields();
  }, []);

  // Handle the section selection
  const handleSectionChange = (event) => {
    const { value } = event.target;
    setSelectedSections((prev) =>
      prev.includes(value) ? prev.filter((section) => section !== value) : [...prev, value]
    );
  };

  // Handle the subsection selection
  const handleSubsectionChange = (event) => {
    const { value } = event.target;
    setSelectedSubsections((prev) =>
      prev.includes(value) ? prev.filter((subsection) => subsection !== value) : [...prev, value]
    );
  };

  // Handle the language selection
  const handleLanguageChange = (event) => {
    const { value, checked } = event.target;
    setSelectedLanguages((prev) =>
      checked ? [...prev, value] : prev.filter((lang) => lang !== value)
    );
  };

  // Handle the selection of specialized fields
  const handleFieldChange = async (event) => {
    const { value } = event.target;
    setSelectedFields((prev) =>
      prev.includes(value) ? prev.filter((field) => field !== value) : [...prev, value]
    );

    // Fetch roles based on selected specialized fields
    try {
      const response = await axios.get(`${API_URL}/api/roles/`, {
        params: { specialized_field: selectedFields.concat(value) }
      });
      setRoles(response.data);
    } catch (error) {
      console.error('Error fetching roles:', error);
    }
  };

  // Handle the selection of roles
  const handleRoleChange = (event) => {
    const { value, checked } = event.target;
    setSelectedRoles((prev) =>
      checked ? [...prev, value] : prev.filter((role) => role !== value)
    );
  };

  const handleNext = () => {
    setStep(step + 1);
  };

  const handlePrevious = () => {
    setStep(step - 1);
  };

  const handleSubmit = async () => {
    const userPreferences = {
      user_id: userId,  // Include the user_id in the body
      selectedSections,
      selectedSubsections,
      selectedLanguages,
      selectedFields,
      selectedRoles,
    };

    try {
      const response = await axios.post(
        `${API_URL}/save_user_preferences/`,  // No need for userId in URL path
        userPreferences
      );
      console.log('User preferences saved:', response.data);
      // After successful submission, redirect to the platform page
      navigate(`/platform/${userId}`);
    } catch (error) {
      console.error('Error saving user preferences:', error);
    }
  };


  const handleSkip = () => {
    console.log('User skipped the preferences setup');
    // After successful submission, redirect to the platform page
    navigate(`/platform/${userId}`);
    // setOpen(false);
  };

  return (
    <Dialog open={open} onClose={() => setOpen(false)} maxWidth="sm" fullWidth sx={{ backgroundColor: "black" }}>
      <DialogTitle sx={{ backgroundColor: "black", color: "white" }} textAlign={'center'}>Select Your Preferences</DialogTitle>
      <DialogContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '2rem', backgroundColor: 'black' }}>
        {/* Step Indicator */}
        <Typography sx={{ textAlign: 'center', fontSize: '1.2rem', color: '#ddd', marginBottom: '2rem' }}>
          Step {step + 1} of 5
        </Typography>

        {/* Step 1: Select Sections */}
        {step === 0 && (
          <Box sx={{ width: '100%' }}>
            <Typography variant="h6" sx={{ fontSize: '1.5rem', textAlign: 'center', fontWeight: '500', marginBottom: '1rem', color: '#fff' }}>
              Select 3 Sections
            </Typography>
            <Grid container spacing={2}>
              {Object.keys(sections).map((section) => (
                <Grid item xs={12} sm={6} key={section}>
                  <Card sx={{ background: 'linear-gradient(135deg, rgba(29, 233, 182, 0.8), rgba(33, 150, 243, 0.8))', borderRadius: '10px', padding: '1rem', boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.3)', cursor: 'pointer', '&:hover': { transform: 'scale(1.05)', boxShadow: '0px 20px 40px rgba(0, 0, 0, 0.5)' } }}>
                    <FormGroup>
                      <FormControlLabel
                        control={<Checkbox value={section} onChange={handleSectionChange} />}
                        label={<Typography sx={{ fontSize: '1rem', color: '#fff' }}>{section}</Typography>}
                      />
                    </FormGroup>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Box>
        )}

        {/* Step 2: Select Subsections */}
        {step === 1 && (
          <Box sx={{ width: '100%' }}>
            <Typography variant="h6" sx={{ fontSize: '1.5rem', fontWeight: '500', textAlign: 'center', marginBottom: '1rem', color: '#fff' }}>
              Select 5 Subsections
            </Typography>
            <Grid container spacing={2}>
              {selectedSections.map((section) =>
                sections[section]?.subsections.map((subsection) => (
                  <Grid item xs={12} sm={6} key={subsection.id}>
                    <Card sx={{ background: 'linear-gradient(135deg, rgba(29, 233, 182, 0.8), rgba(33, 150, 243, 0.8))', borderRadius: '10px', padding: '1rem', boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.3)', cursor: 'pointer', '&:hover': { transform: 'scale(1.05)', boxShadow: '0px 20px 40px rgba(0, 0, 0, 0.5)' } }}>
                      <FormGroup>
                        <FormControlLabel
                          control={<Checkbox value={subsection.name} onChange={handleSubsectionChange} />}
                          label={<Typography sx={{ fontSize: '1rem', color: '#fff' }}>{subsection.name}</Typography>}
                        />
                      </FormGroup>
                    </Card>
                  </Grid>
                ))
              )}
            </Grid>
          </Box>
        )}

        {/* Step 3: Select Languages */}
        {step === 2 && (
          <Box sx={{ width: '100%' }}>
            <Typography variant="h6" sx={{ fontSize: '1.5rem', fontWeight: '500', textAlign: 'center', marginBottom: '1rem', color: '#fff' }}>
              Select Preferred Languages
            </Typography>
            <Grid container spacing={2}>
  {Languages.map((lang) => (
    <Grid item xs={12} sm={4} key={lang.id}>
      <Card sx={{ background: 'linear-gradient(135deg, rgba(29, 233, 182, 0.8), rgba(33, 150, 243, 0.8))', borderRadius: '10px', padding: '1rem', boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.3)', cursor: 'pointer', '&:hover': { transform: 'scale(1.05)', boxShadow: '0px 20px 40px rgba(0, 0, 0, 0.5)' } }}>
        <FormGroup>
          <FormControlLabel
            control={<Checkbox value={lang.id} onChange={handleLanguageChange} />}
            label={<Typography sx={{ fontSize: '1rem', color: '#fff' }}>{lang.name}</Typography>}
          />
        </FormGroup>
      </Card>
    </Grid>
  ))}
</Grid>

          </Box>
        )}

        {/* Step 4: Select Specialized Fields */}
        {step === 3 && (
          <Box sx={{ width: '100%' }}>
            <Typography variant="h6" sx={{ fontSize: '1.5rem', fontWeight: '500', textAlign: 'center', marginBottom: '1rem', color: '#fff' }}>
              Select Specialized Fields
            </Typography>
            <Grid container spacing={2}>
              {specializedFields.map((field) => (
                <Grid item xs={12} sm={6} key={field}>
                  <Card sx={{ background: 'linear-gradient(135deg, rgba(29, 233, 182, 0.8), rgba(33, 150, 243, 0.8))', borderRadius: '10px', padding: '1rem', boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.3)', cursor: 'pointer', '&:hover': { transform: 'scale(1.05)', boxShadow: '0px 20px 40px rgba(0, 0, 0, 0.5)' } }}>
                    <FormGroup>
                      <FormControlLabel
                        control={<Checkbox value={field} onChange={handleFieldChange} />}
                        label={<Typography sx={{ fontSize: '1rem', color: '#fff' }}>{field}</Typography>}
                      />
                    </FormGroup>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Box>
        )}

        {/* Step 5: Select Roles */}
        {step === 4 && (
          <Box sx={{ width: '100%' }}>
            <Typography variant="h6" sx={{ fontSize: '1.5rem', fontWeight: '500', textAlign: 'center', marginBottom: '1rem', color: '#fff' }}>
              Select Roles
            </Typography>
            <Grid container spacing={2}>
              {roles.map((role) => (
                <Grid item xs={12} sm={6} key={role.id}>
                  <Card sx={{ background: 'linear-gradient(135deg, rgba(29, 233, 182, 0.8), rgba(33, 150, 243, 0.8))', borderRadius: '10px', padding: '1rem', boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.3)', cursor: 'pointer', '&:hover': { transform: 'scale(1.05)', boxShadow: '0px 20px 40px rgba(0, 0, 0, 0.5)' } }}>
                    <FormGroup>
                      <FormControlLabel
                        control={<Checkbox value={role.name} onChange={handleRoleChange} />}
                        label={<Typography sx={{ fontSize: '1rem', color: '#fff' }}>{role.name}</Typography>}
                      />
                    </FormGroup>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Box>
        )}
      </DialogContent>
      <DialogActions sx={{ backgroundColor: "black" }}>
        {step > 0 && (
          <Button onClick={handlePrevious} color="primary">
            Previous
          </Button>
        )}
        {step < 4 ? (
          <Button onClick={handleNext} color="primary">
            Next
          </Button>
        ) : (
          <Button onClick={handleSubmit} color="primary">
            Submit
          </Button>
        )}
        <Button onClick={handleSkip} color="primary">
          Skip
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RecommendationSetup;
