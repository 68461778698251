import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import {
  Grid,
  Card,
  CardContent,
  Typography,
  CircularProgress,
  Alert,
  Box,
  Avatar,
  Divider,
} from '@mui/material';


const API_URL = process.env.REACT_APP_API_URL;




// Component to display recommended posts
const PostRecommendations = ({ postId, userId }) => {
  const [recommendedPosts, setRecommendedPosts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const k = 25; // Number of recommendations to fetch

  useEffect(() => {
    const fetchRecommendations = async () => {
      setLoading(true);
      setError(null);
      try {
        const response = await axios.get(
          `${API_URL}/recommendations/?post_id=${postId}&user_id=${userId}&k=${k}`
        );
        setRecommendedPosts(response.data);
        console.log(response.data)
      } catch (err) {
        setError('Failed to load recommendations');
      } finally {
        setLoading(false);
      }
    };

    if (postId && k > 0) {
      fetchRecommendations();
    }
  }, [postId, k]);

  // Displaying loading or error state
  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return <Alert severity="error" sx={{ mt: 2 }}>{error}</Alert>;
  }

  return (
    <div sx={{ backgroundColor: "black", mb:5 }}>
      <Typography variant="h1" gutterBottom textAlign={'center'}>
        Recommended Posts
      </Typography>
      <Grid container spacing={3} sx={{ pt: 3 }}>
        {recommendedPosts.length === 0 ? (
          <Typography variant="h6">No recommendations available</Typography>
        ) : (
          recommendedPosts.map((post) => (
            <Grid item xs={12} sm={6} md={4} key={post.id}>
              <Link to={`/post-tab-details/${post.id}/${userId}`} style={{ textDecoration: "none" }}>
              <Box sx={{
  transition: 'transform 0.3s ease, box-shadow 0.3s ease',
  backgroundColor: 'black',
  '&:hover': {
    transform: 'scale(1.05)',
    boxShadow: (theme) => theme.shadows[8],
  },
  m: 1,
  borderRadius: 2, // Add border radius for rounded corners similar to Card
}}>
  <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', p: 2 }}>
    <Box display="flex" flexDirection="column" mb={2}>
      <Typography sx={{
        backgroundColor: '#ffffff',
        color: 'black',
        p: 1,
        textAlign: 'center',
        borderRadius: 1,
        fontWeight: 700,
        mb: 2,
      }}>
        {post.section_name} - {post.subsection_name}
      </Typography>
      <Typography sx={{
        fontWeight: 600,
        textAlign: 'center',
        fontSize: '1.2rem',
        color: 'white',
        mb: 1,
      }}>
        {post.topic_name}
      </Typography>
      <Typography sx={{
        fontSize: '1rem',
        textAlign: 'center',
        color: 'white',
        mb: 2,
      }}>
        {post.description}
      </Typography>
    </Box>
    <Box display="flex" alignItems="center" mb={2}>
      {/* Show user image and name */}
      <Avatar sx={{
        width: 50,
        height: 50,
        mr: 2,
      }} src={`${API_URL}${post.image}` || '/default-avatar.png'} alt={post.name || 'User'} />
      <Box>
        {/* Display user's full name */}
        <Typography variant="body2" fontWeight="bold" color="white">
          {post.name} {post.surname}
        </Typography>
        <Typography sx={{
          fontSize: '0.875rem',
          fontWeight: 500,
          color: 'white',
        }}>
          {post.language} | {post.views} Views
        </Typography>
      </Box>
    </Box>
    <Divider sx={{ my: 2, borderColor: 'whitesmoke' }} />
    <Box display="flex" justifyContent="space-between">
      <Typography variant="body2" color="white">
        {post.duration} minutes | {`\u20B9`} {post.price}
      </Typography>
      <Typography variant="body2" color="white">
        Likes: {post.likes} | Dislikes: {post.dislikes}
      </Typography>
    </Box>
  </Box>
</Box>

              </Link>
            </Grid>
          ))
        )}
      </Grid>
    </div>
  );
};

export default PostRecommendations;
